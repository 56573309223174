import {defineMessages, MessageDescriptor} from 'react-intl';
import {Mapper} from '@automapper/core';
import {inject, injectable} from 'inversify';
import {Epic} from 'redux-observable';
import {Observable, of} from 'rxjs';
import {catchError, filter} from 'rxjs/operators';
import {isActionOf, PayloadAction} from 'typesafe-actions';
import {v4 as uuid} from 'uuid';

import {fileActions} from '@file/actions';
import {CsvDocumentBuilderAdapter} from '@file/services/CsvDocumentBuilderAdapter';
import {ServiceTypes} from '@inversify';
import {
    ManualTransaction,
    ManualTransactionInput,
    MutationAddManualTransactionArgs,
    MutationClaimRevenueShareArgs,
    RevenueShareClaimResult,
    Transaction,
    UserProfile,
} from '@models/generated/graphql';
import {TransactionDownloadModel, TransactionViewModelKeys} from '@models/transaction';
import {UserProfileViewModelKeys} from '@models/user-profile';
import {UserProfileDownloadModel} from '@models/user-profile/types';
import {map, mergeMap} from '@otel';
import {BaseEpicsBuilder, RootEpic} from '@redux';
import {
    BaseFilterKeys,
    entityActions,
    EntityFetchRequestPayload,
    EntityFetchServiceResponsePayload,
    EntityType,
    PlayerReferrerSummaryNormalized,
    TransactionNormalized,
    UserProfileQueryFields,
} from '@redux/entity';
import {UserProfileService} from '@services';
import {ITransactionService} from '@services/transactionService';
import {
    CustomErrorCodes,
    GqlMutationRequest,
    maxPageSize,
    RestRequest,
    ServerResponseStatus,
    ServiceResponsePayload,
} from '@services/types';
import {getFilterString} from '@utils';

import {localizedTransactionStatus} from '../app/intl/shared-resources/transactionStatus';
import {UserProfileFilterKey} from '../block-user-profile-list/types';
import {showErrorAction, showMessageAction} from '../message-snack-bar/actions';

import {
    AddManualTransactionRequestPayload,
    ClaimRevenueShareRequestPayload,
    OpenCashierPayload,
    SetWithdrawalStatusRequestPayload,
    transactionActions,
} from './actions';
import {ManualTransactionAddViewModel} from './types';

export const transactionActionsEpicsLocalized = defineMessages({
    setWithdrawalStatusSuccess: {
        id: 'TransactionActionsEpicsBuilder_setWithdrawalStatusSuccess',
        defaultMessage: 'Withdrawal status changed to {status}',
    },
    setWithdrawalStatusFailed: {
        id: 'TransactionActionsEpicsBuilder_setWithdrawalStatusFailed',
        defaultMessage: 'Failed to change withdrawal status',
    },
    openCashierFailed: {
        id: 'TransactionActionsEpicsBuilder_openCashierFailed',
        defaultMessage: 'Failed to open cashier',
    },
    totalCountErrorMessage: {
        id: 'TransactionActionsEpicsBuilder_totalCountErrorMessage',
        defaultMessage: 'Total count of downloaded items cannot be more than {maxPageSize}',
    },
    downloadFailure: {
        id: 'TransactionActionsEpicsBuilder_failureMessage',
        defaultMessage: 'Failed to download Agent Report',
    },
    redeemRevenueShareSuccess: {
        id: 'TransactionActionsEpicsBuilder_redeemRevenueShareSuccess',
        defaultMessage: 'Redeem successfully completed',
    },
    redeemRevenueShareFailed: {
        id: 'TransactionActionsEpicsBuilder_redeemRevenueShareFailed',
        defaultMessage: 'Failed to redeem',
    },
    addManualTransactionSuccess: {
        id: 'TransactionActionsEpicsBuilder_addManualTransactionSuccess',
        defaultMessage: 'Manual transaction successfully created',
    },
    addManualTransactionFailed: {
        id: 'TransactionActionsEpicsBuilder_addManualTransactionFailed',
        defaultMessage: 'Failed to create manual transaction',
    },
    addManualTransactionFailedWithTraceId: {
        id: 'TransactionActionsEpicsBuilder_addManualTransactionFailedWithTraceId',
        defaultMessage: 'Failed to create manual transaction{br}traceId: {traceId}',
    },
    addManualTransactionFailedNotEnoughFunds: {
        id: 'TransactionActionsEpicsBuilder_addManualTransactionFailedNotEnoughFunds',
        defaultMessage: "Player balance don't have enough funds",
    },
    creditRemainingAmountExceeded: {
        id: 'TransactionActionsEpicsBuilder_creditRemainingAmountExceeded',
        defaultMessage: 'Exceed the daily allowed manual credit limits',
    },
    ongoingTransacitons: {
        id: 'TransactionActionsEpicsBuilder_ongoingTransacitons',
        defaultMessage: 'Other manual transaction is ongoing. Please wait for a while and re-try',
    },
});

@injectable()
export class TransactionActionsEpicsBuilder extends BaseEpicsBuilder {
    private readonly _service: ITransactionService;
    private readonly _fileFactory: CsvDocumentBuilderAdapter<string>;
    private readonly _mapper: Mapper;
    private _manualTransactionId: string;
    private _userProfileService: UserProfileService;

    constructor(
        @inject(ServiceTypes.TransactionService) service: ITransactionService,
        @inject(ServiceTypes.CsvFileAdapter) fileFactory: CsvDocumentBuilderAdapter<string>,
        @inject(ServiceTypes.AutoMapper) mapper: Mapper,
        @inject(ServiceTypes.UserProfileService) userProfile: UserProfileService
    ) {
        super();
        this._service = service;
        this._fileFactory = fileFactory;
        this._mapper = mapper;
        this._userProfileService = userProfile;
    }

    protected buildEpicList(): RootEpic[] {
        return [
            this.buildSetWithdrawalRiskStatusRequestEpic(),
            this.buildSetWithdrawalPaymentStatusRequestEpic(),
            this.buildSetWithdrawalStatusSuccessEpic(),
            this.buildSetWithdrawalStatusFailureEpic(),
            this.buildOpenCashierRequestEpic(),
            this.buildOpenCashierSuccessEpic(),
            this.buildOpenCashierFailureEpic(),
            this.buildTransactionDownloadEpic(),
            this.buildTransactionDownloadSuccessEpic(),
            this.buildTransactionDownloadFailureEpic(),
            this.buildRedeemRevenueShareRequestEpic(),
            this.buildRedeemRevenueShareSuccessEpic(),
            this.buildRedeemRevenueShareFailureEpic(),
            this.buildAddManualTransactionRequestEpic(),
            this.buildAddManualTransactionSuccessEpic(),
            this.buildAddManualTransactionFailureEpic(),
        ];
    }

    private buildSetWithdrawalRiskStatusRequestEpic(): RootEpic {
        return this.buildRequestEpic<SetWithdrawalStatusRequestPayload, RestRequest, unknown>(
            transactionActions.setWithdrawalRiskStatus,
            payload => this._service.setWithdrawalRiskStatus(payload)
        );
    }

    private buildSetWithdrawalPaymentStatusRequestEpic(): RootEpic {
        return this.buildRequestEpic<SetWithdrawalStatusRequestPayload, RestRequest, unknown>(
            transactionActions.setWithdrawalPaymentStatus,
            payload => this._service.setWithdrawalPaymentStatus(payload)
        );
    }

    private buildSetWithdrawalStatusSuccessEpic(): RootEpic {
        return action$ =>
            action$.pipe(
                filter(
                    isActionOf([transactionActions.setWithdrawalRiskStatus.success, transactionActions.setWithdrawalPaymentStatus.success])
                ),
                mergeMap(res => {
                    const requestBody = res?.payload?.requestPayload?.body as SetWithdrawalStatusRequestPayload;
                    const updatedItem: Partial<TransactionNormalized> = {transaction_status: requestBody.status};

                    return of(
                        showMessageAction({
                            message: transactionActionsEpicsLocalized.setWithdrawalStatusSuccess,
                            values: requestBody?.status ? {status: localizedTransactionStatus[requestBody.status].defaultMessage} : null,
                        }),
                        entityActions.updateItem({type: EntityType.Transaction, id: requestBody.transactionId, updatedItem})
                    );
                })
            );
    }

    private buildSetWithdrawalStatusFailureEpic(): RootEpic {
        return action$ =>
            action$.pipe(
                filter(
                    isActionOf([transactionActions.setWithdrawalRiskStatus.failure, transactionActions.setWithdrawalPaymentStatus.failure])
                ),
                map(() => showErrorAction({message: transactionActionsEpicsLocalized.setWithdrawalStatusFailed}))
            );
    }

    private buildOpenCashierRequestEpic(): RootEpic {
        return this.buildRequestEpic<OpenCashierPayload, RestRequest, string>(transactionActions.openCashier, payload =>
            this._service.getCashierLink(payload.transactionId)
        );
    }

    private buildOpenCashierSuccessEpic(): RootEpic {
        return action$ =>
            action$.pipe(
                filter(isActionOf(transactionActions.openCashier.success)),
                mergeMap(res => {
                    window.open(res.payload.responsePayload, '_blank');
                    return of();
                })
            );
    }

    private buildOpenCashierFailureEpic(): RootEpic {
        return action$ =>
            action$.pipe(
                filter(isActionOf(transactionActions.openCashier.failure)),
                map(() => showErrorAction({message: transactionActionsEpicsLocalized.openCashierFailed}))
            );
    }

    private buildTransactionDownloadEpic(): Epic {
        return actions$ =>
            actions$.pipe(
                filter(isActionOf(transactionActions.transactionDownloadCsv.request)),
                mergeMap(action => {
                    const {filter, fields, filename, worksheetKeys, userWorksheetKeys, headers} = action.payload;
                    const fetchPayload: EntityFetchRequestPayload = {
                        type: EntityType.Transaction,
                        filter,
                        fields,
                    };
                    const failureObservable = of(
                        transactionActions.transactionDownloadCsv.failure({
                            message: transactionActionsEpicsLocalized.downloadFailure,
                        })
                    );

                    return this._service.get(fetchPayload).pipe(
                        mergeMap(response => {
                            let result: Observable<unknown> = this.getFailedMessage(response);
                            if (result === null) {
                                const items: Transaction[] = response?.responsePayload?.items as Transaction[];
                                const uids: string[] = items?.map(i => i?.uid)?.filter(i => i);
                                const userServiceRequestPayload = this.getUserProfileFetchPayload(userWorksheetKeys, uids);
                                if (userWorksheetKeys?.length && uids?.length) {
                                    result = this._userProfileService.get(userServiceRequestPayload).pipe(
                                        mergeMap(userResponse => {
                                            let userResult: Observable<unknown> = this.getFailedMessage(userResponse);
                                            if (userResult === null) {
                                                const data = this.getWorksheetData(
                                                    items,
                                                    userResponse?.responsePayload?.items as UserProfile[]
                                                );
                                                userResult = this.downloadFile(
                                                    [...worksheetKeys, ...userWorksheetKeys],
                                                    data,
                                                    headers,
                                                    filename
                                                );
                                            }
                                            return userResult;
                                        })
                                    );
                                } else {
                                    const worksheetData = this.getWorksheetData(items);
                                    result = this.downloadFile(worksheetKeys, worksheetData, headers, filename);
                                }
                            }

                            return result;
                        }),
                        catchError(() => failureObservable)
                    );
                })
            );
    }

    private getFailedMessage(response: EntityFetchServiceResponsePayload): Observable<PayloadAction<string, unknown>> {
        let result: Observable<PayloadAction<string, unknown>> = null;
        if (response?.status !== ServerResponseStatus.Success) {
            result = of(
                transactionActions.transactionDownloadCsv.failure({
                    message: transactionActionsEpicsLocalized.downloadFailure,
                })
            );
        } else if (response?.responsePayload?.total > maxPageSize) {
            result = of(
                transactionActions.transactionDownloadCsv.failure({
                    message: transactionActionsEpicsLocalized.totalCountErrorMessage,
                    values: {maxPageSize: `${maxPageSize}`},
                })
            );
        }
        return result;
    }

    private getUserProfileFetchPayload(
        userWorksheetKeys: UserProfileViewModelKeys[],
        uids: string[]
    ): EntityFetchRequestPayload<UserProfileQueryFields> {
        const userQueryFields = this._mapper.map<UserProfileViewModelKeys[], UserProfileQueryFields[]>(
            userWorksheetKeys,
            nameof<UserProfileViewModelKeys>(),
            nameof<UserProfileQueryFields>()
        );
        const uidKey: UserProfileFilterKey = 'uid';
        const pageFilterKey: BaseFilterKeys = 'page';
        const sizeFilterKey: BaseFilterKeys = 'size';
        return {
            type: EntityType.UserProfile,
            filter: getFilterString(
                '',
                true,
                {key: uidKey, value: [...new Set(uids)].join('|')},
                {key: pageFilterKey, value: '1'},
                {key: sizeFilterKey, value: `${maxPageSize}`}
            ),
            fields: userQueryFields,
        };
    }

    private getWorksheetData(transactions: Transaction[], users: UserProfile[] = []) {
        return transactions?.map(i => {
            const user = users?.length
                ? this._mapper.map(
                      users.find(u => u.uid === i.uid),
                      UserProfile,
                      UserProfileDownloadModel
                  )
                : {};
            const downloadModel = this._mapper.map(i, Transaction, TransactionDownloadModel);
            return {
                ...user,
                ...downloadModel,
            };
        });
    }

    private downloadFile(
        keys: (TransactionViewModelKeys | UserProfileViewModelKeys)[],
        data: Partial<Record<TransactionViewModelKeys | UserProfileViewModelKeys, string>>[],
        headers: Partial<Record<TransactionViewModelKeys | UserProfileViewModelKeys, string>>,
        filename: string
    ): Observable<unknown> {
        return this._fileFactory.createFile(data, keys, headers).pipe(
            map(arrayBuffer => transactionActions.transactionDownloadCsv.success({arrayBuffer, filename})),
            catchError(() =>
                of(
                    transactionActions.transactionDownloadCsv.failure({
                        message: transactionActionsEpicsLocalized.downloadFailure,
                    })
                )
            )
        );
    }

    private buildTransactionDownloadSuccessEpic(): Epic {
        return actions$ =>
            actions$.pipe(
                filter(isActionOf(transactionActions.transactionDownloadCsv.success)),
                map(action => {
                    const {filename, arrayBuffer} = action.payload;
                    return fileActions.download({
                        file: new Blob([arrayBuffer]),
                        title: filename,
                        type: 'csv',
                    });
                })
            );
    }

    private buildTransactionDownloadFailureEpic(): Epic {
        return action$ =>
            action$.pipe(
                filter(isActionOf(transactionActions.transactionDownloadCsv.failure)),
                map(action => showErrorAction(action.payload))
            );
    }

    private buildRedeemRevenueShareRequestEpic(): Epic {
        return this.buildRequestEpic<
            ClaimRevenueShareRequestPayload,
            GqlMutationRequest<MutationClaimRevenueShareArgs>,
            RevenueShareClaimResult
        >(transactionActions.claimRevenueShare, ({amount, uid}) => this._service.claimRevenueShare({uid, claim: {amount}}));
    }

    private buildRedeemRevenueShareSuccessEpic(): Epic {
        return actions$ =>
            actions$.pipe(
                filter(isActionOf(transactionActions.claimRevenueShare.success)),
                mergeMap(
                    (
                        res: PayloadAction<
                            string,
                            ServiceResponsePayload<GqlMutationRequest<MutationClaimRevenueShareArgs>, RevenueShareClaimResult>
                        >
                    ) => {
                        const {
                            variables: {uid},
                        } = res.payload.requestPayload;
                        const updatedItem: Partial<PlayerReferrerSummaryNormalized> = {redeem_pending_total: 0};
                        return of(
                            showMessageAction({message: transactionActionsEpicsLocalized.redeemRevenueShareSuccess}),
                            entityActions.updateItem({type: EntityType.PlayerReferrerSummary, id: uid, updatedItem})
                        );
                    }
                )
            );
    }

    private buildRedeemRevenueShareFailureEpic(): Epic {
        return action$ =>
            action$.pipe(
                filter(isActionOf(transactionActions.claimRevenueShare.failure)),
                map(() => showErrorAction({message: transactionActionsEpicsLocalized.redeemRevenueShareFailed}))
            );
    }

    private buildAddManualTransactionRequestEpic(): Epic {
        return this.buildRequestEpic<
            AddManualTransactionRequestPayload,
            GqlMutationRequest<MutationAddManualTransactionArgs>,
            ManualTransaction
        >(transactionActions.addManualTransaction, payload => {
            const transactionInput = this._mapper.map(payload, ManualTransactionAddViewModel, ManualTransactionInput);
            if (!this._manualTransactionId) {
                this._manualTransactionId = uuid();
            }
            transactionInput.id = this._manualTransactionId;
            return this._service.addManualTransaction(transactionInput);
        });
    }

    private buildAddManualTransactionSuccessEpic(): Epic {
        return action$ =>
            action$.pipe(
                filter(isActionOf(transactionActions.addManualTransaction.success)),
                map(() => {
                    this._manualTransactionId = null;
                    return showMessageAction({message: transactionActionsEpicsLocalized.addManualTransactionSuccess});
                })
            );
    }

    private buildAddManualTransactionFailureEpic(): Epic {
        return action$ =>
            action$.pipe(
                filter(isActionOf(transactionActions.addManualTransaction.failure)),
                map(
                    (
                        res: PayloadAction<
                            string,
                            ServiceResponsePayload<GqlMutationRequest<MutationAddManualTransactionArgs>, ManualTransaction>
                        >
                    ) => {
                        const error = this.getManualTransactionError(res.payload);
                        let errorActionPayload;
                        const errorMapping: Record<string, MessageDescriptor> = {
                            UserLimitExceeded: transactionActionsEpicsLocalized.creditRemainingAmountExceeded,
                            OngoingCreditTransaction: transactionActionsEpicsLocalized.ongoingTransacitons,
                        };
                        if (error?.traceId) {
                            errorActionPayload = {
                                message: transactionActionsEpicsLocalized.addManualTransactionFailedWithTraceId,
                                values: {traceId: error.traceId},
                            };
                        } else if (error?.code === CustomErrorCodes.NotEnoughFunds) {
                            errorActionPayload = {message: transactionActionsEpicsLocalized.addManualTransactionFailedNotEnoughFunds};
                        } else if (errorMapping[res.payload?.errors?.[0]?.code]) {
                            errorActionPayload = {message: errorMapping[res.payload?.errors?.[0]?.code]};
                        } else {
                            errorActionPayload = {message: transactionActionsEpicsLocalized.addManualTransactionFailed};
                        }
                        return showErrorAction(errorActionPayload);
                    }
                )
            );
    }

    private getManualTransactionError(
        response: ServiceResponsePayload<GqlMutationRequest<MutationAddManualTransactionArgs>, ManualTransaction>
    ) {
        const errorMessage = response.errors?.[0]?.message;
        const errorMessagePayload = errorMessage
            .replace("API status code 500 return error - b'", '')
            .replace("}'", '}')
            .replace("\\'", "'");

        let resultError: ManualTransactionError;
        try {
            resultError = JSON.parse(errorMessagePayload);
        } catch {
            resultError = null;
        }

        return resultError;
    }
}

type ManualTransactionError = {
    code?: number;
    traceId: string;
};
