import {createAsyncAction} from 'typesafe-actions';

import {MutationUpdateUserArgs, UserProfile, UserProfileInput} from '@models/generated/graphql';
import {GqlMutationRequest, ServiceResponsePayload} from '@services/types';

const domain = 'user-profile-personal-info';

export type EditEmailRequestModel = {
    uid: string;
    email: string;
};

export type EditPhoneRequestModel = {
    uid: string;
    area: string;
    mobile: string;
};

export type EditPersonalInfoRequestPayload = {
    user: Pick<UserProfileInput, 'uid' | 'contact' | 'first_name' | 'last_name' | 'nickname' | 'birthday' | 'gender'>;
    userId: string;
};

export const personalInfoActions = {
    editEmail: createAsyncAction(`${domain}/editEmailRequest`, `${domain}/editEmailSuccess`, `${domain}/editEmailFailure`)<
        EditPersonalInfoRequestPayload,
        ServiceResponsePayload<EditEmailRequestModel, null>,
        ServiceResponsePayload<EditEmailRequestModel, null>
    >(),

    editPhone: createAsyncAction(`${domain}/editPhoneRequest`, `${domain}/editPhoneSuccess`, `${domain}/editPhoneFailure`)<
        EditPersonalInfoRequestPayload,
        ServiceResponsePayload<EditPhoneRequestModel, null>,
        ServiceResponsePayload<EditPhoneRequestModel, null>
    >(),

    editFirstName: createAsyncAction(`${domain}/editFirstNameRequest`, `${domain}/editFirstNameSuccess`, `${domain}/editFirstNameFailure`)<
        EditPersonalInfoRequestPayload,
        ServiceResponsePayload<GqlMutationRequest<MutationUpdateUserArgs>, UserProfile>,
        ServiceResponsePayload<GqlMutationRequest<MutationUpdateUserArgs>, UserProfile>
    >(),

    editLastName: createAsyncAction(`${domain}/editLastNameRequest`, `${domain}/editLastNameSuccess`, `${domain}/editLastNameFailure`)<
        EditPersonalInfoRequestPayload,
        ServiceResponsePayload<GqlMutationRequest<MutationUpdateUserArgs>, UserProfile>,
        ServiceResponsePayload<GqlMutationRequest<MutationUpdateUserArgs>, UserProfile>
    >(),

    editNickname: createAsyncAction(`${domain}/editNicknameRequest`, `${domain}/editNicknameSuccess`, `${domain}/editNicknameFailure`)<
        EditPersonalInfoRequestPayload,
        ServiceResponsePayload<GqlMutationRequest<MutationUpdateUserArgs>, UserProfile>,
        ServiceResponsePayload<GqlMutationRequest<MutationUpdateUserArgs>, UserProfile>
    >(),

    editDOB: createAsyncAction(`${domain}/editDOBRequest`, `${domain}/editDOBSuccess`, `${domain}/editDOBFailure`)<
        EditPersonalInfoRequestPayload,
        ServiceResponsePayload<GqlMutationRequest<MutationUpdateUserArgs>, UserProfile>,
        ServiceResponsePayload<GqlMutationRequest<MutationUpdateUserArgs>, UserProfile>
    >(),

    editGender: createAsyncAction(`${domain}/editGenderRequest`, `${domain}/editGenderSuccess`, `${domain}/editGenderFailure`)<
        EditPersonalInfoRequestPayload,
        ServiceResponsePayload<GqlMutationRequest<MutationUpdateUserArgs>, UserProfile>,
        ServiceResponsePayload<GqlMutationRequest<MutationUpdateUserArgs>, UserProfile>
    >(),
};
