import {AccountVerificationViewModel, AccountVerificationViewModelKeys} from '@models/account-verification';
import {AccountVerificationStatus, UserProfile} from '@models/generated/graphql';
import {UserProfileViewModelKeys} from '@models/user-profile';
import {BaseSettingsType} from '@user-settings';
import {AccountVerificationFilterKeys} from '@redux/entity';

import {Filter} from '../../common/types';
import {RecordWithPageViewersKeys} from '../block-page-view';

export type AccountVerificationWithUserProfileGridItem = AccountVerificationViewModel & Omit<UserProfile, '__typename'>;

export type DataGridAccountVerificationWithUserProfileColumns =
    | UserProfileViewModelKeys
    | AccountVerificationViewModelKeys
    | RecordWithPageViewersKeys;

const closedCasesStatuses = [
    AccountVerificationStatus.Approved,
    AccountVerificationStatus.DeniedDepositKyc,
    AccountVerificationStatus.DeniedLoginKyc,
    AccountVerificationStatus.DeniedWithdrawalKyc,
    AccountVerificationStatus.DeniedPaymentKyc,
];

export const closedCasesDefaultFilter: Filter<AccountVerificationStatus[], AccountVerificationFilterKeys>[] = [
    {
        key: 'accountVerificationStatus',
        value: closedCasesStatuses,
    },
    {
        key: 'defaultAccountVerificationStatus',
        value: closedCasesStatuses,
    },
];

const pendingCasesStatuses = [
    AccountVerificationStatus.InitDepositKyc,
    AccountVerificationStatus.InitWithdrawalKyc,
    AccountVerificationStatus.InitLoginKyc,
    AccountVerificationStatus.InitPaymentKyc,
    AccountVerificationStatus.OnHoldDepositKyc,
    AccountVerificationStatus.OnHoldLoginKyc,
    AccountVerificationStatus.OnHoldWithdrawalKyc,
    AccountVerificationStatus.OnHoldPaymentKyc,
];

export const pendingCasesDefaultFilter: Filter<AccountVerificationStatus[], AccountVerificationFilterKeys>[] = [
    {
        key: 'accountVerificationStatus',
        value: pendingCasesStatuses,
    },
    {
        key: 'defaultAccountVerificationStatus',
        value: pendingCasesStatuses,
    },
];

export interface AccountVerificationWithUserProfileColumnSettings extends BaseSettingsType {
    id: {};
    uid: {};
    email: {};
    initiated_at: {};
    duration: {};
    account_verification_status: {};
    type: {};
    documents: {};
    username: {};
    reason_codes: {};
    platform: {};
    labels: {};
    'documents_object.id_card.uploaded_ts': {};
    playerCountry: {};
    page_viewers: {};
    assignee: {};
}

export type AccountVerificationWithUserProfileColumnSettingsKeys = keyof AccountVerificationWithUserProfileColumnSettings;
