import React from 'react';
import {defineMessages} from 'react-intl';
import {makeStyles} from 'tss-react/mui';

import {FilterProps, SelectFilter} from '@components/filter';
import {TransactionType} from '@models/generated/graphql';
import {TransactionFilterType} from '@models/transaction/types';
import {removeItemsFromArray} from '@utils/array';

import {localizedTransactionFilterType} from 'src/features/app/intl/shared-resources/transactionType';
import {SelectOption} from '../../module-shared/types';

const localized = defineMessages({
    filterType: {
        id: 'TransactionFilterType_filterType',
        defaultMessage: 'Type',
    },
    wagerComponents: {
        id: 'TransactionFilterType_wagerComponents',
        defaultMessage: 'Wager Components',
    },
});

const availableTransactionTypes: TransactionType[] = [
    TransactionType.Bonus,
    TransactionType.BuyIn,
    TransactionType.BuyOut,
    TransactionType.Credit,
    TransactionType.Debit,
    TransactionType.Deposit,
    TransactionType.RevenueShare,
    TransactionType.Withdrawal,
    TransactionType.MttDebit,
    TransactionType.MttCredit,
    TransactionType.PokerAddonCredit,
    TransactionType.PokerAddonDebit,
    TransactionType.PokerFlipsPayout,
    TransactionType.PokerFlipsWager,
    TransactionType.CasinoWager,
    TransactionType.CasinoPayout,
    TransactionType.CasinoFreeSpinWager,
    TransactionType.CasinoFreeSpinPayout,
    TransactionType.CasinoWagerRefund,
    TransactionType.CasinoPayoutRefund,
    TransactionType.CasinoFreeSpinWagerRefund,
    TransactionType.CasinoFreeSpinPayoutRefund,
    TransactionType.P2PTransferCredit,
    TransactionType.P2PTransferDebit,
    TransactionType.Chargeback,
    TransactionType.Refund,
    TransactionType.Reverse,
    TransactionType.LiveEventBuyIn,
    TransactionType.SportsBuyin,
    TransactionType.SportsBuyout,
    TransactionType.SportsBetSettlement,
    TransactionType.Payin,
    TransactionType.Payout,
    TransactionType.PayoutReturn,
];

export const availableTransactionFilterOptions: TransactionFilterType[] = [...availableTransactionTypes, 'FEES'];

export const availableTransactionFilterValues: TransactionFilterType[] = [
    ...availableTransactionTypes,
    TransactionType.Fee,
    TransactionType.FeeReverse,
];

const wagerGroupOptionValue: TransactionFilterType[] = [
    TransactionType.BuyIn,
    TransactionType.MttDebit,
    TransactionType.PokerAddonDebit,
    TransactionType.PokerFlipsWager,
    TransactionType.CasinoWager,
    TransactionType.CasinoFreeSpinWager,
    TransactionType.CasinoFreeSpinWagerRefund,
    TransactionType.CasinoWagerRefund,
    TransactionType.LiveEventBuyIn,
    TransactionType.SportsBuyin,
];

const useClasses = makeStyles()(() => ({
    popover: {
        minWidth: '350px !important',
    },
}));

function TransactionTypeFilter({value, onChange}: FilterProps<string[]>) {
    const {classes} = useClasses();
    const feesFilterKey: TransactionFilterType = 'FEES';
    const feesValues: TransactionFilterType[] = [TransactionType.Fee, TransactionType.FeeReverse];

    const filterValue: string[] = value?.some(i => feesValues?.includes(i as TransactionType))
        ? [...removeItemsFromArray(value, ...feesValues), feesFilterKey]
        : value;

    function handleFilterChange(newValue: string[]) {
        let result = [...(newValue ?? [])];
        if (result?.includes(feesFilterKey)) {
            result = [...removeItemsFromArray(result, feesFilterKey), TransactionType.Fee, TransactionType.FeeReverse];
        }
        onChange(result);
    }

    function getOptions(): SelectOption[] {
        return [
            {
                label: localized.wagerComponents,
                value: wagerGroupOptionValue,
                subOptions: wagerGroupOptionValue.map(i => ({
                    value: i,
                    label: localizedTransactionFilterType[i],
                })),
                autoSelectOnExpand: true,
            },
            ...availableTransactionFilterOptions.map(t => ({
                label: localizedTransactionFilterType[t],
                value: t,
            })),
        ];
    }

    return (
        <SelectFilter
            multiple
            value={filterValue}
            onChange={handleFilterChange}
            label={localized.filterType}
            chipType={nameof<TransactionFilterType>()}
            options={getOptions()}
            popoverClassName={classes.popover}
            virtualization
        />
    );
}
export default TransactionTypeFilter;
