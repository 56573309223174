import {inject, injectable} from 'inversify';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {ServiceTypes} from '@inversify';
import {ReasonCode, TransactionType, UserProfile} from '@models/generated/graphql';
import {EntityFetchRequestPayload, EntityType, Filter, UserProfileQueryFields, UserProfileServerFilterKeys} from '@redux/entity';
import {mapViewFilterToString} from '@redux/view';
import {IEntityReadService} from '@services/entity';

import {ValidationResultError} from 'src/features/block-bulk-actions/types';
import {debitReasonCodes, singleCreditTransactionMaxAmount} from 'src/features/block-transaction-actions';
import {IBulkStrategy, ValidationResult, ValidationStrategyResponse} from '../../block-bulk-actions';
import {creditReasonCodes} from '../../block-transaction-actions';
import {BulkDebitCreditAddModel, bulkTransactionsMaxTemplateLine, P2PTransferModel, ValidationCode} from '../types';

export type ValidationManualTransactionRequest = {
    transactions: BulkDebitCreditAddModel[];
    type: TransactionType;
    remainingCreditAmount?: number;
};

@injectable()
export class ValidationManualTransactionStrategy implements IBulkStrategy<ValidationManualTransactionRequest, ValidationStrategyResponse> {
    private _userProfileService: IEntityReadService;
    constructor(@inject(ServiceTypes.UserProfileService) userProfileService: IEntityReadService) {
        this._userProfileService = userProfileService;
    }

    process({transactions, type, remainingCreditAmount = 0}: ValidationManualTransactionRequest): Observable<ValidationStrategyResponse> {
        return this._userProfileService.get(this.getParsedUsersPayload(transactions)).pipe(
            map(r => r.responsePayload?.items as UserProfile[]),
            map(users => {
                let remainingTotalCreditAmount = remainingCreditAmount;
                const validationResults: ValidationResult[] = transactions.map(item => {
                    const codes: ValidationResultError[] = [
                        this.validateUid(item, users),
                        this.validateAmountIsNumber(item),
                        this.validateAmountIsPositive(item),
                        this.validateAmountNumberPrecision(item),
                        this.validateTransactionType(item, type),
                        this.validateReason(item, type),
                        this.validateLineIndex(item),
                        this.validateMaxAmount(item, type, singleCreditTransactionMaxAmount),
                        this.validateTotalAmount(item, type, remainingTotalCreditAmount),
                    ].filter(c => c);
                    remainingTotalCreditAmount -= item.amount;
                    return {id: item.rowIndex, item, errors: codes};
                });

                return validationResults;
            })
        );
    }

    private validateUid(item: BulkDebitCreditAddModel, users: UserProfile[]): ValidationResultError {
        const uids = users.map(u => u.uid);
        return !item.uid || !uids.includes(item.uid) ? {errorCode: ValidationCode.UidNotExists} : null;
    }

    private validateAmountIsNumber(item: BulkDebitCreditAddModel): ValidationResultError {
        return item.amount === null || Number.isNaN(Number(item.amount)) ? {errorCode: ValidationCode.AmountNotNumber} : null;
    }

    private validateAmountNumberPrecision(item: BulkDebitCreditAddModel): ValidationResultError {
        const availableLengthOfDecimalPart = 2;
        const decimalPart: string = !Number.isNaN(Number(item.amount)) && item.amount?.toString()?.split('.')?.[1];
        return item.amount !== null && decimalPart !== undefined && decimalPart?.length > availableLengthOfDecimalPart
            ? {errorCode: ValidationCode.AmountDecimalPartLengthIsNotValid}
            : null;
    }

    private validateAmountIsPositive(item: BulkDebitCreditAddModel): ValidationResultError {
        return item.amount !== null && Number(item?.amount) <= 0 ? {errorCode: ValidationCode.AmountNotPositive} : null;
    }

    private validateTransactionType(item: BulkDebitCreditAddModel, type: TransactionType): ValidationResultError {
        return item.transaction_type !== type ? {errorCode: ValidationCode.TypeInvalid} : null;
    }

    private validateReason(item: BulkDebitCreditAddModel, type: TransactionType): ValidationResultError {
        const mapper: Partial<Record<TransactionType, ReasonCode[]>> = {
            [TransactionType.Debit]: debitReasonCodes,
            [TransactionType.Credit]: creditReasonCodes,
        };
        return !mapper[type].includes(item?.reason) ? {errorCode: ValidationCode.ReasonInvalid} : null;
    }

    private validateLineIndex(item: BulkDebitCreditAddModel): ValidationResultError {
        return item.rowIndex > bulkTransactionsMaxTemplateLine ? {errorCode: ValidationCode.LineIsTooLarge} : null;
    }

    private validateMaxAmount(item: BulkDebitCreditAddModel, type: TransactionType, max: number): ValidationResultError {
        return (type === TransactionType.Credit && item.amount <= max) || type === TransactionType.Debit
            ? null
            : {errorCode: ValidationCode.AmountIsTooLarge, data: max};
    }

    private validateTotalAmount(item: BulkDebitCreditAddModel, type: TransactionType, remainingTotalAmount: number): ValidationResultError {
        const isValid = remainingTotalAmount - item.amount >= 0;

        return (type === TransactionType.Credit && isValid) || type === TransactionType.Debit
            ? null
            : {errorCode: ValidationCode.TotalAmountIsTooLarge};
    }

    private getParsedUsersPayload(items: BulkDebitCreditAddModel[]): EntityFetchRequestPayload {
        const filter: Filter<UserProfileServerFilterKeys> = {
            uid: items
                .map(i => i?.uid)
                .filter(u => u)
                .join(' '),
            size: items.length,
            page: 1,
        };

        return {
            type: EntityType.UserProfile,
            fields: ['uid'],
            filter: mapViewFilterToString<UserProfileServerFilterKeys>(filter, ['uid', 'size', 'page']),
        };
    }
}

export type ValidationP2PTransferRequest = {
    transactions: P2PTransferModel[];
    agentUid: string;
};

@injectable()
export class ValidationP2PTransferStrategy implements IBulkStrategy<ValidationP2PTransferRequest, ValidationStrategyResponse> {
    private _userProfileService: IEntityReadService;

    constructor(@inject(ServiceTypes.UserProfileService) userProfileService: IEntityReadService) {
        this._userProfileService = userProfileService;
    }

    process({transactions, agentUid}: ValidationP2PTransferRequest): Observable<ValidationStrategyResponse> {
        const filter: Filter<UserProfileServerFilterKeys> = {
            uid: agentUid,
            size: 1,
            page: 1,
        };
        const agentFetchPayload: EntityFetchRequestPayload<UserProfileQueryFields> = {
            type: EntityType.UserProfile,
            fields: ['uid', 'finance.balance'],
            filter: mapViewFilterToString<UserProfileServerFilterKeys>(filter, ['uid', 'size', 'page']),
        };

        return this._userProfileService.get(agentFetchPayload).pipe(
            map(r => r.responsePayload?.items?.[0] as UserProfile),
            map(agent => {
                const balance = agent?.finance?.balance;
                const isAgentBalanceEnough: ValidationResultError = this.validateAgentBalanceIsEnough(transactions, balance);
                const validationResults: ValidationResult[] = transactions.map((item, index) => {
                    const codes: ValidationResultError[] = [
                        this.validateAmountIsPositive(item),
                        this.validateTypeIsValid(item),
                        item?.transaction_type === TransactionType.P2PTransferCredit
                            ? isAgentBalanceEnough
                            : this.validatePlayerBalanceIsEnough(item),
                    ].filter(c => c);

                    return {id: index, item, errors: codes};
                });

                return validationResults;
            })
        );
    }

    private validateAmountIsPositive(item: P2PTransferModel): ValidationResultError {
        return item.amount !== null && Number(item?.amount) <= 0 ? {errorCode: ValidationCode.AmountNotPositive} : null;
    }

    private validateTypeIsValid(item: P2PTransferModel): ValidationResultError {
        const bulkP2PTransferTypes: TransactionType[] = [TransactionType.P2PTransferCredit, TransactionType.P2PTransferDebit];
        return !bulkP2PTransferTypes.includes(item?.transaction_type) ? {errorCode: ValidationCode.TypeInvalid} : null;
    }

    private validateAgentBalanceIsEnough(items: P2PTransferModel[], agentBalance: number): ValidationResultError {
        const totalCredit: number = items.reduce((prev, curr) => {
            prev += (curr.transaction_type === TransactionType.P2PTransferCredit ? curr.amount : 0) ?? 0;
            return prev;
        }, 0);

        return !agentBalance || agentBalance < totalCredit ? {errorCode: ValidationCode.BalanceIsNotEnough} : null;
    }

    private validatePlayerBalanceIsEnough(item: P2PTransferModel): ValidationResultError {
        return !item.finance?.balance || item.finance?.balance < item.amount ? {errorCode: ValidationCode.BalanceIsNotEnough} : null;
    }
}
