import {DocumentNode, gql, NormalizedCacheObject} from '@apollo/client';
import {Mapper} from '@automapper/core';
import {inject, injectable} from 'inversify';
import {Observable} from 'rxjs';

import {ServiceTypes} from '@inversify';
import {DashboardUrl, EmbeddedDashboardType, QueryGetEmbeddedDashboardArgs} from '@models/generated/graphql';
import {map} from '@otel';
import {
    EmbeddedReportFilterKeys,
    EmbeddedReportQueryFields,
    EntityFetchRequestPayload,
    EntityFetchServiceResponsePayload,
    Filter,
} from '@redux/entity';
import {EntityBaseGqlService} from '@services/entity';
import {ApolloClientProxy} from '@services/gql-api';
import {GqlRequest} from '@services/types';

import {GqlRequestBuilder, RequestPayload} from './entity/GqlRequestBuilder';

export class EmbeddedReportServerModel extends DashboardUrl {
    type: EmbeddedDashboardType;
}

@injectable()
export class EmbeddedReportService extends EntityBaseGqlService<
    QueryGetEmbeddedDashboardArgs,
    EmbeddedReportQueryFields,
    EmbeddedReportFilterKeys
> {
    constructor(
        @inject(ServiceTypes.ApolloClientIGP) client: ApolloClientProxy<NormalizedCacheObject>,
        @inject(ServiceTypes.AutoMapper) mapper: Mapper
    ) {
        super(client, mapper, new EmbeddedReportRequestBuilder());
    }

    public get(
        requestPayload: EntityFetchRequestPayload<EmbeddedReportQueryFields>
    ): Observable<EntityFetchServiceResponsePayload<EmbeddedReportServerModel>> {
        const type: EmbeddedDashboardType = new URLSearchParams(requestPayload?.filter).get('type') as EmbeddedDashboardType;
        return super.get(requestPayload).pipe(
            map(resp => ({
                ...resp,
                responsePayload: {
                    ...resp.responsePayload,
                    items: resp?.responsePayload?.items?.map((i: DashboardUrl) => ({...((i as object) ?? {}), type})),
                },
            }))
        );
    }
}

export class EmbeddedReportRequestBuilder extends GqlRequestBuilder<
    QueryGetEmbeddedDashboardArgs,
    EmbeddedReportQueryFields,
    EmbeddedReportFilterKeys
> {
    public buildRequest(requestPayload: RequestPayload<EmbeddedReportQueryFields>): GqlRequest<QueryGetEmbeddedDashboardArgs> {
        const filter = this.mapToFilterObject(requestPayload.filter);

        return !this.isFilterInvalid(filter) && filter?.type
            ? {
                  query: this.buildQuery(requestPayload.fields),
                  variables: {type: filter.type as EmbeddedDashboardType},
              }
            : null;
    }

    public buildQuery(fields: EmbeddedReportQueryFields[]): DocumentNode {
        return gql`
            query GetEmbeddedDashboard($type: EmbeddedDashboardType) {
                getEmbeddedDashboard(type: $type) {
                    url @include(if: ${this.hasField(fields, 'url')})
                    expire_at {
                        seconds @include(if: ${this.hasField(fields, 'expire_at.seconds')})
                    }
                }
            }
        `;
    }

    protected buildFilter(_: Filter<EmbeddedReportFilterKeys>): object {
        return undefined;
    }
}
