import {defineMessages} from 'react-intl';

import {TransactionStatus} from '@models/generated/graphql';

export const localizedTransactionStatus = defineMessages({
    [TransactionStatus.Cancelled]: {
        id: 'transactionStatus_Cancelled',
        defaultMessage: 'Cancelled',
    },
    [TransactionStatus.InProgress]: {
        id: 'transactionStatus_InProgress',
        defaultMessage: 'In Progress',
    },
    [TransactionStatus.Pending]: {
        id: 'transactionStatus_Pending',
        defaultMessage: 'Pending',
    },
    [TransactionStatus.Succeeded]: {
        id: 'transactionStatus_Succeeded',
        defaultMessage: 'Succeeded',
    },
    [TransactionStatus.Rejected]: {
        id: 'transactionStatus_Rejected',
        defaultMessage: 'Rejected',
    },
    [TransactionStatus.Failed]: {
        id: 'transactionStatus_Failed',
        defaultMessage: 'Failed',
    },
    [TransactionStatus.OnHoldRisk]: {
        id: 'transactionStatus_OnHoldRisk',
        defaultMessage: 'On Hold (Risk)',
    },
    [TransactionStatus.OnHoldPayment]: {
        id: 'transactionStatus_OnHoldPayment',
        defaultMessage: 'On Hold (Payment)',
    },
    [TransactionStatus.RiskAuthorized]: {
        id: 'transactionStatus_RiskAuthorized',
        defaultMessage: 'Risk Authorized',
    },
});
