import React from 'react';
import {defineMessages} from 'react-intl';

import {defineAccessControlledResource, PoliciesAccessRule, PolicyRestrictedResource} from '@access-control';
import {LayoutQueue, LayoutQueueTabs} from '@components/layout';
import PageHeader from '@components/page/PageHeader';
import {RoutingTabs, Tab} from '@components/tab/RoutingTabs';
import {GameRoomViewModelKeys} from '@models/game-room';
import {GameTemplateViewModelKeys} from '@models/game-template';
import {ModuleName} from '@models/modules';
import {gameAdminReadPermissions, gameTemplateCreatePermissions} from '@models/permissions/permissions';
import {Policy} from '@auth';
import {GameTemplateFilterKeys, ManagedGameType} from '@redux/entity';
import {RealtimeMessageTrigger, RealtimeUpdatesMode} from '@redux/realtime';
import {extendedViewCleanDelay, getNonEmptyValueValidator, ViewType} from '@redux/view';

import {RouteUrl} from 'src/common/routeEnums';
import {useMultiplePermissions} from 'src/features/app/permission/PermissionHoc';
import {DataGridGameRoomServer} from 'src/features/block-game-room-list/components/DataGridGameRoom';
import {useGameRooms} from 'src/features/block-game-room-list/hooks';
import {GameTemplateCreateButton} from 'src/features/block-game-template-actions';
import {DataGridGameTemplateServer, useGameTemplates} from 'src/features/block-game-template-list';
import ModuleHeader from '../features/module-shared/components/ModuleHeader';
import {withModule} from '../features/module-shared/components/ModuleHoc';

const localized = defineMessages({
    header: {
        id: 'GameAdminPLO5_header',
        defaultMessage: 'PLO5',
    },
    gameAdminPlo5Templates: {
        id: 'GameAdminPLO5_gameAdminPlo5Templates',
        defaultMessage: 'Template Management',
    },
    gameAdminPlo5Tables: {
        id: 'GameAdminPLO5_gameAdminPlo5Tables',
        defaultMessage: 'Table Management',
    },
    title: {
        id: 'GameAdminPLO5_displayName',
        defaultMessage: 'Game Tables',
    },
});

function GameAdminPLO5() {
    const tabs: Tab[] = [
        {
            title: localized.gameAdminPlo5Templates,
            component: withModule(GameAdminPLO5Templates, gameAdminReadPermissions),
            path: `${RouteUrl.GameAdmin}${RouteUrl.PLO5}${RouteUrl.TemplateManagement}`,
            id: 'game-admin-plo5-templates',
        },
        {
            title: localized.gameAdminPlo5Tables,
            component: withModule(GameAdminPLO5Tables, gameAdminReadPermissions),
            path: `${RouteUrl.GameAdmin}${RouteUrl.PLO5}${RouteUrl.TableManagement}`,
            id: 'game-admin-plo5-tables',
        },
    ];
    return (
        <>
            <LayoutQueueTabs
                header={<ModuleHeader header={localized.header} removeBottomSpacing={true} />}
                body={<RoutingTabs tabs={tabs} />}
            />
        </>
    );
}

export const gameAdminPLO5Resource: PolicyRestrictedResource = defineAccessControlledResource({
    name: 'GameAdminPLO5',
    rules: new PoliciesAccessRule([new Policy(ModuleName.GameAdmin, null, 'read')]),
});

function GameAdminPLO5Tables() {
    const keys: GameRoomViewModelKeys[] = ['gameType', 'roomId', 'templateId', 'stakes', 'platforms', 'features', 'players', 'close'];
    const {items, totalCount, searchFilter, handlePageChange, handlePageSizeChange, handleSortChange} = useGameRooms({
        viewType: 'GameAdminTables',
        // realtime settings are used to update by event action after action was fetched successfully
        realtimeMode: RealtimeUpdatesMode.Confirm,
        triggers: [{type: RealtimeMessageTrigger.Delete}],
        fields: keys,
        validateFilter: null,
        displayName: localized.title,
        defaultFilters: [{key: 'gameType', value: ManagedGameType.PLO5}],
        cleanDelay: extendedViewCleanDelay,
    });

    return (
        <LayoutQueue
            body={
                <DataGridGameRoomServer
                    rows={items}
                    columns={keys}
                    rowCount={totalCount}
                    onPageChange={handlePageChange}
                    onPageSizeChange={handlePageSizeChange}
                    onSortModelChange={handleSortChange}
                    page={searchFilter?.paging?.page}
                    pageSize={searchFilter?.paging?.pageSize}
                    sortModel={searchFilter?.sorting}
                    rowsPerPageOptions={[10, 20]}
                />
            }
        />
    );
}

function GameAdminPLO5Templates() {
    const viewType: ViewType = 'GameAdminPLO5Templates';
    const keys: GameTemplateViewModelKeys[] = [
        'gameType',
        'id',
        'name',
        'is_enabled',
        'labels',
        'stakes',
        'players',
        'tables',
        'modify',
        'updated_at',
    ];
    const gameType = ManagedGameType.PLO5;
    const {items, totalCount, searchFilter, handlePageChange, handlePageSizeChange, handleSortChange} = useGameTemplates({
        viewType,
        fields: keys,
        defaultFilters: [{key: 'gameType', value: gameType}],
        // realtime settings are used to update by event action after data was fetched successfully
        realtimeMode: RealtimeUpdatesMode.Silent,
        triggers: [{type: RealtimeMessageTrigger.Update}],
        syncWithUrl: true,
        validateFilter: getNonEmptyValueValidator<GameTemplateFilterKeys>('gameType'),
        cleanDelay: extendedViewCleanDelay,
    });

    const hasActions = useMultiplePermissions({allowedPermissions: gameTemplateCreatePermissions});
    return (
        <LayoutQueue
            header={hasActions ? <PageHeader actions={<GameTemplateCreateButton gameType={gameType} />} /> : null}
            body={
                <DataGridGameTemplateServer
                    columns={keys}
                    rows={items}
                    onPageChange={handlePageChange}
                    onPageSizeChange={handlePageSizeChange}
                    onSortModelChange={handleSortChange}
                    page={searchFilter?.paging?.page}
                    pageSize={searchFilter?.paging?.pageSize}
                    sortModel={searchFilter?.sorting}
                    rowCount={totalCount}
                />
            }
        />
    );
}

export default withModule(GameAdminPLO5, gameAdminReadPermissions);
