import {ChipType, ChipVariant} from '@components/chip/types';
import {CustomIcon} from '@components/icons';
import {ChipMapping} from '@config/chip/chipConfig';
import {TransactionStatusWithReason} from '@models/transaction/types';

import {transactionStatusWithReasonLocalized} from 'src/features/app/intl/shared-resources/transactionStatusWithReason';

const defaultChipMapping: ChipMapping = {
    chipType: ChipType.Status,
    chipVariant: ChipVariant.Orange,
    startIconClass: CustomIcon.WarningOutline,
} as ChipMapping;

export const getTransactionStatusWithReasonChipConfig = (): Partial<Record<TransactionStatusWithReason, ChipMapping>> => ({
    'OnHoldRisk.UnderInvestigation': {
        label: transactionStatusWithReasonLocalized['OnHoldRisk.UnderInvestigation'],
        ...defaultChipMapping,
    },
    'OnHoldRisk.Threshold24hrs': {
        label: transactionStatusWithReasonLocalized['OnHoldRisk.Threshold24hrs'],
        ...defaultChipMapping,
    },
    'OnHoldRisk.KYCPending': {
        label: transactionStatusWithReasonLocalized['OnHoldRisk.KYCPending'],
        ...defaultChipMapping,
    },
    'OnHoldRisk.PSPNoFunds': {
        label: transactionStatusWithReasonLocalized['OnHoldRisk.PSPNoFunds'],
        ...defaultChipMapping,
    },
    'OnHoldRisk.GameplayReview': {
        label: transactionStatusWithReasonLocalized['OnHoldRisk.GameplayReview'],
        ...defaultChipMapping,
    },
    'OnHoldRisk.MgmtReview': {
        label: transactionStatusWithReasonLocalized['OnHoldRisk.MgmtReview'],
        ...defaultChipMapping,
    },
    'OnHoldRisk.Other': {
        label: transactionStatusWithReasonLocalized['OnHoldRisk.Other'],
        ...defaultChipMapping,
    },

    'OnHoldPayment.UnderInvestigation': {
        label: transactionStatusWithReasonLocalized['OnHoldPayment.UnderInvestigation'],
        ...defaultChipMapping,
    },
    'OnHoldPayment.Threshold24hrs': {
        label: transactionStatusWithReasonLocalized['OnHoldPayment.Threshold24hrs'],
        ...defaultChipMapping,
    },
    'OnHoldPayment.KYCPending': {
        label: transactionStatusWithReasonLocalized['OnHoldPayment.KYCPending'],
        ...defaultChipMapping,
    },
    'OnHoldPayment.PSPNoFunds': {
        label: transactionStatusWithReasonLocalized['OnHoldPayment.PSPNoFunds'],
        ...defaultChipMapping,
    },
    'OnHoldPayment.GameplayReview': {
        label: transactionStatusWithReasonLocalized['OnHoldPayment.GameplayReview'],
        ...defaultChipMapping,
    },
    'OnHoldPayment.MgmtReview': {
        label: transactionStatusWithReasonLocalized['OnHoldPayment.MgmtReview'],
        ...defaultChipMapping,
    },
    'OnHoldPayment.Other': {
        label: transactionStatusWithReasonLocalized['OnHoldPayment.Other'],
        ...defaultChipMapping,
    },
});
