import React, {Fragment, useEffect, useRef, useState} from 'react';
import {defineMessages} from 'react-intl';

import {FilterWithPlacement} from '@components/filter/types';
import {LayoutQueue} from '@components/layout';
import Toolbar, {ToolbarItems, ToolbarItemsContainer} from '@components/toolbar/Toolbar';
import {withdrawalProcessingPermissions} from '@models/permissions/permissions';
import {TransactionViewModelKeys} from '@models/transaction';
import {UserProfileViewModelKeys} from '@models/user-profile';
import {UserProfileServerFilterKeys} from '@redux/entity';
import {RealtimeMessageTrigger, RealtimeUpdatesMode} from '@redux/realtime';
import {extendedViewCleanDelay, getNonEmptyValueValidator, ViewType} from '@redux/view';

import {RecordWithPageViewersKeys, usePageViews} from '../features/block-page-view';
import {closedCasesDefaultFilter, DataGridTransactionWithUserProfileServer, useTransactions} from '../features/block-transaction-list';
import {TransactionQueryFilters} from '../features/block-transaction-list/components/TransactionQueryFilters';
import {withModule} from '../features/module-shared/components/ModuleHoc';

const localized = defineMessages({
    title: {
        id: 'WithdrawalHistoryCases_title',
        defaultMessage: 'Withdrawal History Cases',
    },
});

function WithdrawalHistoryCases() {
    const viewType: ViewType = 'WithdrawalHistoryCases';
    const triggers = [{type: RealtimeMessageTrigger.Update}];
    const mode = RealtimeUpdatesMode.Confirm;
    const keys: TransactionViewModelKeys[] = [
        'withdrawal_id',
        'duration',
        'uid',
        'email',
        'transaction_status',
        'amount',
        'user_labels',
        'player_country',
    ].filter(c => c) as TransactionViewModelKeys[];
    const userColumns: UserProfileViewModelKeys[] = [
        'withdrawal_approved_amount',
        'withdrawal_approved_count',
        'withdrawal_pending_amount',
        'finance.withdrawal.total.pending_count',
        'latest_kyc.payment.account_verification_status',
        'security_cases',
        'account_status',
        'poker_ggr_lifetime',
        'casino_ggr_lifetime',
    ];
    const userKeys: UserProfileViewModelKeys[] = [...userColumns, 'uid'];
    const pageViewColumns: RecordWithPageViewersKeys[] = ['page_viewers'];
    const columns = [...keys, ...userColumns, ...pageViewColumns];

    const {items, totalCount, searchFilter, filterString, handlePageChange, handlePageSizeChange, handleSortChange, handleFilterChange} =
        useTransactions(
            {
                viewType,
                displayName: localized.title,
                fields: keys,
                realtimeMode: mode,
                triggers,
                defaultFilters: closedCasesDefaultFilter,
                validateFilter: () => true,
                syncWithUrl: true,
                cleanDelay: extendedViewCleanDelay,
            },
            {
                viewType,
                displayName: localized.title,
                fields: userKeys,
                cleanDelay: extendedViewCleanDelay,
                validateFilter: getNonEmptyValueValidator<UserProfileServerFilterKeys>('uid'),
            }
        );
    const pageViews = usePageViews({
        pageIdDescriptors: items?.map(i => ({pageType: 'withdrawal-details', recordId: i.transaction_id})),
        viewType: 'WDHistoryPageView', // Should be used general viewType for full page, replace after problem with view with multiple filters will be solved
    });

    const itemsWithPageViewers = items.map(i => ({...i, page_viewers: pageViews.groupedByRecordId[i.transaction_id]}));

    const [primaryNode, setPrimaryNode] = useState<Omit<FilterWithPlacement, 'filterName'>>();
    const [secondaryNode, setSecondaryNode] = useState<Omit<FilterWithPlacement, 'filterName'>>();
    const [thirdNode, setThirdNode] = useState<Omit<FilterWithPlacement, 'filterName'>>();

    const primaryRef = useRef<HTMLDivElement>();
    const secondaryRef = useRef<HTMLDivElement>();
    const thirdRef = useRef<HTMLDivElement>();

    useEffect(() => {
        if (primaryRef.current) {
            setPrimaryNode({nodeId: 'primary', node: primaryRef.current});
        }
        if (secondaryRef.current) {
            setSecondaryNode({nodeId: 'secondary', node: secondaryRef.current});
        }
        if (thirdRef.current) {
            setThirdNode({nodeId: 'third', node: thirdRef.current});
        }
    }, []);

    return (
        <Fragment>
            <LayoutQueue
                dataTestId="withdrawalHistoryCases"
                toolbar={
                    <Toolbar>
                        <ToolbarItemsContainer primary>
                            <ToolbarItems streched ref={primaryRef} />
                            <ToolbarItems secondary ref={secondaryRef} />
                        </ToolbarItemsContainer>
                        <ToolbarItemsContainer>
                            <ToolbarItems ref={thirdRef} />
                        </ToolbarItemsContainer>
                        <TransactionQueryFilters
                            model={filterString}
                            onChange={handleFilterChange}
                            availableFilters={[
                                {filterName: 'uidUsernameTransactionIdEmail', ...primaryNode},
                                {filterName: 'creationDate', ...secondaryNode},
                                {filterName: 'amount', ...thirdNode},
                                {filterName: 'closedStatuses', ...thirdNode},
                                {filterName: 'country', ...thirdNode},
                                {filterName: 'labels', ...thirdNode},
                            ]}
                        />
                    </Toolbar>
                }
                body={
                    <DataGridTransactionWithUserProfileServer
                        columns={columns}
                        rows={itemsWithPageViewers}
                        rowCount={totalCount}
                        onPageChange={handlePageChange}
                        onPageSizeChange={handlePageSizeChange}
                        onSortModelChange={handleSortChange}
                        page={searchFilter?.paging?.page}
                        pageSize={searchFilter?.paging?.pageSize}
                        sortModel={searchFilter?.sorting}
                    />
                }
            />
        </Fragment>
    );
}

export default withModule(WithdrawalHistoryCases, withdrawalProcessingPermissions);
