import React, {useEffect, useRef, useState} from 'react';
import {defineMessages, useIntl} from 'react-intl';

import {FilterWithPlacement} from '@components/filter/types';
import {LayoutDetailsBlock} from '@components/layout/LayoutDetailsBlock';
import Toolbar, {ToolbarItems, ToolbarItemsContainer} from '@components/toolbar/Toolbar';
import {TransactionViewModelKeys} from '@models/transaction';
import {ViewType} from '@redux/view';
import {isStringNullOrEmpty} from '@utils';

import {TransactionDownloadCsvButton} from 'src/features/block-transaction-actions';
import {
    availableTransactionFilterValues,
    DataGridTransactionsServer,
    TransactionQueryFilters,
    useTransactions,
    useTransactionSettings,
} from 'src/features/block-transaction-list';

type TransactionHistoryProps = {
    userId: string;
};

const localized = defineMessages({
    title: {
        id: 'TransactionHistory_title',
        defaultMessage: 'Transaction History',
    },
});

export function TransactionHistory({userId}: TransactionHistoryProps) {
    const viewType: ViewType = 'TransactionHistory';
    const keys: TransactionViewModelKeys[] = [
        'transaction_updated_ts',
        'transaction_type',
        'payment_method_name',
        'payment_method_description',
        'amount_without_currency',
        'currency',
        'transaction_status',
        'current_balance',
        'current_casino_coin_balance',
    ];
    const {
        items,
        summary,
        totalCount,
        searchFilter,
        filterString,
        handlePageChange,
        handlePageSizeChange,
        handleSortChange,
        handleFilterChange,
    } = useTransactions({
        viewType,
        showTotal: true,
        displayName: localized.title,
        fields: keys,
        defaultFilters: [
            {key: 'uid', value: userId},
            {key: 'defaultTransactionTypes', value: availableTransactionFilterValues},
        ],
        defaultSorting: 'updated',
        validateFilter: filter => !isStringNullOrEmpty(filter?.find(f => f.key === 'uid')?.value),
    });
    const {formatMessage} = useIntl();
    const pinnedColumns: (typeof keys)[number][] = ['transaction_updated_ts', 'transaction_type', 'amount_without_currency'];
    const {visibleColumns, onVisibleColumnsChange} = useTransactionSettings({
        settingId: viewType,
        defaultColumns: keys,
        pinnedColumns,
    });

    const [primaryNode, setPrimaryNode] = useState<Omit<FilterWithPlacement, 'filterName'>>();
    const [secondaryNode, setSecondaryNode] = useState<Omit<FilterWithPlacement, 'filterName'>>();
    const [thirdNode, setThirdNode] = useState<Omit<FilterWithPlacement, 'filterName'>>();

    const primaryRef = useRef<HTMLDivElement>();
    const secondaryRef = useRef<HTMLDivElement>();
    const thirdRef = useRef<HTMLDivElement>();

    useEffect(() => {
        if (primaryRef.current) {
            setPrimaryNode({nodeId: 'primary', node: primaryRef.current});
        }
        if (secondaryRef.current) {
            setSecondaryNode({nodeId: 'secondary', node: secondaryRef.current});
        }
        if (thirdRef.current) {
            setThirdNode({nodeId: 'third', node: thirdRef.current});
        }
    }, []);

    return (
        <LayoutDetailsBlock
            title={localized.title}
            toolbar={
                <>
                    <Toolbar>
                        <ToolbarItemsContainer primary>
                            <ToolbarItems ref={primaryRef} />
                            <ToolbarItems primary ref={secondaryRef} />
                        </ToolbarItemsContainer>
                        <ToolbarItemsContainer>
                            <ToolbarItems streched>
                                <ToolbarItems ref={thirdRef} />
                                <TransactionDownloadCsvButton
                                    keys={visibleColumns}
                                    filterString={filterString}
                                    filename={formatMessage(localized.title)}
                                    showTotal
                                />
                            </ToolbarItems>
                        </ToolbarItemsContainer>
                    </Toolbar>
                    <TransactionQueryFilters
                        model={filterString}
                        onChange={handleFilterChange}
                        availableFilters={[
                            {filterName: 'transactionHistoryTypes', ...primaryNode},
                            {filterName: 'paymentMethod', ...secondaryNode},
                            {filterName: 'amount', ...secondaryNode},
                            {filterName: 'status', ...secondaryNode},
                            {filterName: 'creationDate', ...thirdNode},
                        ]}
                    />
                </>
            }
            content={
                <DataGridTransactionsServer
                    hasColumnSelection
                    noToolbarMargin
                    visibleColumns={visibleColumns}
                    pinnedColumns={pinnedColumns}
                    onColumnsVisibilityUpdate={onVisibleColumnsChange}
                    autoHeight
                    columns={keys}
                    rows={items}
                    pinnedRow={summary}
                    rowCount={totalCount}
                    onPageChange={handlePageChange}
                    onPageSizeChange={handlePageSizeChange}
                    onSortModelChange={handleSortChange}
                    page={searchFilter?.paging?.page}
                    pageSize={searchFilter?.paging?.pageSize}
                    sortModel={searchFilter?.sorting}
                    isFlexible
                />
            }
        ></LayoutDetailsBlock>
    );
}
