import React, {useEffect} from 'react';
import {defineMessages} from 'react-intl';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from 'typesafe-actions';

import Button from '@components/button/Buttons';
import {BulkModalContent, BulkModalTitle, ModalFooter} from '@components/modal';
import {useService} from '@inversify';
import {TransactionViewModel} from '@models/transaction';

import {
    bulkActionsActions,
    BulkStepProps,
    IBulkStrategy,
    p2pTransferResultValueSelector,
    ValidationResult,
    validationResultsSelector,
    ValidationStrategyResponse,
} from '../../block-bulk-actions';
import {ValidationP2PTransferRequest} from '../services/validationStrategy';
import {P2PTransferModel} from '../types';

import {BulkStepValidationContentFailed, BulkStepValidationContentSuccessful} from './BulkTransactionValidationStepContent';

const localized = defineMessages({
    title: {
        id: 'BulkStepValidationP2PTransfer_title',
        defaultMessage: 'Validation Result',
    },
    cancel: {
        id: 'BulkStepValidationP2PTransfer_cancel',
        defaultMessage: 'Cancel',
    },
    apply: {
        id: 'BulkStepValidationP2PTransfer_apply',
        defaultMessage: 'Apply',
    },
    validationFailed: {
        id: 'BulkStepValidationP2PTransfer_validationFailed',
        defaultMessage: 'Transactions for P2P-transfers are not validated',
    },
    validationSuccess: {
        id: 'BulkStepValidationP2PTransfer_validationSuccess',
        defaultMessage: 'Transactions for P2P transfer are successfully validated',
    },
});

type BulkStepValidationP2PTransferProps = BulkStepProps & {
    agentUid: string;
};

export const BulkTransactionP2PTransferValidationStepModal = ({onNext, onPrev, agentUid}: BulkStepValidationP2PTransferProps) => {
    const validationStrategy =
        useService<IBulkStrategy<ValidationP2PTransferRequest, ValidationStrategyResponse>>('ValidationP2PTransferStrategy');
    const dispatch = useDispatch();
    const validationResults: ValidationResult<TransactionViewModel>[] = useSelector((state: RootState) =>
        validationResultsSelector<TransactionViewModel>(state)
    );
    const items = useSelector(p2pTransferResultValueSelector) as P2PTransferModel[];

    useEffect(() => {
        const request: ValidationP2PTransferRequest = {transactions: items, agentUid};
        dispatch(bulkActionsActions.validate({strategy: validationStrategy, request}));

        return () => {
            dispatch(bulkActionsActions.setValidationResults(null));
        };
    }, []);

    function handleCancelClick() {
        onPrev();
    }

    function handleNextClick() {
        onNext();
    }

    const invalidTransactions = validationResults?.filter(r => r.errors?.length > 0);
    const validTransactions = validationResults?.filter(r => r.errors?.length === 0);
    const isNextDisabled = !validationResults?.length || invalidTransactions?.length > 0;

    return (
        <>
            <BulkModalTitle title={localized.title} />
            <BulkModalContent>
                {validationResults?.length > 0 ? (
                    invalidTransactions?.length > 0 ? (
                        <BulkStepValidationContentFailed
                            validTransactions={validTransactions}
                            invalidTransactions={invalidTransactions}
                            gridColumns={['uid', 'error']}
                            alertTitle={localized.validationFailed}
                        />
                    ) : (
                        <BulkStepValidationContentSuccessful
                            validTransactions={validTransactions}
                            alertTitle={localized.validationSuccess}
                        />
                    )
                ) : (
                    <></>
                )}
            </BulkModalContent>
            <ModalFooter>
                <Button label={localized.cancel} onClick={handleCancelClick} />
                <Button color="primary" label={localized.apply} onClick={handleNextClick} disabled={isNextDisabled} />
            </ModalFooter>
        </>
    );
};
