import React from 'react';
import {makeStyles} from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
    layoutHeaderButtonsContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: theme.spacing(),
    },
}));

type LayoutHeaderButtonsProps = {children: React.ReactNode | React.ReactNode[]};

export function LayoutHeaderButtons({children}: LayoutHeaderButtonsProps) {
    const {classes} = useStyles();
    return <div className={classes.layoutHeaderButtonsContainer}>{children}</div>;
}
