import React from 'react';
import {defineMessages} from 'react-intl';
import {Box} from '@mui/material';

import {Select} from '@components/select';

import {SelectOption} from '../../../module-shared/types';
import {PageModuleChipSelectFilter} from '../types';

import {FilterInnerProps} from './ModuleFilterHoc';
import withModuleFilter from './ModuleFilterHoc';

export const localized = defineMessages({
    placeholder: {
        id: 'MultiSelectFilter_placeholder',
        defaultMessage: 'Any',
    },
});

const MultiSelectFilter = <T extends unknown[]>({filter, value, onSubmit, popoverClassName}: FilterInnerProps<T>) => {
    const onChangeSelect = (selectedValue: T): void => {
        onSubmit([{key: filter.key, value: selectedValue}]);
    };

    const multiSelectFilter = filter as PageModuleChipSelectFilter;

    return (
        <Box data-testid={`${filter.key}_multiselectFilter`}>
            <Select
                multiple
                options={filter.options as SelectOption[]}
                value={value.find(i => i.key === filter.key)?.value ?? []}
                onSubmit={onChangeSelect}
                enumFormatter={multiSelectFilter.enumFormatter}
                emptyPlaceholder={multiSelectFilter.emptyPlaceholder}
                label={filter.label}
                chipType={multiSelectFilter.chipType}
                horizontalPosition={multiSelectFilter.horizontalPosition}
                showResetButton
                hasSearch={multiSelectFilter.hasSearch}
                virtualization
                popoverClassName={popoverClassName}
            />
        </Box>
    );
};

const FilterMultiSelect = withModuleFilter(MultiSelectFilter, 'auto', false);

export default FilterMultiSelect;
