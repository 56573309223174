import {defineMessages} from 'react-intl';

import {AccessControlIndicator, defineAccessControlledResource, PoliciesAccessRule, withPolicyAccessCheck} from '@access-control';
import {ModuleName} from '@models/modules';
import {Policy} from '@auth';

import {withUserProfileTextField} from '../../player-actions/pii-actions/components/UserProfileTextField';
import {personalInfoActions} from '../actions';

const localized = defineMessages({
    email: {
        id: 'UserProfileEmail_email',
        defaultMessage: 'Email',
    },
});

const emailEditResource = defineAccessControlledResource({
    name: 'EmailEditResource',
    rules: new PoliciesAccessRule([new Policy(ModuleName.Email, null, 'update')]),
});

export const UserProfileEmail = withPolicyAccessCheck(
    withUserProfileTextField(
        {
            action: personalInfoActions.editEmail,
            placeholder: localized.email,
            submitModelMapper: m => ({
                fieldValue: m?.user?.contact?.email,
                userId: m?.userId,
            }),
            formModelMapper: m => ({
                user: {
                    uid: m?.userId,
                    contact: {
                        email: m?.fieldValue as string,
                    },
                },
                userId: m?.userId,
            }),
            isRequired: true,
        },
        false
    ),
    emailEditResource,
    AccessControlIndicator.Disabled
);
