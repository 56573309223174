import {defineMessages, useIntl} from 'react-intl';

import {useAutoMapper} from '@auto-mapper';
import {Transaction as GqlTransaction} from '@models/generated/graphql';
import {
    TransactionColumnSettings,
    TransactionColumnSettingsKeys,
    TransactionViewModel,
    TransactionViewModelKeys,
} from '@models/transaction';
import {UserProfileViewModelKeys} from '@models/user-profile';
import {useAuthUser} from '@auth';
import {useColumnsSettings} from '@user-settings';
import {
    EntityType,
    TransactionExtended,
    TransactionQueryFields,
    TransactionServerFilterKeys,
    UserProfileServerFilterKeys,
} from '@redux/entity';
import {UseListViewEntityProps, UseListViewEntityResult, useViewInit} from '@redux/view';

import {Sorting} from 'src/common/types';
import {BoUserViewModel, useBoUsers} from '../block-bo-user';
import {useUserProfiles} from '../block-user-profile-list';

import {TransactionWithUserProfileGridItem} from './types';

type TransactionSorting = 'started' | 'updated';

const localized = defineMessages({
    total: {
        id: 'useTransactions_totalLabel',
        defaultMessage: 'Total',
    },
});

type UseTransactionsProps = Omit<UseListViewEntityProps<TransactionServerFilterKeys, TransactionViewModelKeys>, 'defaultSorting'> & {
    defaultSorting?: TransactionSorting;
    showTotal?: boolean;
};

export function useTransactions(
    {
        viewType,
        fields,
        displayName,
        cleanDelay = 0,
        realtimeMode,
        triggers,
        defaultFilters,
        defaultPaging,
        defaultSorting = 'started',
        validateFilter,
        syncWithUrl = false,
        showTotal = false,
    }: UseTransactionsProps,
    userProfileProps?: UseListViewEntityProps<UserProfileServerFilterKeys, UserProfileViewModelKeys>
): UseListViewEntityResult<TransactionWithUserProfileGridItem, TransactionViewModelKeys | UserProfileViewModelKeys> & {
    summary: TransactionViewModel;
} {
    const mapper = useAutoMapper();
    const {formatMessage} = useIntl();

    const {
        items: transactions,
        totalCount,
        searchFilter,
        viewEntity: {filter: filterString},
        handlePageChange,
        handlePageSizeChange,
        handleSortChange,
        handleFilterChange,
    } = useViewInit<TransactionExtended, TransactionServerFilterKeys, TransactionQueryFields>({
        viewType,
        displayName,
        entity: {
            entity: EntityType.Transaction,
            fields: showTotal ? [...mapFields(fields), 'total_amount'] : mapFields(fields),
        },
        realtime: realtimeMode ? {entity: EntityType.Transaction, mode: realtimeMode, triggers} : null,
        defaultSorting: getDefaultSorting(),
        defaultPaging,
        defaultFilters: showTotal ? [...defaultFilters, {key: 'show_total_amount', value: true}] : defaultFilters,
        syncWithUrl,
        validateFilter,
        cleanDelay,
    });

    const {items: users} = useUserProfiles({
        ...userProfileProps,
        validateFilter: userProfileProps?.validateFilter ?? (() => false),
        ids: transactions?.map(i => i.uid),
    });

    const assigneeIds = transactions?.map(i => i?.assigned_user_id)?.filter(i => i);
    const {items: boUsers} = useBoUsers({
        viewType,
        fields: ['id', 'firstName', 'lastName', 'email'],
        ids: assigneeIds,
        validateFilter: () => !!assigneeIds?.length,
    });

    const items: TransactionWithUserProfileGridItem[] = transactions
        ?.filter(i => !i.is_summary)
        .map(i => {
            const user = users.find(u => u.uid === i.uid);
            const assignee: BoUserViewModel = boUsers?.find(bou => bou?.id === i?.assigned_user_id);
            const transaction = mapper.map(i, GqlTransaction, TransactionViewModel);
            return {
                ...user,
                ...transaction,
                assignee,
            };
        });
    const summaryItem: TransactionViewModel = {
        ...mapper.map(
            transactions?.find(t => t.is_summary),
            GqlTransaction,
            TransactionViewModel
        ),
        [fields[0]]: formatMessage(localized.total),
        is_summary: true,
    };

    return {
        items,
        summary: summaryItem,
        totalCount,
        searchFilter,
        filterString,
        handlePageChange,
        handlePageSizeChange,
        handleSortChange: handleVmSortChange,
        handleFilterChange,
    };

    function getDefaultSorting(): Sorting<TransactionQueryFields>[] {
        const sorting: Record<TransactionSorting, Sorting<TransactionQueryFields>[]> = {
            started: [
                {
                    field: 'transaction_started_ts',
                    sort: 'desc',
                },
            ],
            updated: [
                {
                    field: 'transaction_updated_ts',
                    sort: 'desc',
                },
            ],
        };
        return sorting[defaultSorting];
    }

    function mapFields(fields: TransactionViewModelKeys[]): TransactionQueryFields[] {
        return mapper?.map<TransactionViewModelKeys[], TransactionQueryFields[]>(
            fields,
            nameof<TransactionViewModelKeys>(),
            nameof<TransactionQueryFields>()
        );
    }

    function mapField(field: TransactionViewModelKeys): TransactionQueryFields {
        return mapFields([field])[0];
    }

    function mapSorting(sorting: Sorting<TransactionViewModelKeys>[]): Sorting<TransactionQueryFields>[] {
        return sorting?.map(s => ({...s, field: mapField(s?.field)}));
    }

    function handleVmSortChange(newSorting: Sorting<TransactionViewModelKeys>[]) {
        handleSortChange(mapSorting(newSorting));
    }
}

type UseTransactionSettingsPorps = {
    settingId: string;
    defaultColumns: TransactionViewModelKeys[];
    pinnedColumns?: TransactionViewModelKeys[];
};

type UseTransactionSettingsResult = {
    visibleColumns?: TransactionViewModelKeys[];
    onVisibleColumnsChange: (value: TransactionViewModelKeys[]) => void;
};

export function useTransactionSettings({
    settingId,
    defaultColumns,
    pinnedColumns = [],
}: UseTransactionSettingsPorps): UseTransactionSettingsResult {
    const {sub} = useAuthUser();
    const mapper = useAutoMapper();
    const {visible, onVisibleColumnsChange} = useColumnsSettings<TransactionColumnSettings>({
        userId: sub,
        settingId,
        defaultColumns: mapViewModelKeysToSettings(defaultColumns),
        pinnedColumns: mapViewModelKeysToSettings(pinnedColumns),
    });

    function mapViewModelKeysToSettings(keys: TransactionViewModelKeys[]): TransactionColumnSettingsKeys[] {
        return [
            ...new Set(
                keys?.map(i =>
                    mapper.map<TransactionViewModelKeys, TransactionColumnSettingsKeys>(
                        i,
                        nameof<TransactionViewModelKeys>(),
                        nameof<TransactionColumnSettingsKeys>()
                    )
                ) ?? []
            ),
        ];
    }

    function mapSettingsToViewModelKeys(settings: TransactionColumnSettingsKeys[]): TransactionViewModelKeys[] {
        return [
            ...new Set(
                settings?.map(i =>
                    mapper.map<TransactionColumnSettingsKeys, TransactionViewModelKeys>(
                        i,
                        nameof<TransactionColumnSettingsKeys>(),
                        nameof<TransactionViewModelKeys>()
                    )
                ) ?? []
            ),
        ];
    }

    function handleColumnSettingsChange(fields: TransactionViewModelKeys[]) {
        const columns = mapViewModelKeysToSettings(fields);
        onVisibleColumnsChange(columns);
    }

    return {
        visibleColumns: mapSettingsToViewModelKeys(visible).filter(f => defaultColumns.includes(f)),
        onVisibleColumnsChange: handleColumnSettingsChange,
    };
}
