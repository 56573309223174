import React from 'react';

import {Select, SelectProps} from '@components/select';

import {FilterProps} from './types';

export type SelectFilterProps = Omit<FilterProps<string | string[]>, 'mode'> &
    Pick<
        SelectProps<string | string[]>,
        'options' | 'chipType' | 'label' | 'multiple' | 'hasSearch' | 'popoverClassName' | 'virtualization'
    > & {
        selectMode?: Pick<SelectProps<string | string[]>, 'mode'>['mode'];
    };

export function SelectFilter({value, onChange, selectMode = 'client', ...props}: SelectFilterProps) {
    function handleSubmit(value: string | string[]) {
        let result: string[] | string;
        if (props?.multiple) {
            result = value?.length ? value : '';
        } else {
            result = value ? value[0] : '';
        }
        onChange(result);
    }

    return <Select {...props} value={value} onSubmit={handleSubmit} showResetButton mode={selectMode} />;
}
