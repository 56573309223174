import React from 'react';
import {defineMessages, useIntl} from 'react-intl';

import {KeyValueCell, KeyValueColumn, KeyValueTable} from '@components/key-value-table';
import {UserAddressInfo as UserAddress, UserProfile} from '@models/generated/graphql';
import {agentReadPolicies} from '@models/permissions/permissions';
import {EntityType, State, UserProfileQueryFields, UserProfileServerFilterKeys} from '@redux/entity';
import {RealtimeMessageTrigger, RealtimeUpdatesMode} from '@redux/realtime';
import {useViewInit} from '@redux/view';
import {isStringNullOrEmpty} from '@utils';

import {withMultiplePermission} from '../../app/permission/PermissionHoc';
import {UserProfileAddress} from '../../block-user-profile-actions-address/components/UserProfileAddress';
import {UserProfileCity} from '../../block-user-profile-actions-address/components/UserProfileCity';
import {UserProfileCountryState} from '../../block-user-profile-actions-address/components/UserProfileCountryState';
import {UserProfilePostCode} from '../../block-user-profile-actions-address/components/UserProfilePostCode';

const localized = defineMessages({
    header: {
        id: 'UserAddressInfo_header',
        defaultMessage: 'Home Address',
    },
    address: {
        id: 'UserAddressInfo_address',
        defaultMessage: 'Street Address',
    },
    city: {
        id: 'UserAddressInfo_city',
        defaultMessage: 'City',
    },
    postCode: {
        id: 'UserAddressInfo_postCode',
        defaultMessage: 'Post Code',
    },
    countryState: {
        id: 'UserAddressInfo_countryState',
        defaultMessage: 'Country/State',
    },
    addressInfo: {
        id: 'useAddressInfoData_AddressInfo',
        defaultMessage: 'Home Address',
    },
});

type UserAddressInfoProps = {
    uid: string;
};

type UserAddressInfoModel = UserAddress & {
    stateName?: string;
};

type UserProfileData = UserProfile & {
    address?: UserAddressInfoModel;
};

type UseAddressResult = {
    user: UserProfileData;
};

function useAddress(uid: string): UseAddressResult {
    //TODO: use UserProfile and State hooks to read data
    const fields: UserProfileQueryFields[] = [
        'address.address',
        'address.city',
        'address.state',
        'address.country',
        'address.country_info.iso_alpha2_code',
        'address.country_info.name',
        'address.post_code',
    ];
    const {items} = useViewInit<UserProfile, UserProfileServerFilterKeys, UserProfileQueryFields>({
        displayName: localized.addressInfo,
        viewType: 'AddressInfo',
        entity: {
            entity: EntityType.UserProfile,
            fields,
        },
        defaultPaging: {page: 1, pageSize: 1},
        defaultFilters: [{key: 'uid', value: uid}],
        realtime: {
            entity: EntityType.UserProfile,
            mode: RealtimeUpdatesMode.Confirm,
            triggers: uid
                ? [
                      {
                          type: RealtimeMessageTrigger.Update,
                          args: {
                              filter: {
                                  field: 'uid',
                                  value: uid,
                              },
                          },
                      },
                  ]
                : undefined,
        },
        validateFilter: filter => !isStringNullOrEmpty(filter?.find(f => f.key === 'uid')?.value),
    });
    const user = items?.length > 0 ? items[0] : null;

    const {items: states} = useViewInit<State, string, string>({
        displayName: localized.addressInfo,
        viewType: 'AddressInfo',
        entity: {
            entity: EntityType.State,
            fields: ['code'],
        },
        defaultPaging: {page: 1, pageSize: 1},
        defaultFilters: [{key: 'country', value: user?.address?.country_info?.iso_alpha2_code}],
        validateFilter: filter => !isStringNullOrEmpty(filter?.find(f => f.key === 'country')?.value),
    });

    const userData: UserProfileData = {
        ...user,
        address: {
            ...(user?.address ?? {}),
            stateName: states?.find(i => i.code === user?.address?.state)?.name,
        },
    };

    return {user: userData};
}

function UserAddressInfoInner({uid}: UserAddressInfoProps) {
    const {formatMessage} = useIntl();
    const {user} = useAddress(uid);

    return (
        <KeyValueTable title={formatMessage(localized.header)}>
            <KeyValueColumn>
                <KeyValueCell title={formatMessage(localized.postCode)}>
                    <UserProfilePostCode userId={user?.uid} user={user} />
                </KeyValueCell>
                <KeyValueCell title={formatMessage(localized.address)}>
                    <UserProfileAddress userId={user?.uid} user={user} />
                </KeyValueCell>
            </KeyValueColumn>
            <KeyValueColumn>
                <KeyValueCell title={formatMessage(localized.city)}>
                    <UserProfileCity userId={user?.uid} user={user} />
                </KeyValueCell>
                <KeyValueCell title={formatMessage(localized.countryState)}>
                    <UserProfileCountryState userId={user?.uid} user={user} />
                </KeyValueCell>
            </KeyValueColumn>
        </KeyValueTable>
    );
}

export const UserAddressInfo = withMultiplePermission(UserAddressInfoInner, {restrictedPermissions: agentReadPolicies});
