import {defineAccessControlledResource, PoliciesAccessRule, PolicyRestrictedResource} from '@access-control';
import {ModuleName, SubmoduleName} from '@models/modules';
import {PermissionAccessibleItem, PermissionEnum} from '@models/permissions';
import {Policy} from '@auth';

export const withdrawalActionsPermissions: PermissionAccessibleItem[] = [
    {
        permissions: [PermissionEnum.Update],
        moduleName: ModuleName.WithdrawalProcessing,
        submoduleName: SubmoduleName.Payment,
    },
    {
        permissions: [PermissionEnum.Update],
        moduleName: ModuleName.WithdrawalProcessing,
        submoduleName: SubmoduleName.WithdrawalProcessingRisk,
    },
    {
        permissions: [PermissionEnum.Update],
        moduleName: ModuleName.WithdrawalProcessing,
    },
];

export const withdrawalRiskUpdatePermissions: PermissionAccessibleItem[] = [
    {
        permissions: [PermissionEnum.Update],
        moduleName: ModuleName.WithdrawalProcessing,
        submoduleName: SubmoduleName.WithdrawalProcessingRisk,
    },
    {
        permissions: [PermissionEnum.Update],
        moduleName: ModuleName.WithdrawalProcessing,
    },
];

export const withdrawalPaymentUpdatePermissions: PermissionAccessibleItem[] = [
    {
        permissions: [PermissionEnum.Update],
        moduleName: ModuleName.WithdrawalProcessing,
        submoduleName: SubmoduleName.Payment,
    },
    {
        permissions: [PermissionEnum.Update],
        moduleName: ModuleName.WithdrawalProcessing,
    },
];

export const redeemRevenueSharePermissions: PermissionAccessibleItem[] = [
    {
        permissions: [PermissionEnum.Update],
        moduleName: ModuleName.EntityTransactions,
        submoduleName: SubmoduleName.RevenueShare,
    },
    {
        permissions: [PermissionEnum.Update],
        moduleName: ModuleName.EntityTransactions,
    },
];

export const manualTransactionAddPolicies: PermissionAccessibleItem[] = [
    {moduleName: ModuleName.ActionCenter, permissions: [PermissionEnum.Update]},
    {moduleName: ModuleName.ActionCenter, submoduleName: SubmoduleName.FinanceActions, permissions: [PermissionEnum.Update]},
];

export const creditCreatePolicies: Policy[] = [
    new Policy(ModuleName.CreditLimit1),
    new Policy(ModuleName.CreditLimit2),
    new Policy(ModuleName.CreditLimit3),
    new Policy(ModuleName.CreditLimit1, null, 'create'),
    new Policy(ModuleName.CreditLimit2, null, 'create'),
    new Policy(ModuleName.CreditLimit3, null, 'create'),
];

export const debitCreatePolicies: Policy[] = [new Policy(ModuleName.Debit), new Policy(ModuleName.Debit, null, 'create')];

export const creditCreateResource: PolicyRestrictedResource = defineAccessControlledResource({
    name: 'CreditCreateResourse',
    rules: new PoliciesAccessRule(creditCreatePolicies),
});

export const debitCreateResource: PolicyRestrictedResource = defineAccessControlledResource({
    name: 'DebitCreateResource',
    rules: new PoliciesAccessRule(debitCreatePolicies),
});

export const manualTransactionCreateResource: PolicyRestrictedResource = defineAccessControlledResource({
    name: 'ManualTransactionCreateResource',
    rules: new PoliciesAccessRule([...creditCreatePolicies, ...debitCreatePolicies]),
});
