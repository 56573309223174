import {Observable} from 'rxjs';

import {EditSecurityCasesSubmitModel, SecurityCaseEditModel} from '../player-actions/cases-actions/types';
import {Message} from '../player-message/types';

import {SaveOperationIdPayload} from './actions';
import {BulkValidationState} from './reducers';

export type BulkStepProps = {
    onNext: () => void;
    onPrev?: () => void;
};

export type BulkStepExecutionProps = Omit<BulkStepProps, 'onPrev'> & {
    hasRetry: boolean;
    withFlexibleGrid?: boolean;
};

export enum BulkActionKey {
    AccountLock = 'account_lock',
    WithdrawalLock = 'withdrawal_lock',
    DepositLock = 'deposit_lock',
    LobbyLock = 'lobby_lock',
    CasinoLock = 'CasinoLock',
    SportsbookLock = 'SportsbookLock',
    P2PTransferLock = 'P2PTransferLock',
    SecurityCases = 'security_cases',
    SecurityCasesAdd = 'security_cases_add',
    SecurityCasesUpdate = 'security_cases_update',
    SecurityCasesRemove = 'security_cases_remove',
    SecurityCasesAll = 'security_cases_all',
    Message = 'message',
    NotesAndAttachments = 'notes_attachments',
    ManualTriggerMTTTicket = 'ManualTriggerMTTTicket',
    ManualTriggerDepositCashMatch = 'ManualTriggerDepositCashMatch',
    ManualTriggerDepositCashMatchAmount = 'ManualTriggerDepositCashMatchAmount',
    ManualTriggerDepositCashMatchCode = 'ManualTriggerDepositCashMatchCode',
    ManualTriggerImmediateCashBonus = 'ManualTriggerImmediateCashBonus',
    ManualTransactions = 'ManualTransactions',
    AddUserLabel = 'AddUserLabel',
    RemoveUserLabel = 'RemoveUserLabel',
    P2PTransfer = 'P2PTransfer',
    KYCReject = 'KYCReject',
    InitiatePaymentKYC = 'InitiatePaymentKYC',
    KYCAssign = 'KYCAssign',
    TransactionAssign = 'TransactionAssign',
}

export enum BulkItemStatus {
    Failed = 'Failed',
    InProgress = 'InProgress',
    Pending = 'Pending',
    Successful = 'Successful',
    PartiallySuccessful = 'PartiallySuccessful',
    TimedOut = 'TimedOut',
}

export type BulkActionItemPayload<T = unknown> = {
    actionKey: string;
    itemId: string;
    value: T;
    labelValue?: string;
    status: BulkItemStatus;
    errorCode?: string;
    message?: string;
};

export type BulkSecurityCasesResultValue = {
    addedCases: EditSecurityCasesSubmitModel;
    updatedCases: EditSecurityCasesSubmitModel;
    removedCases: EditSecurityCasesSubmitModel;
};

export type ValidationResultError = {
    errorCode: number;
    data?: any;
};

export type ValidationResult<TItem = unknown> = {
    id: number;
    item: TItem;
    errors?: ValidationResultError[];
};

export const bulkItemFailedStatues: BulkItemStatus[] = [BulkItemStatus.Failed, BulkItemStatus.TimedOut];

export type UserProfileActionItem = {
    uid: string;
};

type AccountAndFinancialLockActionItem = UserProfileActionItem & {
    uid: string;
    isLocked: boolean;
};

export type AccountLockActionItem = AccountAndFinancialLockActionItem & {boUserId?: string};

export type DepositLockActionItem = AccountAndFinancialLockActionItem;

export type WithdrawalLockActionItem = AccountAndFinancialLockActionItem;

export type LobbyLockActionItem = AccountAndFinancialLockActionItem;

export type AddSecurityCaseActionItem = UserProfileActionItem & {
    add: SecurityCaseEditModel[];
};

export type RemoveSecurityCaseActionItem = UserProfileActionItem & {
    remove: SecurityCaseEditModel[];
};

export type UpdateSecurityCaseActionItem = AddSecurityCaseActionItem & RemoveSecurityCaseActionItem;

export type SendMessageActionItem = UserProfileActionItem & {
    message: Message;
};

export type ChangeBulkActionStatusItem = Omit<BulkActionItemPayload, 'value'>;

export const failedOperationId = 'failedOperationId';

export interface IBulkStrategy<TRequest, TResponse> {
    process(request: TRequest): Observable<TResponse>;
}

export interface IBulkApplyStrategy<TViewModel, TApplyResponseValue = unknown> {
    process(request: TViewModel): ApplyStrategyResponse<TApplyResponseValue>;
}

export type ValidationStrategyResponse = BulkValidationState;

export type ApplyStrategyResponse<T = unknown> = {
    actionKey: string;
    items: BulkActionItemPayload<T>[];
};

export type PerformStrategyRequest<T = unknown> = {
    items: BulkActionItemPayload<T>[];
    actionKey: string;
};

export type PerformStrategyActionItemsResponse = {
    items: ChangeBulkActionStatusItem[];
    actionKey: string;
};

export type PerformStrategyOperationResponse = SaveOperationIdPayload & {errorMessage?: string};

type PerformOperationData = {
    strategy: IBulkStrategy<PerformStrategyRequest, PerformStrategyOperationResponse>;
    actionKey: string;
    type: 'operation';
};

// TODO: [BO-2063] make strategy required
type PerformActionItemsData = {
    strategy?: IBulkStrategy<PerformStrategyRequest, PerformStrategyActionItemsResponse>;
    actionKey: string;
    type: 'actionItems';
};

export type PerformActionData = PerformOperationData | PerformActionItemsData;
