import React from 'react';
import {defineMessages} from 'react-intl';
import {makeStyles} from 'tss-react/mui';

import {usePolicyAccessCheck} from '@access-control';
import {LocalizedText} from '@components/i18n';
import {CustomIcon, Icon, IconColor} from '@components/icons';
import StyledTooltip from '@components/Tooltip';
import {useAuthUser} from '@auth';

import {BulkActionButton, BulkActionKey, useOneClickOperation} from 'src/features/block-bulk-actions';
import {withdrawalStatusChangeResource} from '../permissions';
import {TransactionAssignData, TransactionAssignRequest} from '../services/applyStrategy';

const localized = defineMessages({
    label: {
        id: 'BulkTransactionAssignButton_label',
        defaultMessage: 'Assign to me',
    },
    successMessage: {
        id: 'BulkTransactionAssignButton_successMessage',
        defaultMessage: '{count} selected withdrawal cases have been assigned to you',
    },
    errorMessage: {
        id: 'BulkTransactionAssignButton_errorMessage',
        defaultMessage: 'Operation failed. None of selected withdrawal cases were assigned to you',
    },
    accessFailedMessage: {
        id: 'BulkTransactionAssignButton_accessFailedMessage',
        defaultMessage: 'You don’t have the required permissions to assign withdrawal cases to yourself',
    },
});

const useStyles = makeStyles()({
    bulkTransactionAssignButtonTooltipContainer: {
        maxWidth: '300px',
    },
});

type BulkTransactionAssignButtonProps = {selectedItems: string[]};

export function BulkTransactionAssignButton({selectedItems}: BulkTransactionAssignButtonProps) {
    const {classes} = useStyles();
    const actionKey: BulkActionKey = BulkActionKey.TransactionAssign;
    const boUserId = useAuthUser()?.sub;
    const hasAccess = usePolicyAccessCheck(withdrawalStatusChangeResource);

    const applyRequest: TransactionAssignRequest = {
        items: selectedItems?.map<TransactionAssignData>(id => ({id, user_id: boUserId})),
    };

    const {inProgress, handleClick} = useOneClickOperation<TransactionAssignRequest>({
        actionKey,
        successMessage: localized.successMessage,
        errorMessage: localized.errorMessage,
        applyRequest,
        applyStrategyKey: 'ApplyTransactionAssignStrategy',
        performStrategyKey: 'PerformTransactionAssignStrategy',
    });

    return (
        <StyledTooltip
            element={
                <div>
                    <BulkActionButton
                        color="secondary"
                        label={localized.label}
                        itemsCount={selectedItems?.length}
                        loading={inProgress}
                        disabled={!hasAccess || inProgress}
                        onClick={handleClick}
                        startIcon={<Icon icon={CustomIcon.ConfirmOutLine} fontSize="small" color={IconColor.Secondary} />}
                    />
                </div>
            }
            title={
                <div className={classes.bulkTransactionAssignButtonTooltipContainer}>
                    <LocalizedText label={localized.accessFailedMessage} />
                </div>
            }
            hideTooltip={hasAccess}
        />
    );
}
