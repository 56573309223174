import React from 'react';
import {defineMessages} from 'react-intl';
import {Chip} from '@mui/material';
import {CSSObject} from 'tss-react';
import {makeStyles} from 'tss-react/mui';

import Button, {ButtonProps} from '@components/button/Buttons';
import {CustomTheme} from '@style';

const localized = defineMessages({
    addBulkAction: {
        id: 'BulkApplyStepModalButton_addBulkAction',
        defaultMessage: 'Add Bulk Action',
    },
});

export const useBulkOpenActionsModalButtonStyles = makeStyles()((theme: CustomTheme) => ({
    bulkApplyStepModalButton: {
        display: 'flex',
        alignItems: 'center',
        borderRadius: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    bulkApplyStepModalButtonChip: {
        marginLeft: theme.spacing(0.75),
        height: theme.spacing(2.5),
        ...(theme.typography.subtitle2 as CSSObject),
    },
    bulkApplyStepModalButtonChipPrimary: {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.primary.contrastText,
    },
}));

export type BulkActionButtonProps = ButtonProps & {
    itemsCount: number;
    onClick: () => void;
};

export function BulkActionButton({label, startIcon, itemsCount, onClick, disabled, color = 'primary', ...props}: BulkActionButtonProps) {
    const {classes, cx} = useBulkOpenActionsModalButtonStyles();
    const hasSelectedItems: boolean = itemsCount > 0;

    return (
        <Button
            className={classes.bulkApplyStepModalButton}
            label={label ?? localized.addBulkAction}
            color={color}
            onClick={onClick}
            startIcon={startIcon}
            disabled={disabled || !hasSelectedItems}
            {...props}
        >
            {hasSelectedItems ? (
                <Chip
                    label={itemsCount}
                    size="small"
                    className={cx(classes.bulkApplyStepModalButtonChip, color === 'primary' && classes.bulkApplyStepModalButtonChipPrimary)}
                />
            ) : null}
        </Button>
    );
}
