import {DateRangeFilterValue, ValueRange} from '@components/filter';
import {MultiKeyFilterTextValue} from '@components/filter/types';
import {TransactionStatus, TransactionType, UserProfile} from '@models/generated/graphql';
import {TransactionViewModel, TransactionViewModelKeys} from '@models/transaction';
import {UserProfileViewModelKeys} from '@models/user-profile';
import {TransactionServerFilterKeys} from '@redux/entity';
import {getLastNDaysDateRange} from '@utils/date';

import {Filter} from 'src/common/types';
import {AgentFilterValue} from '../block-agent-profile/components/AgentFilterNew';

import {TransactionStatusFilterValue} from './components/TransactionStatusFilter';

export type TransactionWithUserProfileGridItem = TransactionViewModel & Omit<UserProfile, '__typename'>;

export type DataGridTransactionWithUserProfileColumns = UserProfileViewModelKeys | TransactionViewModelKeys;

const rangePeriodDays = 13;
const rangePeriod = getLastNDaysDateRange(rangePeriodDays);
export const transactionDateDefaultFilter: Filter<string | number, TransactionServerFilterKeys>[] = [
    {
        key: 'startedTs.from',
        value: rangePeriod[0],
    },
    {
        key: 'startedTs.to',
        value: rangePeriod[1],
    },
];

export const pendingCasesStatuses = [
    TransactionStatus.Pending,
    TransactionStatus.OnHoldRisk,
    TransactionStatus.OnHoldPayment,
    TransactionStatus.RiskAuthorized,
    TransactionStatus.InProgress,
];
export const closedCasesStatuses = [TransactionStatus.Rejected, TransactionStatus.Failed, TransactionStatus.Succeeded];

export const pendingCasesDefaultFilter: Filter<unknown, TransactionServerFilterKeys>[] = [
    ...transactionDateDefaultFilter,
    {
        key: 'transactionStatus',
        value: pendingCasesStatuses,
    },
    {
        key: 'defaultTransactionStatus',
        value: pendingCasesStatuses,
    },
    {key: 'transactionTypes', value: TransactionType.Withdrawal},
];

export const closedCasesDefaultFilter: Filter<unknown, TransactionServerFilterKeys>[] = [
    ...transactionDateDefaultFilter,
    {
        key: 'transactionStatus',
        value: closedCasesStatuses,
    },
    {
        key: 'defaultTransactionStatus',
        value: closedCasesStatuses,
    },
    {key: 'transactionTypes', value: TransactionType.Withdrawal},
];

export type TransactionFilterName =
    | 'uidEmailUsernameMarketingCode'
    | 'uidUsernameTransactionIdEmail'
    | 'transactionHistoryTypes'
    | 'paymentMethod'
    | 'amount'
    | 'status'
    | 'pendingStatuses'
    | 'closedStatuses'
    | 'creationDate'
    | 'duration'
    | 'agent'
    | 'country'
    | 'labels';

export type TransactionFilterKeys = 'text' | 'types' | 'paymentMethod' | 'amount' | 'status' | 'date' | 'agent' | 'country' | 'labels';
export type TransactionTextFilterKeys =
    | 'uid_em_un_rmc'
    | 'uid_un_tid_em'
    | 'uid'
    | 'username'
    | 'transactionId'
    | 'withdrawalId'
    | 'email'
    | 'register_marketing_code'
    | 'labelsText';
export type TransactionTextFilterValue = MultiKeyFilterTextValue<TransactionTextFilterKeys>;

export class TransactionFilterModel implements Record<TransactionFilterKeys, unknown> {
    text: TransactionTextFilterValue;
    types: string[];
    paymentMethod: string[];
    amount: ValueRange;
    status: TransactionStatusFilterValue;
    date: DateRangeFilterValue;
    agent: AgentFilterValue;
    country: string[];
    labels: string;
}
