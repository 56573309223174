export enum NoteType {
    Global = 'Global',
    Request = 'Request',
}

export enum Workspace {
    CustomerSupport = 'CustomerSupport',
    Global = 'Global',
    Kyc = 'KYC',
    Payment = 'Payment',
    Security = 'Security',
    Fraud = 'Fraud',
}

export type Timestamp = {
    seconds?: number;
    nanos?: number;
};

export type Attachment = {
    id?: string;
    extension?: string;
    filename?: string;
    size?: number;
    type?: string;
    url?: string;
    posted_at?: Timestamp;
    onDownload?: () => void;
    onRemove?: () => void;
};

export type Suggestion = {
    /** Mention modal title */
    text: string;
    /** Mention editor title */
    value: string;
    /** Mention link value */
    url: string;
};

export class CommentFormModel {
    body: string;
    users_tagged: string[];
    workspace?: Workspace;
    note_type: NoteType;
    attachments: FileList;
    attachmentsFinal: FileList;
    fileMeta?: Attachment[];
}

export const defaultCommentFormModel: CommentFormModel = {
    body: '',
    attachments: null,
    attachmentsFinal: null,
    users_tagged: [],
    workspace: null,
    note_type: NoteType.Global,
};

/**
 * @sse https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
 */
export type AcceptedFiles = {
    /** File MIME Type */
    [key: string]: {
        /** File extension starting with dot @ex '.pdf' */
        ext: string;
        /** Maximum file size in bytes */
        max: number;
    };
};
