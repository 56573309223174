import {inject, injectable} from 'inversify';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {ServiceTypes} from '@inversify';
import {BulkItemInput, KycStatusInput, UserAssignInput} from '@models/generated/graphql';
import {IAccountVerificationService} from '@services';
import {ServerResponseStatus} from '@services/types';

import {
    BulkActionKey,
    failedOperationId,
    IBulkStrategy,
    PerformStrategyOperationResponse,
    PerformStrategyRequest,
} from 'src/features/block-bulk-actions';

@injectable()
export class PerformKYCRejectStrategy implements IBulkStrategy<PerformStrategyRequest, PerformStrategyOperationResponse> {
    private _accountVerificationService: IAccountVerificationService;

    constructor(@inject(ServiceTypes.AccountVerificationService) accountVerificationService: IAccountVerificationService) {
        this._accountVerificationService = accountVerificationService;
    }

    process({items}: PerformStrategyRequest<KycStatusInput>): Observable<PerformStrategyOperationResponse> {
        const payload: BulkItemInput[] = items.map(i => ({id: i.itemId, data: {kyc_status: i.value}}));
        return this._accountVerificationService.rejectKYCBulk(payload).pipe(
            map(response => ({
                actionKey: BulkActionKey.KYCReject,
                id: response.status === ServerResponseStatus.Success ? response.responsePayload.id : failedOperationId,
            }))
        );
    }
}

@injectable()
export class PerformKYCAssignStrategy implements IBulkStrategy<PerformStrategyRequest, PerformStrategyOperationResponse> {
    private _accountVerificationService: IAccountVerificationService;

    constructor(@inject(ServiceTypes.AccountVerificationService) accountVerificationService: IAccountVerificationService) {
        this._accountVerificationService = accountVerificationService;
    }

    process({items}: PerformStrategyRequest<UserAssignInput>): Observable<PerformStrategyOperationResponse> {
        const payload: BulkItemInput[] = items.map(i => ({id: i.itemId, data: {user_assign: i.value}}));
        return this._accountVerificationService.assignUserBulk(payload).pipe(
            map(response => ({
                actionKey: BulkActionKey.KYCAssign,
                id: response.status === ServerResponseStatus.Success ? response.responsePayload.id : failedOperationId,
            }))
        );
    }
}
