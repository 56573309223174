import React, {memo} from 'react';
import {Typography} from '@mui/material';
import {GridCellParams} from '@mui/x-data-grid';
import {makeStyles} from 'tss-react/mui';

import {EmptyLabel} from '@components/label';
import StyledTooltip from '@components/Tooltip';
import {CustomTheme, SystemColorsEnum} from '@style';

import {CircleBox, CircleSizes} from './CircleBox';

//TODO: [BO-2686] Move files to src/common/components/user
const useStyles = makeStyles()((theme: CustomTheme) => ({
    avatarCircleMaximized: {
        textAlign: 'center',
        borderRadius: '50%',
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(2),
    },
    avatarCircleMinimized: {
        textAlign: 'center',
        borderRadius: '50%',
    },
    avatarCircleInactive: {
        opacity: 0.5,
    },
    userInformationContent: {
        display: 'flex',
        overflow: 'hidden',
    },
    username: {
        fontWeight: 'bold',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    email: {
        marginTop: theme.spacing(1),
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    userInfoHidden: {
        display: 'none',
    },
    userInformationTooltip: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: theme.spacing(1.5),
    },
}));

type UserInformationProps = {
    onlyCircle?: boolean;
    name?: string;
    username?: string;
    email?: string;
    color?: SystemColorsEnum;
    tooltip?: boolean;
    size?: CircleSizes;
    active?: boolean;
    letterFrom?: 'name' | 'username' | 'email';
};

export function UserInformationInternal({
    onlyCircle,
    email,
    name,
    username,
    color,
    size,
    letterFrom = 'username',
    tooltip = true,
    active = true,
}: UserInformationProps) {
    const {classes: styles, cx} = useStyles();
    const avatarCircleLetter = getFirstLetterString()?.charAt(0);
    const circleColor: SystemColorsEnum = color ?? getCircleColorByText();

    function getFirstLetterString() {
        let res = null;

        switch (letterFrom) {
            case 'name':
                res = name;
                break;
            case 'username':
                res = username;
                break;
            case 'email':
                res = email;
                break;
            default:
                res = username;
                break;
        }

        res = res ?? username ?? name;

        return res;
    }

    function getCircleColorByText() {
        const circleColors = [
            SystemColorsEnum.LightBlue,
            SystemColorsEnum.Bronze,
            SystemColorsEnum.LightGreen,
            SystemColorsEnum.Orange,
            SystemColorsEnum.LightGrey,
            SystemColorsEnum.DarkGrey,
            SystemColorsEnum.Violet,
            SystemColorsEnum.Coffee,
            SystemColorsEnum.Beige,
            SystemColorsEnum.Mint,
            SystemColorsEnum.Burgundy,
            SystemColorsEnum.SkyBlue,
            SystemColorsEnum.Pink,
            SystemColorsEnum.WarmYellow,
            SystemColorsEnum.Emerald,
            SystemColorsEnum.DarkTeal,
            SystemColorsEnum.Red,
            SystemColorsEnum.NavyBlue,
            SystemColorsEnum.Green,
            SystemColorsEnum.DarkPink,
            SystemColorsEnum.DarkViolet,
        ];

        const colorIndex = avatarCircleLetter.charCodeAt(0) % circleColors.length;
        return circleColors[colorIndex];
    }

    function renderCircle() {
        return (
            <div
                className={cx({
                    [styles.avatarCircleMinimized]: onlyCircle,
                    [styles.avatarCircleMaximized]: !onlyCircle,
                    [styles.avatarCircleInactive]: !active,
                })}
            >
                <CircleBox letter={avatarCircleLetter} color={circleColor} size={size} />
            </div>
        );
    }

    function renderTooltip() {
        return (
            <div className={styles.userInformationTooltip}>
                <CircleBox letter={avatarCircleLetter} color={circleColor} size="large" />
                <div>
                    <Typography variant="h6">{name ?? '-'}</Typography>
                    {email?.length ? <Typography variant="subtitle1">{email ?? '-'}</Typography> : null}
                </div>
            </div>
        );
    }

    return (
        <React.Fragment>
            <div className={styles.userInformationContent}>
                {tooltip ? <StyledTooltip element={renderCircle()} title={renderTooltip()} narrow={true} /> : renderCircle()}

                <div className={cx({[styles.userInfoHidden]: onlyCircle})}>
                    <div className={styles.username}>
                        <Typography variant="h6">{name ?? '-'}</Typography>
                    </div>
                    <div className={styles.email}>
                        <Typography variant="subtitle1">{username ?? '-'}</Typography>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export const UserInformation = memo(UserInformationInternal);

export type UserInformationCellProps = Pick<UserInformationProps, 'name' | 'email'>;

export function renderUserInformation(params: GridCellParams) {
    const props: UserInformationCellProps = params.value as UserInformationCellProps;
    return props ? (
        <UserInformation {...props} tooltip={true} onlyCircle={true} size="medium" letterFrom="name" />
    ) : (
        <EmptyLabel defaultSymbol="dash" />
    );
}
