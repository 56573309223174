import React, {PropsWithChildren} from 'react';
import {defineMessages, MessageDescriptor} from 'react-intl';
import {useSelector} from 'react-redux';
import {makeStyles} from 'tss-react/mui';

import {ErrorAlert} from '@components/alerts/InfoAlert';
import {IModuleGridItem} from '@components/data-grid';
import {EntityDataGridProps, gridMaxSize, MuiDataGridClientProps} from '@components/data-grid/mui';
import LocalizedText from '@components/i18n/LocalizedText';
import {Icon, IconColor} from '@components/icons/Icon';
import {CustomIcon} from '@components/icons/types';
import {EntityType} from '@redux/entity';

import {getErrorByCode} from '../../app/intl/shared-resources/serverResponse';
import {itemsSelector} from '../selectors';
import {BulkActionItemPayload, bulkItemFailedStatues, BulkItemStatus} from '../types';

import {BulkExecutionStepDetailsButton} from './BulkExecutionStepDetailsButton';
import {BulkExecutionStepTotalProgress} from './BulkExecutionStepTotalProgress';
import {
    BulkActionSummaryDetailsInfo,
    BulkActionSummaryDetailsInfoExpanded,
    BulkActionSummaryDetailsInfoNotExpandable,
} from './BulkSummaryStepActionItem';
import {BulkSummaryStepActionLabel} from './BulkSummaryStepActionLabel';
import {BulkTotalCountChip} from './BulkTotalCountChip';
import {BulkResultsRecord} from './DataGridBulkResults';

export const localized = defineMessages({
    bulkStepExecutionSummarySkippedLabel: {
        id: 'bulkStepExecutionSummarySkippedLabel',
        defaultMessage: 'Skipped',
    },
    bulkStepExecutionSummaryPartiallyAppliedLabel: {
        id: 'bulkStepExecutionSummaryPartiallyApplied',
        defaultMessage: 'Applied partially',
    },
    bulkStepExecutionSummaryAppliedLabel: {
        id: 'bulkStepExecutionSummaryAppliedLabel',
        defaultMessage: 'Applied',
    },
    bulkStepExecutionSummaryOperationNotCreated: {
        id: 'bulkStepExecutionSummary_OperationNotCreated',
        defaultMessage: 'Operation is failed',
    },
});

const useClasses = makeStyles()(() => ({
    bulkStepExecutionSummaryResultIcon: {
        fontSize: '1.5em',
    },
}));

type BulkStepSummaryContentDataGridModel<TEntity extends IModuleGridItem & BulkResultsRecord> = {
    successfulItems: TEntity[];
    partiallySuccessfulItems: TEntity[];
    failedItems: TEntity[];
};

function useExecutionData<TEntity extends IModuleGridItem & Pick<BulkResultsRecord, 'rowIndex'>>(
    actionItems: BulkActionItemPayload[],
    isOperationFailed: boolean
): BulkStepSummaryContentDataGridModel<TEntity & BulkResultsRecord> {
    const selectedItems = useSelector(itemsSelector) as TEntity[];

    function getSelectedItemById(id: string, index: number, value?: TEntity): TEntity {
        const item = selectedItems?.some(item => item.serverId) ? selectedItems?.find(r => r.serverId === id) : value;
        return {...item, rowIndex: item?.rowIndex ?? index};
    }

    function getPartiallySuccessfulRows(): (TEntity & BulkResultsRecord)[] {
        const successfulItems = isOperationFailed ? [] : actionItems?.filter(i => i.status === BulkItemStatus.PartiallySuccessful);
        return (
            successfulItems?.map((i, index) => ({
                ...getSelectedItemById(i?.itemId, index, i?.value as TEntity),
                error: undefined,
                rawErrorMessage: undefined,
            })) ?? []
        );
    }
    function getSuccessfulRows(): (TEntity & BulkResultsRecord)[] {
        const successfulItems = isOperationFailed ? [] : actionItems?.filter(i => i.status === BulkItemStatus.Successful);
        return (
            successfulItems?.map((i, index) => ({
                ...getSelectedItemById(i?.itemId, index, i?.value as TEntity),
                error: undefined,
                rawErrorMessage: undefined,
            })) ?? []
        );
    }

    function getFailedRows(): (TEntity & BulkResultsRecord)[] {
        const failedItems = isOperationFailed ? actionItems : actionItems?.filter(i => bulkItemFailedStatues.includes(i.status));
        return (
            failedItems?.map((i, index) => ({
                ...getSelectedItemById(i?.itemId, index, i?.value as TEntity),
                error: getErrorByCode(i.errorCode),
                rawErrorMessage: i.message,
            })) ?? []
        );
    }

    return {
        successfulItems: getSuccessfulRows(),
        partiallySuccessfulItems: getPartiallySuccessfulRows(),
        failedItems: getFailedRows(),
    };
}

export type BulkStepSummaryContentProps<T = unknown> = {
    actionItems: BulkActionItemPayload<T>[];
    actionKey: string;
    entityType: EntityType;
    isProcessingFinished: boolean;
    isOperationFailed: boolean;
    withFlexibleGrid?: boolean;
    label?: string | MessageDescriptor;
    getLabelParams?: (value: T) => any;
};

type ExecutionDetailsStatus = 'success' | 'partialSuccess' | 'failed';
type BulkDetailsInfoExpandedProps = {
    items: unknown[];
    size?: 'small' | 'normal';
    status: ExecutionDetailsStatus;
};

export function BulkDetailsInfoExpanded({items, children, size, status}: PropsWithChildren<BulkDetailsInfoExpandedProps>) {
    const {classes} = useClasses();

    function getLabel() {
        const labelMap: Record<ExecutionDetailsStatus, MessageDescriptor> = {
            failed: localized.bulkStepExecutionSummarySkippedLabel,
            partialSuccess: localized.bulkStepExecutionSummaryPartiallyAppliedLabel,
            success: localized.bulkStepExecutionSummaryAppliedLabel,
        };
        return labelMap[status];
    }

    const isSuccessful = status === 'success';

    return (
        <BulkActionSummaryDetailsInfoExpanded
            actionLabel={
                <>
                    <Icon
                        icon={isSuccessful ? CustomIcon.ConfirmCircle : CustomIcon.ErrorCircleOutline}
                        color={isSuccessful ? IconColor.Success : IconColor.Warning}
                        className={classes.bulkStepExecutionSummaryResultIcon}
                    />
                    <LocalizedText label={getLabel()} />
                </>
            }
            actionTotal={<BulkTotalCountChip totalItems={items?.length} chipType={isSuccessful ? 'successDark' : 'warningDark'} />}
            size={size}
        >
            {children}
        </BulkActionSummaryDetailsInfoExpanded>
    );
}

/**
 * used to show the details for particular action using data grid
 * @param WrappedDataGrid component to render action summary using data grid
 * @param columns columns that are shown in summary grid
 * @param expandable If expandable is false data grid will be always visible. Otherwise, it can be collapsed
 */
//TODO: add limitation for TEntity that will check if Entity has error and rawServerError properties
export const withBulkStepSummaryContentDataGrid =
    <TEntity extends IModuleGridItem & BulkResultsRecord & BulkActionItemPayload, TColumn extends string>(
        WrappedDataGrid: (props: EntityDataGridProps<MuiDataGridClientProps, TColumn, TEntity>) => JSX.Element,
        columns: TColumn[],
        expandable = false
    ) =>
    ({
        actionKey,
        entityType,
        actionItems,
        isProcessingFinished,
        isOperationFailed,
        withFlexibleGrid,
        label,
        getLabelParams,
    }: BulkStepSummaryContentProps) => {
        const {successfulItems, partiallySuccessfulItems, failedItems} = useExecutionData<TEntity>(actionItems, isOperationFailed);
        const isSuccessfulItemsVisible = successfulItems?.length > 0;
        const isPartiallySuccessfulItemsVisible = partiallySuccessfulItems?.length > 0;
        const isFailedItemsVisible = failedItems?.length > 0;
        return actionItems?.length > 0 ? (
            <BulkActionSummaryDetailsInfo
                key={`summaryDetails-${actionKey}`}
                expandable={expandable}
                actionKey={actionKey}
                actionLabel={
                    label ? (
                        <LocalizedText label={label} labelParams={getLabelParams ? getLabelParams(actionItems?.[0]?.value) : {}} />
                    ) : (
                        <BulkSummaryStepActionLabel actionKey={actionKey} labelValue={actionItems?.[0]?.labelValue} />
                    )
                }
                actionTotal={
                    <BulkExecutionStepTotalProgress
                        totalItems={actionItems?.length}
                        processedItems={
                            isOperationFailed
                                ? actionItems?.length
                                : [...successfulItems, ...partiallySuccessfulItems, ...failedItems]?.length
                        }
                        failedItems={isOperationFailed ? actionItems?.length : failedItems.length}
                        type={entityType}
                    />
                }
            >
                {isOperationFailed ? <ErrorAlert title={localized.bulkStepExecutionSummaryOperationNotCreated} /> : null}
                {isProcessingFinished && !isOperationFailed && isFailedItemsVisible ? (
                    <BulkDetailsInfoExpanded
                        status="failed"
                        items={failedItems}
                        size={isSuccessfulItemsVisible || isPartiallySuccessfulItemsVisible ? 'small' : 'normal'}
                    >
                        <WrappedDataGrid
                            rows={failedItems}
                            columns={columns}
                            rowsPerPageOptions={[gridMaxSize]}
                            hidePagination={failedItems.length < gridMaxSize}
                            autoHeight
                            isFlexible={withFlexibleGrid}
                        />
                    </BulkDetailsInfoExpanded>
                ) : (
                    <></>
                )}
                {isProcessingFinished && !isOperationFailed && isPartiallySuccessfulItemsVisible ? (
                    <BulkDetailsInfoExpanded
                        status="partialSuccess"
                        items={partiallySuccessfulItems}
                        size={isSuccessfulItemsVisible || isFailedItemsVisible ? 'small' : 'normal'}
                    >
                        <WrappedDataGrid
                            rows={partiallySuccessfulItems}
                            columns={columns}
                            rowsPerPageOptions={[gridMaxSize]}
                            hidePagination={partiallySuccessfulItems.length < gridMaxSize}
                            autoHeight
                            isFlexible={withFlexibleGrid}
                        />
                    </BulkDetailsInfoExpanded>
                ) : (
                    <></>
                )}
                {isProcessingFinished && !isOperationFailed && isSuccessfulItemsVisible ? (
                    <BulkDetailsInfoExpanded
                        status="success"
                        items={successfulItems}
                        size={isPartiallySuccessfulItemsVisible || isFailedItemsVisible ? 'small' : 'normal'}
                    >
                        <WrappedDataGrid
                            rows={successfulItems}
                            columns={columns}
                            rowsPerPageOptions={[gridMaxSize]}
                            hidePagination={successfulItems.length < gridMaxSize}
                            autoHeight
                            isFlexible={withFlexibleGrid}
                        />
                    </BulkDetailsInfoExpanded>
                ) : (
                    <></>
                )}
            </BulkActionSummaryDetailsInfo>
        ) : null;
    };

type BulkStepSummaryContentChipListProps = BulkStepSummaryContentProps & {
    getChipValue: (item: BulkActionItemPayload) => string;
};

/**
 * used to show the details for particular action using data grid
 * @param actionKey BulkActionKey
 * @param entityType entity type that used in bulk action
 * @param actionItems list of bulk items
 * @param isOperationFailed <b>true</b> if operation is not created
 * @param getChipValue function to get chip value from action value
 */
export function BulkStepSummaryContentChipList({
    actionKey,
    entityType,
    actionItems,
    isOperationFailed,
    getChipValue,
}: BulkStepSummaryContentChipListProps) {
    const successfulItems = actionItems?.filter(i => i.status === BulkItemStatus.Successful) ?? [];
    const failedItems = actionItems?.filter(i => bulkItemFailedStatues.includes(i.status)) ?? [];

    const getSuccessfulIds = (): string[] => {
        const successfulItems = isOperationFailed ? [] : actionItems?.filter(i => i.status === BulkItemStatus.Successful);
        return successfulItems?.map<string>(item => getChipValue(item)) ?? [];
    };

    const getFailedIds = (): string[] => {
        const failedItems = isOperationFailed ? actionItems : actionItems?.filter(i => bulkItemFailedStatues.includes(i.status));
        return failedItems?.map<string>(item => getChipValue(item)) ?? [];
    };

    return actionItems?.length > 0 ? (
        <BulkActionSummaryDetailsInfoNotExpandable
            key={`summaryDetails-${actionKey}`}
            actionKey={actionKey}
            actionLabel={
                <BulkSummaryStepActionLabel actionKey={actionKey} labelValue={actionItems?.[0]?.labelValue}></BulkSummaryStepActionLabel>
            }
            actionTotal={
                <BulkExecutionStepTotalProgress
                    totalItems={actionItems?.length}
                    processedItems={isOperationFailed ? actionItems?.length : [...successfulItems, ...failedItems]?.length}
                    failedItems={isOperationFailed ? actionItems?.length : failedItems.length}
                    type={entityType}
                >
                    <BulkExecutionStepDetailsButton applied={getSuccessfulIds()} missed={getFailedIds()} />
                </BulkExecutionStepTotalProgress>
            }
        />
    ) : null;
}
