import React from 'react';
import Autosizer from 'react-virtualized-auto-sizer';
import {FixedSizeList} from 'react-window';
import {makeStyles} from 'tss-react/mui';
import {v4 as uuid} from 'uuid';

import {MultiSelectItem, MultiSelectItemProps} from '@components/select/MultiSelectItem';
import {CustomTheme} from '@style';

import {SelectOption} from 'src/features/module-shared/types';

import {doesInclude} from './utils';

const useStyles = makeStyles<number>()((theme: CustomTheme, params) => ({
    virtualizedMultiSelectOptionListContainer: {
        height: theme.custom.menuItemHeight * params,
    },
}));

type VirtualizedMultiSelectOptionListProps<TValue extends unknown[]> = Pick<MultiSelectItemProps, 'chipType'> & {
    selectedValue: TValue;
    expandValue?: string[];
    items: SelectOption[];
    multiselectId: string;
    itemSize?: number;
    visibleOptionCount?: number;
    suboptionsWithCheckbox?: boolean;
    onExpand?: (item: TValue) => void;
    onCollapse?: (item: TValue) => void;
    onItemClick: (value: string) => void;
};

type LocalSelectOption = SelectOption & {hasSuboptions?: boolean; suboption?: boolean; divider?: boolean; autoSelected?: boolean};

export function VirtualizedMultiSelectOptionList<TValue extends unknown[]>({
    chipType,
    multiselectId,
    items,
    selectedValue,
    expandValue,
    visibleOptionCount = 6,
    suboptionsWithCheckbox = false,
    onItemClick,
    onExpand,
    onCollapse,
}: VirtualizedMultiSelectOptionListProps<TValue>) {
    const {classes, theme} = useStyles(visibleOptionCount);
    const flatItems: LocalSelectOption[] = items
        .map(i => {
            const hasSuboptions = i.subOptions?.length > 0;
            const result: LocalSelectOption[] = [];
            const isExpanded = doesInclude(expandValue, i.value.toString());
            if (hasSuboptions) {
                result.push({
                    ...i,
                    divider: !isExpanded,
                    hasSuboptions: true,
                });
                if (isExpanded) {
                    i.subOptions.forEach((j, index) =>
                        result.push({
                            ...j,
                            suboption: true,
                            autoSelected: i.autoSelectOnExpand,
                            divider: index === i.subOptions.length - 1,
                        })
                    );
                }
            } else {
                result.push(i);
            }
            return result;
        })
        .flat();

    return (
        <div className={classes.virtualizedMultiSelectOptionListContainer}>
            <Autosizer>
                {({height, width}) => (
                    <FixedSizeList
                        height={height}
                        width={width}
                        itemSize={(theme as CustomTheme).custom.menuItemHeight}
                        itemCount={flatItems?.length}
                    >
                        {({index, style}) => {
                            const item = flatItems[index];
                            return (
                                <MultiSelectItem
                                    key={`multiselect-${multiselectId}-${item.value}-${uuid()}`}
                                    value={item.value as string}
                                    chipValue={!item.autoSelected ? (item.value as string) : undefined}
                                    label={item.label}
                                    isSelected={doesInclude(selectedValue, item.value)}
                                    chipType={!(item.autoSelected || item.hasSuboptions) ? chipType : undefined}
                                    style={style}
                                    onClick={() => (!item.autoSelected ? onItemClick(item.value as string) : () => {})}
                                    checkbox={!item.autoSelected || suboptionsWithCheckbox}
                                    divider={item.divider}
                                    suboption={item.suboption}
                                    hasSuboptions={item.hasSuboptions}
                                    isExpanded={doesInclude(expandValue, item.value.toString())}
                                    onExpand={() => onExpand(item.value as TValue)}
                                    onCollapse={() => onCollapse(item.value as TValue)}
                                />
                            );
                        }}
                    </FixedSizeList>
                )}
            </Autosizer>
        </div>
    );
}
