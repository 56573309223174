import {MessageDescriptor} from 'react-intl';
import {PopoverOrigin, SelectProps as MuiSelectProps} from '@mui/material';

import {SelectOption} from 'src/features/module-shared/types';

import {EmptyPlaceholder} from './Select';

export enum SelectStyle {
    Default = 'default',
    Filter = 'filter',
}

export type SelectProps<T> = Pick<MuiSelectProps, 'startAdornment' | 'renderValue'> & {
    options: SelectOption[];
    value: T;
    onSubmit: (value: any) => void;
    enumFormatter?: (value: string) => string | MessageDescriptor;
    multiple?: boolean;
    hasRadio?: boolean;
    selectStyle?: SelectStyle;
    emptyPlaceholder?: EmptyPlaceholder;
    emptyValue?: MessageDescriptor | string;
    label?: string | MessageDescriptor;
    iconLabel?: JSX.Element;
    disabled?: boolean;
    chipType?: string;
    isSelectedValueChip?: boolean;
    showResetButton?: boolean;
    onOpen?: () => void;
    onClose?: () => void;
    horizontalPosition?: PopoverOrigin['horizontal'];
    className?: string;
    hasSearch?: boolean;
    searchPlaceholder?: string | MessageDescriptor;
    mode?: 'server' | 'client';
    hasSelectAll?: boolean;
    onFilterChange?: (filter: string) => void;
    onPageChange?: (newPage: number) => void;
    showPagination?: boolean;
    page?: number;
    pageSize?: number;
    total?: number;
    onSelectAll?: (isSelectAll: boolean) => void;
    defaultSelectAll?: boolean;
    showApplyButton?: boolean;
    onApply?: () => void;
    virtualization?: boolean;
    popoverClassName?: string;
};
