import React from 'react';
import {defineMessages, useIntl} from 'react-intl';

import {OutlinedButton} from '@components/button/Buttons';
import {ChipType, ChipVariant} from '@components/chip/types';
import {OutlinedButtonDropdown} from '@components/dropdown/Dropdown';
import {DropdownChipItem} from '@components/dropdown/DropdownChipItem';
import {Icon, IconColor} from '@components/icons/Icon';
import {CustomIcon} from '@components/icons/types';
import {PaymentVendor, ReasonCode, TransactionStatus} from '@models/generated/graphql';

import {localizedReasonCode} from '../../app/intl/shared-resources/transactionReasonCode';
import {LackOfPermissionIndicator, withMultiplePermission} from '../../app/permission/PermissionHoc';
import {useChangeWithdrawalStatus} from '../hooks';
import {withdrawalPaymentUpdatePermissions, withdrawalRiskUpdatePermissions} from '../permissions';
import {ActionType} from '../types';

const localized = defineMessages({
    rejectionReason: {
        id: 'withdrawalChangeStatusButtons_RejectionReason',
        defaultMessage: 'Rejection reason',
    },
    onHoldReasonsTitle: {
        id: 'withdrawalChangeStatusButtons_onHoldReasonsTitle',
        defaultMessage: 'Reason',
    },
    withdrawalDecline: {
        id: 'WithdrawalChangeStatusButtons_withdrawalDecline',
        defaultMessage: 'Decline',
    },
    withdrawalApprove: {
        id: 'WithdrawalChangeStatusButtons_withdrawalApprove',
        defaultMessage: 'Approve',
    },
    withdrawalOnHold: {
        id: 'WithdrawalChangeStatusButtons_withdrawalOnHold',
        defaultMessage: 'On Hold',
    },
});

type WithdrawalStatusButtonProps = {
    transactionId: string;
    transactionStatus: TransactionStatus;
    isRiskStatus: boolean;
    disabled?: boolean;
    uid: string;
};

function WithdrawalChangeStatusButtonsInner({transactionId, transactionStatus, isRiskStatus, disabled, uid}: WithdrawalStatusButtonProps) {
    const {handleChangeStatus, isApproveDisabled, isOnHoldDisabled, isDeclineDisabled} = useChangeWithdrawalStatus(
        transactionId,
        transactionStatus,
        isRiskStatus,
        uid
    );

    return (
        <>
            <ApproveButtonMemo onClick={handleChangeStatus} disabled={disabled || isApproveDisabled} />
            <OnHoldButtonMemo onClick={handleChangeStatus} disabled={disabled || isOnHoldDisabled} />
            <DeclineButtonMemo onClick={handleChangeStatus} disabled={disabled || isDeclineDisabled} />
        </>
    );
}

type StatusButtonProps = {
    onClick: (status: ActionType, reason?: ReasonCode) => void;
    disabled: boolean;
};

function ApproveButton({onClick, disabled}: StatusButtonProps) {
    const {formatMessage} = useIntl();
    return (
        <OutlinedButton
            onClick={() => onClick('Approve')}
            label={formatMessage(localized.withdrawalApprove)}
            startIcon={<Icon icon={CustomIcon.Confirm} color={IconColor.Success} />}
            disabled={disabled}
            fullWidth
        />
    );
}

const ApproveButtonMemo = React.memo(ApproveButton);

function OnHoldButton({onClick, disabled}: StatusButtonProps) {
    const {formatMessage} = useIntl();

    const onHoldReasons: ReasonCode[] = [
        ReasonCode.UnderInvestigation,
        ReasonCode.Threshold24hrs,
        ReasonCode.KycPending,
        ReasonCode.PspNoFunds,
        ReasonCode.GameplayReview,
        ReasonCode.MgmtReview,
        ReasonCode.Other,
    ];

    return (
        <OutlinedButtonDropdown
            label={formatMessage(localized.withdrawalOnHold)}
            startIcon={<Icon icon={CustomIcon.ClockOutline} color={IconColor.InProgress} />}
            listTitle={localized.onHoldReasonsTitle}
            disabled={disabled}
            fullWidth
        >
            {onHoldReasons.map(reason => (
                <DropdownChipItem
                    key={reason}
                    onClick={() => onClick('OnHold', reason)}
                    value={formatMessage(localizedReasonCode[reason])}
                    type={ChipType.Mark}
                    variant={ChipVariant.DropdownDefault}
                    data-testid={`${reason}_listItem`}
                />
            ))}
        </OutlinedButtonDropdown>
    );
}

const OnHoldButtonMemo = React.memo(OnHoldButton);

function DeclineButton({onClick, disabled}: StatusButtonProps) {
    const {formatMessage} = useIntl();

    const rejectReasons = [
        ReasonCode.DepositNotFullyWagered,
        ReasonCode.KycPendingFailed,
        ReasonCode.NoCloseLoop,
        ReasonCode.FraudulentActivities,
        ReasonCode.ThirdDeposit,
        ReasonCode.Other,
    ];

    return (
        <OutlinedButtonDropdown
            label={formatMessage(localized.withdrawalDecline)}
            startIcon={<Icon icon={CustomIcon.CancelOutline} />}
            listTitle={localized.rejectionReason}
            disabled={disabled}
            fullWidth
        >
            {rejectReasons.map(reason => (
                <DropdownChipItem
                    key={reason}
                    onClick={() => onClick('Decline', reason)}
                    value={formatMessage(localizedReasonCode[reason])}
                    type={ChipType.Mark}
                    variant={ChipVariant.DropdownDefault}
                    data-testid={`${reason}_listItem`}
                />
            ))}
        </OutlinedButtonDropdown>
    );
}

const DeclineButtonMemo = React.memo(DeclineButton);

export const WithdrawalChangeRiskStatusButtons = withMultiplePermission(WithdrawalChangeStatusButtonsInner, {
    allowedPermissions: withdrawalRiskUpdatePermissions,
    indicator: LackOfPermissionIndicator.Disabled,
});

export const WithdrawalChangePaymentStatusButtons = withMultiplePermission(WithdrawalChangeStatusButtonsInner, {
    allowedPermissions: withdrawalPaymentUpdatePermissions,
    indicator: LackOfPermissionIndicator.Disabled,
});

type WithdrawalChangeStatusActionsProps = {
    transactionId: string;
    transactionStatus: TransactionStatus;
    uid: string;
    paymentVendor: PaymentVendor;
};

export function WithdrawalChangeStatusActions({transactionId, transactionStatus, paymentVendor, uid}: WithdrawalChangeStatusActionsProps) {
    const isRiskStatus = [TransactionStatus.Pending, TransactionStatus.OnHoldRisk].includes(transactionStatus);
    const isStatusChangeDisabled = [PaymentVendor.PremierCashier].includes(paymentVendor);

    return isRiskStatus ? (
        <WithdrawalChangeRiskStatusButtons
            uid={uid}
            transactionId={transactionId}
            transactionStatus={transactionStatus}
            isRiskStatus={isRiskStatus}
            disabled={transactionStatus === null || isStatusChangeDisabled}
        />
    ) : (
        <WithdrawalChangePaymentStatusButtons
            uid={uid}
            transactionId={transactionId}
            transactionStatus={transactionStatus}
            isRiskStatus={isRiskStatus}
            disabled={transactionStatus === null || isStatusChangeDisabled}
        />
    );
}
