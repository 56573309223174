import {createMap, forMember, mapFrom, Mapper} from '@automapper/core';

import {IMapping} from '@auto-mapper';
import {BoUserSettings} from '@models/generated/graphql';

import {BoUserSettingsViewModel} from './types';

export class BoUserSettingsMapping implements IMapping {
    createMapping(mapper: Mapper): void {
        createMap<BoUserSettings, BoUserSettingsViewModel>(
            mapper,
            nameof<BoUserSettings>(),
            nameof<BoUserSettingsViewModel>(),
            forMember(
                vm => vm.personalDailyCreditLimit,
                mapFrom(m => m.personal_daily_credit_limit)
            ),
            forMember(
                vm => vm.remainingCreditAmount,
                mapFrom(m => m.remaining_credit_amount)
            ),
            forMember(
                vm => vm.totalDaulyCreditLimit,
                mapFrom(m => m.total_daily_credit_limit)
            )
        );
    }
}
