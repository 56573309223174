import {injectable} from 'inversify';

import {BulkItemStatus, UserAssignInput} from '@models/generated/graphql';

import {ApplyStrategyResponse, BulkActionItemPayload, BulkActionKey, IBulkApplyStrategy} from '../../block-bulk-actions';
import {BulkDebitCreditAddModel, P2PTransferModel} from '../types';

export type ApplyManualTransactionsRequest = {transactions: BulkDebitCreditAddModel[]};

@injectable()
export class ApplyManualTransactionsStrategy implements IBulkApplyStrategy<ApplyManualTransactionsRequest> {
    process({transactions}: ApplyManualTransactionsRequest): ApplyStrategyResponse {
        const items = transactions.map<BulkActionItemPayload>(item => ({
            actionKey: BulkActionKey.ManualTransactions,
            value: item,
            itemId: item.serverId,
            status: BulkItemStatus.Pending,
        }));

        return {actionKey: BulkActionKey.ManualTransactions, items};
    }
}

export type ApplyP2PTransfersRequest = {transactions: P2PTransferModel[]};

@injectable()
export class ApplyP2PTransfersStrategy implements IBulkApplyStrategy<ApplyP2PTransfersRequest> {
    process({transactions}: ApplyP2PTransfersRequest): ApplyStrategyResponse {
        const items = transactions.map<BulkActionItemPayload>(item => ({
            actionKey: BulkActionKey.P2PTransfer,
            value: item,
            itemId: item.id,
            status: BulkItemStatus.Pending,
        }));

        return {actionKey: BulkActionKey.P2PTransfer, items};
    }
}

export type TransactionAssignData = UserAssignInput;
export type TransactionAssignRequest = {items: TransactionAssignData[]};

@injectable()
export class ApplyTransactionAssignStrategy implements IBulkApplyStrategy<TransactionAssignRequest> {
    public process({items}: TransactionAssignRequest): ApplyStrategyResponse<TransactionAssignData> {
        const actionKey: BulkActionKey = BulkActionKey.TransactionAssign;
        return {
            actionKey,
            items:
                items?.map(item => ({
                    actionKey,
                    value: {user_id: item?.user_id, id: item?.id} as UserAssignInput,
                    itemId: item?.id,
                    status: BulkItemStatus.Pending,
                })) ?? [],
        };
    }
}
