import React from 'react';

import {FilterProps, SelectFilter} from '@components/filter';
import {SelectFilterProps} from '@components/filter/SelectFilter';
import {ReasonCode, TransactionStatus} from '@models/generated/graphql';
import {TransactionStatusWithReason} from '@models/transaction/types';
import {removeItemsFromArray} from '@utils/array';

import {localizedReasonCode} from '../../app/intl/shared-resources/transactionReasonCode';
import {localizedTransactionStatus} from '../../app/intl/shared-resources/transactionStatus';
import {SelectOption} from '../../module-shared/types';

export type TransactionStatusFilterValue = {status: TransactionStatus; reason?: ReasonCode}[];

const onHoldRiskReasons: SelectOption<TransactionStatusWithReason>[] = [
    {label: localizedReasonCode[ReasonCode.UnderInvestigation], value: 'OnHoldRisk.UnderInvestigation'},
    {label: localizedReasonCode[ReasonCode.Threshold24hrs], value: 'OnHoldRisk.Threshold24hrs'},
    {label: localizedReasonCode[ReasonCode.KycPending], value: 'OnHoldRisk.KYCPending'},
    {label: localizedReasonCode[ReasonCode.PspNoFunds], value: 'OnHoldRisk.PSPNoFunds'},
    {label: localizedReasonCode[ReasonCode.GameplayReview], value: 'OnHoldRisk.GameplayReview'},
    {label: localizedReasonCode[ReasonCode.MgmtReview], value: 'OnHoldRisk.MgmtReview'},
    {label: localizedReasonCode[ReasonCode.Other], value: 'OnHoldRisk.Other'},
];

const onHoldPaymentReasons: SelectOption<TransactionStatusWithReason>[] = [
    {label: localizedReasonCode[ReasonCode.UnderInvestigation], value: 'OnHoldPayment.UnderInvestigation'},
    {label: localizedReasonCode[ReasonCode.Threshold24hrs], value: 'OnHoldPayment.Threshold24hrs'},
    {label: localizedReasonCode[ReasonCode.KycPending], value: 'OnHoldPayment.KYCPending'},
    {label: localizedReasonCode[ReasonCode.PspNoFunds], value: 'OnHoldPayment.PSPNoFunds'},
    {label: localizedReasonCode[ReasonCode.GameplayReview], value: 'OnHoldPayment.GameplayReview'},
    {label: localizedReasonCode[ReasonCode.MgmtReview], value: 'OnHoldPayment.MgmtReview'},
    {label: localizedReasonCode[ReasonCode.Other], value: 'OnHoldPayment.Other'},
];

const subOptionMapper: Partial<Record<TransactionStatus, SelectOption[]>> = {
    [TransactionStatus.OnHoldRisk]: onHoldRiskReasons,
    [TransactionStatus.OnHoldPayment]: onHoldPaymentReasons,
};
const subOptionValuesMapper: Partial<Record<TransactionStatus, TransactionStatusWithReason[]>> = {
    [TransactionStatus.OnHoldRisk]: onHoldRiskReasons?.map(o => o?.value),
    [TransactionStatus.OnHoldPayment]: onHoldPaymentReasons?.map(o => o?.value),
};

type TransactionStatusFilterProps = Pick<SelectFilterProps, 'label'> &
    FilterProps<TransactionStatusFilterValue> & {
        options: TransactionStatus[];
    };

export function TransactionStatusFilter({value, onChange, label, options}: TransactionStatusFilterProps) {
    const filterValue: (TransactionStatus | TransactionStatusWithReason)[] = getFilterValue(value);

    function handleChange(newValue: (TransactionStatus | TransactionStatusWithReason)[]) {
        const result: TransactionStatusFilterValue = [];
        if (newValue?.length) {
            Object.entries(subOptionValuesMapper).forEach(
                ([status, subOptionsValues]: [TransactionStatus, TransactionStatusWithReason[]]) => {
                    if (subOptionsValues?.every(v => newValue?.includes(v))) {
                        newValue = removeItemsFromArray(newValue, ...subOptionsValues);
                        newValue.push(status);
                    }
                }
            );

            newValue?.forEach(v => {
                const slices = v?.split('.');
                if (slices?.length > 1) {
                    result.push({status: slices[0] as TransactionStatus, reason: slices[1] as ReasonCode});
                } else {
                    result.push({status: slices[0] as TransactionStatus});
                }
            });
        }
        onChange(result);
    }

    function getFilterValue(value: TransactionStatusFilterValue): (TransactionStatus | TransactionStatusWithReason)[] {
        let filterValue: (TransactionStatus | TransactionStatusWithReason)[] = value?.map(v =>
            v?.reason ? (`${v?.status}.${v?.reason}` as TransactionStatusWithReason) : v?.status
        );
        Object.entries(subOptionValuesMapper).forEach(([status, subOptionsValues]: [TransactionStatus, TransactionStatusWithReason[]]) => {
            if (filterValue?.includes(status)) {
                filterValue = removeItemsFromArray(filterValue, status);
                filterValue.push(...subOptionsValues);
            }
        });
        return filterValue;
    }

    function getOptions() {
        return options?.map<SelectOption>(s => {
            const subOptions = subOptionMapper[s];
            return {
                label: localizedTransactionStatus[s],
                value: subOptions?.length ? subOptions?.map(o => o?.value as string) : s,
                chipValue: s,
                subOptions,
            };
        });
    }

    return (
        <SelectFilter
            multiple
            label={label}
            value={filterValue}
            chipType={nameof<TransactionStatus>()}
            options={getOptions()}
            onChange={handleChange}
        />
    );
}
