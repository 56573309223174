import {constructUsing, createMap, extend, forMember, mapFrom, Mapper, mapWith} from '@automapper/core';

import {IMapping} from '@auto-mapper';
import {
    Transaction as GqlTransaction,
    TransactionHistory,
    UserLabel as GqlUserLabel,
    UserLabelGroup as GqlUserLabelGroup,
} from '@models/generated/graphql';
import {getTransactionDuration} from '@models/transaction/utils';
import {TransactionQueryFields} from '@redux/entity';
import {formatTimestamp, IsNever} from '@utils';

import {TransactionColumnSettingsKeys} from './types';
import {
    StatusLog,
    TransactionDownloadModel,
    TransactionDuration,
    TransactionViewModel,
    TransactionViewModelKeys,
    UserLabelGroupViewModel,
    UserLabelViewModel,
} from './types';

export class TransactionMapping implements IMapping {
    createMapping(mapper: Mapper): void {
        createMap(
            mapper,
            TransactionHistory,
            StatusLog,
            forMember(
                vm => vm.status,
                mapFrom(m => m.status)
            ),
            forMember(
                vm => vm.logged_at,
                mapFrom(m => m.logged_at)
            )
        );
        createMap(
            mapper,
            GqlTransaction,
            TransactionDuration,
            forMember(
                vm => vm.history,
                mapWith(StatusLog, TransactionHistory, m => m.status_log)
            ),
            forMember(
                vm => vm.started_at,
                mapFrom(m => m.transaction_started_ts)
            )
        );
        createMap(
            mapper,
            GqlUserLabelGroup,
            UserLabelGroupViewModel,
            forMember(
                vm => vm.id,
                mapFrom(m => m.id)
            ),
            forMember(
                vm => vm.name,
                mapFrom(m => m.name)
            ),
            forMember(
                vm => vm.color,
                mapFrom(m => m.color)
            ),
            forMember(
                vm => vm.source_type,
                mapFrom(m => m.source_type)
            )
        );
        createMap(
            mapper,
            GqlUserLabel,
            UserLabelViewModel,
            forMember(
                vm => vm.id,
                mapFrom(m => m.id)
            ),
            forMember(
                vm => vm.name,
                mapFrom(m => m.name)
            ),
            forMember(
                vm => vm.group,
                mapWith(UserLabelGroupViewModel, GqlUserLabelGroup, m => m.group)
            )
        );
        createMap(
            mapper,
            GqlTransaction,
            TransactionViewModel,
            forMember(
                vm => vm.amount,
                mapFrom(m => m.amount)
            ),
            forMember(
                vm => vm.duration,
                mapWith(TransactionDuration, GqlTransaction, m => m)
            ),
            forMember(
                vm => vm.amount_without_currency,
                mapFrom(m => m.amount)
            ),
            forMember(
                vm => vm.counterpart_player_id,
                mapFrom(m => {
                    const paymentDescription = m.payment_method_description;
                    const parts = paymentDescription?.split('_');
                    return parts?.length > 0 ? parts[0] : null;
                })
            ),
            forMember(
                vm => vm.created_by_uid,
                mapFrom(m => m.created_by_uid)
            ),
            forMember(
                vm => vm.currency,
                mapFrom(m => m.currency)
            ),
            forMember(
                vm => vm.current_balance,
                mapFrom(m => m.current_balance)
            ),
            forMember(
                vm => vm.email,
                mapFrom(m => m.contact?.email)
            ),
            forMember(
                vm => vm.id,
                mapFrom(m => m.transaction_id)
            ),
            forMember(
                vm => vm.payment_method_name,
                mapFrom(m => m.payment_method_name)
            ),
            forMember(
                vm => vm.payment_method_description,
                mapFrom(m => m.payment_method_description)
            ),
            forMember(
                vm => vm.player_country,
                mapFrom(m => m.iso_alpha2_country_code)
            ),
            forMember(
                vm => vm.status_log,
                mapWith(StatusLog, TransactionHistory, m => m.status_log)
            ),
            forMember(
                vm => vm.transaction_id,
                mapFrom(m => m.transaction_id)
            ),
            forMember(
                vm => vm.transaction_type,
                mapFrom(m => m.type)
            ),
            forMember(
                vm => vm.transaction_started_ts,
                mapFrom(m => m.transaction_started_ts)
            ),
            forMember(
                vm => vm.transaction_updated_ts,
                mapFrom(m => m.transaction_updated_ts)
            ),
            forMember(
                vm => vm.transaction_status,
                mapFrom(m => m.transaction_status)
            ),
            forMember(
                vm => vm.uid,
                mapFrom(m => m.uid)
            ),
            forMember(
                vm => vm.username,
                mapFrom(m => m.username)
            ),
            forMember(
                vm => vm.phone,
                mapFrom(m => m?.contact?.mobile?.full_number)
            ),
            forMember(
                vm => vm.referrer_player_id,
                mapFrom(m => m?.referrer_player_id)
            ),
            forMember(
                vm => vm.withdrawal_id,
                mapFrom(m => m.transaction_id)
            ),
            forMember(
                vm => vm.register_marketing_code,
                mapFrom(m => m.register_marketing_code)
            ),
            forMember(
                vm => vm.user_labels,
                mapWith(UserLabelViewModel, GqlUserLabel, m => m.user_labels)
            ),
            forMember(
                vm => vm.current_casino_coin_balance,
                mapFrom(m => m.current_casino_coin_balance)
            ),
            forMember(
                vm => vm.last_reason,
                mapFrom(m => m.last_reason)
            )
        );
        createMap(
            mapper,
            GqlTransaction,
            TransactionDownloadModel,
            extend(GqlTransaction, TransactionViewModel),
            forMember(
                vm => vm.transaction_started_ts,
                mapFrom(m => formatTimestamp(m.transaction_started_ts, 'date-time'))
            ),
            forMember(
                vm => vm.transaction_updated_ts,
                mapFrom(m => formatTimestamp(m.transaction_updated_ts, 'date-time'))
            ),
            forMember(
                vm => vm.user_labels,
                mapFrom(m => m.user_labels?.map(label => label?.name)?.join())
            ),
            forMember(
                vm => vm.duration,
                mapFrom(m => {
                    const started_at = m.transaction_started_ts;
                    const history = m.status_log;
                    return getTransactionDuration({history, started_at});
                })
            ),
            forMember(
                vm => vm.assignee,
                mapFrom(m => m?.assigned_user_id)
            )
        );
        createMap<TransactionViewModelKeys[], TransactionQueryFields[]>(
            mapper,
            nameof<TransactionViewModelKeys>(),
            nameof<TransactionQueryFields>(),
            constructUsing((m, _) => {
                const mapper: Record<TransactionViewModelKeys, TransactionQueryFields[]> = {
                    id: ['transaction_id'],
                    created_by_uid: ['created_by_uid'],
                    status_log: ['status_log.logged_at.seconds', 'status_log.status'],
                    amount: ['amount', 'currency'],
                    amount_without_currency: ['amount'],
                    counterpart_player_id: ['payment_method_description'],
                    currency: ['currency'],
                    current_balance: ['current_balance'],
                    current_casino_coin_balance: ['current_casino_coin_balance'],
                    duration: ['status_log.status', 'status_log.logged_at.seconds', 'transaction_started_ts'],
                    email: ['contact.email', 'email'],
                    payment_method_description: ['payment_method_description'],
                    payment_method_name: ['payment_method_name'],
                    phone: ['contact.mobile'],
                    player_country: ['iso_alpha2_country_code'],
                    referrer_player_id: ['referrer_player_id'],
                    transaction_id: ['transaction_id'],
                    transaction_started_ts: ['transaction_started_ts'],
                    transaction_status: ['transaction_status', 'last_reason'],
                    transaction_type: ['type'],
                    type: ['type'],
                    uid: ['uid'],
                    username: ['username'],
                    withdrawal_id: ['transaction_id'],
                    transaction_updated_ts: ['transaction_updated_ts'],
                    register_marketing_code: ['register_marketing_code'],
                    user_labels: [
                        'user_labels.id',
                        'user_labels.name',
                        'user_labels.group.id',
                        'user_labels.group.name',
                        'user_labels.group.color',
                    ],
                    p2p_direction: ['type'],
                    assignee: ['assigned_user_id'],
                    last_reason: ['last_reason'],
                };

                return [...new Set(m.flatMap(i => mapper[i]))];
            })
        );
        createMap<TransactionViewModelKeys, TransactionColumnSettingsKeys>(
            mapper,
            nameof<TransactionViewModelKeys>(),
            nameof<TransactionColumnSettingsKeys>(),
            constructUsing(m => {
                type FieldsToMap = Exclude<TransactionViewModelKeys, TransactionColumnSettingsKeys>;
                const isNever: IsNever<FieldsToMap> = true;
                if (!isNever) {
                    throw new Error('Mapping should be defined for all values');
                }
                return m;
            })
        );
        createMap<TransactionColumnSettingsKeys, TransactionViewModelKeys>(
            mapper,
            nameof<TransactionColumnSettingsKeys>(),
            nameof<TransactionViewModelKeys>(),
            constructUsing(m => {
                type FieldsToMap = Exclude<TransactionColumnSettingsKeys, TransactionViewModelKeys | '__type'>;
                const isNever: IsNever<FieldsToMap> = true;
                if (!isNever) {
                    throw new Error('Mapping should be defined for all values');
                }
                return m === '__type' ? undefined : m;
            })
        );
    }
}
