import React, {useEffect} from 'react';
import {Controller, FormProvider, useForm, useWatch} from 'react-hook-form';
import {defineMessages, MessageDescriptor} from 'react-intl';
import {Box, Grid} from '@mui/material';
import equal from 'fast-deep-equal';
import {makeStyles} from 'tss-react/mui';

import Button, {OutlinedButton} from '@components/button/Buttons';
import {SelectButton} from '@components/select';
import {CustomTheme} from '@style';

import {FilterProps} from '../types';

import {useSelectBoxValue, useValidation} from './hooks';
import {OperatorSelect} from './OperatorSelect';
import {RelativeRange} from './RelativeRange';
import {ValueRange} from './types';
import {defaultValueRangeModel, Operator, ValueRangeModel} from './types';

export type ValueRangeFilterProps = FilterProps<ValueRange> & {
    label: string | MessageDescriptor;
    min?: number;
    max?: number;
    hideEqualOperator?: boolean;
};

const localized = defineMessages({
    operator: {
        id: 'ValueRangeFilter_operator',
        defaultMessage: 'Operator',
    },
    clearAll: {
        id: 'ValueRangeFilter_clearAll',
        defaultMessage: 'Clear All',
    },
    apply: {
        id: 'ValueRangeFilter_apply',
        defaultMessage: 'Apply',
    },
});

const useClasses = makeStyles()((theme: CustomTheme) => ({
    valueRangeFilterContainer: {
        padding: theme.spacing(2),
    },
    valueRangeFilterClearAll: {
        width: '100%',
    },
    valueRangeFilterApply: {
        borderRadius: theme.custom.roundedButtonRadius,
        border: 0,
        width: '100%',
    },
    valueRangeFilterButtonFullWidth: {
        width: '100%',
        '& .MuiButton-startIcon': {
            marginRight: 'auto',
        },
    },
}));

export function ValueRangeFilter({label, value, onChange, width = 'full', min, max, hideEqualOperator}: ValueRangeFilterProps) {
    const {classes, cx} = useClasses();
    const methods = useForm<ValueRangeModel>({defaultValues: defaultValueRangeModel});
    const {reset, control, handleSubmit} = methods;
    const watched = useWatch({control});

    const validateToValue = ['less', 'between'].includes(watched.operator);
    const validateFromValue = ['greater', 'between', 'equals'].includes(watched.operator);

    const validate = useValidation({
        min,
        max,
        validateFromValue,
        validateToValue,
    });

    const parsedValue: ValueRangeModel = {
        operator: getOperator(value),
        value: {
            from: value?.from,
            to: value?.to,
        },
    };

    const selectedText = useSelectBoxValue(parsedValue);

    useEffect(() => {
        if (!equal(watched, parsedValue)) {
            reset(parsedValue);
        }
    }, [value?.from, value?.to]);

    useEffect(() => {
        if (parsedValue.operator !== watched.operator) {
            const {value} = defaultValueRangeModel;

            reset({
                operator: watched.operator,
                value,
            });
        }
    }, [watched.operator]);

    function getOperator(value: ValueRange) {
        let res: Operator;
        if (!isNaN(value?.from) && !isNaN(value?.to)) {
            if (value?.from === value?.to) {
                res = 'equals';
            } else {
                res = 'between';
            }
        } else if (!isNaN(value?.from) && isNaN(value?.to)) {
            res = 'greater';
        } else {
            res = 'less';
        }
        return res;
    }

    function onSubmit(data: ValueRangeModel) {
        onChange({
            from: data?.value?.from,
            to: data?.operator === 'equals' ? data?.value?.from : data?.value?.to,
        });
    }

    function handleClear() {
        methods.reset(defaultValueRangeModel);

        const {value} = defaultValueRangeModel;
        onChange(value);
    }

    return (
        <SelectButton
            label={label}
            selectedText={selectedText}
            styles={{
                dropdownButton: cx(width === 'full' && classes.valueRangeFilterButtonFullWidth),
                dropdownContainer: classes.valueRangeFilterContainer,
            }}
        >
            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Box>
                        <Controller
                            render={({field, fieldState}) => (
                                <OperatorSelect
                                    label={localized.operator}
                                    value={field.value}
                                    onChange={field.onChange}
                                    key={field.name}
                                    fieldState={fieldState}
                                    hideEqualOperator={hideEqualOperator}
                                />
                            )}
                            control={control}
                            name="operator"
                        />
                        <Controller
                            render={({field, fieldState}) => (
                                <RelativeRange
                                    showTo={validateToValue}
                                    showFrom={validateFromValue}
                                    value={field.value}
                                    fieldState={fieldState}
                                    onChange={field.onChange}
                                    label={label}
                                />
                            )}
                            control={control}
                            name="value"
                            rules={{validate}}
                        />
                        <Grid container spacing={1}>
                            <Grid item xs={12} md={6}>
                                <OutlinedButton
                                    className={classes.valueRangeFilterClearAll}
                                    label={localized.clearAll}
                                    onClick={handleClear}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Button type="submit" className={classes.valueRangeFilterApply} color="primary" label={localized.apply} />
                            </Grid>
                        </Grid>
                    </Box>
                </form>
            </FormProvider>
        </SelectButton>
    );
}
