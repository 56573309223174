import {Mapper} from '@automapper/core';
import {inject, injectable} from 'inversify';
import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

import {ServiceTypes} from '@inversify';
import {BulkItemInput, BulkMutationInput, ManualTransactionInput, UserAssignInput} from '@models/generated/graphql';
import {ITransactionService} from '@services';
import {ServerResponseStatus} from '@services/types';

import {
    BulkActionKey,
    failedOperationId,
    IBulkStrategy,
    PerformStrategyOperationResponse,
    PerformStrategyRequest,
} from '../../block-bulk-actions';
import {ManualTransactionAddViewModel} from '../../block-transaction-actions';
import {BulkDebitCreditAddModel, P2PTransferModel} from '../types';

@injectable()
export class PerformManualTransactionsStrategy
    implements IBulkStrategy<PerformStrategyRequest<BulkDebitCreditAddModel>, PerformStrategyOperationResponse>
{
    private _transactionService: ITransactionService;
    private _mapper: Mapper;

    constructor(
        @inject(ServiceTypes.TransactionService) transactionService: ITransactionService,
        @inject(ServiceTypes.AutoMapper) mapper: Mapper
    ) {
        this._transactionService = transactionService;
        this._mapper = mapper;
    }

    process({items}: PerformStrategyRequest<BulkDebitCreditAddModel>): Observable<PerformStrategyOperationResponse> {
        const transactionViewModels: BulkDebitCreditAddModel[] = items.map(i => i.value);
        const transactionModel: ManualTransactionInput[] = transactionViewModels.map((i: BulkDebitCreditAddModel) => {
            const model = this._mapper.map(i, ManualTransactionAddViewModel, ManualTransactionInput);
            model.id = i.id;
            return model;
        });

        const bulkInput: BulkMutationInput = {
            timeout: 10000,
            retries: 0,
            items: transactionModel.map<BulkItemInput>(transaction => ({
                id: transaction.id,
                data: {transaction},
            })),
        };

        return this._transactionService.addManualTransactions(bulkInput).pipe(
            map(response => ({
                actionKey: BulkActionKey.ManualTransactions,
                id: response?.status === ServerResponseStatus.Success ? response?.responsePayload?.id : failedOperationId,
                errorMessage: response?.errors?.[0]?.code,
            })),
            catchError(() => of({actionKey: BulkActionKey.ManualTransactions, id: failedOperationId}))
        );
    }
}

@injectable()
export class PerformP2PTransfersStrategy
    implements IBulkStrategy<PerformStrategyRequest<P2PTransferModel>, PerformStrategyOperationResponse>
{
    private _transactionService: ITransactionService;

    constructor(@inject(ServiceTypes.TransactionService) transactionService: ITransactionService) {
        this._transactionService = transactionService;
    }

    process({items}: PerformStrategyRequest<P2PTransferModel>): Observable<PerformStrategyOperationResponse> {
        const timeout = 10000;
        const retries = 3;
        const payload: BulkMutationInput = {
            timeout,
            retries,
            items: items.map(i => ({
                id: i.itemId,
                data: {
                    p2p_transfer: {
                        amount: i.value?.amount,
                        uid: i.value?.uid,
                        type: i.value?.transaction_type,
                    },
                },
            })),
        };

        return this._transactionService.addP2PTransfers(payload).pipe(
            map(response => ({
                actionKey: BulkActionKey.P2PTransfer,
                id: response?.status === ServerResponseStatus.Success ? response?.responsePayload?.id : failedOperationId,
            })),
            catchError(() => of({actionKey: BulkActionKey.P2PTransfer, id: failedOperationId}))
        );
    }
}

@injectable()
export class PerformTransactionAssignStrategy implements IBulkStrategy<PerformStrategyRequest, PerformStrategyOperationResponse> {
    private _transactionService: ITransactionService;

    constructor(@inject(ServiceTypes.TransactionService) transactionService: ITransactionService) {
        this._transactionService = transactionService;
    }

    process({items}: PerformStrategyRequest<UserAssignInput>): Observable<PerformStrategyOperationResponse> {
        const payload: BulkItemInput[] = items.map(i => ({id: i.itemId, data: {user_assign: i.value}}));
        return this._transactionService.assignTransactionBulk(payload).pipe(
            map(response => ({
                actionKey: BulkActionKey.TransactionAssign,
                id: response.status === ServerResponseStatus.Success ? response.responsePayload.id : failedOperationId,
            }))
        );
    }
}
