import React, {useContext} from 'react';

import {CustomIcon, Icon} from '@components/icons';
import {BulkModalContext} from '@components/modal';
import {useService} from '@inversify';
import {UserProfileQueryFields} from '@redux/entity';

import {BulkActionButton, BulkActionKey, useBulkAction} from '../../block-bulk-actions';
import {LoadPlayerItemsStrategy} from '../services/loadPlayerItemsStrategy';

import {BulkUserProfileChooseActionsModal} from './BulkUserProfileChooseActionsModal';
import {BulkUserProfileExecutionStepModal} from './BulkUserProfileExecutionStepModal';
import {BulkUserProfileSummaryStepModal} from './BulkUserProfileSummaryStepModal';

type BulkPlayerButtonProps = {
    uids: string[];
    actionKeys: BulkActionKey[];
};

export const BulkUserProfileChooseActionsStepButton = ({uids, actionKeys}: BulkPlayerButtonProps) => {
    const loadStrategy = useService<LoadPlayerItemsStrategy>('LoadPlayerItemsStrategy');
    const {chooseStep, closeModal} = useContext(BulkModalContext);
    const fields: UserProfileQueryFields[] = [
        'uid',
        'nickname',
        'first_name',
        'last_name',
        'account_status',
        'agent_info.bo_agent_id',
        'deposit_status',
        'casino_status',
        'sportsbook_status',
        'withdrawal_status',
        'lobby_access_status',
        'p2p_transfer_status',
        'sportsbook_status',
        'security_cases.case_id',
        'security_cases.type',
        'latest_kyc.security.account_verification_status',
        'business_pool',
        'pool_id',
    ];
    const {resetBulkState} = useBulkAction({strategy: loadStrategy, request: {uids, fields}});

    const openChangesSummary = () => chooseStep(changesSummaryStep);
    const openExecutionSummary = () => chooseStep(executionSummaryStep);
    const openChooseAction = () => chooseStep(chooseActionStep);

    const chooseActionStep = (
        <BulkUserProfileChooseActionsModal
            onNext={openChangesSummary}
            onPrev={closeModal}
            onApply={openChooseAction}
            actionKeys={actionKeys}
        />
    );
    const changesSummaryStep = <BulkUserProfileSummaryStepModal onNext={openExecutionSummary} onPrev={closeModal} />;
    const executionSummaryStep = <BulkUserProfileExecutionStepModal onNext={closeModal} hasRetry={true} />;

    const handleClick = () => {
        resetBulkState();
        openChooseAction();
    };

    return (
        <BulkActionButton
            itemsCount={uids.length}
            onClick={handleClick}
            startIcon={<Icon icon={CustomIcon.ArrowRightSquareOutline} fontSize="small" />}
        />
    );
};
