import React, {useContext} from 'react';
import {defineMessages} from 'react-intl';

import {CustomIcon, Icon} from '@components/icons';
import {BulkModalContext} from '@components/modal';

import {BulkActionButton, useBulkAction} from '../../block-bulk-actions';

import {BulkBonusMttApplyStep} from './BulkBonusApplyStep';
import {BulkBonusMttChangesSummaryStep} from './BulkBonusChangesSummaryStep';
import {BulkBonusMttExecutionStep} from './BulkBonusExecutionStep';
import {BulkBonusSelectUsersModal} from './BulkBonusSelectUsersModal';

const localized = defineMessages({
    buttonLabel: {
        id: 'BonusEngineBulkButton_label',
        defaultMessage: 'Multi Manual Trigger',
    },
});

type BonusEngineBulkButtonProps = {
    selectedIds: string[];
};

export function BulkBonusEngineButton({selectedIds}: BonusEngineBulkButtonProps) {
    const {chooseStep, closeModal} = useContext(BulkModalContext);
    const {resetBulkState} = useBulkAction();

    const openSelectUsersStep = () => chooseStep(selectUsersStep, 'md');
    const openApplyStep = () => chooseStep(applyStep, 'md');
    const openChangesSummary = () => chooseStep(changesSummaryStep, 'md');
    const openExecutionSummary = () => chooseStep(executionSummaryStep, 'md');

    const selectUsersStep = <BulkBonusSelectUsersModal selectedBonusesIds={selectedIds} onNext={openApplyStep} onPrev={closeModal} />;
    const applyStep = <BulkBonusMttApplyStep onNext={openChangesSummary} onPrev={closeModal} />;
    const changesSummaryStep = <BulkBonusMttChangesSummaryStep onNext={openExecutionSummary} onPrev={closeModal} />;
    const executionSummaryStep = <BulkBonusMttExecutionStep onNext={closeModal} hasRetry={true} />;

    const handleClick = () => {
        resetBulkState();
        openSelectUsersStep();
    };

    return (
        <BulkActionButton
            label={localized.buttonLabel}
            startIcon={<Icon icon={CustomIcon.ListPlayOutline} />}
            itemsCount={selectedIds?.length}
            onClick={handleClick}
        />
    );
}
