import * as React from 'react';
import {defineMessages, MessageDescriptor} from 'react-intl';

import LocalizedText from '@components/i18n/LocalizedText';

import {BulkActionKey} from '../types';

const localized = defineMessages({
    bulkActionSummaryLabelAccountLock: {
        id: 'bulkActionSummaryLabelAccountLock',
        defaultMessage: 'Account Lock',
    },
    bulkActionSummaryLabelLobbyLock: {
        id: 'bulkActionSummaryLabelLobbyLock',
        defaultMessage: 'Lobby Lock',
    },
    bulkActionSummaryLabelDepositLock: {
        id: 'bulkActionSummaryLabelDepositLock',
        defaultMessage: 'Deposit Lock',
    },
    bulkActionSummaryLabelWithdrawalLock: {
        id: 'bulkActionSummaryLabelWithdrawalLock',
        defaultMessage: 'Withdrawal Lock',
    },
    bulkActionSummaryLabelCasinoLock: {
        id: 'bulkActionSummaryLabelCasinoLock',
        defaultMessage: 'Casino Lock',
    },
    bulkActionSummaryLabelSportsbookLock: {
        id: 'bulkActionSummaryLabelSportsbookLock',
        defaultMessage: 'Sportsbook Lock',
    },
    bulkActionSummaryLabelP2PLock: {
        id: 'bulkActionSummaryLabelP2PLock',
        defaultMessage: 'P2P Transfer',
    },
    bulkActionSummaryLabelAddSecurityCaseID: {
        id: 'bulkActionSummaryLabelAddSecurityCaseID',
        defaultMessage: 'Add Security Case ID',
    },
    bulkActionSummaryLabelUpdateSecurityCaseID: {
        id: 'bulkActionSummaryLabelUpdateSecurityCaseID',
        defaultMessage: 'Update Security Case ID',
    },
    bulkActionSummaryLabelRemoveSecurityCaseID: {
        id: 'bulkActionSummaryLabelRemoveSecurityCaseID',
        defaultMessage: 'Remove Security Case ID',
    },
    bulkActionSummaryLabelSendAMessage: {
        id: 'bulkActionSummaryLabelSendAMessage',
        defaultMessage: 'Send a Message',
    },
    bulkActionSummaryLabelAddNotesAndAttachment: {
        id: 'bulkActionSummaryLabelAddNotesAndAttachment',
        defaultMessage: 'Add Notes and Attachment',
    },
    bulkActionSummaryLabelBonusManualTrigger: {
        id: 'bulkActionSummaryLabelBonusManualTrigger',
        defaultMessage: 'Addition Manual Trigger',
    },
    bulkActionSummaryLabelManualTransactions: {
        id: 'bulkActionSummaryLabelManualTransactions',
        defaultMessage: 'Add Transactions',
    },
    bulkActionSummaryLabelAddUserLabel: {
        id: 'bulkActionSummaryLabelAddUserLabel',
        defaultMessage: 'Assign Label {labelValue}',
    },
    bulkActionSummaryLabelRemoveUserLabel: {
        id: 'bulkActionSummaryLabelRemoveUserLabel',
        defaultMessage: 'Delete Label {labelValue}',
    },
    bulkActionSummaryLabelP2PTransfer: {
        id: 'bulkActionSummaryLabelP2PTransfer',
        defaultMessage: 'P2P Transfer',
    },
    bulkActionSummaryLabelRejectKYC: {
        id: 'bulkActionSummaryLabelRejectKYC',
        defaultMessage: 'Reject KYC',
    },
    bulkActionSummaryLabelInitiatePaymentKYC: {
        id: 'bulkActionSummaryLabelInitiatePaymentKYC',
        defaultMessage: 'Initiate Payment KYC',
    },
});

type BulkActionSummaryLabelProps = {
    actionKey: string;
    labelValue?: string;
};

const useLabel = (type: BulkActionKey): MessageDescriptor => {
    const mapping: Record<BulkActionKey, MessageDescriptor> = {
        [BulkActionKey.AccountLock]: localized.bulkActionSummaryLabelAccountLock,
        [BulkActionKey.DepositLock]: localized.bulkActionSummaryLabelDepositLock,
        [BulkActionKey.LobbyLock]: localized.bulkActionSummaryLabelLobbyLock,
        [BulkActionKey.WithdrawalLock]: localized.bulkActionSummaryLabelWithdrawalLock,
        [BulkActionKey.P2PTransferLock]: localized.bulkActionSummaryLabelP2PLock,
        [BulkActionKey.CasinoLock]: localized.bulkActionSummaryLabelCasinoLock,
        [BulkActionKey.SportsbookLock]: localized.bulkActionSummaryLabelSportsbookLock,
        [BulkActionKey.SecurityCasesAdd]: localized.bulkActionSummaryLabelAddSecurityCaseID,
        [BulkActionKey.SecurityCasesRemove]: localized.bulkActionSummaryLabelRemoveSecurityCaseID,
        [BulkActionKey.SecurityCasesUpdate]: localized.bulkActionSummaryLabelUpdateSecurityCaseID,
        [BulkActionKey.Message]: localized.bulkActionSummaryLabelSendAMessage,
        [BulkActionKey.NotesAndAttachments]: localized.bulkActionSummaryLabelAddNotesAndAttachment,
        [BulkActionKey.ManualTriggerDepositCashMatch]: localized.bulkActionSummaryLabelBonusManualTrigger,
        [BulkActionKey.ManualTriggerMTTTicket]: localized.bulkActionSummaryLabelBonusManualTrigger,
        [BulkActionKey.ManualTriggerImmediateCashBonus]: localized.bulkActionSummaryLabelBonusManualTrigger,
        [BulkActionKey.ManualTransactions]: localized.bulkActionSummaryLabelManualTransactions,
        [BulkActionKey.AddUserLabel]: localized.bulkActionSummaryLabelAddUserLabel,
        [BulkActionKey.RemoveUserLabel]: localized.bulkActionSummaryLabelRemoveUserLabel,
        [BulkActionKey.P2PTransfer]: localized.bulkActionSummaryLabelP2PTransfer,
        [BulkActionKey.KYCReject]: localized.bulkActionSummaryLabelRejectKYC,
        [BulkActionKey.SecurityCases]: undefined,
        [BulkActionKey.SecurityCasesAll]: undefined,
        [BulkActionKey.ManualTriggerDepositCashMatchAmount]: undefined,
        [BulkActionKey.ManualTriggerDepositCashMatchCode]: undefined,
        [BulkActionKey.InitiatePaymentKYC]: localized.bulkActionSummaryLabelInitiatePaymentKYC,
        [BulkActionKey.KYCAssign]: undefined,
        [BulkActionKey.TransactionAssign]: undefined,
    };

    return mapping[type];
};

export const BulkSummaryStepActionLabel = ({actionKey, labelValue}: BulkActionSummaryLabelProps) => {
    const label = useLabel(actionKey as BulkActionKey);

    return <LocalizedText label={label} labelParams={{labelValue}}></LocalizedText>;
};
