import {BaseFilterKeys, EntityFetchRequestPayload, Filter} from '@redux/entity';

export abstract class BaseRequestBuilder<TQueryFields extends string, TFilterKeys extends string = BaseFilterKeys> {
    public abstract buildRequest(requestPayload: EntityFetchRequestPayload<TQueryFields>): unknown;

    protected isFilterInvalid({invalid}: Filter): boolean {
        return <boolean>invalid;
    }

    protected mapToFilterObject(filter: string): Filter<TFilterKeys> {
        const result: Filter<TFilterKeys> = {};
        const searchParams = new URLSearchParams(filter);
        searchParams.forEach((value, key) => {
            result[key as TFilterKeys] = value;
        });
        return result;
    }

    protected toMultiselectFilter<TResult>(filter: Filter<TFilterKeys>, filterName: TFilterKeys): TResult {
        return filterName in filter
            ? ((this.getMultiselectFilterList(filter[filterName] as string) ?? filter[filterName]) as TResult)
            : undefined;
    }

    private getMultiselectFilterList(serializedFilter: string): string[] {
        try {
            const value = JSON.parse(serializedFilter);
            return Array.isArray(value) ? value?.flatMap(v => v) : null;
        } catch (e) {
            return null;
        }
    }
}
