import React, {ForwardedRef} from 'react';
import {Close} from '@mui/icons-material';
import {Box, Chip as MuiChip} from '@mui/material';

import {StyledTooltip, StyledTooltipProps} from '../Tooltip';

import {useChipStyles} from './Chips.style';
import {ChipProps, ChipType} from './types';

export function Chip({fillWidth = true, cursorPointer, ...props}: ChipProps) {
    const {classes: chipClasses, cx} = useChipStyles(props);
    const {chipType, startIconClass, className, isCrossedOut, classes, ...otherProps} = props;
    const typeClassName = (chipClasses as any)[`chipType${ChipType[chipType]}`];
    const deleteIcon = props.deleteIcon ? props.deleteIcon : <Close />;
    return (
        <MuiChip
            className={cx(typeClassName, chipClasses.chipVariantColor, {
                [className]: !!className,
                [chipClasses.chipCursorPointer]: cursorPointer,
            })}
            variant="outlined"
            size="small"
            color="default"
            icon={startIconClass ? <Box component="span" className={startIconClass}></Box> : null}
            data-testid="chip"
            {...otherProps}
            classes={{
                label: cx(isCrossedOut && chipClasses.chipLabelCrossedOut, classes?.label),
                icon: cx(chipClasses.chipIcon, classes?.icon),
                ...classes,
            }}
            style={{width: fillWidth ? '100%' : 'unset'}}
            deleteIcon={deleteIcon}
        ></MuiChip>
    );
}

const ChipWithRef = React.forwardRef((props: ChipProps, ref: ForwardedRef<HTMLDivElement>): React.JSX.Element => {
    return <Chip {...props} ref={ref} />;
});

export function ChipWithTooltip({title, hideTooltip, ...chipProps}: Omit<ChipProps, 'title'> & Omit<StyledTooltipProps, 'element'>) {
    return (
        <StyledTooltip
            element={
                <div>
                    <ChipWithRef {...chipProps}></ChipWithRef>
                </div>
            }
            title={title}
            hideTooltip={hideTooltip}
        ></StyledTooltip>
    );
}
