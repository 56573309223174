import {BaseSyntheticEvent, ChangeEvent, useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {SelectChangeEvent} from '@mui/material';

import {removeItemsFromArray} from '@utils/array';

import {SelectOption} from 'src/features/module-shared/types';

import {SelectProps} from './types';
import {SelectFormattedOption, selectOptionFilter} from './utils';

export type UseMultiSelectProps<T extends unknown[]> = Pick<
    SelectProps<T>,
    'defaultSelectAll' | 'mode' | 'options' | 'value' | 'onApply' | 'onFilterChange' | 'onPageChange' | 'onSelectAll' | 'onSubmit'
>;

export type UseMultiSelectResult<T extends unknown[]> = {
    expandValue: string[];
    inputValue: string;
    selectAll: boolean;
    selectOpen: boolean;
    selectedValue: T;
    visibleOptions: SelectOption[];
    handleApply: () => void;
    handleChange?: (event: SelectChangeEvent<T>) => void;
    handleClearSelection: (event: BaseSyntheticEvent) => void;
    handleClose: () => void;
    handleCollapse: (item: T) => void;
    handleExpand: (item: T) => void;
    handleFilterChange: (page: number) => void;
    handleItemClick?: (itemValue: string) => void;
    handleOpen: () => void;
    handleSearchChange?: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    handleSelectAll: () => void;
};

export const selectAllOptionValue = 'all';

export function useMultiSelect<T extends unknown[]>({
    defaultSelectAll,
    mode = 'client',
    options,
    value,
    onApply,
    onFilterChange,
    onPageChange,
    onSelectAll,
    onSubmit,
}: UseMultiSelectProps<T>): UseMultiSelectResult<T> {
    const {formatMessage} = useIntl();

    const [selectedValue, setSelectedValue] = useState([] as T);
    const [selectOpen, setSelectOpen] = useState(false);
    const [visibleOptions, setVisibleOptions] = useState<SelectOption[]>(options);
    const [inputValue, setInputValue] = useState('');
    const [selectAll, setSelectAll] = useState(defaultSelectAll);
    const [expandValue, setExpandValue] = useState<string[]>([]);

    useEffect(() => {
        setSelectAll(defaultSelectAll);
    }, [defaultSelectAll]);

    useEffect(() => {
        if (value) {
            setSelectedValue(value);
        }
    }, [Array.isArray(value) ? value.join() : value]);

    useEffect(() => {
        if (options) {
            setVisibleOptions(options);
        }
    }, [options?.map(o => `${o.label}${o.value}`).join()]);

    const handleClose = () => {
        setSelectOpen(false);
    };

    const handleOpen = () => {
        setSelectOpen(true);
    };

    function handleGroupChange(initArray: T, updatedArray: T): T {
        const fullSet = new Set([...initArray, ...updatedArray]);
        if ([...fullSet].length > initArray.length) {
            return [...fullSet] as T;
        }
        return removeItemsFromArray(initArray, ...updatedArray) as T;
    }

    function handleChange(event: SelectChangeEvent<T>) {
        const value = event.target.value;
        const array = Array.isArray(value) ? value : [value];
        let result = array;
        if (array.length > 0 && Array.isArray(array[array.length - 1])) {
            const updatedArray = array.splice(-1, 1)[0] as T;
            result = handleGroupChange(array as T, updatedArray);
        }
        if (result[result.length - 1] !== selectAllOptionValue) {
            setSelectedValue(result as T);
            onSubmit(result.length > 0 ? result : null);
        }
    }

    function handleExpand(item: T) {
        const stringItem = item.toString();
        if (!expandValue?.includes(stringItem)) {
            setExpandValue([...expandValue, stringItem]);
        }
    }

    function handleCollapse(item: T) {
        const stringItem = item.toString();
        if (expandValue?.includes(stringItem)) {
            setExpandValue(removeItemsFromArray(expandValue, stringItem));
        }
    }

    function handleItemClick(itemValue: string) {
        let result: T;
        if (selectedValue?.includes(itemValue)) {
            result = removeItemsFromArray(selectedValue, itemValue) as T;
        } else {
            result = [...selectedValue, itemValue] as T;
        }
        handleChange({target: {value: result}} as SelectChangeEvent<T>);
    }

    function handleClearSelection(e: React.BaseSyntheticEvent) {
        e.stopPropagation();
        setSelectedValue([] as T);
        onSubmit(null);
    }

    function handleSearchChange(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
        e.stopPropagation();
        const filterValue = e.target.value as string;
        setInputValue(filterValue);
        if (mode === 'client') {
            const filteredOptions = selectOptionFilter(getFormattedOptions(options), filterValue);
            setVisibleOptions(filteredOptions);
            if (onFilterChange) {
                onFilterChange(filterValue);
            }
        } else {
            onFilterChange(filterValue);
        }
    }

    function handleSelectAll() {
        if (onSelectAll) {
            onSelectAll(!selectAll);
        } else {
            const newValue = selectAll ? ([] as T) : (options?.map(o => o.value) as T);
            setSelectedValue(newValue);
            onSubmit(newValue);
            setSelectAll(!selectAll);
        }
    }

    function handleFilterChange(page: number) {
        if (mode === 'client') {
            throw new Error('method not implemented');
        } else {
            onPageChange(page);
        }
    }

    function handleApply() {
        if (onApply) {
            onApply();
        }
        handleClose();
    }

    function getFormattedOptions(options: SelectOption[]): SelectFormattedOption[] {
        return options?.map(o => ({...o, label: o?.label ? (typeof o.label === 'string' ? o.label : formatMessage(o.label)) : null}));
    }

    return {
        expandValue,
        inputValue,
        selectAll,
        selectOpen,
        selectedValue,
        visibleOptions,
        handleApply,
        handleChange,
        handleClearSelection,
        handleClose,
        handleCollapse,
        handleExpand,
        handleFilterChange,
        handleItemClick,
        handleOpen,
        handleSearchChange,
        handleSelectAll,
    };
}
