import {defineMessages, MessageDescriptor} from 'react-intl';

import {TransactionViewModelKeys} from '@models/transaction';

const timestamp: MessageDescriptor = {
    id: 'DataGridTransaction_Timestamp',
    defaultMessage: 'Timestamp',
};

const amount: MessageDescriptor = {
    id: 'DataGridTransaction_Amount',
    defaultMessage: 'Amount',
};

export const localizedTransactionDataGridHeaders = defineMessages<TransactionViewModelKeys>({
    uid: {
        id: 'DataGridTransaction_Uid',
        defaultMessage: 'User ID',
    },
    transaction_id: {
        id: 'DataGridTransaction_TransactionId',
        defaultMessage: 'Transaction ID',
    },
    type: {
        id: 'DataGridTransaction_Direction',
        defaultMessage: 'Direction',
    },
    amount: amount,
    amount_without_currency: amount,
    transaction_started_ts: timestamp,
    transaction_updated_ts: timestamp,
    transaction_type: {
        id: 'DataGridTransaction_TransactionType',
        defaultMessage: 'Type',
    },
    payment_method_name: {
        id: 'DataGridTransaction_PaymentMethod',
        defaultMessage: 'Method',
    },
    payment_method_description: {
        id: 'DataGridTransaction_PaymentMethodDescription',
        defaultMessage: 'Reference',
    },
    currency: {
        id: 'DataGridTransaction_Currency',
        defaultMessage: 'Currency',
    },
    transaction_status: {
        id: 'DataGridTransaction_TransactionStatus',
        defaultMessage: 'Status',
    },
    current_balance: {
        id: 'DataGridTransaction_CurrentBalance',
        defaultMessage: 'Balance',
    },
    current_casino_coin_balance: {
        id: 'DataGridTransaction_CurrentCasinoCoinBalance',
        defaultMessage: 'Casino Coin Balance',
    },
    withdrawal_id: {
        id: 'DataGridTransaction_WithdrawalId',
        defaultMessage: 'Withdrawal ID',
    },
    email: {
        id: 'DataGridTransaction_Email',
        defaultMessage: 'Email Address',
    },
    duration: {
        id: 'DataGridTransaction_Duration',
        defaultMessage: 'Duration',
    },
    p2p_direction: {
        id: 'DataGridTransaction_P2PDirection',
        defaultMessage: 'Direction',
    },
    phone: {
        id: 'DataGridTransaction_Phone',
        defaultMessage: 'Phone number',
    },
    username: {
        id: 'DataGridTransaction_Username',
        defaultMessage: 'Username',
    },
    register_marketing_code: {
        id: 'DataGridTransaction_MarketingCode',
        defaultMessage: 'Marketing Code',
    },
    referrer_player_id: {
        id: 'DataGridTransaction_ReferrerPlayerId',
        defaultMessage: 'Referral ID',
    },
    player_country: {
        id: 'DataGridTransaction_Country',
        defaultMessage: 'Country',
    },
    counterpart_player_id: {
        id: 'DataGridTransaction_CounterpartPlayerId',
        defaultMessage: 'Counterpart player ID',
    },
    user_labels: {
        id: 'DataGridTransaction_userLabels',
        defaultMessage: 'Label',
    },
    created_by_uid: null,
    id: null,
    status_log: null,
    assignee: {
        id: 'DataGridTransaction_assignee',
        defaultMessage: 'Assignee',
    },
    last_reason: null,
});
