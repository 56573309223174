import {SelectOption} from '../../../features/module-shared/types';

export type SelectFormattedOption = Omit<SelectOption, 'label'> & {label: string};

export function selectOptionFilter(options: SelectFormattedOption[], filter: string) {
    return options?.filter(o => !filter || o.label?.toLowerCase()?.startsWith(filter.toLowerCase()));
}

export function doesInclude(a: any, b: any) {
    if (Array.isArray(a) && Array.isArray(b)) return b.every(bi => a.includes(bi));
    return a.indexOf(b) > -1;
}
