import {defineMessages} from 'react-intl';

import {ReasonCode} from '@models/generated/graphql';

export const localizedReasonCode = defineMessages<ReasonCode>({
    [ReasonCode.Undefined]: {
        id: 'reasonCodeUndefined',
        defaultMessage: 'Undefined',
    },
    [ReasonCode.Other]: {
        id: 'reasonCodeOther',
        defaultMessage: 'Others',
    },
    [ReasonCode.Fraud]: {
        id: 'reasonCodeFraud',
        defaultMessage: 'Fraudulent Activity',
    },
    [ReasonCode.Confiscation]: {
        id: 'reasonCodeConfiscation',
        defaultMessage: 'Confiscation',
    },
    [ReasonCode.ConfiscationBonusAbuse]: {
        id: 'reasonConfiscationBonusAbuse',
        defaultMessage: 'Confiscation - Bonus Abuse',
    },
    [ReasonCode.ConfiscationRpf]: {
        id: 'reasonConfiscationRpf',
        defaultMessage: 'Confiscation - RPF',
    },
    [ReasonCode.ConfiscationSec]: {
        id: 'reasonConfiscationSec',
        defaultMessage: 'Confiscation - Sec',
    },
    [ReasonCode.Collusion]: {
        id: 'reasonCodeCollusion',
        defaultMessage: 'Collusion',
    },
    [ReasonCode.Cheating]: {
        id: 'reasonCodeCheating',
        defaultMessage: 'Cheating',
    },
    [ReasonCode.GoodwillGesture]: {
        id: 'reasonCodeGoodwillGesture',
        defaultMessage: 'Goodwill Gesture',
    },
    [ReasonCode.Compensation]: {
        id: 'reasonCodeCompensation',
        defaultMessage: 'Compensation',
    },
    [ReasonCode.DepositNotFullyWagered]: {
        id: 'reasonCodeDepositNotFullyWagered',
        defaultMessage: 'Deposit not fully wagered',
    },
    [ReasonCode.KycPendingFailed]: {
        id: 'reasonCodeKYCPendingFailed',
        defaultMessage: 'KYC Pending/Failed',
    },
    [ReasonCode.NoCloseLoop]: {
        id: 'reasonCodeNoCloseLoop',
        defaultMessage: 'No-close loop',
    },
    [ReasonCode.FraudulentActivities]: {
        id: 'reasonCodeFraudulentActivities',
        defaultMessage: 'Fraudulent Activities',
    },
    [ReasonCode.ThirdDeposit]: {
        id: 'reasonCodeThirdDeposit',
        defaultMessage: '3rd Deposit',
    },
    [ReasonCode.CancelledByPlayer]: {
        id: 'reasonCodeCancelledByPlayer',
        defaultMessage: 'Cancelled by player',
    },
    [ReasonCode.PspFailed]: {
        id: 'reasonCodePSPFailed',
        defaultMessage: 'PSP Fail',
    },
    [ReasonCode.ReachRGlimits]: {
        id: 'reasonCodeReachRGlimits',
        defaultMessage: 'Reach RG Limits',
    },
    [ReasonCode.KycRequired]: {
        id: 'reasonCodeKYCRequired',
        defaultMessage: 'KYC Required',
    },
    [ReasonCode.SecurityCase]: {
        id: 'reasonCodeSecurityCase',
        defaultMessage: 'Security Case',
    },
    [ReasonCode.PspError]: {
        id: 'reasonCodePSPError',
        defaultMessage: 'PSP Error',
    },
    [ReasonCode.Test]: {
        id: 'reasonCodeTest',
        defaultMessage: 'Test',
    },
    [ReasonCode.Correction]: {
        id: 'reasonCodeCorrection',
        defaultMessage: 'Correction',
    },
    [ReasonCode.ManualWithdrawal]: {
        id: 'reasonCodeManualWithdrawal',
        defaultMessage: 'Manual Withdrawal',
    },
    [ReasonCode.RefundChargeback]: {
        id: 'reasonCodeRefundChargeback',
        defaultMessage: 'Refund Chargeback',
    },
    [ReasonCode.ManualDeposit]: {
        id: 'reasonCodeManualDeposit',
        defaultMessage: 'Manual Deposit',
    },
    [ReasonCode.Bonus]: {
        id: 'reasonCodeBonus',
        defaultMessage: 'Bonus',
    },
    [ReasonCode.P2pTransfer]: {
        id: 'reasonCodeP2pTransfer',
        defaultMessage: 'P2P Transfer',
    },
    [ReasonCode.RakeBack]: {
        id: 'reasonCodeRakeBack',
        defaultMessage: 'Rake Back',
    },
    [ReasonCode.BountyBonus]: {
        id: 'reasonCodeBountyBonus',
        defaultMessage: 'Bounty Bonus',
    },
    [ReasonCode.Marketing]: {
        id: 'reasonCodeMarketing',
        defaultMessage: 'Marketing',
    },
    [ReasonCode.RevenueShare]: {
        id: 'reasonCodeRevenueShare',
        defaultMessage: 'Revenue Share',
    },
    [ReasonCode.CasinoManualBonus]: {
        id: 'reasonCodeCasinoManualBonus',
        defaultMessage: 'Casino Manual Bonus',
    },
    [ReasonCode.OtherCost]: {
        id: 'reasonCodeCostToOperatorPoker',
        defaultMessage: 'Cost to operator - Poker',
    },
    [ReasonCode.OtherRevenue]: {
        id: 'reasonCodeCostToOperatorCasino',
        defaultMessage: 'Cost to operator - Casino',
    },
    [ReasonCode.RevShareAffiliate]: {
        id: 'reasonCodeRevShareAffiliate',
        defaultMessage: 'Revshare (Affiliate)',
    },
    [ReasonCode.RevShareReferral]: {
        id: 'reasonCodeRevShareReferral',
        defaultMessage: 'Revshare (Referral)',
    },
    [ReasonCode.GameplayReview]: {
        id: 'reasonCodeGameplayReview',
        defaultMessage: 'Gameplay Review',
    },
    [ReasonCode.KycPending]: {
        id: 'reasonCodeKycPending',
        defaultMessage: 'KYC Pending',
    },
    [ReasonCode.MgmtReview]: {
        id: 'reasonCodeMgmtReview',
        defaultMessage: 'Mgmt Review',
    },
    [ReasonCode.PspNoFunds]: {
        id: 'reasonCodePspNoFunds',
        defaultMessage: 'PSP No Funds',
    },
    [ReasonCode.Threshold24hrs]: {
        id: 'reasonCodeThreshold24hrs',
        defaultMessage: '24hrs Threshold',
    },
    [ReasonCode.UnderInvestigation]: {
        id: 'reasonCodeUnderInvestigation',
        defaultMessage: 'Under Investigation',
    },
});
