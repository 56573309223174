import React from 'react';
import {defineMessages} from 'react-intl';

import {CustomIcon, Icon, IconColor} from '@components/icons';
import {useAuthUser} from '@auth';

import {BulkActionButton, BulkActionKey, useOneClickOperation} from 'src/features/block-bulk-actions';
import {KYCAssignData, KYCAssignRequest} from '../services/applyStrategy';

const localized = defineMessages({
    label: {
        id: 'BulkKYCAssignButton_label',
        defaultMessage: 'Assign to me',
    },
    successMessage: {
        id: 'BulkKYCAssignButton_successMessage',
        defaultMessage: '{count} selected KYC cases have been assigned to you',
    },
    errorMessage: {
        id: 'BulkKYCAssignButton_errorMessage',
        defaultMessage: 'Operation failed. None of selected KYC cases were assigned to you',
    },
});

type BulkKYCAssignButtonProps = {selectedItems: string[]};

export function BulkKYCAssignButton({selectedItems}: BulkKYCAssignButtonProps) {
    const actionKey: BulkActionKey = BulkActionKey.KYCAssign;
    const boUserId = useAuthUser()?.sub;

    const applyRequest: KYCAssignRequest = {
        items: selectedItems?.map<KYCAssignData>(id => ({id, user_id: boUserId})),
    };

    const {inProgress, handleClick} = useOneClickOperation<KYCAssignRequest>({
        actionKey,
        successMessage: localized.successMessage,
        errorMessage: localized.errorMessage,
        applyRequest,
        applyStrategyKey: 'ApplyKYCAssignStrategy',
        performStrategyKey: 'PerformKYCAssignStrategy',
    });

    return (
        <BulkActionButton
            color="secondary"
            label={localized.label}
            onClick={handleClick}
            loading={inProgress}
            disabled={inProgress}
            itemsCount={selectedItems?.length}
            startIcon={<Icon icon={CustomIcon.ConfirmOutLine} fontSize="small" color={IconColor.Secondary} />}
        />
    );
}
