import {
    LabelGroupSourceType,
    ReasonCode,
    Timestamp,
    TransactionStatus,
    TransactionType as GqlTransactionType,
} from '@models/generated/graphql';
import {BaseSettingsType} from '@user-settings';

import {BoUserViewModel} from 'src/features/block-bo-user';

export class StatusLog {
    logged_at: Timestamp;
    status: TransactionStatus;
}

export class TransactionDuration {
    history: StatusLog[];
    started_at: Timestamp;
}

export class UserLabelViewModel {
    group: UserLabelGroupViewModel;
    id: string;
    name: string;
}
export class UserLabelGroupViewModel {
    color: string;
    id: string;
    name: string;
    source_type?: LabelGroupSourceType;
}

export type TransactionFilterType = GqlTransactionType | 'FEES';

export class TransactionViewModel {
    amount?: number;
    amount_without_currency?: number;
    counterpart_player_id?: string;
    created_by_uid?: string;
    currency?: string;
    current_balance?: number;
    duration?: TransactionDuration;
    email?: string;
    id: string;
    payment_method_description?: string;
    payment_method_name?: string;
    phone?: string;
    player_country?: string;
    referrer_player_id?: string;
    register_marketing_code?: string;
    status_log?: StatusLog[];
    transaction_id: string;
    transaction_started_ts?: Timestamp;
    transaction_updated_ts?: Timestamp;
    transaction_status?: TransactionStatus;
    transaction_type?: GqlTransactionType;
    type?: GqlTransactionType;
    uid?: string;
    username?: string;
    withdrawal_id?: string;
    user_labels: UserLabelViewModel[];
    p2p_direction?: GqlTransactionType;
    current_casino_coin_balance?: number;
    is_summary?: boolean;
    assignee?: BoUserViewModel;
    last_reason?: ReasonCode;
}

export type TransactionViewModelKeys = keyof Omit<TransactionViewModel, 'is_summary'>;

export type TransactionEditableViewModel = Partial<TransactionViewModel> & {id: string};

export type TransactionViewModelEditableKeys =
    | keyof TransactionEditableViewModel
    | 'p2p_transfer_amount_editable'
    | 'p2p_direction_editable';

export class TransactionDownloadModel implements Partial<Record<TransactionViewModelKeys, string>> {
    amount: string;
    amount_without_currency: string;
    counterpart_player_id: string;
    created_by_uid: string;
    currency: string;
    current_balance: string;
    duration: string;
    email: string;
    id: string;
    payment_method_description: string;
    payment_method_name: string;
    phone: string;
    player_country: string;
    referrer_player_id: string;
    status_log: string;
    transaction_id: string;
    transaction_started_ts: string;
    transaction_updated_ts: string;
    'transaction_updated_ts.seconds': string;
    'transaction_updated_ts.nanos': string;
    'transaction_started_ts.seconds': string;
    'transaction_started_ts.nanos': string;
    transaction_status: string;
    transaction_type: string;
    type: string;
    uid: string;
    username: string;
    withdrawal_id: string;
    register_marketing_code: string;
    user_labels: string;
    p2p_direction: string;
    current_casino_coin_balance: string;
    assignee: string;
}

export interface TransactionColumnSettings extends BaseSettingsType {
    amount: {};
    amount_without_currency: {};
    counterpart_player_id: {};
    created_by_uid: {};
    currency: {};
    current_balance: {};
    duration: {};
    email: {};
    id: {};
    payment_method_description: {};
    payment_method_name: {};
    phone: {};
    player_country: {};
    referrer_player_id: {};
    register_marketing_code: {};
    status_log: {};
    transaction_id: {};
    transaction_started_ts: {};
    transaction_updated_ts: {};
    transaction_status: {};
    transaction_type: {};
    type: {};
    uid: {};
    username: {};
    withdrawal_id: {};
    user_labels: {};
    p2p_direction: {};
    current_casino_coin_balance: {};
    assignee: {};
    last_reason: {};
}

export type TransactionColumnSettingsKeys = keyof TransactionColumnSettings;

export type TransactionStatusWithReason = `${TransactionStatus}.${ReasonCode}`;
