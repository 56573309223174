import {defineMessages, MessageDescriptor} from 'react-intl';
import produce from 'immer';
import {inject, injectable} from 'inversify';
import {concat, merge, Observable, of} from 'rxjs';
import {catchError, delay, filter} from 'rxjs/operators';
import {Action, ActionType, isActionOf, PayloadAction, RootState} from 'typesafe-actions';

import {InvalidStructureError} from '@file/services/BaseExcelDocumentBuilder';
import {ServiceTypes} from '@inversify';
import {BulkOperationResult} from '@models/generated/graphql';
import {map, mergeMap} from '@otel';
import {RootEpic} from '@redux';
import {BaseEpicsBuilder} from '@redux';
import {BulkOperationQueryFields, EntityFetchServiceResponsePayload, EntityType} from '@redux/entity';
import {viewActions} from '@redux/view';
import {IEntityReadService} from '@services/entity';
import {ServerResponseStatus} from '@services/types';

import {transactionActionsEpicsLocalized} from '../block-transaction-actions/epics';
import {showErrorAction} from '../message-snack-bar/actions';

import {ApplyPayload, bulkActionsActions, PerformPayload, PollingState} from './actions';
import {BulkActionState, BulkOperationIdsState} from './reducers';
import {actionsDataSelector, bulkOperationsFilterSelector, bulkOperationsNotFailedIdsSelector} from './selectors';
import {ApplyStrategyResponse, BulkActionItemPayload, BulkItemStatus, ChangeBulkActionStatusItem} from './types';

const localized = defineMessages({
    incorrectType: {
        id: 'ActionBulkEpicsBuilder_incorrectType',
        defaultMessage: 'Please make sure direction in the file is equal to selected bulk type.',
    },
    incorrectStructure: {
        id: 'ActionBulkEpicsBuilder_incorrectStructure',
        defaultMessage: 'Wrong file structure. Expected column {expectedValue}, recieved {recievedValue}.',
    },
    defaultError: {
        id: 'ActionBulkEpicsBuilder_defaultError',
        defaultMessage: "Can't parse your file.",
    },
});

@injectable()
export class ActionBulkEpicsBuilder extends BaseEpicsBuilder {
    private readonly _bulkOperationService: IEntityReadService;

    constructor(@inject(ServiceTypes.BulkOperationService) bulkOperationService: IEntityReadService) {
        super();
        this._bulkOperationService = bulkOperationService;
    }

    protected buildEpicList(): RootEpic[] {
        return [
            this.buildLoadItemsEpic(),
            this.buildValidateEpic(),
            this.buildPerformEpic(),
            this.buildApplyEpic(),
            this.buildRetryEpic(),
            this.buildPollingEpic(),
            this.buildInstantOperationEpic(),
        ];
    }

    private buildLoadItemsEpic(): RootEpic {
        return action$ =>
            action$.pipe(
                filter(isActionOf(bulkActionsActions.loadItems)),
                mergeMap(action =>
                    action.payload.strategy.process(action.payload.request).pipe(
                        map(bulkActionsActions.setBulkActionsItems),
                        catchError(err => {
                            let message: MessageDescriptor | undefined = undefined;
                            if (err instanceof TypeError) {
                                message = localized.incorrectType;
                            } else if (err instanceof InvalidStructureError) {
                                message = localized.incorrectStructure;
                            } else {
                                message = localized.defaultError;
                            }
                            return of(
                                bulkActionsActions.clearBulkActions(),
                                showErrorAction({
                                    message,
                                    values: err.cause ?? {},
                                })
                            );
                        })
                    )
                )
            );
    }

    private buildValidateEpic(): RootEpic {
        return action$ =>
            action$.pipe(
                filter(isActionOf(bulkActionsActions.validate)),
                mergeMap(action => action.payload.strategy.process(action.payload.request)),
                map(bulkActionsActions.setValidationResults)
            );
    }

    private buildApplyEpic(): RootEpic {
        return action$ =>
            action$.pipe(
                filter(isActionOf(bulkActionsActions.apply)),
                map(action => {
                    const {state, items, actionKey} = this.applyOperation(action.payload);
                    return items?.length
                        ? bulkActionsActions.setBulkActions(state)
                        : bulkActionsActions.removeBulkActionsWithKey(actionKey);
                })
            );
    }

    private buildPerformEpic(): RootEpic {
        return action$ =>
            action$.pipe(
                filter(isActionOf(bulkActionsActions.perform)),
                mergeMap(action => {
                    const performPayloads = produce(action.payload, draftState => {
                        const performableStatuses: BulkItemStatus[] = [
                            BulkItemStatus.Pending,
                            BulkItemStatus.Failed,
                            BulkItemStatus.TimedOut,
                        ];
                        return draftState
                            ?.filter(payload => payload?.request?.items?.some(i => performableStatuses.includes(i?.status)))
                            .map(payload => {
                                const performableItems = payload.request.items
                                    ?.filter(i => performableStatuses.includes(i?.status))
                                    ?.map(i => ({...i, status: BulkItemStatus.InProgress}));
                                return {...payload, request: {...payload.request, items: performableItems}};
                            });
                    });
                    const changeStatusObservables = performPayloads?.map(payload =>
                        of(bulkActionsActions.changeBulkActionStatus(payload.request))
                    );
                    const performObservables = performPayloads
                        ?.map(a =>
                            a.strategy?.process(a.request)?.pipe(
                                mergeMap(res => {
                                    const resultActions = [];
                                    const errorMapping: Record<string, MessageDescriptor> = {
                                        UserLimitExceeded: transactionActionsEpicsLocalized.creditRemainingAmountExceeded,
                                        OngoingCreditTransaction: transactionActionsEpicsLocalized.ongoingTransacitons,
                                    };
                                    // remove error localization from epics
                                    const error = errorMapping[res.errorMessage];
                                    if (error) {
                                        resultActions.push(showErrorAction({message: error}));
                                    } else if (res.errorMessage) {
                                        resultActions.push(showErrorAction({message: res.errorMessage}));
                                    }

                                    resultActions.push(a?.postProcessingAction(res));
                                    return resultActions;
                                })
                            )
                        )
                        ?.filter(o => o);

                    return merge(...changeStatusObservables, ...performObservables);
                })
            );
    }

    private buildInstantOperationEpic(): RootEpic {
        return action$ =>
            action$.pipe(
                filter(isActionOf(bulkActionsActions.instantOperation)),
                mergeMap(action => {
                    const {applyRequest, applyStrategy, performStrategy} = action?.payload;
                    const {actionKey, items, state} = this.applyOperation({request: applyRequest, strategy: applyStrategy});
                    return concat(
                        of(bulkActionsActions.setBulkActions(state)),
                        of(
                            bulkActionsActions.perform([
                                {
                                    request: {actionKey, items},
                                    strategy: performStrategy,
                                    postProcessingAction: bulkActionsActions.saveOperationId,
                                },
                            ])
                        )
                    );
                })
            );
    }

    private buildRetryEpic(): RootEpic {
        return (action$, state$) =>
            action$.pipe(
                filter(isActionOf(bulkActionsActions.retry)),
                mergeMap((action: ActionType<typeof bulkActionsActions.retry>) => {
                    const operationIdsState = bulkOperationsNotFailedIdsSelector(state$.value);
                    const operationIdList = Object.values(operationIdsState);

                    return operationIdList.length > 0
                        ? this.retryOperations(action.payload, state$.value)
                        : of(bulkActionsActions.cleanOperationIds(), bulkActionsActions.perform(action.payload));
                })
            );
    }

    private buildPollingEpic(): RootEpic {
        return (action$, state$) => {
            let isPollingEnabled = false;
            return action$.pipe(
                filter(isActionOf(bulkActionsActions.fetchOperations)),
                delay(4000),
                mergeMap(action => {
                    let result: Observable<PayloadAction<string, unknown>> = of();
                    if (action.payload.pollingState === PollingState.Start) {
                        isPollingEnabled = true;
                    } else if (action.payload.pollingState === PollingState.Finished) {
                        isPollingEnabled = false;
                    }

                    if (isPollingEnabled) {
                        const filter = bulkOperationsFilterSelector(state$.value);
                        result = filter
                            ? of(
                                  viewActions.updateFilter({
                                      entity: EntityType.BulkOperation,
                                      view: action.payload.viewType,
                                      filter: `id=${filter}`,
                                  }),
                                  bulkActionsActions.fetchOperations({
                                      viewType: action.payload.viewType,
                                      pollingState: PollingState.InProgress,
                                  })
                              )
                            : of(
                                  bulkActionsActions.fetchOperations({
                                      viewType: action.payload.viewType,
                                      pollingState: PollingState.InProgress,
                                  })
                              );
                    }

                    return result;
                })
            );
        };
    }

    private applyOperation(request: ApplyPayload): {actionKey: string; state: BulkActionState; items: BulkActionItemPayload[]} {
        const payload: ApplyStrategyResponse = request.strategy.process(request.request);
        const appliedItems = payload.items?.filter(i => i.value !== null);
        const result = appliedItems.reduce(
            (state, item) => (
                (state[item.actionKey] = appliedItems
                    ?.filter(i => i.actionKey === item.actionKey)
                    .map(item => ({...item, actionKey: payload.actionKey}))),
                state
            ),
            {} as BulkActionState
        );
        return {state: result, items: appliedItems, actionKey: payload.actionKey};
    }

    private retryOperations(payload: PerformPayload, state: RootState) {
        const operationIdsState = bulkOperationsNotFailedIdsSelector(state);
        const operationIdList = Object.values(operationIdsState);

        return this.getOperations(operationIdList).pipe(
            mergeMap((res: EntityFetchServiceResponsePayload) => {
                if (res.status !== ServerResponseStatus.Success) {
                    return of();
                }

                const actionItems = actionsDataSelector(state);
                const operations = res.responsePayload.items as BulkOperationResult[];
                const synchronizedItemsPayloads = this.getSynchronizedActionItemsPayloads(actionItems, operations, operationIdsState);

                const resultActions: Action[] = [
                    bulkActionsActions.cleanOperationIds(),
                    ...synchronizedItemsPayloads.map(synchronizedItemsPayload =>
                        bulkActionsActions.changeBulkActionStatus({
                            actionKey: synchronizedItemsPayload.actionKey,
                            items: synchronizedItemsPayload.items,
                        })
                    ),
                    bulkActionsActions.perform(payload),
                ];
                return of(...resultActions);
            })
        );
    }

    private getOperations(operationIdList: string[]) {
        const filter = `id=${operationIdList.join(',')}`;
        const fields: BulkOperationQueryFields[] = ['id', 'status', 'items.id', 'items.status', 'items.message', 'entity_type'];

        return this._bulkOperationService.get({
            type: EntityType.BulkOperation,
            filter,
            fields,
        });
    }

    private getSynchronizedActionItemsPayloads(
        actionItems: BulkActionState,
        operations: BulkOperationResult[],
        operationIdsState: BulkOperationIdsState
    ) {
        return Object.entries(operationIdsState).map(([actionKey, operationId]: [string, string]) => {
            const operation = operations.find(o => o.id === operationId);
            return {
                items: actionItems[actionKey].map<ChangeBulkActionStatusItem>((item: BulkActionItemPayload) => ({
                    actionKey: item.actionKey,
                    itemId: item.itemId,
                    status: operation?.items?.find(i => i.id === item.itemId)?.status ?? item.status,
                })),
                actionKey,
            };
        });
    }
}
