export enum ModuleName {
    AccessManagement = 'admin',
    PlayerManagement = 'playermodule',
    LegalContentManagement = 'legal',
    ActionCenter = 'action-center',
    Comments = 'comments',
    WithdrawalProcessing = 'withdrawal-processing',
    KycProcessing = 'kyc-processing',
    SecurityWorkspace = 'security-workspace',
    CustomerSupport = 'customer-support',
    MarketingFunction = 'page-marketing-function',
    PageAgentReport = 'page-agent-report',
    PageAgentReportVn = 'page-agent-report-vn',
    PageAgentReportKr = 'page-agent-report-kr',
    TeamWorkspace = 'page-team-workspace',
    GameAdmin = 'page-plo5',
    EntityGameTable = 'entity-game-table',
    EntityGameTemplate = 'entity-game-template',
    EntityTransactions = 'entity-transaction',
    EntityBonus = 'entity-bonus',
    EntityBulk = 'entity-bulk',
    EntityAll = 'entity-all',
    Agent = 'agent',
    AgentVn = 'agent-vn',
    AgentKr = 'agent-kr',
    PageRules = 'page-rules',
    EntityRule = 'entity-rule',
    Club = 'club',
    EntityReport = 'entity-report',
    PageAgentManagement = 'page-agent-management',
    PageNdrp = 'page-ndrp',
    WptAsia = 'wpt-asia',
    WptPH = 'wpt-ph',
    WptGlobal = 'wpt-global',
    PageSportsbookOperation = 'page-sportsbook-history',
    PageEmbeddedReport = 'page-embedded-report',
    FullPlayer = 'full-player',
    CreditLimit1 = 'credit-limit-1',
    CreditLimit2 = 'credit-limit-2',
    CreditLimit3 = 'credit-limit-3',
    Debit = 'debit',
    Phone = 'phone',
    Email = 'email',
}

export enum SubmoduleName {
    AccountActions = 'account',
    ActionCenter = 'action-center',
    AgentLevelReport = 'agent-level-report',
    BlacklistActions = 'blacklist',
    BonusEngine = 'bonus-engine',
    Cases = 'cases',
    CommunicationActions = 'communication',
    Details = 'details',
    /**
     * @deprecated
     * <p>Use {@link SubmoduleName.FinanceLock} instead</p>
     */
    FinanceActions = 'finance',
    /**
     * @deprecated
     * <p>Use {@link SubmoduleName.P2PLock} instead</p>
     */
    P2PAction = 'p2p',
    GGRReport = 'ggr-report',
    GamesActions = 'games',
    KYC = 'kyc',
    LoginHistory = 'loginhistory',
    Marketing = 'mkt',
    MarketingBonusCode = 'marketing-bonus-code',
    None = 'none',
    NotesBotDetection = 'bot-detection',
    NotesCustomerSupport = 'customer-support',
    Payment = 'payment',
    NotesPlayer = 'player-details',
    NotesSecurity = 'security',
    P2PReferralOfferingAdmin = 'p2p-referral-offering-admin',
    /**
     * @deprecated
     * <p>Use {@link SubmoduleName.P2PTransaction} instead</p>
     */
    P2PTransfer = 'p2p-transfer',
    PLO5 = 'plo5',
    PermanentBans = 'permanent-locks',
    PrivacyPolicy = 'privacypolicy',
    RegistrationInformation = 'registration',
    RevenueShare = 'revenue-share',
    Roles = 'roles',
    TermsAndConditions = 'tandc',
    TransactionHistory = 'payments',
    TransferReport = 'transfer',
    UserAcceptanceVersion = 'versionacceptance',
    Users = 'users',
    Verification = 'verification',
    WithdrawalProcessingRisk = 'risk',
    SportsbookTransactionHistory = 'transaction-history',
    FinanceLock = 'finance-lock',
    P2PLock = 'p2p-lock',
    P2PTransaction = 'p2p-transaction',
    NotesFraud = 'fraud',
}
