import {defineMessages, useIntl} from 'react-intl';

import {useAutoMapper} from '@auto-mapper';
import {BoUserSettingsViewModel} from '@models/bo-user-settings';
import {BoUserSettings} from '@models/generated/graphql';
import {EntityType} from '@redux/entity';
import {BoUserSettingsFilterKeys, BoUserSettingsKeys} from '@redux/entity/types/entityBoUserSettings';
import {useViewInit} from '@redux/view';

const localized = defineMessages({
    creditRemainingAmount: {
        id: 'useBoUserSettings_creditRemainingAmount',
        defaultMessage: 'Available daily credit remains: ${amount}',
    },
});

export function useBoUserSettings(uid: string): BoUserSettingsViewModel & {creditRemainingAmountMessage: string} {
    const {formatMessage} = useIntl();
    const {items} = useViewInit<BoUserSettings, BoUserSettingsFilterKeys, BoUserSettingsKeys>({
        viewType: 'BoUserSettingsView',
        entity: {
            entity: EntityType.BoUserSettings,
            fields: ['personal_daily_credit_limit', 'total_daily_credit_limit', 'remaining_credit_amount'],
        },
        defaultFilters: [
            {
                key: 'uid',
                value: uid,
            },
        ],
        validateFilter: () => !!uid.length,
    });

    const mapper = useAutoMapper();
    const item = mapper.map<BoUserSettings, BoUserSettingsViewModel>(items[0], nameof<BoUserSettings>(), nameof<BoUserSettingsViewModel>());
    return {
        creditRemainingAmountMessage: formatMessage(localized.creditRemainingAmount, {amount: item?.remainingCreditAmount}),
        ...(item ?? {}),
    };
}
