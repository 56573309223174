import React, {useState} from 'react';

import {MultiSelect} from '@components/select/MultiSelect';

import {SelectOption} from 'src/features/module-shared/types';

import {Wrapper} from './WrapperDev';

export function MultiSelectGroupsDev() {
    const [value, setValue] = useState<any[]>([]);

    const options: SelectOption[] = [...Array(10).keys()].map(i => ({
        value: (i + 1).toString(),
        label: (i + 1).toString(),
    }));

    options[0] = {
        ...options[0],
        value: ['2', '3'],
        subOptions: [
            {
                value: '2',
                label: '2',
            },
            {
                value: '3',
                label: '3',
            },
        ],
    };

    return (
        <Wrapper label={'Multi Select Groups'}>
            <MultiSelect
                showResetButton
                showApplyButton
                label={'Test items'}
                options={options}
                value={value}
                onSubmit={setValue}
                virtualization
            />
            <div>Selected items</div>
            {value?.map(i => (
                <div>{i}</div>
            ))}
        </Wrapper>
    );
}
