import {useEffect, useState} from 'react';
import {defineMessages, MessageDescriptor, useIntl} from 'react-intl';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from 'typesafe-actions';

import {IModuleGridItem} from '@components/data-grid';
import {ServiceTypesKeys} from '@inversify';
import {useService} from '@inversify/hooks';
import {BulkOperationResult} from '@models/generated/graphql';
import {BulkOperationFilterKeys, BulkOperationQueryFields, EntityType} from '@redux/entity';
import {useViewInit, viewActions, ViewType} from '@redux/view';
import {isStringNullOrEmpty} from '@utils';

import {showErrorAction, showMessageAction} from '../message-snack-bar/actions';

import {bulkActionsActions, LoadItemsPayload, PerformPayload, PollingState} from './actions';
import {BulkActionState} from './reducers';
import {
    accountLockResultValueSelector,
    actionsDataSelector,
    addUserLabelResultValueSelector,
    bulkFailedOperationActionKeysSelector,
    bulkOperationNotCreatedActionKeys,
    bulkOperationsFilterSelector,
    casinoLockResultValueSelector,
    depositLockResultValueSelector,
    executionItemsSelector,
    hasExecutionFailedItemsSelector,
    isExecutionInProgressSelector,
    itemsSelector,
    lobbyLockResultValueSelector,
    manualTransactionResultValueSelector,
    manualTriggerDepositCashMatchResultValueSelector,
    manualTriggerImmediateCashBonusResultValueSelector,
    manualTriggerMTTTicketResultValueSelector,
    messageResultValueSelector,
    notesAttachmentsResultValueSelector,
    p2pTransferLockResultValueSelector,
    p2pTransferResultValueSelector,
    removeUserLabelResultValueSelector,
    securityCaseAddResultValueSelector,
    securityCaseAllResultValueSelector,
    securityCaseRemoveResultValueSelector,
    securityCaseUpdateResultValueSelector,
    simpleResultValueSelector,
    sportsbookLockResultValueSelector,
    withdrawalLockResultValueSelector,
} from './selectors';
import {
    BulkActionItemPayload,
    BulkActionKey,
    bulkItemFailedStatues,
    BulkItemStatus,
    IBulkApplyStrategy,
    IBulkStrategy,
    PerformActionData,
    PerformStrategyActionItemsResponse,
    PerformStrategyOperationResponse,
    PerformStrategyRequest,
} from './types';

export function useAffectedRecords<T extends IModuleGridItem>(): Partial<Record<string, T[]>> {
    const allActions = useSelector(actionsDataSelector);
    const affectedRecords = useSelector(itemsSelector) as T[];

    const result: Partial<Record<string, T[]>> = {};

    for (const actionKey in allActions) {
        result[actionKey] = affectedRecords?.filter(user => allActions[actionKey]?.map(i => i.itemId)?.includes(user.serverId));
    }

    return result;
}

type UseBulkActionResult = {
    resetBulkState: () => void;
};

export function useBulkAction(loadItemsPayload?: LoadItemsPayload): UseBulkActionResult {
    const dispatch = useDispatch();

    const resetBulkState = () => {
        dispatch(bulkActionsActions.clearBulkActions());
        if (loadItemsPayload) {
            dispatch(bulkActionsActions.loadItems(loadItemsPayload));
        }
    };

    return {resetBulkState};
}

export const useBulkModalStatus = () => {
    const actions = useSelector(actionsDataSelector);
    const actionItemPayloads = Object.values(actions)?.flatMap(i => i);
    const processingItemStatuses = [BulkItemStatus.Pending, BulkItemStatus.InProgress];
    const hasProcessingItem = actionItemPayloads?.some(i => processingItemStatuses.includes(i.status));
    const hasFailedItem = actionItemPayloads?.some(i => bulkItemFailedStatues.includes(i.status));

    return {
        hasProcessingItem,
        hasFailedItem,
    };
};

export const useBulkActionResultValue = () => {
    const resultValues: Partial<Record<string, unknown>> = {
        [BulkActionKey.AccountLock]: useSelector(accountLockResultValueSelector),
        [BulkActionKey.WithdrawalLock]: useSelector(withdrawalLockResultValueSelector),
        [BulkActionKey.DepositLock]: useSelector(depositLockResultValueSelector),
        [BulkActionKey.LobbyLock]: useSelector(lobbyLockResultValueSelector),
        [BulkActionKey.CasinoLock]: useSelector(casinoLockResultValueSelector),
        [BulkActionKey.SportsbookLock]: useSelector(sportsbookLockResultValueSelector),
        [BulkActionKey.SecurityCasesAdd]: useSelector(securityCaseAddResultValueSelector),
        [BulkActionKey.SecurityCasesUpdate]: useSelector(securityCaseUpdateResultValueSelector),
        [BulkActionKey.SecurityCasesRemove]: useSelector(securityCaseRemoveResultValueSelector),
        [BulkActionKey.SecurityCasesAll]: useSelector(securityCaseAllResultValueSelector),
        [BulkActionKey.Message]: useSelector(messageResultValueSelector),
        [BulkActionKey.NotesAndAttachments]: useSelector(notesAttachmentsResultValueSelector),
        [BulkActionKey.ManualTriggerDepositCashMatch]: useSelector(manualTriggerDepositCashMatchResultValueSelector),
        [BulkActionKey.ManualTriggerImmediateCashBonus]: useSelector(manualTriggerImmediateCashBonusResultValueSelector),
        [BulkActionKey.ManualTriggerMTTTicket]: useSelector(manualTriggerMTTTicketResultValueSelector),
        [BulkActionKey.ManualTransactions]: useSelector(manualTransactionResultValueSelector),
        [BulkActionKey.P2PTransferLock]: useSelector(p2pTransferLockResultValueSelector),
        [BulkActionKey.AddUserLabel]: useSelector(addUserLabelResultValueSelector),
        [BulkActionKey.RemoveUserLabel]: useSelector(removeUserLabelResultValueSelector),
        [BulkActionKey.P2PTransfer]: useSelector(p2pTransferResultValueSelector),
    };
    return resultValues;
};

const localized = defineMessages({
    bulkActionSummaryUsers: {
        id: 'bulkActionSummaryUsers',
        defaultMessage: 'users',
    },
    bulkActionSummaryTransactions: {
        id: 'bulkActionSummaryTransactions',
        defaultMessage: 'transactions',
    },
    bulkActionSummaryItems: {
        id: 'bulkActionSummaryItems',
        defaultMessage: 'items',
    },
});

export function useLabel(type: EntityType): string {
    const {formatMessage} = useIntl();
    const mapping: Partial<Record<EntityType, MessageDescriptor>> = {
        [EntityType.UserProfile]: localized.bulkActionSummaryUsers,
        [EntityType.Transaction]: localized.bulkActionSummaryTransactions,
    };

    return formatMessage(mapping[type] ?? localized.bulkActionSummaryItems);
}

export function useApply<TStrategy, TApplyModel>(strategySymbol: symbol, action: BulkActionKey) {
    const strategy = useService<TStrategy>(strategySymbol);
    const selector = (state: RootState) => simpleResultValueSelector(state, action);
    const applyValue = useSelector(selector) as TApplyModel;

    return {strategy, applyValue};
}

export function usePerformBulkOperation<TStrategy extends IBulkStrategy<PerformStrategyRequest, PerformStrategyOperationResponse>>(
    strategySymbol: symbol,
    action: BulkActionKey
): PerformActionData {
    const strategy = useService<TStrategy>(strategySymbol);

    return {
        actionKey: action,
        type: 'operation',
        strategy,
    };
}

export function usePerformMultipleRequests<TStrategy extends IBulkStrategy<PerformStrategyRequest, PerformStrategyActionItemsResponse>>(
    strategySymbol: symbol,
    action: BulkActionKey
): PerformActionData {
    const strategy = useService<TStrategy>(strategySymbol);

    return {
        actionKey: action,
        type: 'actionItems',
        strategy,
    };
}

export function useSelectedItems<TItem>() {
    const selectedItems = useSelector(itemsSelector) as TItem[];
    return selectedItems;
}

export function useApplyStrategy<TRequest = unknown>(strategyKey: ServiceTypesKeys): IBulkApplyStrategy<TRequest> {
    return useService<IBulkApplyStrategy<TRequest>>(strategyKey);
}

export function usePerformStrategy(strategyKey: ServiceTypesKeys): IBulkStrategy<PerformStrategyRequest, PerformStrategyOperationResponse> {
    return useService<IBulkStrategy<PerformStrategyRequest, PerformStrategyOperationResponse>>(strategyKey);
}

// Execution hooks
type BulkStepExecutionSummaryData = {
    isExecutionInProgress: boolean;
    isExecutionFailed: boolean;
    actionItems: BulkActionState;
    failedOperationKeys: string[];
    operationIdFilter: string;
};

function useBulkStepExecutionSummaryData(operations: BulkOperationResult[]): BulkStepExecutionSummaryData {
    const failedOperationKeys = useSelector(bulkOperationNotCreatedActionKeys);
    const isExecutionInProgress = useSelector<RootState, boolean>(state => isExecutionInProgressSelector(state, {operations}));
    const hasFailedItems = useSelector<RootState, boolean>(state => hasExecutionFailedItemsSelector(state, {operations}));
    const actionItems = useSelector<RootState, BulkActionState>(state => executionItemsSelector(state, {operations}));
    const operationIdFilter: string = useSelector(bulkOperationsFilterSelector);

    return {
        isExecutionInProgress,
        isExecutionFailed: failedOperationKeys?.length > 0 || hasFailedItems,
        actionItems,
        failedOperationKeys,
        operationIdFilter,
    };
}

type ExecutionActions = {
    performExecution: () => void;
    retryExecution: () => void;
};

export function useExecutionActions(actionItems: BulkActionState, actionData: PerformActionData[]): ExecutionActions {
    const dispatch = useDispatch();
    const failedOperationKeys = useSelector(bulkFailedOperationActionKeysSelector);

    const performPayload: PerformPayload = Object.entries(actionItems)
        .map(([actionKey, payloads]) => {
            const items: BulkActionItemPayload[] = failedOperationKeys.includes(actionKey)
                ? payloads.map(i => ({...i, status: BulkItemStatus.Failed}))
                : payloads;
            const peformData = actionData?.find(i => i.actionKey === items?.[0]?.actionKey);
            return {
                strategy: peformData?.strategy,
                postProcessingAction:
                    peformData?.type === 'operation' ? bulkActionsActions.saveOperationId : bulkActionsActions.changeBulkActionStatus,
                request: items?.length ? {items, actionKey} : null,
            };
        })
        .filter(s => s.request !== null);

    function performExecution() {
        dispatch(bulkActionsActions.perform(performPayload));
    }

    function retryExecution() {
        dispatch(bulkActionsActions.retry(performPayload));
    }

    return {performExecution, retryExecution};
}

function useBatchOperationsPolling(isExecutionInProgress: boolean) {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(
            bulkActionsActions.fetchOperations({
                viewType: 'BulkExecutionSummary',
                pollingState: isExecutionInProgress ? PollingState.Start : PollingState.Finished,
            })
        );
    }, [isExecutionInProgress]);
}

const executionLocalized = defineMessages({
    bulkStepExecutionSummaryDisplayName: {
        id: 'BulkStepExecutionSummary_displayName',
        defaultMessage: 'Execution Summary',
    },
});

type UserBulkOperationExecutionResult = {
    actionItems: BulkActionState;
    isExecutionInProgress: boolean;
    isExecutionFailed: boolean;
    failedOperationKeys: string[];
};

export function useBulkOperationExecution(viewType: ViewType = 'BulkExecutionSummary'): UserBulkOperationExecutionResult {
    const dispatch = useDispatch();

    const fields: BulkOperationQueryFields[] = ['id', 'status', 'items.id', 'items.status', 'items.message', 'entity_type'];
    const {
        items: operations,
        viewEntity: {filter: viewFilterString},
    } = useViewInit<BulkOperationResult, BulkOperationFilterKeys, BulkOperationQueryFields>({
        viewType,
        displayName: executionLocalized.bulkStepExecutionSummaryDisplayName,
        entity: {
            entity: EntityType.BulkOperation,
            fields,
        },
        validateFilter: filter => !isStringNullOrEmpty(filter?.find(f => f.key === 'id')?.value),
    });
    const {isExecutionInProgress, isExecutionFailed, actionItems, failedOperationKeys, operationIdFilter} =
        useBulkStepExecutionSummaryData(operations);

    useBatchOperationsPolling(isExecutionInProgress);

    useEffect(() => {
        if (operationIdFilter && operationIdFilter !== viewFilterString) {
            dispatch(
                viewActions.updateFilter({
                    view: viewType,
                    entity: EntityType.BulkOperation,
                    filter: `id=${operationIdFilter}`,
                })
            );
        }
    }, [operationIdFilter]);

    return {
        actionItems,
        isExecutionInProgress,
        isExecutionFailed,
        failedOperationKeys,
    };
}

export type UseOneClickOperationProps<TApplyRequest = unknown> = {
    actionKey: BulkActionKey;
    applyRequest: TApplyRequest;
    successMessage: MessageDescriptor;
    errorMessage: MessageDescriptor;
    applyStrategyKey: ServiceTypesKeys;
    performStrategyKey: ServiceTypesKeys;
};

type UseOneClickOperationResult = {
    inProgress: boolean;
    handleClick: () => void;
};

export function useOneClickOperation<TApplyRequest = unknown>({
    actionKey,
    successMessage,
    errorMessage,
    applyRequest,
    applyStrategyKey,
    performStrategyKey,
}: UseOneClickOperationProps<TApplyRequest>): UseOneClickOperationResult {
    const dispatch = useDispatch();
    const {resetBulkState} = useBulkAction();
    const [isDirty, setIsDirty] = useState(false);
    const {isExecutionInProgress, actionItems} = useBulkOperationExecution('BulkExecutionSummaryOneClick');
    const applyStrategy = useApplyStrategy<TApplyRequest>(applyStrategyKey);
    const performStrategy = usePerformStrategy(performStrategyKey);

    useEffect(() => {
        if (isDirty && !isExecutionInProgress) {
            setIsDirty(false);
            const successItemsCount = actionItems?.[actionKey]?.filter(
                (i: BulkActionItemPayload) => i?.status === BulkItemStatus.Successful
            )?.length;
            if (successItemsCount > 0) {
                dispatch(showMessageAction({message: successMessage, values: {count: `${successItemsCount}`}}));
            } else {
                dispatch(showErrorAction({message: errorMessage}));
            }
            resetBulkState();
        }
    }, [isDirty, isExecutionInProgress]);

    function handleClick() {
        setIsDirty(true);
        dispatch(bulkActionsActions.instantOperation({applyRequest, applyStrategy, performStrategy}));
    }

    return {inProgress: isDirty && isExecutionInProgress, handleClick};
}
