import {defineMessages, MessageDescriptor} from 'react-intl';
import {Mapper} from '@automapper/core';
import {inject, injectable} from 'inversify';
import moment from 'moment';
import {combineEpics, Epic} from 'redux-observable';
import {Observable, of} from 'rxjs';
import {catchError, filter} from 'rxjs/operators';
import {isActionOf, PayloadAction} from 'typesafe-actions';

import {fileActions} from '@file/actions';
import {CsvDocumentBuilderAdapter} from '@file/services/CsvDocumentBuilderAdapter';
import {ServiceTypes} from '@inversify';
import {FeatureAccessStatus, MutationLockSportsbookArgs, UserAccountStatus, UserProfile} from '@models/generated/graphql';
import {UserProfileDownloadModel, UserProfileViewModelKeys} from '@models/user-profile/types';
import {map, mergeMap} from '@otel';
import {BaseEpicsBuilder} from '@redux';
import {entityActions, EntityFetchRequestPayload, EntityType, UserProfileNormalized, UserProfileQueryFields} from '@redux/entity';
import {IUserProfileService} from '@services';
import {
    CustomErrorCodes,
    GqlMutationRequest,
    maxPageSize,
    ServerResponseError,
    ServerResponseStatus,
    ServiceResponsePayload,
} from '@services/types';
import {momentToTimestampSeconds} from '@utils';

import {showErrorAction, showMessageAction} from '../message-snack-bar/actions';
import {Message} from '../message-snack-bar/types';

import {UpdatePlayerIdRequestPayload, UserAccountLockRequestPayload, userProfileActions, UserProfileLockRequestPayload} from './actions';

export const userActionsEpicLocalized = defineMessages({
    accountStatusLockedSuccessfully: {
        id: 'UserProfileActionsEpicsBuilder_accountStatusLockedSuccessfully',
        defaultMessage: 'Account status is changed',
    },
    accountStatusChangedButAccessLoginNotDisabled: {
        id: 'UserProfileActionsEpicsBuilder_accountStatusChangedButAccessLoginNotDisabled',
        defaultMessage: 'Account status is changed, but agent login access not disabled',
    },
    accountStatusChangedButAccessLoginNotEnabled: {
        id: 'UserProfileActionsEpicsBuilder_accountStatusChangedButAccessLoginNotEnabled',
        defaultMessage: 'Account status is changed, but agent login access not enabled',
    },
    accountStatusLockFailed: {
        id: 'UserProfileActionsEpicsBuilder_accountStatusLockedFailed',
        defaultMessage: 'Failed to change Account status',
    },
    depositStatusLockedSuccessfully: {
        id: 'UserProfileActionsEpicsBuilder_depositStatusLockedSuccessfully',
        defaultMessage: 'Deposit status is changed',
    },
    depositStatusLockFailed: {
        id: 'UserProfileActionsEpicsBuilder_depositStatusLockedFailed',
        defaultMessage: 'Failed to change Deposit status',
    },
    lobbyStatusLockedSuccessfully: {
        id: 'UserProfileActionsEpicsBuilder_lobbyStatusLockedSuccessfully',
        defaultMessage: 'Lobby status is changed',
    },
    lobbyStatusLockFailed: {
        id: 'UserProfileActionsEpicsBuilder_lobbyStatusLockedFailed',
        defaultMessage: 'Failed to change Lobby status',
    },
    casinoStatusLockedSuccessfully: {
        id: 'UserProfileActionsEpicsBuilder_casinoStatusLockedSuccessfully',
        defaultMessage: 'Casino status is changed',
    },
    casinoStatusLockFailed: {
        id: 'UserProfileActionsEpicsBuilder_casinoStatusLockedFailed',
        defaultMessage: 'Failed to change Casino status',
    },
    sportsbookStatusLockedSuccessfully: {
        id: 'UserProfileActionsEpicsBuilder_sportsbookStatusLockedSuccessfully',
        defaultMessage: 'Sportsbook status is changed',
    },
    sportsbookStatusLockFailed: {
        id: 'UserProfileActionsEpicsBuilder_sportsbookStatusLockedFailed',
        defaultMessage: 'Failed to change Sportsbook status',
    },
    withdrawalStatusLockedSuccessfully: {
        id: 'UserProfileActionsEpicsBuilder_withdrawalStatusLockedSuccessfully',
        defaultMessage: 'Withdrawal status is changed',
    },
    withdrawalStatusLockFailed: {
        id: 'UserProfileActionsEpicsBuilder_withdrawalStatusLockedFailed',
        defaultMessage: 'Failed to change Withdrawal status',
    },
    p2pTransferStatusLockedSuccessfully: {
        id: 'UserProfileActionsEpicsBuilder_p2pTransferStatusLockedSuccessfully',
        defaultMessage: 'P2P transfer status is changed',
    },
    p2pTransferStatusLockFailed: {
        id: 'UserProfileActionsEpicsBuilder_p2pTransferStatusLockedFailed',
        defaultMessage: 'Failed to change P2P transfer status',
    },
    locked: {
        id: 'UserProfileActionsEpicsBuilder_locked',
        defaultMessage: 'locked',
    },
    unlocked: {
        id: 'UserProfileActionsEpicsBuilder_unlocked',
        defaultMessage: 'unlocked',
    },
    updatePlayerIdSuccess: {
        id: 'UserProfileActionsEpicsBuilder_updatePlayerIdSuccess',
        defaultMessage: 'Agent Player ID successfully changed to {uid}',
    },
    updatePlayerIdFailure: {
        id: 'UserProfileActionsEpicsBuilder_updatePlayerIdFailure',
        defaultMessage: 'Failed to change Agent Player ID',
    },
    noParentAgentRevenueShare: {
        id: 'UserProfileActionsEpicsBuilder_noParentAgentRevenueShare',
        defaultMessage: "The parent agent doesn't have revenue share %",
    },
    invalidSubagentRevenueShare: {
        id: 'UserProfileActionsEpicsBuilder_invalidSubagentRevenueShare',
        defaultMessage: 'You cannot set a revenue share % higher than parent agent',
    },
    downloadFailureMessage: {
        id: 'UserProfileActionsEpicsBuilder_downloadFailureMessage',
        defaultMessage: 'Failed to download',
    },
    downloadMaxPageSizeErrorMessage: {
        id: 'UserProfileActionsEpicsBuilder_downloadMaxPageSizeErrorMessage',
        defaultMessage: 'Total count of downloaded items cannot be more than {maxPageSize}',
    },
});

@injectable()
export class UserProfileActionsEpicsBuilder extends BaseEpicsBuilder {
    private readonly _service: IUserProfileService;
    private _mapper: Mapper;
    private _fileAdapter: CsvDocumentBuilderAdapter<UserProfileViewModelKeys>;

    constructor(
        @inject(ServiceTypes.UserProfileService) service: IUserProfileService,
        @inject(ServiceTypes.CsvFileAdapter) fileAdapter: CsvDocumentBuilderAdapter<UserProfileViewModelKeys>,
        @inject(ServiceTypes.AutoMapper) mapper: Mapper
    ) {
        super();
        this._service = service;
        this._fileAdapter = fileAdapter;
        this._mapper = mapper;
    }

    protected buildEpicList(): Epic[] {
        return [
            this.buildChangeAccountStatusRequestEpic(),
            this.buildChangeAccountStatusSuccessEpic(),
            this.buildChangeAccountStatusFailureEpic(),
            this.buildChangeCasinoStatusRequestEpic(),
            this.buildChangeCasinoStatusSuccessEpic(),
            this.buildChangeCasinoStatusFailureEpic(),
            this.buildChangeSportsboookStatusRequestEpic(),
            this.buildChangeSportsboookStatusSuccessEpic(),
            this.buildChangeSportsboookStatusFailureEpic(),
            this.buildChangeDepositStatusRequestEpic(),
            this.buildChangeDepositStatusSuccessEpic(),
            this.buildChangeDepositStatusFailureEpic(),
            this.buildChangeWithdrawalStatusRequestEpic(),
            this.buildChangeWithdrawalStatusSuccessEpic(),
            this.buildChangeWithdrawalStatusFailureEpic(),
            this.buildChangeLobbyStatusRequestEpic(),
            this.buildChangeLobbyStatusSuccessEpic(),
            this.buildChangeLobbyStatusFailureEpic(),
            this.buildChangeP2PTransferStatusRequestEpic(),
            this.buildChangeP2PTransferStatusSuccessEpic(),
            this.buildChangeP2PTransferStatusFailureEpic(),
            this.buildUpdatePlayerIdRequestEpic(),
            this.buildUpdatePlayerIdSuccessEpic(),
            this.buildUpdatePlayerIdFailureEpic(),

            this.buildDownloadEpic(),
            this.buildDownloadSuccessEpic(),
            this.buildDownloadFailureEpic(),
        ];
    }

    private buildDownloadEpic(): Epic {
        return actions$ =>
            actions$.pipe(
                filter(isActionOf(userProfileActions.download.request)),
                mergeMap(action => {
                    const {filter, fields, filename, worksheetKeys, headers} = action.payload;
                    const fetchPayload: EntityFetchRequestPayload<UserProfileQueryFields> = {
                        type: EntityType.UserProfile,
                        filter,
                        fields,
                    };
                    const failureActionObservable: Observable<PayloadAction<string, Message>> = of(
                        userProfileActions.download.failure({message: userActionsEpicLocalized.downloadFailureMessage})
                    );

                    return this._service.get(fetchPayload).pipe(
                        mergeMap(response => {
                            let result: Observable<unknown>;
                            if (response?.status === ServerResponseStatus.Success) {
                                if (response?.responsePayload?.total <= maxPageSize) {
                                    const worksheetData = response.responsePayload?.items?.map(i =>
                                        this._mapper.map(i, UserProfile, UserProfileDownloadModel)
                                    );
                                    result = this._fileAdapter.createFile(worksheetData, worksheetKeys, headers).pipe(
                                        map(arrayBuffer => userProfileActions.download.success({arrayBuffer, filename})),
                                        catchError(() => failureActionObservable)
                                    );
                                } else {
                                    result = of(
                                        userProfileActions.download.failure({
                                            message: userActionsEpicLocalized.downloadMaxPageSizeErrorMessage,
                                            values: {maxPageSize: maxPageSize.toString()},
                                        })
                                    );
                                }
                            } else {
                                result = failureActionObservable;
                            }

                            return result;
                        }),
                        catchError(() => failureActionObservable)
                    );
                })
            );
    }

    private buildDownloadSuccessEpic(): Epic {
        return actions$ =>
            actions$.pipe(
                filter(isActionOf(userProfileActions.download.success)),
                map(action => {
                    const {filename, arrayBuffer} = action.payload;
                    return fileActions.download({
                        file: new Blob([arrayBuffer]),
                        title: filename,
                        type: 'csv',
                    });
                })
            );
    }

    private buildDownloadFailureEpic(): Epic {
        return action$ =>
            action$.pipe(
                filter(isActionOf(userProfileActions.download.failure)),
                map((action: PayloadAction<string, Message>) => showErrorAction(action.payload))
            );
    }

    private buildChangeAccountStatusRequestEpic(): Epic {
        return this.buildRequestEpic<UserAccountLockRequestPayload, UserAccountLockRequestPayload, null>(
            userProfileActions.changeAccountStatus,
            payload => this._service.changeAccountLockStatus(payload)
        );
    }

    private buildChangeAccountStatusSuccessEpic(): Epic {
        return action$ =>
            action$.pipe(
                filter(isActionOf(userProfileActions.changeAccountStatus.success)),
                mergeMap(action => {
                    const {uid, value} = action.payload.requestPayload;
                    const updatedItem: Partial<UserProfileNormalized> = {
                        account_status: value ? UserAccountStatus.Locked : UserAccountStatus.Active,
                    };
                    const entityUpdateAction = entityActions.updateItem({type: EntityType.UserProfile, id: uid, updatedItem});
                    const successMessageAction = showMessageAction({
                        message: userActionsEpicLocalized.accountStatusLockedSuccessfully,
                    });
                    const partialCompleteMessage = showErrorAction({
                        message: value
                            ? userActionsEpicLocalized.accountStatusChangedButAccessLoginNotDisabled
                            : userActionsEpicLocalized.accountStatusChangedButAccessLoginNotEnabled,
                    });

                    return of(
                        action?.payload?.errors?.find(e => e.code === CustomErrorCodes.StatusChangedButLoginAccessFailed.toString())
                            ? partialCompleteMessage
                            : successMessageAction,
                        entityUpdateAction
                    );
                })
            );
    }

    private buildChangeAccountStatusFailureEpic(): Epic {
        return action$ =>
            action$.pipe(
                filter(isActionOf(userProfileActions.changeAccountStatus.failure)),
                map(() => showErrorAction({message: userActionsEpicLocalized.accountStatusLockFailed}))
            );
    }

    private buildChangeCasinoStatusRequestEpic(): Epic {
        return this.buildRequestEpic<UserProfileLockRequestPayload, GqlMutationRequest, UserProfile>(
            userProfileActions.changeCasinoStatus,
            payload => this._service.changeCasinoLockStatus(payload)
        );
    }

    private buildChangeCasinoStatusSuccessEpic(): Epic {
        return action$ =>
            action$.pipe(
                filter(isActionOf(userProfileActions.changeCasinoStatus.success)),
                mergeMap(action => {
                    const {uid, feature_status} = action.payload.requestPayload.variables.casino_lock;
                    const updatedItem: Partial<UserProfileNormalized> = {
                        casino_status: feature_status,
                    };
                    return of(
                        showMessageAction({message: userActionsEpicLocalized.casinoStatusLockedSuccessfully}),
                        entityActions.updateItem({type: EntityType.UserProfile, id: uid, updatedItem})
                    );
                })
            );
    }

    private buildChangeCasinoStatusFailureEpic(): Epic {
        return action$ =>
            action$.pipe(
                filter(isActionOf(userProfileActions.changeCasinoStatus.failure)),
                map(() => showErrorAction({message: userActionsEpicLocalized.casinoStatusLockFailed}))
            );
    }

    private buildChangeSportsboookStatusRequestEpic(): Epic {
        return this.buildRequestEpic<UserProfileLockRequestPayload, GqlMutationRequest<MutationLockSportsbookArgs>, UserProfile>(
            userProfileActions.changeSportsbookStatus,
            payload => this._service.changeSportsbookLockStatus(payload)
        );
    }

    private buildChangeSportsboookStatusSuccessEpic(): Epic {
        return action$ =>
            action$.pipe(
                filter(isActionOf(userProfileActions.changeSportsbookStatus.success)),
                mergeMap(action => {
                    const {uid, feature_status} = action.payload.requestPayload.variables.sportsbook_lock;
                    const updatedItem: Partial<UserProfileNormalized> = {
                        sportsbook_status: feature_status,
                    };
                    return of(
                        showMessageAction({message: userActionsEpicLocalized.sportsbookStatusLockedSuccessfully}),
                        entityActions.updateItem({type: EntityType.UserProfile, id: uid, updatedItem})
                    );
                })
            );
    }

    private buildChangeSportsboookStatusFailureEpic(): Epic {
        return action$ =>
            action$.pipe(
                filter(isActionOf(userProfileActions.changeSportsbookStatus.failure)),
                map(() => showErrorAction({message: userActionsEpicLocalized.sportsbookStatusLockFailed}))
            );
    }

    private buildChangeDepositStatusRequestEpic(): Epic {
        return this.buildRequestEpic<UserProfileLockRequestPayload, GqlMutationRequest, UserProfile>(
            userProfileActions.changeDepositStatus,
            payload => this._service.changeDepositLockStatus(payload)
        );
    }

    private buildChangeDepositStatusSuccessEpic(): Epic {
        return action$ =>
            action$.pipe(
                filter(isActionOf(userProfileActions.changeDepositStatus.success)),
                mergeMap(action => {
                    const {uid, feature_status} = action.payload.requestPayload.variables.deposit_lock;
                    const updatedItem: Partial<UserProfileNormalized> = {
                        deposit_status: feature_status,
                    };
                    return of(
                        showMessageAction({message: userActionsEpicLocalized.depositStatusLockedSuccessfully}),
                        entityActions.updateItem({type: EntityType.UserProfile, id: uid, updatedItem})
                    );
                })
            );
    }

    private buildChangeDepositStatusFailureEpic(): Epic {
        return action$ =>
            action$.pipe(
                filter(isActionOf(userProfileActions.changeDepositStatus.failure)),
                map(() => showErrorAction({message: userActionsEpicLocalized.depositStatusLockFailed}))
            );
    }

    private buildChangeWithdrawalStatusRequestEpic(): Epic {
        return this.buildRequestEpic<UserProfileLockRequestPayload, GqlMutationRequest, UserProfile>(
            userProfileActions.changeWithdrawalStatus,
            payload => this._service.changeWithdrawalLockStatus(payload)
        );
    }

    private buildChangeWithdrawalStatusSuccessEpic(): Epic {
        return action$ =>
            action$.pipe(
                filter(isActionOf(userProfileActions.changeWithdrawalStatus.success)),
                mergeMap(action => {
                    const {uid, feature_status} = action.payload.requestPayload.variables.withdrawal_lock;
                    const updatedItem: Partial<UserProfileNormalized> = {
                        withdrawal_status: feature_status,
                    };
                    return of(
                        showMessageAction({message: userActionsEpicLocalized.withdrawalStatusLockedSuccessfully}),
                        entityActions.updateItem({type: EntityType.UserProfile, id: uid, updatedItem})
                    );
                })
            );
    }

    private buildChangeWithdrawalStatusFailureEpic(): Epic {
        return action$ =>
            action$.pipe(
                filter(isActionOf(userProfileActions.changeWithdrawalStatus.failure)),
                map(() => showErrorAction({message: userActionsEpicLocalized.withdrawalStatusLockFailed}))
            );
    }

    private buildChangeLobbyStatusRequestEpic(): Epic {
        return this.buildRequestEpic<UserProfileLockRequestPayload, UserProfileLockRequestPayload, null>(
            userProfileActions.changeLobbyAccessStatus,
            payload => this._service.changeLobbyLockStatus(payload)
        );
    }

    private buildChangeLobbyStatusSuccessEpic(): Epic {
        return action$ =>
            action$.pipe(
                filter(isActionOf(userProfileActions.changeLobbyAccessStatus.success)),
                mergeMap(action => {
                    const {uid, value} = action.payload.requestPayload;
                    const updatedItem: Partial<UserProfileNormalized> = {
                        lobby_access_status: value ? FeatureAccessStatus.Locked : FeatureAccessStatus.Unlocked,
                    };
                    return of(
                        showMessageAction({message: userActionsEpicLocalized.lobbyStatusLockedSuccessfully}),
                        entityActions.updateItem({
                            type: EntityType.UserProfile,
                            id: uid,
                            updatedItem,
                        })
                    );
                })
            );
    }

    private buildChangeLobbyStatusFailureEpic(): Epic {
        return action$ =>
            action$.pipe(
                filter(isActionOf(userProfileActions.changeLobbyAccessStatus.failure)),
                map(() => showErrorAction({message: userActionsEpicLocalized.lobbyStatusLockFailed}))
            );
    }

    private buildChangeP2PTransferStatusRequestEpic(): Epic {
        return this.buildRequestEpic<UserProfileLockRequestPayload, GqlMutationRequest, UserProfile>(
            userProfileActions.changeP2PTransferStatus,
            payload => this._service.changeP2PTransferStatus(payload)
        );
    }

    private buildChangeP2PTransferStatusSuccessEpic(): Epic {
        return action$ =>
            action$.pipe(
                filter(isActionOf(userProfileActions.changeP2PTransferStatus.success)),
                mergeMap(action => {
                    const {uid, feature_status} = action.payload.requestPayload.variables.p2p_lock;
                    const updatedItem: Partial<UserProfileNormalized> = {p2p_transfer_status: feature_status};
                    return of(
                        showMessageAction({message: userActionsEpicLocalized.p2pTransferStatusLockedSuccessfully}),
                        entityActions.updateItem({type: EntityType.UserProfile, id: uid, updatedItem})
                    );
                })
            );
    }

    private buildChangeP2PTransferStatusFailureEpic(): Epic {
        return action$ =>
            action$.pipe(
                filter(isActionOf(userProfileActions.changeP2PTransferStatus.failure)),
                map(() => showErrorAction({message: userActionsEpicLocalized.p2pTransferStatusLockFailed}))
            );
    }

    // TODO: [BO-2903] Leave single epic
    private buildUpdatePlayerIdRequestEpic(): Epic {
        const handlePlayerIdUpdate = (
            payload: UpdatePlayerIdRequestPayload
        ): Observable<ServiceResponsePayload<GqlMutationRequest, UserProfile>> => {
            if (payload.newPlayerId && payload.oldPlayerId && payload.oldPlayerId !== payload.newPlayerId) {
                return this.getRemoveAgentInfoRequest(payload).pipe(mergeMap(() => this.getAddAgentInfoRequest(payload)));
            } else if (payload.newPlayerId && payload.oldPlayerId && payload.oldPlayerId === payload.newPlayerId) {
                return this.getAddAgentInfoRequest(payload);
            } else if (payload.oldPlayerId) {
                return this.getRemoveAgentInfoRequest(payload);
            } else if (payload.newPlayerId) {
                return this.getAddAgentInfoRequest(payload);
            } else {
                return of({
                    status: ServerResponseStatus.Success,
                    requestPayload: null,
                    responsePayload: null,
                });
            }
        };

        const updatePlayerIdRequestEpic = this.buildRequestEpic<UpdatePlayerIdRequestPayload, GqlMutationRequest, UserProfile>(
            userProfileActions.updatePlayerId,
            handlePlayerIdUpdate
        );

        const updateAccessManagementPlayerIdRequestEpic = this.buildRequestEpic<
            UpdatePlayerIdRequestPayload,
            GqlMutationRequest,
            UserProfile
        >(userProfileActions.updateAccessManagementPlayerId, handlePlayerIdUpdate);

        return combineEpics(updatePlayerIdRequestEpic, updateAccessManagementPlayerIdRequestEpic);
    }

    private getRemoveAgentInfoRequest(payload: UpdatePlayerIdRequestPayload) {
        return this._service.updateAgentInfo({
            uid: payload.oldPlayerId,
            agent_info: null,
        });
    }

    private getAddAgentInfoRequest(payload: UpdatePlayerIdRequestPayload) {
        return this._service.updateAgentInfo({
            uid: payload.newPlayerId,
            agent_info: {
                bo_agent_id: payload.agentId,
                agent_granted_ts: {seconds: momentToTimestampSeconds(moment())},
                default_agent_revenue_share_history: payload.revenueShare,
            },
        });
    }

    private buildUpdatePlayerIdSuccessEpic(): Epic {
        return action$ =>
            action$.pipe(
                filter(isActionOf([userProfileActions.updatePlayerId.success, userProfileActions.updateAccessManagementPlayerId.success])),
                mergeMap((res: PayloadAction<string, ServiceResponsePayload<GqlMutationRequest, UserProfile>>) => {
                    let result: Observable<PayloadAction<string, unknown>> = of();
                    if (res.payload.responsePayload) {
                        const {uid, agent_info} = res.payload.responsePayload;
                        const updatedItem: Partial<UserProfileNormalized> = {agent_info};
                        result = of(
                            showMessageAction({message: userActionsEpicLocalized.updatePlayerIdSuccess, values: {uid: uid}}),
                            entityActions.updateItem({type: EntityType.UserProfile, id: uid, updatedItem})
                        );
                    }
                    return result;
                })
            );
    }

    private buildUpdatePlayerIdFailureEpic(): Epic {
        return action$ =>
            action$.pipe(
                filter(isActionOf([userProfileActions.updatePlayerId.failure, userProfileActions.updateAccessManagementPlayerId.failure])),
                map(res => showErrorAction({message: this.getUpdatePlayerIdErrorMessage(res.payload.errors?.[0])}))
            );
    }

    private getUpdatePlayerIdErrorMessage(error: ServerResponseError): MessageDescriptor | string {
        let result: MessageDescriptor | string;
        const validationErrors = error?.validation_errors;
        const code = error?.code;
        if (validationErrors) {
            result = validationErrors;
        } else if (code === CustomErrorCodes.NoParentAgentRevenueShare.toString()) {
            result = userActionsEpicLocalized.noParentAgentRevenueShare;
        } else if (code === CustomErrorCodes.InvalidSubagentRevenueShare.toString()) {
            result = userActionsEpicLocalized.invalidSubagentRevenueShare;
        } else {
            result = userActionsEpicLocalized.updatePlayerIdFailure;
        }
        return result;
    }
}
