import {defineMessages} from 'react-intl';

export const localizedHeaders = defineMessages({
    id: {
        id: 'accountVerification_id',
        defaultMessage: 'KYC ID',
    },
    ndrpId: {
        id: 'accountVerification_ndrpId',
        defaultMessage: 'NDRP case ID',
    },
    requestDate: {
        id: 'accountVerification_requestDate',
        defaultMessage: 'Request Date',
    },
    duration: {
        id: 'accountVerification_duration',
        defaultMessage: 'Duration',
    },
    kycStatus: {
        id: 'accountVerification_kycStatus',
        defaultMessage: 'KYC Status',
    },
    type: {
        id: 'accountVerification_type',
        defaultMessage: 'KYC Type',
    },
    jumioStatus: {
        id: 'accountVerification_jumioStatus',
        defaultMessage: 'Jumio Status',
    },
    idUpload: {
        id: 'accountVerification_idUpload',
        defaultMessage: 'ID Upload',
    },
    docsJumioStatus: {
        id: 'accountVerification_docsJumioStatus',
        defaultMessage: 'Docs Jumio Status',
    },
    uid: {
        id: 'accountVerification_uid',
        defaultMessage: 'UID',
    },
    email: {
        id: 'accountVerification_email',
        defaultMessage: 'Email',
    },
    username: {
        id: 'accountVerification_username',
        defaultMessage: 'Username',
    },
    reasonCodes: {
        id: 'accountVerification_reasonCodes',
        defaultMessage: 'KYC Reason',
    },
    labels: {
        id: 'accountVerification_labels',
        defaultMessage: 'Labels',
    },
    ndrpStatus: {
        id: 'accountVerification_ndrpStatus',
        defaultMessage: 'NDRP Status',
    },
    playerCountry: {
        id: 'accountVerification_playerCountry',
        defaultMessage: 'Country',
    },
    assignee: {
        id: 'accountVerification_assignee',
        defaultMessage: 'Assignee',
    },
});
