import {TransactionType, UserProfile} from '@models/generated/graphql';
import {TransactionEditableViewModel} from '@models/transaction/types';

import {ManualTransactionAddViewModel} from '../block-transaction-actions';

import {ManualTransactionTemplateModelKeys} from './services/ManualTransactionExcelTemplateFactory';

export enum ValidationCode {
    UidNotExists = 1000,
    AmountNotInteger = 1001,
    AmountNotPositive = 1002,
    AmountNotNumber = 1003,
    TypeInvalid = 1004,
    ReasonInvalid = 1005,
    LineIsTooLarge = 1006,
    BalanceIsNotEnough = 1007,
    AmountDecimalPartLengthIsNotValid = 1008,
    AmountIsTooLarge = 1009,
    TotalAmountIsTooLarge = 1010,
}

export type ManualTransactionParsedModel = Record<ManualTransactionTemplateModelKeys | 'rowIndex', string>;

export type BulkDebitCreditAddModel = ManualTransactionAddViewModel & {
    rowIndex: number;
    id: string;
    serverId: string;
};

export type P2PTransferModel = Pick<TransactionEditableViewModel, 'id' | 'uid' | 'transaction_type' | 'amount' | 'currency'> &
    Pick<UserProfile, 'finance'>;

export const bulkTransactionsMaxTemplateLine = 500;

export type ParseManualTransactionFileData = {file: File; uid: string; type: TransactionType};

export type P2PTransferCounterpartUser = UserProfile & {
    id: string;
    serverId: string;
    amount?: number;
    transaction_type?: TransactionType;
};
