import React from 'react';

import {QueryFilterAdapterModel, QueryFilterAdapterProps, withQueryFilter} from '@components/filter';
import {TransactionStatus} from '@models/generated/graphql';
import {TransactionServerFilterKeys, TransactionServerTextFilterKeys} from '@redux/entity';
import {getMirrorObject} from '@utils/object';

import {TransactionFilterModel, TransactionFilterName, TransactionTextFilterKeys} from '../types';

import {TransactionAgentFilterProps, TransactionFilters} from './TransactionFilters';
import {TransactionStatusFilterValue} from './TransactionStatusFilter';

type TransactionFiltersAdapterProps = QueryFilterAdapterProps<
    TransactionServerFilterKeys,
    TransactionFilterName,
    TransactionServerTextFilterKeys
> &
    TransactionAgentFilterProps;

function TransactionFiltersAdapter({model, onChange, ...props}: TransactionFiltersAdapterProps) {
    const clientTextKeyToServer: Record<TransactionTextFilterKeys, TransactionServerTextFilterKeys> = {
        uid_em_un_rmc: 'uid_em_un_rmc',
        uid_un_tid_em: 'uid_un_tid_em',
        uid: 'uid',
        username: 'username',
        transactionId: 'transactionId',
        withdrawalId: 'withdrawalId',
        email: 'email',
        register_marketing_code: 'register_marketing_code',
        labelsText: 'labelsText',
    };
    const serverTextKeyToClient: Record<TransactionServerTextFilterKeys, TransactionTextFilterKeys> =
        getMirrorObject(clientTextKeyToServer);

    const filterModel: TransactionFilterModel = {
        text: model?.text ? {...model?.text, option: serverTextKeyToClient[model?.text?.option]} : null,
        types: model?.transactionTypes as string[],
        paymentMethod: model?.paymentMethodName as string[],
        amount: {
            from: model?.amountMin as number,
            to: model?.amountMax as number,
        },
        status: getTransactionStatusFilterValue(
            model?.transactionStatus as string[],
            model?.transactionStatusWithReason as TransactionStatusFilterValue
        ),
        date: {
            from: model?.['startedTs.from'] as number,
            to: model?.['startedTs.to'] as number | 'now',
        },
        agent: {
            agentId: model?.referrerPlayerId as string,
            isDownstream: model?.isDownstream as boolean,
        },
        country: model?.registrationCountry as string[],
        labels: model?.labels as string,
    };

    function handleChange(model: TransactionFilterModel) {
        const statusFilterHasReasons = model?.status?.some(s => s?.reason);
        const result: QueryFilterAdapterModel<TransactionServerFilterKeys, TransactionServerTextFilterKeys> = {
            text: model?.text ? {...model?.text, option: clientTextKeyToServer[model?.text?.option]} : undefined,
            'startedTs.from': model?.date?.from,
            'startedTs.to': model?.date?.to,
            referrerPlayerId: model?.agent?.agentId,
            isDownstream: model?.agent?.isDownstream,
            registrationCountry: model?.country,
            labels: model?.labels,
            transactionStatus: !statusFilterHasReasons && model?.status?.length ? model?.status?.map(i => i?.status) : undefined,
            transactionStatusWithReason: statusFilterHasReasons ? model?.status : undefined,
            amountMin: model?.amount?.from,
            amountMax: model?.amount?.to,
            transactionTypes: model?.types,
            paymentMethodName: model?.paymentMethod,
        };

        onChange(result);
    }

    function getTransactionStatusFilterValue(
        statuses: string[],
        statusWithReasons: TransactionStatusFilterValue
    ): TransactionStatusFilterValue {
        const statusesValue = (statuses ?? [])?.map((s: TransactionStatus) => ({status: s}));
        return [...statusesValue, ...(statusWithReasons ?? [])];
    }

    return <TransactionFilters model={filterModel} onChange={handleChange} {...props} />;
}

export const TransactionQueryFilters = withQueryFilter<
    TransactionServerFilterKeys,
    TransactionFilterName,
    TransactionServerTextFilterKeys,
    TransactionAgentFilterProps
>(TransactionFiltersAdapter, {
    uidEmailUsernameMarketingCode: ['uid_em_un_rmc', 'uid', 'email', 'register_marketing_code', 'username'],
    uidUsernameTransactionIdEmail: ['uid_un_tid_em', 'uid', 'username', 'transactionId', 'email'],
});
