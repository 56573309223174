import React, {useContext} from 'react';

import {CustomIcon, Icon} from '@components/icons';
import {BulkModalContext} from '@components/modal';

import {BulkActionButton, BulkActionKey, useBulkAction} from '../../block-bulk-actions';
import {useBulkOperationExecution} from '../../block-bulk-actions/hooks';

import {BulkKYCChooseActionStepModal} from './BulkKYCChooseActionStepModal';
import {BulkKYCExecutionStepModal} from './BulkKYCExecutionStepModal';
import {BulkKYCSummaryStepModal} from './BulkKYCSummaryStepModal';

type BulkButtonProps = {
    ids: string[];
    actionKeys: BulkActionKey[];
};

export const BulkKYCChooseActionStepButton = ({ids, actionKeys}: BulkButtonProps) => {
    const {chooseStep, closeModal} = useContext(BulkModalContext);
    const {resetBulkState} = useBulkAction();
    const {isExecutionInProgress} = useBulkOperationExecution();

    const openChangesSummary = () => chooseStep(changesSummaryStep);
    const openExecutionSummary = () => chooseStep(executionSummaryStep);
    const openChooseAction = () => chooseStep(chooseActionStep);

    const chooseActionStep = (
        <BulkKYCChooseActionStepModal
            onNext={openChangesSummary}
            onPrev={closeModal}
            onApply={openChooseAction}
            actionKeys={actionKeys}
            ids={ids}
        />
    );
    const changesSummaryStep = <BulkKYCSummaryStepModal onNext={openExecutionSummary} onPrev={closeModal} />;
    const executionSummaryStep = <BulkKYCExecutionStepModal onNext={closeModal} hasRetry={true} />;

    const handleClick = () => {
        resetBulkState();
        openChooseAction();
    };

    return (
        <BulkActionButton
            itemsCount={ids.length}
            disabled={isExecutionInProgress}
            onClick={handleClick}
            startIcon={<Icon icon={CustomIcon.ArrowRightSquareOutline} fontSize="small" />}
        />
    );
};
