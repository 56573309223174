import React from 'react';
import {defineMessages} from 'react-intl';

import {EntityType} from '@redux/entity';

import {
    BulkActionKey,
    BulkStepExecutionProps,
    BulkStepSummaryContentProps,
    usePerformStrategy,
    withBulkStepExecutionSummary,
    withBulkStepSummaryContentDataGrid,
} from '../../block-bulk-actions';
import {useMttBonusGroupedByTicket} from '../hooks';
import {BonusTriggerViewModel} from '../types';

import {DataGridBonusTriggerClient} from './DataGridBonusTrigger';

const localized = defineMessages({
    triggerMttBonus: {
        id: 'bulkBonusExecutionStep_triggerMttBonus',
        defaultMessage: 'Trigger Mtt bonus: {bonus}',
    },
    triggerImmediateCashBonus: {
        id: 'bulkBonusExecutionStep_triggerImmediateCashBonus',
        defaultMessage: 'Trigger Immediate Cash bonus: {bonus}',
    },
    triggerDepositCashMatchBonus: {
        id: 'bulkBonusExecutionStep_triggerDepositCashMatchBonus',
        defaultMessage: 'Trigger Deposit Cash Match bonus: {bonus}',
    },
});

const BulkMTTBonusExecutionStepGrid = withBulkStepSummaryContentDataGrid(
    DataGridBonusTriggerClient,
    ['uid', 'marketing_code', 'ticket_count', 'triggered_tickets', 'rawErrorMessage'],
    true
);

function BulkMttBonusesExecutionDetails({
    actionKey,
    isProcessingFinished,
    isOperationFailed,
    actionItems,
    entityType,
}: BulkStepSummaryContentProps<BonusTriggerViewModel>) {
    const {groupByTicket} = useMttBonusGroupedByTicket();
    const itemsGroupedByTicket = groupByTicket(actionItems);

    return itemsGroupedByTicket?.length > 0 ? (
        <BulkMTTBonusExecutionStepGrid
            actionItems={itemsGroupedByTicket}
            actionKey={actionKey}
            entityType={entityType}
            isOperationFailed={isOperationFailed}
            isProcessingFinished={isProcessingFinished}
            label={localized.triggerMttBonus}
            getLabelParams={(value: BonusTriggerViewModel) => ({bonus: value?.bonus_name})}
        />
    ) : null;
}

const BulkImmediateCashBonusesExecutionStepGrid = withBulkStepSummaryContentDataGrid(
    DataGridBonusTriggerClient,
    ['uid', 'marketing_code', 'rawErrorMessage'],
    true
);

function BulkImmediateCashBonusesExecutionDetails({
    actionKey,
    isProcessingFinished,
    isOperationFailed,
    actionItems,
    entityType,
}: BulkStepSummaryContentProps<BonusTriggerViewModel>) {
    return actionItems?.length > 0 ? (
        <BulkImmediateCashBonusesExecutionStepGrid
            actionItems={actionItems}
            actionKey={actionKey}
            entityType={entityType}
            isOperationFailed={isOperationFailed}
            isProcessingFinished={isProcessingFinished}
            label={localized.triggerImmediateCashBonus}
            getLabelParams={(value: BonusTriggerViewModel) => ({bonus: value?.bonus_name})}
        />
    ) : null;
}

const BulkDepositCashMatchBonusesExecutionStepGrid = withBulkStepSummaryContentDataGrid(
    DataGridBonusTriggerClient,
    ['uid', 'marketing_code', 'total_amount', 'rawErrorMessage'],
    true
);

function BulkDepositCashMatchBonusesExecutionDetails({
    actionKey,
    isProcessingFinished,
    isOperationFailed,
    actionItems,
    entityType,
}: BulkStepSummaryContentProps<BonusTriggerViewModel>) {
    return actionItems?.length > 0 ? (
        <BulkDepositCashMatchBonusesExecutionStepGrid
            actionItems={actionItems}
            actionKey={actionKey}
            entityType={entityType}
            isOperationFailed={isOperationFailed}
            isProcessingFinished={isProcessingFinished}
            label={localized.triggerDepositCashMatchBonus}
            getLabelParams={(value: BonusTriggerViewModel) => ({bonus: value?.bonus_name})}
        />
    ) : null;
}

const BulkMttBonusesExecutionStep = withBulkStepExecutionSummary(BulkMttBonusesExecutionDetails);

const BulkImmediateCashBonusesExecutionStep = withBulkStepExecutionSummary(BulkImmediateCashBonusesExecutionDetails);

const BulkDepositCashMatchBonusesExecutionStep = withBulkStepExecutionSummary(BulkDepositCashMatchBonusesExecutionDetails);

export function BulkBonusMttExecutionStep({onNext, hasRetry}: BulkStepExecutionProps) {
    const strategy = usePerformStrategy('PerformTriggerMttBonusStrategy');

    return (
        <BulkMttBonusesExecutionStep
            onNext={onNext}
            hasRetry={hasRetry}
            entityType={EntityType.UserProfile}
            actionsData={[{type: 'operation', actionKey: BulkActionKey.ManualTriggerMTTTicket, strategy}]}
        />
    );
}

export function BulkBonusImmediateCashExecutionStep({onNext, hasRetry}: BulkStepExecutionProps) {
    const strategy = usePerformStrategy('PerformTriggerImmediateCashBonusStrategy');

    return (
        <BulkImmediateCashBonusesExecutionStep
            onNext={onNext}
            hasRetry={hasRetry}
            entityType={EntityType.UserProfile}
            actionsData={[{type: 'operation', actionKey: BulkActionKey.ManualTriggerImmediateCashBonus, strategy}]}
        />
    );
}

export function BulkBonusDepositCashMatchExecutionStep({onNext, hasRetry}: BulkStepExecutionProps) {
    const strategy = usePerformStrategy('PerformTriggerDepositCashMatchBonusStrategy');

    return (
        <BulkDepositCashMatchBonusesExecutionStep
            onNext={onNext}
            hasRetry={hasRetry}
            entityType={EntityType.UserProfile}
            actionsData={[{type: 'operation', actionKey: BulkActionKey.ManualTriggerDepositCashMatch, strategy}]}
        />
    );
}
