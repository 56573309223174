import React from 'react';
import {GridCellParams} from '@mui/x-data-grid';

import {renderChip} from '@components/chip/ChipRenderer';
import {ReasonCode, TransactionStatus} from '@models/generated/graphql';
import {TransactionStatusWithReason} from '@models/transaction/types';

export type TransactionStatusProps = {status: TransactionStatus; reason: ReasonCode};

export function TransactionStatusChip({status, reason}: TransactionStatusProps) {
    const reasonStatuses: TransactionStatus[] = [TransactionStatus.OnHoldRisk, TransactionStatus.OnHoldPayment];
    return reasonStatuses?.includes(status) && reason && reason !== ReasonCode.Undefined
        ? renderChip<TransactionStatusWithReason>(`${status}.${reason}`, nameof<TransactionStatusWithReason>())
        : renderChip<TransactionStatus>(status, nameof<TransactionStatus>());
}

export function renderTransactionStatus(params: GridCellParams) {
    const props = params.value as TransactionStatusProps;
    return <TransactionStatusChip {...props} />;
}
