import React, {useMemo} from 'react';
import {defineMessages, useIntl} from 'react-intl';

import {LayoutDetailsActionsCollapsible} from '@components/layout';
import {LayoutDetailsItemGroup} from '@components/layout/LayoutDetailsItemGroup';
import {
    AccountVerification,
    AccountVerificationStatus,
    AccountVerificationType,
    KycReasonCode,
    PaymentVendor,
    Transaction,
    TransactionStatus,
} from '@models/generated/graphql';
import {
    AccountVerificationFilterKeys,
    AccountVerificationQueryFields,
    EntityType,
    TransactionQueryFields,
    TransactionServerFilterKeys,
} from '@redux/entity';
import {RealtimeMessageTrigger, RealtimeUpdatesMode} from '@redux/realtime';
import {useViewInit} from '@redux/view';
import {isStringNullOrEmpty} from '@utils/string';

import {UserProfileAction, UserProfileActions} from 'src/features/block-user-profile-actions';
import {InitiatePaymentKyc} from '../../block-account-verification';
import {WithdrawalChangeStatusActions, WithdrawalExternalCashierLink} from '../../block-transaction-actions';

const localized = defineMessages({
    withdrawalActions: {
        id: 'WithdrawalActions_title',
        defaultMessage: 'Withdrawal Actions',
    },
});

type WithdrawalActionsProps = {
    transactionId: string;
};

type UseTransactionData = {
    transaction: Transaction;
};

function useTransactions(transactionId: string): UseTransactionData {
    //TODO: use Transaction hook to read data
    const fields: TransactionQueryFields[] = ['transaction_id', 'payment_vendor', 'transaction_status', 'uid'];
    const {items} = useViewInit<Transaction, TransactionServerFilterKeys, TransactionQueryFields>({
        displayName: localized.withdrawalActions,
        viewType: 'WithdrawalActions',
        entity: {
            entity: EntityType.Transaction,
            fields,
        },
        defaultPaging: {page: 1, pageSize: 1},
        defaultFilters: [{key: 'transactionId', value: transactionId}],
        realtime: {
            entity: EntityType.Transaction,
            mode: RealtimeUpdatesMode.Silent,
            triggers: transactionId
                ? [
                      {
                          type: RealtimeMessageTrigger.Update,
                          args: {
                              filter: {
                                  field: 'transaction_id',
                                  value: transactionId,
                              },
                          },
                      },
                  ]
                : undefined,
        },
        validateFilter: filter => !isStringNullOrEmpty(filter?.find(f => f.key === 'transactionId')?.value),
    });

    return {transaction: items?.length > 0 ? items[0] : null};
}

type UseAccountVerificationData = {
    latestKyc: AccountVerification;
};

function useAccountVerification(uid: string): UseAccountVerificationData {
    //TODO: use AccountVerification hook to read data
    const fields: AccountVerificationQueryFields[] = ['uid', 'id', 'account_verification_status', 'reason_codes'];
    const {items} = useViewInit<AccountVerification, AccountVerificationFilterKeys, AccountVerificationQueryFields>({
        displayName: localized.withdrawalActions,
        viewType: 'WithdrawalActions',
        entity: {
            entity: EntityType.AccountVerification,
            fields,
        },
        defaultPaging: {page: 1, pageSize: 1},
        defaultFilters: [
            {key: 'uid', value: uid},
            {key: 'type', value: [AccountVerificationType.Payment]},
        ],
        defaultSorting: [{field: 'initiated_at.seconds', sort: 'desc'}],
        realtime: {
            entity: EntityType.AccountVerification,
            mode: RealtimeUpdatesMode.Silent,
            triggers: [{type: RealtimeMessageTrigger.Add}, {type: RealtimeMessageTrigger.Update}],
        },
        validateFilter: filter => !isStringNullOrEmpty(filter?.find(f => f.key === 'uid')?.value),
    });

    return {latestKyc: items?.length > 0 ? items[0] : null};
}

type UseWithdrawalActionsData = {
    transactionStatus: TransactionStatus;
    transactionPaymentVendor: PaymentVendor;
    uid: string;
    accountVerificationStatus: AccountVerificationStatus;
    reasonsCodes: KycReasonCode[];
};

export function useWithdrawalActionsData(transactionId: string): UseWithdrawalActionsData {
    const {transaction} = useTransactions(transactionId);
    const {latestKyc} = useAccountVerification(transaction?.uid);

    return {
        transactionStatus: transaction?.transaction_status,
        transactionPaymentVendor: transaction?.payment_vendor,
        uid: transaction?.uid,
        accountVerificationStatus: latestKyc?.account_verification_status,
        reasonsCodes: latestKyc?.reason_codes,
    };
}

export function WithdrawalActions({transactionId}: WithdrawalActionsProps) {
    const {formatMessage} = useIntl();
    const {transactionStatus, transactionPaymentVendor, accountVerificationStatus, reasonsCodes, uid} =
        useWithdrawalActionsData(transactionId);

    const actions: UserProfileAction[] = useMemo(
        () => [
            'account-status',
            'deposit-status',
            'lobby-status',
            'withdrawal-status',
            'casino-status',
            'sportsbook-status',
            'p2p-transfer-status',
        ],
        []
    );

    return (
        <LayoutDetailsActionsCollapsible
            header={
                <>
                    {formatMessage(localized.withdrawalActions)}{' '}
                    <LayoutDetailsItemGroup orientation="horizontal">
                        <WithdrawalChangeStatusActions
                            transactionId={transactionId}
                            transactionStatus={transactionStatus}
                            paymentVendor={transactionPaymentVendor}
                            uid={uid}
                        />
                    </LayoutDetailsItemGroup>
                </>
            }
        >
            <WithdrawalExternalCashierLink
                transactionId={transactionId}
                transactionStatus={transactionStatus}
                paymentVendor={transactionPaymentVendor}
            />
            <UserProfileActions uid={uid} actions={actions} includeGroupTitle={false} />
            <InitiatePaymentKyc uid={uid} status={accountVerificationStatus} reasons={reasonsCodes} disabled={uid === undefined} />
        </LayoutDetailsActionsCollapsible>
    );
}
