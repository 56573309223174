import {Mapper} from '@automapper/core';
import {inject, injectable} from 'inversify';

import {ServiceTypes} from '@inversify';

import {NoteInputViewModel} from '../../block-note/types';
import {NoteActionItem} from '../../block-notes-bulk-actions/types';
import {ApplyStrategyResponse, BulkActionItemPayload, BulkActionKey, BulkItemStatus, IBulkApplyStrategy} from '../types';

export type ApplyNotesRequest = {
    notes: NoteInputViewModel[];
};

export type ApplyNoteStrategyResponseValue = {
    noteInput: NoteActionItem;
    uid: string;
};

@injectable()
export class ApplyNoteStrategy implements IBulkApplyStrategy<ApplyNotesRequest> {
    private _mapper: Mapper;

    constructor(@inject(ServiceTypes.AutoMapper) mapper: Mapper) {
        this._mapper = mapper;
    }

    process({notes}: ApplyNotesRequest): ApplyStrategyResponse {
        const items =
            notes
                ?.map<BulkActionItemPayload>(item => {
                    const {entityId, uid, ...note} = item;
                    const noteInput = this._mapper.map(note, NoteInputViewModel, NoteActionItem);
                    return {
                        actionKey: BulkActionKey.NotesAndAttachments,
                        value: noteInput ? ({noteInput, uid} as ApplyNoteStrategyResponseValue) : null,
                        itemId: entityId,
                        status: BulkItemStatus.Pending,
                    };
                })
                ?.filter(i => i?.value !== null) ?? [];

        return {actionKey: BulkActionKey.NotesAndAttachments, items};
    }
}
