import {makeStyles} from 'tss-react/mui';

import {CustomTheme} from '@style';

//TODO: [BO-2668] Move to src/common/components/dropdown (rename or merge?)
//TODO: [BO-2669] Move dropdown components to input folder (?)
export const useFilterSelectClasses = makeStyles()((theme: CustomTheme) => ({
    selectFilter: {
        '&:not(:last-of-type)': {
            marginRight: theme.spacing(4),
        },
        display: 'flex',
        flexDirection: 'row',
        border: '1px solid #E1E7EB',
        boxShadow: `0px 1px 1px ${theme.custom.palette.content.boxShadow}`,
        background: theme.palette.background.paper,
        borderRadius: 8,
        height: theme.custom.denseButtonHeight,
        padding: theme.spacing(1, 2, 1, 0.5),
        justifyContent: 'center',
        alignItems: 'center',
        '& .MuiInputBase-root.Mui-focused': {
            border: 0,
        },
    },
    selectInput: {
        maxWidth: 300,
        width: '100%',
        border: 0,
        boxShadow: 'none',
    },
    selectInputIconLabel: {
        '& .MuiSelect-select': {
            paddingRight: '0 !important',
            display: 'flex',
            textAlign: 'center',
            alignItems: 'center',
        },
    },
    selectHasValue: {
        color: theme.palette.primary.contrastText,
    },
    selectTotalChip: {
        display: 'inline-flex !important',
        marginRight: theme.spacing(1),
    },
    selectLabel: {
        marginRight: theme.spacing(1.25),
        color: theme.palette.secondary.main,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    selectPopover: {
        maxHeight: 450,
        minWidth: '300px !important',
    },
    selectSearch: {
        padding: theme.spacing(2, 2, 0),
        background: theme.custom.palette.status.dropdownDefault.light,
    },
    selectDropdown: {
        marginTop: theme.spacing(0),
    },
}));

export const useDefaultSelectClasses = makeStyles()((theme: CustomTheme) => ({
    selectInput: {
        width: '100%',
        border: 0,
        boxShadow: 'none',
    },
    selectHasValue: {
        color: theme.palette.common.black,
    },
    selectLabel: {
        display: 'none',
    },
}));

export const useGeneralSelectClasses = makeStyles<void, 'selectWithConfirmIcon'>()((theme: CustomTheme, _params, classes) => ({
    divider: {
        margin: '0 !important',
    },
    menuItemDivider: {
        borderWidth: 0,
        borderStyle: 'solid',
        borderColor: theme.custom.palette.content.border,
        borderBottomWidth: 'thin',
    },
    cursor: {
        cursor: 'default',
    },
    selectedValueEllipsis: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    notSelectedValueWithChip: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: 'flex',
        flexDirection: 'row',
        '&:not(:last-of-type)': {
            marginRight: theme.spacing(4),
        },
        height: theme.custom.denseButtonHeight,
        alignItems: 'center',
    },
    selectedValueWithChip: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: 'flex',
        flexDirection: 'row',
        '&:not(:last-of-type)': {
            marginRight: theme.spacing(4),
        },
        height: theme.custom.denseButtonHeight,
        alignItems: 'center',
    },
    selectButtonEndIcon: {
        fontSize: '10px !important',
        color: theme.palette.text.secondary,
    },
    selectButtonOpen: {
        color: theme.palette.primary.main,
    },
    listItemTextTypography: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    listItemText: {
        marginLeft: theme.spacing(0.5),
        verticalAlign: 'middle',
        display: 'inline-flex',
    },
    selectWithConfirmMenuItem: {
        '&:hover': {
            backgroundColor: 'unset',
            color: theme.palette.primary.main,
            '& .MuiListItemIcon-root': {
                color: theme.palette.primary.main,
            },
        },
        '&[aria-selected=true]': {
            backgroundColor: 'unset !important',
            color: theme.palette.primary.main,
            [`& > .${classes.selectWithConfirmIcon}`]: {
                display: 'block !important',
                width: theme.spacing(3.5),
                paddingLeft: theme.spacing(1.5),
            },
        },
    },
    menuItem: {
        padding: theme.spacing(0.25, 1.5, 0.25, 1.5),
    },
    selectWithConfirmIcon: {
        display: 'none',
        marginLeft: 'auto',
    },
    selectItemHidden: {
        display: 'none',
    },
    expander: {
        flex: '2 1 auto',
        marginLeft: theme.spacing(1.25),
        '&::before': {
            fontSize: theme.spacing(1.5),
        },
    },
    chip: {
        border: 'none',
        fontSize: theme.typography.h4.fontSize,
    },
    suboption: {
        paddingLeft: theme.spacing(8),
    },
    groupListItemText: {
        flex: 'none',
    },
    menuSelectItemContent: {
        background: 'transparent',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
    },
}));
