import {DocumentNode, gql, NormalizedCacheObject} from '@apollo/client';
import {Mapper} from '@automapper/core';
import {inject, injectable} from 'inversify';

import {ServiceTypes} from '@inversify/inversifyTypes';
import {BoUserSettingsFilterKeys, BoUserSettingsKeys, QueryGetBoUserSettingsArgs} from '@redux/entity/types/entityBoUserSettings';

import {GqlRequestBuilder} from './entity/GqlRequestBuilder';
import {EntityBaseGqlService} from './entity';
import {ApolloClientProxy} from './gql-api';

@injectable()
export class BoUserSettingsService extends EntityBaseGqlService<QueryGetBoUserSettingsArgs, BoUserSettingsKeys, BoUserSettingsFilterKeys> {
    constructor(
        @inject(ServiceTypes.ApolloClientIGPMocked) client: ApolloClientProxy<NormalizedCacheObject>,
        @inject(ServiceTypes.AutoMapper) mapper: Mapper
    ) {
        super(client, mapper, new BoUserSettingsQueryBuilder());
    }
}

export class BoUserSettingsQueryBuilder extends GqlRequestBuilder<
    QueryGetBoUserSettingsArgs,
    BoUserSettingsKeys,
    BoUserSettingsFilterKeys
> {
    protected buildQuery(fields: BoUserSettingsKeys[]): DocumentNode {
        return gql`
            query GetBoUserSettings($uid: String!) {
                getBoUserSettings(uid: $uid) {
                    ${this.buildQueryFields(fields)}
                }
            }
        `;
    }

    protected buildFilter(filter: Partial<Record<'uid', unknown>>): object {
        const uid: string = this.toGQLStringFilter(filter, 'uid');
        return {uid};
    }
}
