import {MessageDescriptor} from 'react-intl';

import {Affiliates, PlayerReferral, UserProfile} from '@models/generated/graphql';
import {EntityType, UserProfileQueryFields, UserProfileServerFilterKeys} from '@redux/entity';
import {RealtimeMessageTrigger, RealtimeUpdatesMode} from '@redux/realtime';
import {useViewInit, ViewModel, ViewType} from '@redux/view';
import {isStringNullOrEmpty} from '@utils';

import {usePlayerReferralData} from '../block-player-referral-details';

type UseReferralInfoResult = {
    referral: ViewModel<PlayerReferral>;
    affiliate: Affiliates;
};

export function useReferralInfoData(view: ViewType, title: MessageDescriptor, uid: string): UseReferralInfoResult {
    const {items} = usePlayerReferralData(view, title, uid, 'referee_uid', RealtimeUpdatesMode.Silent, () => uid);
    const referral = items?.[0];

    const {items: userItems} = useViewInit<UserProfile, UserProfileServerFilterKeys, UserProfileQueryFields>({
        viewType: view,
        displayName: title,
        entity: {
            entity: EntityType.UserProfile,
            fields: ['uid', 'affiliate.btag'],
        },
        defaultFilters: [{key: 'uid', value: uid}],
        realtime: {
            entity: EntityType.UserProfile,
            mode: RealtimeUpdatesMode.Silent,
            triggers: uid
                ? [
                      {
                          type: RealtimeMessageTrigger.Update,
                          args: {
                              filter: {
                                  field: 'uid',
                                  value: uid,
                              },
                          },
                      },
                  ]
                : undefined,
        },
        validateFilter: filter => !isStringNullOrEmpty(filter?.find(f => f.key === 'uid')?.value),
    });

    const affiliate = userItems?.[0]?.affiliate;

    return {referral, affiliate};
}
