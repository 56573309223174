import React, {useEffect} from 'react';
import {defineMessages} from 'react-intl';

import Button from '@components/button/Buttons';
import {BulkModalContent, BulkModalTitle, ModalFooter} from '@components/modal';
import {EntityType} from '@redux/entity';

import {useBulkOperationExecution, useExecutionActions} from '../hooks';
import {BulkStepProps, PerformActionData} from '../types';

import {BulkStepSummaryContentProps} from './BulkExecutionStepDetailsContent';

const localized = defineMessages({
    bulkStepExecutionSummaryModalHeader: {
        id: 'BulkStepExecutionSummary_modalHeader',
        defaultMessage: 'Summary',
    },
    bulkStepExecutionSummaryModalConfirm: {
        id: 'BulkStepExecutionSummary_modalConfirm',
        defaultMessage: 'Confirm',
    },
    bulkStepExecutionSummaryModalRetry: {
        id: 'BulkStepExecutionSummary_modalRetry',
        defaultMessage: 'Retry',
    },
});

type BulkStepExecutionSummaryProps = Omit<BulkStepProps, 'onPrev'> & {
    hasRetry: boolean;
    actionsData: PerformActionData[];
    entityType: EntityType;
    withFlexibleGrid?: boolean;
};

export function withBulkStepExecutionSummary(WrappedComponent: (props: BulkStepSummaryContentProps) => JSX.Element) {
    return function BulkStepExecutionSummary({
        onNext,
        hasRetry = false,
        actionsData,
        entityType,
        withFlexibleGrid,
    }: BulkStepExecutionSummaryProps) {
        const {actionItems, isExecutionInProgress, isExecutionFailed, failedOperationKeys} = useBulkOperationExecution();
        const {performExecution, retryExecution} = useExecutionActions(actionItems, actionsData);

        useEffect(() => {
            performExecution();
        }, []);

        function handlePrevClick() {
            retryExecution();
        }

        function handleNextClick() {
            onNext();
        }

        return (
            <>
                <BulkModalTitle title={localized.bulkStepExecutionSummaryModalHeader} />
                <BulkModalContent>
                    {Object.keys(actionItems).map(actionKey => (
                        <WrappedComponent
                            key={`summaryDetails-${actionKey}`}
                            actionItems={actionItems[actionKey]}
                            actionKey={actionItems[actionKey]?.[0]?.actionKey}
                            entityType={entityType}
                            isProcessingFinished={!isExecutionInProgress}
                            isOperationFailed={failedOperationKeys.includes(actionKey)}
                            withFlexibleGrid={withFlexibleGrid}
                        />
                    ))}
                </BulkModalContent>
                <ModalFooter>
                    {hasRetry && !isExecutionInProgress && isExecutionFailed ? (
                        <Button label={localized.bulkStepExecutionSummaryModalRetry} onClick={handlePrevClick} />
                    ) : (
                        <></>
                    )}
                    <Button
                        color="primary"
                        label={localized.bulkStepExecutionSummaryModalConfirm}
                        onClick={handleNextClick}
                        disabled={isExecutionInProgress}
                    />
                </ModalFooter>
            </>
        );
    };
}
