import {MessageDescriptor} from 'react-intl';

import {ChipType, ChipVariant} from '@components/chip/types';
import {CustomIcon} from '@components/icons/types';
import {getTransactionFilterTypeChipsMapping} from '@config/chip/transactionFilterTypeChipConfig';
import {getTransactionStatusWithReasonChipConfig} from '@config/chip/transactionStatusWithReasonChipConfig';
import {BulkStatus} from '@models/bulk-operation';
import {GamePlatform} from '@models/game-template';
import {
    AccountVerificationStatus,
    AccountVerificationType,
    AmlLabel,
    BackpackItemStatus,
    BonusCreditType,
    BonusStatus,
    FeatureAccessStatus,
    Gender,
    GeoCheckEventSource,
    GeoCheckStatus,
    KycStatus,
    LoginStatus,
    NoteType,
    PaymentOption,
    PaymentVendor,
    PlayerBonusStatus,
    PlayersRelationType,
    RevenueShareOfferingStatus,
    TransactionStatus,
    TransactionType,
    UserAccountStatus,
    UserStatisticsGameType,
    Workspace,
} from '@models/generated/graphql';
import {TicketStatus} from '@models/sportsbook-transaction/type';
import {TransactionFilterType, TransactionStatusWithReason} from '@models/transaction/types';

import {localizedAccountVerificationStatus} from '../../../features/app/intl/shared-resources/accountVerificationStatus';
import {localizedAccountVerificationType} from '../../../features/app/intl/shared-resources/accountVerificationType';
import {localizedAmlLabel} from '../../../features/app/intl/shared-resources/amlLabel';
import {localizedBackpackStatus} from '../../../features/app/intl/shared-resources/backpackStatus';
import {localizedPlayerBonusStatus} from '../../../features/app/intl/shared-resources/bonusStatus';
import {localizedBulkStatus} from '../../../features/app/intl/shared-resources/bulkStatus';
import {localizedConnectionType} from '../../../features/app/intl/shared-resources/connectionType';
import {localizedFeatureAccessStatus} from '../../../features/app/intl/shared-resources/featureAccessStatus';
import {localizedGender} from '../../../features/app/intl/shared-resources/gender';
import {localizedKycStatus} from '../../../features/app/intl/shared-resources/kycStatus';
import {localizedLoginStatus} from '../../../features/app/intl/shared-resources/loginStatus';
import {localizedPaymentOption} from '../../../features/app/intl/shared-resources/paymentOption';
import {localizedPlayersRelationType} from '../../../features/app/intl/shared-resources/playersRelationType';
import {localizedSecuritySettings} from '../../../features/app/intl/shared-resources/securitySettings';
import {localizedUserAccountStatus} from '../../../features/app/intl/shared-resources/userAccountStatus';
import {localizedUserStatisticsGameType} from '../../../features/app/intl/shared-resources/userStatisticsGameType';
import {ConnectionType} from '../connectionType';
import {EnablementMode} from '../enablementMode';

import {getPlayerBonusStatusChipsMapping} from './beBonusStatusChipConfig';
import {getBeCreditTypeChipsMapping} from './beCreditTypeChipConfig';
import {getBulkAccountVerificationStatusChipsMapping} from './bulkAccountVerificationStatusConfig';
import {getGamePlatformChipsMapping} from './gamePlatformChipConfig';
import {getGeoCheckEventSourceChipsMapping} from './geoCheckEventSourceChipConfig';
import {getGeoCheckStatusChipsMapping} from './geoCheckStatusChipConfig';
import {getNoteTypeChipsMapping} from './noteTypeChipsConfig';
import {getRevenueShareOfferingStatusChipsMapping} from './revenueShareOfferingStatusChipConfig';
import {getTicketStatusChipMapping} from './ticketStatusChipConfig';
import {getTransactionStatusChipsMapping} from './transactionStatusChipConfig';
import {getTransactionTypeChipsMapping} from './transactionTypeChipConfig';
import {getWorkspaceChipsMapping} from './workspaceChipsConfig';

//TODO: [BO-2691] Remove localization configuration from chip configuration - should be managed through EnumLocalizer BO-2688
export type ChipMapping = {
    chipType: ChipType;
    chipVariant: ChipVariant;
    label: MessageDescriptor | string;
    labelTypeString?: boolean;
    startIconClass?: string;
};

export type ChipsMapping = {
    [chipName: string]: {
        [key: string]: ChipMapping;
    };
};

export const BulkAccountVerificationStatus = `Bulk${nameof<AccountVerificationStatus>()}`;

export const chipsMapping: ChipsMapping = {
    [nameof<UserAccountStatus>()]: {
        [UserAccountStatus.Active]: {
            label: localizedUserAccountStatus[UserAccountStatus.Active],
            chipType: ChipType.Status,
            chipVariant: ChipVariant.Green,
        },

        [UserAccountStatus.Locked]: {
            label: localizedUserAccountStatus[UserAccountStatus.Locked],
            chipType: ChipType.Status,
            chipVariant: ChipVariant.Disabled,
        },

        [UserAccountStatus.Dormant]: {
            label: localizedUserAccountStatus[UserAccountStatus.Dormant],
            chipType: ChipType.Status,
            chipVariant: ChipVariant.Disabled,
        },

        [UserAccountStatus.Blocked]: {
            label: localizedUserAccountStatus[UserAccountStatus.Blocked],
            chipType: ChipType.Status,
            chipVariant: ChipVariant.Disabled,
        },

        [UserAccountStatus.Deregistered]: {
            label: localizedUserAccountStatus[UserAccountStatus.Deregistered],
            chipType: ChipType.Status,
            chipVariant: ChipVariant.Disabled,
        },

        [UserAccountStatus.Pending]: {
            label: localizedUserAccountStatus[UserAccountStatus.Pending],
            chipType: ChipType.Status,
            chipVariant: ChipVariant.Orange,
        },

        [UserAccountStatus.Closed]: {
            label: localizedUserAccountStatus[UserAccountStatus.Closed],
            chipType: ChipType.Status,
            chipVariant: ChipVariant.Disabled,
        },
    },

    [nameof<AmlLabel>()]: {
        [AmlLabel.AccountReview]: {
            label: localizedAmlLabel[AmlLabel.AccountReview],
            chipType: ChipType.Mark,
            chipVariant: ChipVariant.Orange,
        },

        [AmlLabel.ActivityReview]: {
            label: localizedAmlLabel[AmlLabel.ActivityReview],
            chipType: ChipType.Mark,
            chipVariant: ChipVariant.Green,
        },

        [AmlLabel.EddDeposit]: {
            label: localizedAmlLabel[AmlLabel.EddDeposit],
            chipType: ChipType.Mark,
            chipVariant: ChipVariant.LightBlue,
        },

        [AmlLabel.EddGameplay]: {
            label: localizedAmlLabel[AmlLabel.EddGameplay],
            chipType: ChipType.Mark,
            chipVariant: ChipVariant.Turquoise,
        },

        [AmlLabel.EddPep]: {
            label: localizedAmlLabel[AmlLabel.EddPep],
            chipType: ChipType.Mark,
            chipVariant: ChipVariant.Disabled,
        },

        [AmlLabel.EddWithdrawal]: {
            label: localizedAmlLabel[AmlLabel.EddWithdrawal],
            chipType: ChipType.Mark,
            chipVariant: ChipVariant.Purple,
        },

        [AmlLabel.GameplayReview]: {
            label: localizedAmlLabel[AmlLabel.GameplayReview],
            chipType: ChipType.Mark,
            chipVariant: ChipVariant.Disabled,
        },

        [AmlLabel.VerifyPaymentMethod]: {
            label: localizedAmlLabel[AmlLabel.VerifyPaymentMethod],
            chipType: ChipType.Mark,
            chipVariant: ChipVariant.Disabled,
        },
    },

    [nameof<ConnectionType>()]: {
        [ConnectionType.Mobile]: {
            label: localizedConnectionType[ConnectionType.Mobile],
            chipType: ChipType.Mark,
            chipVariant: ChipVariant.Orange,
        },
        [ConnectionType.Corporate]: {
            label: localizedConnectionType[ConnectionType.Corporate],
            chipType: ChipType.Mark,
            chipVariant: ChipVariant.Green,
        },
        [ConnectionType.VPN]: {
            label: localizedConnectionType[ConnectionType.VPN],
            chipType: ChipType.Mark,
            chipVariant: ChipVariant.Turquoise,
        },
        [ConnectionType.Cloud]: {
            label: localizedConnectionType[ConnectionType.Cloud],
            chipType: ChipType.Mark,
            chipVariant: ChipVariant.LightBlue,
        },
        [ConnectionType.Proxy]: {
            label: localizedConnectionType[ConnectionType.Proxy],
            chipType: ChipType.Mark,
            chipVariant: ChipVariant.Yellow,
        },
        [ConnectionType.Residential]: {
            label: localizedConnectionType[ConnectionType.Residential],
            chipType: ChipType.Mark,
            chipVariant: ChipVariant.Purple,
        },
        [ConnectionType.Hosting]: {
            label: localizedConnectionType[ConnectionType.Hosting],
            chipType: ChipType.Mark,
            chipVariant: ChipVariant.Orange,
        },
        [ConnectionType.Cellular]: {
            label: localizedConnectionType[ConnectionType.Cellular],
            chipType: ChipType.Mark,
            chipVariant: ChipVariant.Green,
        },
        [ConnectionType.Business]: {
            label: localizedConnectionType[ConnectionType.Business],
            chipType: ChipType.Mark,
            chipVariant: ChipVariant.Turquoise,
        },
    },

    [nameof<AccountVerificationStatus>()]: {
        [AccountVerificationStatus.InitLoginKyc]: {
            label: localizedAccountVerificationStatus[AccountVerificationStatus.InitLoginKyc],
            chipType: ChipType.Mark,
            chipVariant: ChipVariant.Disabled,
            startIconClass: CustomIcon.ClockOutline,
        },

        [AccountVerificationStatus.InitWithdrawalKyc]: {
            label: localizedAccountVerificationStatus[AccountVerificationStatus.InitWithdrawalKyc],
            chipType: ChipType.Mark,
            chipVariant: ChipVariant.Disabled,
            startIconClass: CustomIcon.ClockOutline,
        },

        [AccountVerificationStatus.InitDepositKyc]: {
            label: localizedAccountVerificationStatus[AccountVerificationStatus.InitDepositKyc],
            chipType: ChipType.Mark,
            chipVariant: ChipVariant.Disabled,
            startIconClass: CustomIcon.ClockOutline,
        },

        [AccountVerificationStatus.InitPaymentKyc]: {
            label: localizedAccountVerificationStatus[AccountVerificationStatus.InitPaymentKyc],
            chipType: ChipType.Mark,
            chipVariant: ChipVariant.Disabled,
            startIconClass: CustomIcon.ClockOutline,
        },

        [AccountVerificationStatus.OnHoldLoginKyc]: {
            label: localizedAccountVerificationStatus[AccountVerificationStatus.OnHoldLoginKyc],
            chipType: ChipType.Mark,
            chipVariant: ChipVariant.Orange,
            startIconClass: CustomIcon.WarningOutline,
        },

        [AccountVerificationStatus.OnHoldWithdrawalKyc]: {
            label: localizedAccountVerificationStatus[AccountVerificationStatus.OnHoldWithdrawalKyc],
            chipType: ChipType.Mark,
            chipVariant: ChipVariant.Orange,
            startIconClass: CustomIcon.WarningOutline,
        },

        [AccountVerificationStatus.OnHoldDepositKyc]: {
            label: localizedAccountVerificationStatus[AccountVerificationStatus.OnHoldDepositKyc],
            chipType: ChipType.Mark,
            chipVariant: ChipVariant.Orange,
            startIconClass: CustomIcon.WarningOutline,
        },

        [AccountVerificationStatus.OnHoldPaymentKyc]: {
            label: localizedAccountVerificationStatus[AccountVerificationStatus.OnHoldPaymentKyc],
            chipType: ChipType.Mark,
            chipVariant: ChipVariant.Orange,
            startIconClass: CustomIcon.WarningOutline,
        },

        [AccountVerificationStatus.Approved]: {
            label: localizedAccountVerificationStatus[AccountVerificationStatus.Approved],
            chipType: ChipType.Mark,
            chipVariant: ChipVariant.Green,
            startIconClass: CustomIcon.Confirm,
        },

        [AccountVerificationStatus.DeniedLoginKyc]: {
            label: localizedAccountVerificationStatus[AccountVerificationStatus.DeniedLoginKyc],
            chipType: ChipType.TagMark,
            chipVariant: ChipVariant.Default,
            startIconClass: CustomIcon.CancelOutline,
        },

        [AccountVerificationStatus.DeniedWithdrawalKyc]: {
            label: localizedAccountVerificationStatus[AccountVerificationStatus.DeniedWithdrawalKyc],
            chipType: ChipType.TagMark,
            chipVariant: ChipVariant.Default,
            startIconClass: CustomIcon.CancelOutline,
        },

        [AccountVerificationStatus.DeniedDepositKyc]: {
            label: localizedAccountVerificationStatus[AccountVerificationStatus.DeniedDepositKyc],
            chipType: ChipType.TagMark,
            chipVariant: ChipVariant.Default,
            startIconClass: CustomIcon.CancelOutline,
        },

        [AccountVerificationStatus.DeniedPaymentKyc]: {
            label: localizedAccountVerificationStatus[AccountVerificationStatus.DeniedPaymentKyc],
            chipType: ChipType.TagMark,
            chipVariant: ChipVariant.Default,
            startIconClass: CustomIcon.CancelOutline,
        },

        [AccountVerificationStatus.InitNdrpKyc]: {
            label: localizedAccountVerificationStatus[AccountVerificationStatus.InitNdrpKyc],
            chipType: ChipType.Mark,
            chipVariant: ChipVariant.Disabled,
            startIconClass: CustomIcon.ClockOutline,
        },

        [AccountVerificationStatus.DeniedNdrpKyc]: {
            label: localizedAccountVerificationStatus[AccountVerificationStatus.DeniedNdrpKyc],
            chipType: ChipType.TagMark,
            chipVariant: ChipVariant.Default,
            startIconClass: CustomIcon.CancelOutline,
        },
    },

    [nameof<Gender>()]: {
        [Gender.Male]: {
            label: localizedGender[Gender.Male],
            chipType: ChipType.Mark,
            chipVariant: ChipVariant.LightBlue,
        },

        [Gender.Female]: {
            label: localizedGender[Gender.Female],
            chipType: ChipType.Mark,
            chipVariant: ChipVariant.Purple,
        },

        [Gender.Undefined]: {
            label: localizedGender[Gender.Undefined],
            chipType: ChipType.Mark,
            chipVariant: ChipVariant.Disabled,
        },
    },

    [nameof<EnablementMode>()]: {
        [EnablementMode.Enabled]: {
            label: localizedSecuritySettings[EnablementMode.Enabled],
            chipType: ChipType.Status,
            chipVariant: ChipVariant.Green,
        },

        [EnablementMode.Disabled]: {
            label: localizedSecuritySettings[EnablementMode.Disabled],
            chipType: ChipType.Status,
            chipVariant: ChipVariant.Disabled,
        },

        [EnablementMode.Null]: {
            label: localizedSecuritySettings[EnablementMode.Null],
            chipType: ChipType.Status,
            chipVariant: ChipVariant.Disabled,
        },
    },

    [nameof<LoginStatus>()]: {
        [LoginStatus.Success]: {
            label: localizedLoginStatus[LoginStatus.Success],
            chipType: ChipType.Status,
            chipVariant: ChipVariant.Green,
        },

        [LoginStatus.Failed]: {
            label: localizedLoginStatus[LoginStatus.Failed],
            chipType: ChipType.Status,
            chipVariant: ChipVariant.Disabled,
        },
    },

    [nameof<KycStatus>()]: {
        [KycStatus.Successful]: {
            label: localizedKycStatus[KycStatus.Successful],
            chipType: ChipType.Mark,
            chipVariant: ChipVariant.LightGreen,
            startIconClass: CustomIcon.CheckDouble,
        },
        [KycStatus.Pending]: {
            label: localizedKycStatus[KycStatus.Pending],
            chipType: ChipType.Mark,
            chipVariant: ChipVariant.Disabled,
            startIconClass: CustomIcon.ClockOutline,
        },
        [KycStatus.Failed]: {
            label: localizedKycStatus[KycStatus.Failed],
            chipType: ChipType.Mark,
            chipVariant: ChipVariant.Bronze,
            startIconClass: CustomIcon.CancelOutline,
        },
        [KycStatus.None]: {
            label: localizedKycStatus[KycStatus.None],
            chipType: ChipType.Mark,
            chipVariant: ChipVariant.Disabled,
            startIconClass: CustomIcon.ClockOutline,
        },
    },

    [nameof<PlayersRelationType>()]: {
        [PlayersRelationType.Ip]: {
            label: localizedPlayersRelationType[PlayersRelationType.Ip],
            chipType: ChipType.Mark,
            chipVariant: ChipVariant.Orange,
        },
        [PlayersRelationType.BankCard]: {
            label: localizedPlayersRelationType[PlayersRelationType.BankCard],
            chipType: ChipType.Mark,
            chipVariant: ChipVariant.Green,
        },
        [PlayersRelationType.BankCardName]: {
            label: localizedPlayersRelationType[PlayersRelationType.BankCardName],
            chipType: ChipType.Mark,
            chipVariant: ChipVariant.LightBlue,
        },
        [PlayersRelationType.Device]: {
            label: localizedPlayersRelationType[PlayersRelationType.Device],
            chipType: ChipType.Mark,
            chipVariant: ChipVariant.Turquoise,
        },
        [PlayersRelationType.Subnet]: {
            label: localizedPlayersRelationType[PlayersRelationType.Subnet],
            chipType: ChipType.Mark,
            chipVariant: ChipVariant.Purple,
        },
    },

    [nameof<UserStatisticsGameType>()]: {
        [UserStatisticsGameType.Nl]: {
            label: localizedUserStatisticsGameType[UserStatisticsGameType.Nl],
            chipType: ChipType.Mark,
            chipVariant: ChipVariant.Turquoise,
        },
        [UserStatisticsGameType.Sd]: {
            label: localizedUserStatisticsGameType[UserStatisticsGameType.Sd],
            chipType: ChipType.Mark,
            chipVariant: ChipVariant.Orange,
        },
        [UserStatisticsGameType.Plo]: {
            label: localizedUserStatisticsGameType[UserStatisticsGameType.Plo],
            chipType: ChipType.Mark,
            chipVariant: ChipVariant.Green,
        },
    },

    [nameof<PaymentOption>()]: {
        [PaymentOption.PaymentOptionCreditcard]: {
            label: localizedPaymentOption[PaymentOption.PaymentOptionCreditcard],
            chipType: ChipType.Mark,
            chipVariant: ChipVariant.LightGreen,
        },
        [PaymentOption.PaymentOptionDebitcard]: {
            label: localizedPaymentOption[PaymentOption.PaymentOptionDebitcard],
            chipType: ChipType.Mark,
            chipVariant: ChipVariant.LightGreen,
        },
        [PaymentOption.PaymentOptionFastbankttransfer]: {
            label: localizedPaymentOption[PaymentOption.PaymentOptionFastbankttransfer],
            chipType: ChipType.Mark,
            chipVariant: ChipVariant.LightGreen,
        },
        [PaymentOption.PaymentOptionNeteller]: {
            label: localizedPaymentOption[PaymentOption.PaymentOptionNeteller],
            chipType: ChipType.Mark,
            chipVariant: ChipVariant.LightGreen,
        },
        [PaymentOption.PaymentOptionNone]: {
            label: localizedPaymentOption[PaymentOption.PaymentOptionNone],
            chipType: ChipType.Mark,
            chipVariant: ChipVariant.Disabled,
        },
        [PaymentOption.PaymentOptionPaypal]: {
            label: localizedPaymentOption[PaymentOption.PaymentOptionPaypal],
            chipType: ChipType.Mark,
            chipVariant: ChipVariant.LightGreen,
        },
        [PaymentOption.PaymentOptionQiwi]: {
            label: localizedPaymentOption[PaymentOption.PaymentOptionQiwi],
            chipType: ChipType.Mark,
            chipVariant: ChipVariant.LightGreen,
        },
        [PaymentOption.PaymentOptionYandex]: {
            label: localizedPaymentOption[PaymentOption.PaymentOptionYandex],
            chipType: ChipType.Mark,
            chipVariant: ChipVariant.LightGreen,
        },
        [PaymentOption.PaymentOptionSkrill]: {
            label: localizedPaymentOption[PaymentOption.PaymentOptionSkrill],
            chipType: ChipType.Mark,
            chipVariant: ChipVariant.LightGreen,
        },
        [PaymentOption.PaymentOptionCryptocurrency]: {
            label: localizedPaymentOption[PaymentOption.PaymentOptionCryptocurrency],
            chipType: ChipType.Mark,
            chipVariant: ChipVariant.LightGreen,
        },
        [PaymentOption.PaymentOptionAstropaycard]: {
            label: localizedPaymentOption[PaymentOption.PaymentOptionAstropaycard],
            chipType: ChipType.Mark,
            chipVariant: ChipVariant.LightGreen,
        },
        [PaymentOption.PaymentOptionBanklocal]: {
            label: localizedPaymentOption[PaymentOption.PaymentOptionBanklocal],
            chipType: ChipType.Mark,
            chipVariant: ChipVariant.LightGreen,
        },
        [PaymentOption.PaymentOptionBankintl]: {
            label: localizedPaymentOption[PaymentOption.PaymentOptionBankintl],
            chipType: ChipType.Mark,
            chipVariant: ChipVariant.LightGreen,
        },
    },

    [nameof<PaymentVendor>()]: {
        [PaymentVendor.Hexopay]: {
            label: PaymentVendor.Hexopay,
            chipType: ChipType.Mark,
            chipVariant: ChipVariant.Green,
            labelTypeString: true,
        },
        [PaymentVendor.Nuvei]: {
            label: PaymentVendor.Nuvei,
            chipType: ChipType.Mark,
            chipVariant: ChipVariant.Green,
            labelTypeString: true,
        },
        [PaymentVendor.PayPal]: {
            label: PaymentVendor.PayPal,
            chipType: ChipType.Mark,
            chipVariant: ChipVariant.Green,
            labelTypeString: true,
        },
        [PaymentVendor.None]: {
            label: PaymentVendor.None,
            chipType: ChipType.Mark,
            chipVariant: ChipVariant.Disabled,
            labelTypeString: true,
        },
        [PaymentVendor.DevCode]: {
            label: PaymentVendor.DevCode,
            chipType: ChipType.Mark,
            chipVariant: ChipVariant.Disabled,
            labelTypeString: true,
        },
        [PaymentVendor.Intech]: {
            label: PaymentVendor.Intech,
            chipType: ChipType.Mark,
            chipVariant: ChipVariant.Disabled,
            labelTypeString: true,
        },
        [PaymentVendor.OkaysPay]: {
            label: PaymentVendor.OkaysPay,
            chipType: ChipType.Mark,
            chipVariant: ChipVariant.Disabled,
            labelTypeString: true,
        },
    },

    [nameof<AccountVerificationType>()]: {
        [AccountVerificationType.Payment]: {
            label: localizedAccountVerificationType[AccountVerificationType.Payment],
            chipType: ChipType.Status,
            chipVariant: ChipVariant.Violet,
        },
        [AccountVerificationType.Security]: {
            label: localizedAccountVerificationType[AccountVerificationType.Security],
            chipType: ChipType.Status,
            chipVariant: ChipVariant.Purple,
        },
        [AccountVerificationType.NdrpVerification]: {
            label: localizedAccountVerificationType[AccountVerificationType.NdrpVerification],
            chipType: ChipType.Status,
            chipVariant: ChipVariant.Orange,
        },
    },

    [nameof<PlayerBonusStatus>()]: {
        [PlayerBonusStatus.Active]: {
            label: localizedPlayerBonusStatus[PlayerBonusStatus.Active],
            chipType: ChipType.Mark,
            chipVariant: ChipVariant.Disabled,
            startIconClass: CustomIcon.ClockOutline,
        },
        [PlayerBonusStatus.Complete]: {
            label: localizedPlayerBonusStatus[PlayerBonusStatus.Complete],
            chipType: ChipType.Mark,
            chipVariant: ChipVariant.Disabled,
            startIconClass: CustomIcon.ClockOutline,
        },
        [PlayerBonusStatus.Expired]: {
            label: localizedPlayerBonusStatus[PlayerBonusStatus.Expired],
            chipType: ChipType.Mark,
            chipVariant: ChipVariant.Disabled,
            startIconClass: CustomIcon.ClockOutline,
        },
        [PlayerBonusStatus.Pending]: {
            label: localizedPlayerBonusStatus[PlayerBonusStatus.Pending],
            chipType: ChipType.Mark,
            chipVariant: ChipVariant.Disabled,
            startIconClass: CustomIcon.ClockOutline,
        },
    },

    [nameof<BackpackItemStatus>()]: {
        [BackpackItemStatus.Active]: {
            label: localizedBackpackStatus[BackpackItemStatus.Active],
            chipType: ChipType.Mark,
            chipVariant: ChipVariant.LightGreen,
            startIconClass: CustomIcon.Target,
        },
        [BackpackItemStatus.Expired]: {
            label: localizedBackpackStatus[BackpackItemStatus.Expired],
            chipType: ChipType.Mark,
            chipVariant: ChipVariant.LightGrey,
        },
        [BackpackItemStatus.Used]: {
            label: localizedBackpackStatus[BackpackItemStatus.Used],
            chipType: ChipType.Mark,
            chipVariant: ChipVariant.LightGrey,
        },
    },

    [nameof<BulkStatus>()]: {
        [BulkStatus.Success]: {
            label: localizedBulkStatus[BulkStatus.Success],
            chipType: ChipType.Mark,
            chipVariant: ChipVariant.LightGreen,
            startIconClass: CustomIcon.Confirm,
        },
        [BulkStatus.Warning]: {
            label: localizedBulkStatus[BulkStatus.Warning],
            chipType: ChipType.Mark,
            chipVariant: ChipVariant.Orange,
            startIconClass: CustomIcon.ErrorCircleOutline,
        },
    },

    [nameof<FeatureAccessStatus>()]: {
        [FeatureAccessStatus.Locked]: {
            label: localizedFeatureAccessStatus[FeatureAccessStatus.Locked],
            chipType: ChipType.Status,
            chipVariant: ChipVariant.Disabled,
        },
        [FeatureAccessStatus.Unlocked]: {
            label: localizedFeatureAccessStatus[FeatureAccessStatus.Unlocked],
            chipType: ChipType.Status,
            chipVariant: ChipVariant.Green,
        },
    },

    [nameof<BonusStatus>()]: getPlayerBonusStatusChipsMapping(),
    [nameof<BonusCreditType>()]: getBeCreditTypeChipsMapping(ChipType.StatusV2),
    [nameof<TransactionStatus>()]: getTransactionStatusChipsMapping(ChipType.Status),
    [nameof<NoteType>()]: getNoteTypeChipsMapping(ChipType.TagMark),
    [nameof<Workspace>()]: getWorkspaceChipsMapping(ChipType.Mark),
    [nameof<TransactionType>()]: getTransactionTypeChipsMapping(ChipType.Mark),
    [nameof<TransactionFilterType>()]: getTransactionFilterTypeChipsMapping(ChipType.Mark),
    [BulkAccountVerificationStatus]: getBulkAccountVerificationStatusChipsMapping(ChipType.Mark),

    TransactionStatusMark: getTransactionStatusChipsMapping(ChipType.Mark),
    [nameof<RevenueShareOfferingStatus>()]: getRevenueShareOfferingStatusChipsMapping(),
    [nameof<GeoCheckStatus>()]: getGeoCheckStatusChipsMapping(),
    [nameof<GeoCheckEventSource>()]: getGeoCheckEventSourceChipsMapping(),

    [nameof<GamePlatform>()]: getGamePlatformChipsMapping(),
    [nameof<TicketStatus>()]: getTicketStatusChipMapping(),

    [nameof<TransactionStatusWithReason>()]: getTransactionStatusWithReasonChipConfig(),
};

export type ChipsTypes =
    | UserAccountStatus
    | AmlLabel
    | ConnectionType
    | AccountVerificationStatus
    | Gender
    | EnablementMode
    | LoginStatus
    | KycStatus
    | PlayersRelationType
    | UserStatisticsGameType
    | PaymentOption
    | PaymentVendor
    | TransactionType
    | AccountVerificationType
    | TransactionStatus
    | Workspace
    | PlayerBonusStatus
    | BackpackItemStatus
    | NoteType
    | BulkStatus
    | BonusCreditType
    | BonusStatus
    | FeatureAccessStatus
    | RevenueShareOfferingStatus
    | GeoCheckStatus
    | GeoCheckEventSource
    | TicketStatus
    | TransactionStatusWithReason;
