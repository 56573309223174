import React from 'react';
import {defineMessages, MessageDescriptor} from 'react-intl';

import {
    DateRangeFilter,
    FilterGroupNew,
    FilterGroupNewFilter,
    FilterProps,
    FiltersProps,
    SelectFilter,
    TextFilter,
    ValueRange,
    ValueRangeFilter,
} from '@components/filter';
import {TextInputWithDropdownFilter} from '@components/filter/TextFilterWithDropdown';
import {HoursRangeFilter} from '@components/filter/valueRangeFilter/HoursRangeFilter';
import {TransactionStatus} from '@models/generated/graphql';
import {ViewType} from '@redux/view';
import {sharedLocalization} from '@localization';

import {useJurisdictionConfig} from '../../app/config/hooks';
import {localizedTransactionStatus} from '../../app/intl/shared-resources/transactionStatus';
import {AgentFilterNew, AgentFilterValue} from '../../block-agent-profile/components/AgentFilterNew';
import {CountryFilter} from '../../block-country';
import {LabelFilter} from '../../block-label-group-list/components/LabelFilter';
import {
    closedCasesStatuses,
    pendingCasesStatuses,
    TransactionFilterModel,
    TransactionFilterName,
    TransactionTextFilterKeys,
    TransactionTextFilterValue,
} from '../types';

import {TransactionStatusFilter, TransactionStatusFilterValue} from './TransactionStatusFilter';
import TransactionTypeFilter from './TransactionTypeFilter';

const localized = defineMessages({
    textFullFilter: {
        id: 'TransactionFilterText_textFullFilter',
        defaultMessage: 'All',
    },
    userIdFilter: {
        id: 'TransactionFilterText_userIdFilter',
        defaultMessage: 'User Id',
    },
    usernameFilter: {
        id: 'TransactionFilterText_usernameFilter',
        defaultMessage: 'Username',
    },
    withdrawalIdFilter: {
        id: 'TransactionFilterText_withdrawalIdFilter',
        defaultMessage: 'Withdrawal Id',
    },
    emailFilter: {
        id: 'TransactionFilterText_emailFilter',
        defaultMessage: 'Email',
    },
    labelFilter: {
        id: 'TransactionFilterText_labelFilter',
        defaultMessage: 'Label',
    },
    marketingCodeFilter: {
        id: 'TransactionFilterText_marketingCodeFilter',
        defaultMessage: 'Registration Bonus Code',
    },
    transactionIdFilter: {
        id: 'TransactionFilterText_transactionIdFilter',
        defaultMessage: 'Transaction Id',
    },

    textFullFilterPlaceholder: {
        id: 'TransactionFilterText_textFullPlaceholder',
        defaultMessage: 'For example, 123',
    },
    withdrawalTextFullFilterPlaceholder: {
        id: 'TransactionFilterText_textFullFilterPlaceholder',
        defaultMessage: 'Search in Withdrawal Processing Workspace',
    },
    userIdFilterPlaceholder: {
        id: 'TransactionFilterText_userIdFilterPlaceholder',
        defaultMessage: '123',
    },
    usernameFilterPlaceholder: {
        id: 'TransactionFilterText_usernameFilterPlaceholder',
        defaultMessage: 'For example, Antonio',
    },
    withdrawalIdFilterPlaceholder: {
        id: 'TransactionFilterText_withdrawalIdFilterPlaceholder',
        defaultMessage: 'bbe7e0d8-8630-443e-b69a-7100c9dfb8b5',
    },
    transactionIdFilterPlaceholder: {
        id: 'TransactionFilterText_transactionIdFilterPlaceholder',
        defaultMessage: 'bbe7e0d8-8630-443e-b69a-7100c9dfb8b5',
    },
    emailFilterPlaceholder: {
        id: 'TransactionFilterText_emailFilterPlaceholder',
        defaultMessage: 'For example, antonio@xyz.com',
    },
    marketingCodeFilterPlaceholder: {
        id: 'TransactionFilterText_marketingCodeFilterPlaceholder',
        defaultMessage: 'REG_00001',
    },
    labelFilterPlaceholder: {
        id: 'TransactionFilterText_labelFilterPlaceholder',
        defaultMessage: '123',
    },

    transactionStatusFilter: {
        id: 'TransactionFilterStatuses_transactionStatusFilter',
        defaultMessage: 'Status',
    },
    withdrawalStatusFilter: {
        id: 'TransactionFilterStatuses_withdrawalStatusFilter',
        defaultMessage: 'Withdrawal Status',
    },

    amountFilter: {
        id: 'TransactionFilterAmount_filterAmount',
        defaultMessage: 'Amount',
    },
    openDurationFilter: {
        id: 'DurationRangePicker_filterOpenDuration',
        defaultMessage: 'Open Duration',
    },
    paymentMethodFilter: {
        id: 'DurationRangePicker_paymentMethodFilter',
        defaultMessage: 'Method',
    },
});

export type TransactionAgentFilterProps = {boAgentPlayerId?: string; viewType?: ViewType};
type TransactionFiltersProps = FiltersProps<TransactionFilterModel, TransactionFilterName> & TransactionAgentFilterProps;

export function TransactionFilters({model, onChange, availableFilters, viewType, boAgentPlayerId}: TransactionFiltersProps) {
    const {locale} = useJurisdictionConfig();

    function getTextFilterPlaceholder(option: string) {
        const placeholderMapper: Record<TransactionTextFilterKeys, MessageDescriptor> = {
            uid_em_un_rmc: localized.textFullFilterPlaceholder,
            uid_un_tid_em: localized.withdrawalTextFullFilterPlaceholder,
            uid: localized.userIdFilterPlaceholder,
            username: localized.usernameFilterPlaceholder,
            transactionId: localized.transactionIdFilterPlaceholder,
            withdrawalId: localized.withdrawalIdFilterPlaceholder,
            email: localized.emailFilterPlaceholder,
            register_marketing_code: localized.marketingCodeFilterPlaceholder,
            labelsText: sharedLocalization.textFullFilter,
        };
        return placeholderMapper[option as TransactionTextFilterKeys] ?? localized.textFullFilterPlaceholder;
    }

    const filters: FilterGroupNewFilter<TransactionFilterModel, TransactionFilterName>[] = [
        {
            filterName: 'uidUsernameTransactionIdEmail',
            modelField: 'text',
            component: (props: FilterProps<TransactionTextFilterValue>) => (
                <TextInputWithDropdownFilter
                    options={[
                        {label: sharedLocalization.textFullFilter, value: 'uid_un_tid_em'},
                        {label: localized.userIdFilter, value: 'uid'},
                        {label: localized.usernameFilter, value: 'username'},
                        {label: localized.emailFilter, value: 'email'},
                    ]}
                    getInputPlaceholder={getTextFilterPlaceholder}
                    width="full"
                    {...props}
                />
            ),
            collapseOnMobile: false,
        },
        {
            filterName: 'uidEmailUsernameMarketingCode',
            modelField: 'text',
            component: (props: FilterProps<TransactionTextFilterValue>) => (
                <TextInputWithDropdownFilter
                    options={[
                        {label: sharedLocalization.textFullFilter, value: 'uid_em_un_rmc'},
                        {label: localized.userIdFilter, value: 'uid'},
                        {label: localized.usernameFilter, value: 'username'},
                        {label: localized.emailFilter, value: 'email'},
                        {label: localized.marketingCodeFilter, value: 'register_marketing_code'},
                    ]}
                    getInputPlaceholder={getTextFilterPlaceholder}
                    width="full"
                    {...props}
                />
            ),
            collapseOnMobile: false,
        },
        {
            filterName: 'creationDate',
            modelField: 'date',
            component: props => <DateRangeFilter {...props} locale={locale} />,
            collapseOnMobile: false,
        },
        {
            filterName: 'pendingStatuses',
            modelField: 'status',
            component: (props: FilterProps<TransactionStatusFilterValue>) => (
                <TransactionStatusFilter label={localized.withdrawalStatusFilter} options={pendingCasesStatuses} {...props} />
            ),
            collapseOnMobile: false,
        },
        {
            filterName: 'closedStatuses',
            modelField: 'status',
            component: (props: FilterProps<TransactionStatusFilterValue>) => (
                <TransactionStatusFilter label={localized.withdrawalStatusFilter} options={closedCasesStatuses} {...props} />
            ),
            collapseOnMobile: false,
        },
        {
            filterName: 'status',
            modelField: 'status',
            component: (props: FilterProps<string[]>) => (
                <SelectFilter
                    multiple
                    label={localized.transactionStatusFilter}
                    chipType={nameof<TransactionStatus>()}
                    options={[...pendingCasesStatuses, ...closedCasesStatuses].map(s => ({label: localizedTransactionStatus[s], value: s}))}
                    {...props}
                />
            ),
            collapseOnMobile: false,
        },
        {
            filterName: 'amount',
            modelField: 'amount',
            component: (props: FilterProps<ValueRange>) => {
                return <ValueRangeFilter {...props} label={localized.amountFilter} min={0} />;
            },
            collapseOnMobile: false,
        },
        {
            filterName: 'duration',
            modelField: 'date',
            component: (props: FilterProps<ValueRange>) => {
                return <HoursRangeFilter {...props} width="fixed" label={localized.openDurationFilter} />;
            },
            collapseOnMobile: false,
        },
        {
            filterName: 'country',
            modelField: 'country',
            component: (props: FilterProps<string[]>) => <CountryFilter {...props} />,
            collapseOnMobile: false,
        },
        {
            filterName: 'labels',
            modelField: 'labels',
            component: (props: FilterProps<string[]>) => <LabelFilter {...props} />,
            collapseOnMobile: false,
        },
        {
            filterName: 'agent',
            modelField: 'agent',
            component: (props: FilterProps<AgentFilterValue>) => (
                <AgentFilterNew boAgentPlayerId={boAgentPlayerId} width="full" viewType={viewType} withDownstream={true} {...props} />
            ),
            collapseOnMobile: false,
        },
        {
            filterName: 'paymentMethod',
            modelField: 'paymentMethod',
            component: (props: FilterProps<string>) => (
                <TextFilter width="full" inputPlaceholder={localized.paymentMethodFilter} {...props} />
            ),
            collapseOnMobile: false,
        },
        {
            filterName: 'transactionHistoryTypes',
            modelField: 'types',
            component: (props: FilterProps<string[]>) => <TransactionTypeFilter {...props} />,
            collapseOnMobile: false,
        },
    ];

    return (
        <FilterGroupNew
            model={model}
            onChange={onChange}
            mode="update-on-change"
            allFilters={filters}
            availableFilters={availableFilters}
        />
    );
}
