import {createSelector} from 'reselect';
import {RootState} from 'typesafe-actions';

import {ModuleName, SubmoduleName} from '@models/modules';

import {IModule, Module, Submodule} from '../../features/modules/types';

export const filterSelector = (state: RootState) => state.roles.data.state.filter;

const oldModules: ModuleName[] = [
    ModuleName.ActionCenter,
    ModuleName.AccessManagement,
    ModuleName.Comments,
    ModuleName.CustomerSupport,
    ModuleName.KycProcessing,
    ModuleName.LegalContentManagement,
    ModuleName.PlayerManagement,
    ModuleName.SecurityWorkspace,
    ModuleName.WithdrawalProcessing,
];
const pageModules: ModuleName[] = [
    ModuleName.MarketingFunction,
    ModuleName.PageAgentReport,
    ModuleName.PageAgentReportVn,
    ModuleName.PageAgentReportKr,
    ModuleName.TeamWorkspace,
    ModuleName.GameAdmin,
    ModuleName.PageRules,
    ModuleName.PageNdrp,
    ModuleName.PageSportsbookOperation,
    ModuleName.PageEmbeddedReport,
];
const entityModules: ModuleName[] = [
    ModuleName.EntityAll,
    ModuleName.EntityGameTable,
    ModuleName.EntityGameTemplate,
    ModuleName.EntityTransactions,
    ModuleName.EntityBonus,
    ModuleName.EntityBulk,
    ModuleName.EntityRule,
    ModuleName.EntityReport,
];
const businessRulesModules: ModuleName[] = [
    ModuleName.Agent,
    ModuleName.AgentVn,
    ModuleName.AgentKr,
    ModuleName.Club,
    ModuleName.WptGlobal,
    ModuleName.WptAsia,
    ModuleName.WptPH,
];

const playerModules: ModuleName[] = [
    ModuleName.CreditLimit1,
    ModuleName.CreditLimit2,
    ModuleName.CreditLimit3,
    ModuleName.Debit,
    ModuleName.FullPlayer,
    ModuleName.Email,
    ModuleName.Phone,
];

function setIsDeprecated<T extends IModule>(module: T): T {
    const deprecatedModules: string[] = [SubmoduleName.FinanceActions, SubmoduleName.P2PAction, SubmoduleName.P2PTransfer];
    return {
        ...module,
        isDeprecated: deprecatedModules.includes(module.name),
    };
}

function markDeprecatedModules(module: Module): Module {
    return setIsDeprecated({...module, submodules: module.submodules.map(setIsDeprecated)});
}

// TODO: Remove when old submodules will be removed from auth service
function getFilteredSubmodules(submodules: Submodule[]) {
    const availableSubmodules: string[] = [
        // Player Actions
        SubmoduleName.AccountActions,
        SubmoduleName.FinanceActions,
        SubmoduleName.CommunicationActions,
        SubmoduleName.GamesActions,
        SubmoduleName.BlacklistActions,
        SubmoduleName.PermanentBans,
        SubmoduleName.Cases,
        SubmoduleName.Verification,
        SubmoduleName.P2PAction,
        SubmoduleName.FinanceLock,
        SubmoduleName.P2PLock,
        SubmoduleName.P2PTransaction,

        // Login/Access Management
        SubmoduleName.Roles,
        SubmoduleName.Users,

        // Comments
        SubmoduleName.NotesPlayer,
        SubmoduleName.NotesBotDetection,
        SubmoduleName.NotesSecurity,
        SubmoduleName.NotesCustomerSupport,
        SubmoduleName.Payment,
        SubmoduleName.KYC,
        SubmoduleName.ActionCenter,
        SubmoduleName.NotesFraud,

        // Legal Content Management
        SubmoduleName.PrivacyPolicy,
        SubmoduleName.TermsAndConditions,
        SubmoduleName.UserAcceptanceVersion,

        // Player Management
        SubmoduleName.RegistrationInformation,
        SubmoduleName.LoginHistory,
        SubmoduleName.TransactionHistory,

        // Withdrawal Processing
        SubmoduleName.WithdrawalProcessingRisk,
    ];

    return submodules?.filter(s => availableSubmodules.includes(s.name));
}

export const oldModulesSelector = createSelector<RootState, Module[], Module[]>(
    (state: RootState) => state.roles.additional.modules,
    modules =>
        modules
            .filter(m => oldModules.some(h => h === m.name))
            .map(m => ({...m, submodules: getFilteredSubmodules(m.submodules)}))
            .map(markDeprecatedModules)
);

export const pageModulesSelector = createSelector<RootState, Module[], Module[]>(
    (state: RootState) => state.roles.additional.modules,
    modules => modules.filter(m => pageModules.some(h => h === m.name)).map(markDeprecatedModules)
);

export const entityModulesSelector = createSelector<RootState, Module[], Module[]>(
    (state: RootState) => state.roles.additional.modules,
    modules => modules.filter(m => entityModules.some(h => h === m.name)).map(markDeprecatedModules)
);

export const businessRulesModulesSelector = createSelector<RootState, Module[], Module[]>(
    (state: RootState) => state.roles.additional.modules,
    modules => modules.filter(m => businessRulesModules.some(h => h === m.name)).map(markDeprecatedModules)
);

export const playerModulesSelector = createSelector<RootState, Module[], Module[]>(
    (state: RootState) => state.roles.additional.modules,
    modules => modules.filter(m => playerModules.some(h => h === m.name)).map(markDeprecatedModules)
);

export const submodulesSelector = (state: RootState) => state.roles.additional.submodules;
