import React, {PropsWithChildren, ReactNode} from 'react';
import {Box, DialogActions} from '@mui/material';

import {useModalClasses} from './Modal.style';

type ModalFooterProps = {
    infoMessage?: ReactNode;
};

export const ModalFooter = ({infoMessage, children}: PropsWithChildren<ModalFooterProps>) => {
    const {classes} = useModalClasses();

    return (
        <DialogActions className={classes.modalActions}>
            <Box className={classes.modalSubInfoMessageContainer}>{infoMessage}</Box>
            <Box className={classes.modalActionsButtonsContainer}>{children}</Box>
        </DialogActions>
    );
};
