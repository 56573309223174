import {injectable} from 'inversify';

import {KycStatusInput, UserAssignInput} from '@models/generated/graphql';

import {AccountVerificationGridItem} from 'src/pages/kyc-processing/types';
import {ApplyStrategyResponse, BulkActionItemPayload, BulkActionKey, BulkItemStatus, IBulkApplyStrategy} from '../../block-bulk-actions';

export type ApplyKYCRejectRequest = {items: AccountVerificationGridItem[]};

@injectable()
export class ApplyKYCRejectStrategy implements IBulkApplyStrategy<ApplyKYCRejectRequest> {
    process({items}: ApplyKYCRejectRequest): ApplyStrategyResponse {
        const actionItems = items?.map<BulkActionItemPayload<KycStatusInput>>(item => ({
            actionKey: BulkActionKey.KYCReject,
            value: {id: item.id, status: item.account_verification_status},
            itemId: item.serverId,
            status: BulkItemStatus.Pending,
        }));

        return {actionKey: BulkActionKey.KYCReject, items: actionItems};
    }
}

export type KYCAssignData = UserAssignInput;
export type KYCAssignRequest = {items: KYCAssignData[]};

@injectable()
export class ApplyKYCAssignStrategy implements IBulkApplyStrategy<KYCAssignRequest> {
    public process({items}: KYCAssignRequest): ApplyStrategyResponse<KYCAssignData> {
        const actionKey: BulkActionKey = BulkActionKey.KYCAssign;
        return {
            actionKey,
            items:
                items?.map(item => ({
                    actionKey,
                    value: {user_id: item?.user_id, id: item?.id} as UserAssignInput,
                    itemId: item?.id,
                    status: BulkItemStatus.Pending,
                })) ?? [],
        };
    }
}
