import ExcelJS from 'exceljs';

export type ExcelColDef<TKey extends string> = {
    key: TKey;
    width?: number;
};

export type ExcelDataColDef<TKey extends string> = ExcelColDef<TKey> & {
    header: string;
    alignment?: Partial<ExcelJS.Alignment>;
    validation?: ExcelJS.DataValidation;
    colIndex: number;
};

type ValidationFieldKey = 'validationField';

export type ExcelValidationColDef = ExcelColDef<ValidationFieldKey>;

export type DataWorksheetColDefs<TKey extends string> = Record<TKey, ExcelDataColDef<TKey>>;

export type ValidationWorksheetColDefs = Record<ValidationFieldKey, ExcelValidationColDef>;

export type DataWorksheet<TKey extends string> = {
    title: string;
    columns: DataWorksheetColDefs<TKey>;
    data?: Record<TKey, string>[];
};

export type ValidationWorksheet = {
    title: string;
    columns: ValidationWorksheetColDefs;
    data: Record<ValidationFieldKey, string>[];
};

export type FileType = 'xlsx' | 'csv';

export const domain = 'file';

export const defaultExcelColWidth = 5;

/**
 * @deprecated
 * <p>Should be removed. Use features/block-download</p>
 */
export type DownloadRequestActionPayload<TViewKeys extends string, TQueryFields extends string> = {
    filter: string;
    filename: string;
    fields: TQueryFields[];
    worksheetKeys: TViewKeys[];
    headers?: Record<TViewKeys, string>;
};

/**
 * @deprecated
 * <p>Should be removed. Use features/block-download</p>
 */
export type DownloadSuccessActionPayload = {
    arrayBuffer: ArrayBuffer;
    filename: string;
};
