import {useEffect, useState} from 'react';
import {hasAuthParams, useAuth} from 'react-oidc-context';
import {History} from 'history';
import {IdTokenClaims} from 'oidc-client-ts';

import {Policy} from './policy';
import {checkAuthQueryParams, getPolicies, getRedirectUrls} from './utils';

//TODO: send logs to Newrelic
export function useAutoLoginLogout(history: History<unknown>) {
    const auth = useAuth();
    const {error, isAuthenticated, isLoading, activeNavigator, signinRedirect, signinSilent} = auth;

    useEffect(() => {
        loginWithRedirect();
    }, [isAuthenticated, activeNavigator, isLoading, signinRedirect]);

    useEffect(() => {
        // remove auth params from url in case when is not needed, but passed
        if (error?.message?.length && !isAuthenticated && !isLoading && checkAuthQueryParams(window.location.search)) {
            const {relativeUrl} = getRedirectUrls();
            history.replace(relativeUrl);
            window.location.reload();
        }
    }, [error?.message, isAuthenticated, isLoading]);

    useEffect(() => {
        return (() => {
            return auth.events.addAccessTokenExpired(async () => {
                const {absoluteUrl} = getRedirectUrls();
                if (!(await signinSilent())) {
                    await signinRedirect({redirect_uri: absoluteUrl});
                }
            });
        })();
    }, [auth.events, signinSilent, signinRedirect]);

    async function loginWithRedirect(forceLogin = false) {
        let signInInitiated = false;
        try {
            if ((!isAuthenticated || forceLogin) && !hasAuthParams() && !activeNavigator && !isLoading) {
                await signinRedirect();
                signInInitiated = true;
            }
        } catch (e) {
            //TODO: add tracing
        }
        return signInInitiated;
    }

    return {...auth};
}

export function useLogout() {
    const {signoutRedirect} = useAuth();

    async function logout() {
        try {
            await signoutRedirect();
        } catch (e) {
            //TODO: add tracing
        }
    }

    return {logout};
}

export function useAuthUrl(origin?: string) {
    const [hasAuthQueryParams, setHasAuthQueryParams] = useState(true);
    const {search} = window.location;
    const {relativeUrl, absoluteUrl} = getRedirectUrls(origin);

    useEffect(() => {
        if (checkAuthQueryParams(search)) {
            setHasAuthQueryParams(true);
        } else {
            setHasAuthQueryParams(false);
        }
    }, [search]);

    return {hasAuthQueryParams, relativeRedirectUrl: relativeUrl, absoluteRedirectUrl: absoluteUrl};
}

export function useAuthUser(): IdTokenClaims & {policies: string[]} {
    const auth = useAuth();
    const user = auth?.user?.profile ?? ({policies: [] as string[]} as unknown as IdTokenClaims);
    return {...user, policies: (typeof user?.policies === 'string' ? [user?.policies] : (user?.policies as string[])) ?? []};
}

export function usePolicies(): Policy[] {
    const auth = useAuth();

    return getPolicies(auth?.user)?.map(p => new Policy(p)) ?? [];
}

/**
 * @deprecated Temporary solution for prod hotfix. Should be removed
 */
export function useRoles(): string[] {
    const role = useAuth()?.user?.profile?.role;

    const roles = Array.isArray(role) ? role : [role];
    return roles;
}

const exportedHooks = {useAuth, useLogout, useAutoLogin: useAutoLoginLogout, useRoles};
//TODO: in scope of separate ticket move permissions there?

export default exportedHooks;
