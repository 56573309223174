import {constructUsing, createMap, Mapper} from '@automapper/core';

import {IMapping} from '@auto-mapper';

import {AccountVerificationWithUserProfileColumnSettingsKeys, DataGridAccountVerificationWithUserProfileColumns} from './types';

export class BlockAccountVerificationMapping implements IMapping {
    createMapping(mapper: Mapper): void {
        createMap<DataGridAccountVerificationWithUserProfileColumns, AccountVerificationWithUserProfileColumnSettingsKeys>(
            mapper,
            nameof<DataGridAccountVerificationWithUserProfileColumns>(),
            nameof<AccountVerificationWithUserProfileColumnSettingsKeys>(),
            constructUsing(m => {
                const mapper: Partial<
                    Record<DataGridAccountVerificationWithUserProfileColumns, AccountVerificationWithUserProfileColumnSettingsKeys>
                > = {
                    account_verification_status: 'account_verification_status',
                    reason_codes: 'reason_codes',
                    documents: 'documents',
                    email: 'email',
                    id: 'id',
                    'initiated_at.seconds': 'initiated_at',
                    iso_alpha2_country_code: 'playerCountry',
                    kyc_case_id: null,
                    status_log: null,
                    type: 'type',
                    uid: 'uid',
                    username: 'username',
                    player_country: null,
                    duration: 'duration',
                    serverId: null,
                    platform: 'platform',
                    labels: 'labels',
                    'documents_object.id_card.uploaded_ts': 'documents_object.id_card.uploaded_ts',
                    page_viewers: 'page_viewers',
                    assignee: 'assignee',
                };

                return mapper[m];
            })
        );
        createMap<AccountVerificationWithUserProfileColumnSettingsKeys, DataGridAccountVerificationWithUserProfileColumns>(
            mapper,
            nameof<AccountVerificationWithUserProfileColumnSettingsKeys>(),
            nameof<DataGridAccountVerificationWithUserProfileColumns>(),
            constructUsing(m => {
                const mapping: Record<
                    AccountVerificationWithUserProfileColumnSettingsKeys,
                    DataGridAccountVerificationWithUserProfileColumns
                > = {
                    __type: undefined,
                    uid: 'uid',
                    initiated_at: 'initiated_at.seconds',
                    duration: 'duration',
                    account_verification_status: 'account_verification_status',
                    type: 'type',
                    documents: 'documents',
                    username: 'username',
                    reason_codes: 'reason_codes',
                    platform: 'platform',
                    id: 'id',
                    email: 'email',
                    labels: 'labels',
                    'documents_object.id_card.uploaded_ts': 'documents_object.id_card.uploaded_ts',
                    playerCountry: 'iso_alpha2_country_code',
                    page_viewers: 'page_viewers',
                    assignee: 'assignee',
                };

                return mapping[m];
            })
        );
    }
}
