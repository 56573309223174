import {Schema, schema} from 'normalizr';

import {
    AccountVerification,
    AgentPathStatistics,
    AgentPlayerReporting,
    AgentPlayerReportingVn,
    AgentRevenueShareWeeklyReport,
    AggregatedFinanceSummary,
    BackpackItem,
    BonusCodeDetails,
    BotGroup,
    BulkOperationResult,
    EventLogRecord,
    FriendGroup,
    GeoCheckSummary,
    KycCheckResults,
    LabelGroup,
    MarketingCode,
    Note,
    PageView,
    PlayerBonus,
    PlayerReferral,
    PlayerReferrerSummary,
    PlayersRelation,
    RgUserLimits,
    SecurityCase,
    Transaction,
    UserAction,
    UserLogin,
    UserProfile,
    UserStatistics,
} from '@models/generated/graphql';

import {Module} from 'src/features/modules/types';
import {Country, State} from 'src/features/view-autocomplete/types';
import {VersionAcceptanceGridItem} from 'src/pages/legal-docs/types';

import {EntityType} from './types/base';
import Entity = schema.Entity;
import SchemaFunction = schema.SchemaFunction;

import {BoUser} from '@models/bo-user';
import {CasinoGame, CasinoSupplier} from '@models/casino-game';
import {GameRoom} from '@models/game-room';
import {Rule} from '@models/rule/types';
import {SportsbookTransactionRecord} from '@models/sportsbook-transaction/type';
import {EmbeddedReportServerModel} from '@services/embeddedReportService';
import {UserActivitySummaryServiceModel} from '@services/userActivitySummaryService';

import {entityReservedId} from './types/base';
import {BoRole} from './types/entityBoRole';
import {GameBlind} from './types/entityGameBlind';
import {GameTemplate} from './types/entityGameTemplate';
import {Occupation} from './types/entityOccupation';

class SchemaMapper {
    private entitiesMap: Record<EntityType, Entity>;
    private entityIdMap: Record<EntityType, string | SchemaFunction> = {
        AccountVerification: nameof<AccountVerification>(i => i.id),
        BoModule: nameof<Module>(i => i.id),
        BoRole: nameof<BoRole>(i => i.id),
        BoUser: nameof<BoUser>(i => i.id),
        BotGroup: nameof<BotGroup>(i => i.group_id),
        FriendGroup: nameof<FriendGroup>(i => i.group_id),
        KycCheckResults: nameof<KycCheckResults>(i => i.uid),
        LegalDocAcceptanceVersion: nameof<VersionAcceptanceGridItem>(i => i.id),
        MarketingCode: (value: MarketingCode) => `${value?.marketing_code}-${value?.type}`,
        Note: nameof<Note>(i => i.id),
        PlayerBonus: nameof<PlayerBonus>(i => i.bonus_id),
        PlayerReferral: (value: PlayerReferral) => `${value?.referrer_id}-${value?.referee?.uid}`,
        PlayerReferrerSummary: (value: PlayerReferrerSummary) => value?.referrer?.uid,
        PlayersRelation: nameof<PlayersRelation>(i => i.id),
        RgUserLimits: nameof<RgUserLimits>(i => i.uid),
        SecurityCase: nameof<SecurityCase>(i => i.case_id),
        Transaction: nameof<Transaction>(i => i.transaction_id),
        UserAction: nameof<UserAction>(i => i.id),
        UserLogin: nameof<UserLogin>(i => i.correlation_id),
        UserProfile: nameof<UserProfile>(i => i.uid),
        UserStatistics: (value: UserStatistics) => `${value?.playerid}-${value?.gametype}-${value?.player}-${value?.stake}`,
        Country: nameof<Country>(i => i.iso2Code),
        State: nameof<State>(i => i.code),
        Occupation: nameof<Occupation>(i => i.code),
        LabelGroup: nameof<LabelGroup>(i => i.id),
        ReferralSettings: entityReservedId, //since there is no id for referral settings which is not a paginated items, we use entityReservedId
        GeoCheckSummary: nameof<GeoCheckSummary>(i => i.uid),
        BulkOperation: (value: BulkOperationResult) => value.id,
        EventLogRecord: nameof<EventLogRecord>(i => i.id),
        AggregatedFinanceSummary: nameof<AggregatedFinanceSummary>(i => i.uid),
        AgentPathStatistics: nameof<AgentPathStatistics>(i => i.path_to_subagent),
        AgentPlayerReporting: (value: AgentPlayerReporting | AgentPlayerReportingVn) =>
            value?.__typename === 'AgentPlayerReporting'
                ? `${value?.referrer_agent_id}_${value?.uid}`
                : `${value?.referrer_agent_id}_${value?.uid}_${value?.revenue_share_type}_${value?.referee_type}`,
        AgentProfile: nameof<UserProfile>(i => i.uid),
        Backpack: nameof<BackpackItem>(i => i.bonus_id),
        GameTemplate: nameof<GameTemplate>(i => i.id),
        GameRoom: nameof<GameRoom>(i => i.roomId),
        GameBlind: nameof<GameBlind>(i => i.id),
        BonusCode: nameof<BonusCodeDetails>(i => i.bonus_id),
        Rule: nameof<Rule>(i => i.id),
        CasinoGame: nameof<CasinoGame>(i => i.code),
        CasinoSupplier: nameof<CasinoSupplier>(i => i.code),
        AgentLevelReport: (value: AgentRevenueShareWeeklyReport) => `${value?.referrer_id}_${value?.week_start_date_ts?.seconds}`,
        SportsbookTransaction: nameof<SportsbookTransactionRecord>(i => i.ticketId),
        EmbeddedReport: nameof<EmbeddedReportServerModel>(i => i.type),
        PageView: (value: PageView) => `${value?.page_id}_${value?.user_id}`,
        UserActivitySummary: nameof<UserActivitySummaryServiceModel>(i => i.uid),
        BoUserSettings: () => entityReservedId, //since there is only single BoUserSettings BoUser can access and uid not provided in response we use entityReservedId
    };

    constructor() {
        this.initializeEntities();
    }

    public getSchema(entityType: EntityType): Entity {
        return this.entitiesMap[entityType];
    }

    public getSchemaDefinitions(entityType: EntityType): Schema {
        const schemaMap: Partial<Record<EntityType, Schema>> = {
            UserProfile: this.getUserProfileSchema(),
            KycCheckResults: this.getKycCheckResultsSchema(),
            PlayersRelation: this.getPlayersRelationSchema(),
            PlayerReferral: this.getPlayerReferralSchema(),
            PlayerReferrerSummary: this.getPlayerReferrerSummarySchema(),
            BoUser: this.getBoUserSchema(),
        };

        return schemaMap[entityType] ?? {};
    }

    private initializeEntities() {
        this.entitiesMap = {} as Record<EntityType, Entity>;

        Object.values<EntityType>(EntityType).forEach(type => {
            this.entitiesMap[type] = new Entity(type, {}, {idAttribute: this.entityIdMap[type]});
        });

        this.defineSchemas();
    }

    private defineSchemas(): void {
        this.entitiesMap.UserProfile.define(this.getUserProfileSchema());
        this.entitiesMap.KycCheckResults.define(this.getKycCheckResultsSchema());
        this.entitiesMap.PlayersRelation.define(this.getPlayersRelationSchema());
        this.entitiesMap.PlayerReferral.define(this.getPlayerReferralSchema());
        this.entitiesMap.PlayerReferrerSummary.define(this.getPlayerReferrerSummarySchema());
        this.entitiesMap.BoUser.define(this.getBoUserSchema());
    }

    private getUserProfileSchema(): Schema {
        return {
            security_cases: [this.entitiesMap.SecurityCase],
            friend_groups: [this.entitiesMap.FriendGroup],
            latest_login: this.entitiesMap.UserLogin,
            latest_kyc: {
                security: this.entitiesMap.AccountVerification,
                payment: this.entitiesMap.AccountVerification,
            },
        };
    }

    private getKycCheckResultsSchema(): Schema {
        return {
            history: [this.entitiesMap.AccountVerification],
        };
    }

    private getPlayersRelationSchema(): Schema {
        return {
            player: this.entitiesMap.UserProfile,
            related_player: this.entitiesMap.UserProfile,
        };
    }

    private getPlayerReferralSchema(): Schema {
        return {
            referee: this.entitiesMap.UserProfile,
        };
    }

    private getPlayerReferrerSummarySchema(): Schema {
        return {
            referrer: this.entitiesMap.UserProfile,
        };
    }

    private getBoUserSchema(): Schema {
        return {
            roles: [this.entitiesMap.BoRole],
        };
    }
}

export const schemaMapper = new SchemaMapper();

export type ParsedEntityKey = {type: EntityType; id: string};

export class EntityKeysMapper {
    private schemaMapper: SchemaMapper;
    private readonly separator = '_._';

    constructor(mapper: SchemaMapper) {
        this.schemaMapper = mapper;
    }

    public getUniqueKeyObjects(entity: object, type: EntityType): ParsedEntityKey[] {
        const result: string[] = [];
        const schemaDefinition = this.schemaMapper.getSchemaDefinitions(type);
        const entitySchema = this.schemaMapper.getSchema(type);

        const id = entitySchema.getId(entity, null, null);
        if (id !== null && id !== undefined) {
            result.push(this.getUniqueKey(type, id));
        }

        if (entity) {
            result.push(...this.getNestedKeys(entity, Object.keys(entity), schemaDefinition));
        }

        return result.map(key => this.mapToObjectKey(key));
    }

    public getUniqueKeyStrings(entity: object, type: EntityType): string[] {
        return this.getUniqueKeyObjects(entity, type).map(key => this.getUniqueKey(key.type, key.id));
    }

    public mapToStringKey(key: ParsedEntityKey): string {
        return this.getUniqueKey(key.type, key.id);
    }

    public mapToObjectKey(key: string): ParsedEntityKey {
        let res: ParsedEntityKey;

        if (key?.length) {
            const splittedKey = key.split(this.separator);

            if (splittedKey.length === 2 && Object.keys(EntityType).includes(splittedKey[0])) {
                res = {
                    type: splittedKey[0] as EntityType,
                    id: splittedKey[1],
                };
            }
        }

        return res;
    }

    private getNestedKeys(parent: any, fields: string[], schemaDefinition: Schema<any>): string[] {
        const result: string[] = [];

        fields.forEach(field => {
            const value = parent[field];
            const nestedDefinition: any = schemaDefinition?.[field as keyof typeof schemaDefinition];

            //push single key in case of entity field
            if (nestedDefinition instanceof schema.Entity) {
                const type: EntityType = (nestedDefinition as schema.Entity).key as EntityType;
                result.push(this.getUniqueKey(type, value as string));
            }
            //push array of keys in case of array of entities
            else if (Array.isArray(nestedDefinition) && nestedDefinition.length && nestedDefinition[0] instanceof schema.Entity) {
                const type: EntityType = (nestedDefinition as schema.Entity[])[0].key as EntityType;
                const uniqueKeys = (value as string[])?.map(id => this.getUniqueKey(type, id)) ?? [];
                result.push(...uniqueKeys);
            }
            //check nested fields in case of object
            else if (nestedDefinition instanceof Object && value) {
                result.push(...this.getNestedKeys(value, Object.keys(value), nestedDefinition));
            }
            // ignore all other fields
        });

        return result;
    }

    private getUniqueKey(type: EntityType, id: string): string {
        return `${type}${this.separator}${id}`;
    }
}
