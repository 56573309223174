import {ActionType, createAction, PayloadActionCreator} from 'typesafe-actions';

import {ViewType} from '@redux/view';

import {BulkActionState} from './reducers';
import {
    ApplyStrategyResponse,
    ChangeBulkActionStatusItem,
    IBulkApplyStrategy,
    IBulkStrategy,
    PerformStrategyActionItemsResponse,
    PerformStrategyOperationResponse,
    PerformStrategyRequest,
    ValidationResult,
    ValidationStrategyResponse,
} from './types';

const domain = 'block-bulk-operation';

//#region Action Names

const setBulkActions = '/setBulkActions';
const changeBulkActionStatus = '/changeBulkActionStatus';
const clearBulkActions = '/clearBulkActions';
const removeBulkActions = '/removeBulkActions';
const setBulkActionsItems = '/setBulkActionsItems';
const setValidationResults = '/setValidationResults';
const saveOperationId = '/saveOperationId';

//#endregion Action Names

//#region Payload Types

export type BulkStrategyPayload<TRequest, TResponse, TStrategy = IBulkStrategy<TRequest, TResponse>> = {
    request: TRequest;
    strategy: TStrategy;
};

export type LoadItemsPayload = BulkStrategyPayload<unknown, unknown[]>;

export type ValidatePayload = BulkStrategyPayload<unknown, ValidationStrategyResponse>;

export type ApplyPayload = BulkStrategyPayload<unknown, ApplyStrategyResponse, IBulkApplyStrategy<unknown>>;

export type PerformItemPayload = BulkStrategyPayload<
    PerformStrategyRequest,
    (PerformStrategyOperationResponse | PerformStrategyActionItemsResponse) & {errorMessage?: string}
> & {
    postProcessingAction: PayloadActionCreator<
        string,
        (PerformStrategyOperationResponse | PerformStrategyActionItemsResponse) & {errorMessage?: string}
    >;
};

export type PerformPayload = PerformItemPayload[];

export enum PollingState {
    Start,
    InProgress,
    Finished,
}

type FetchOperationsPayload = {
    viewType: ViewType;
    pollingState: PollingState;
};

type SetBulkActionsItemsPayload = unknown[];

type SetBulkActionsPayload = BulkActionState;

export type SaveOperationIdPayload = {
    actionKey: string;
    id: string;
};

type ChangeBulkActionStatusPayload = {
    items: ChangeBulkActionStatusItem[];
    actionKey: string;
};

type RemoveBulkActionsPayload = string;

type SetValidationResultsPayload = ValidationResult[];

export type instantOperationPayload = {
    applyRequest: ApplyPayload['request'];
    applyStrategy: ApplyPayload['strategy'];
    performStrategy: PerformPayload[number]['strategy'];
};

//#endregion Payload Types

export const bulkActionsActions = {
    loadItems: createAction(`${domain}/loadItems`)<LoadItemsPayload>(),

    validate: createAction(`${domain}/validate`)<ValidatePayload>(),

    apply: createAction(`${domain}/apply`)<ApplyPayload>(),

    perform: createAction(`${domain}/perform`)<PerformPayload>(),

    instantOperation: createAction(`${domain}/onClickOperation`)<instantOperationPayload>(),

    retry: createAction(`${domain}/retry`)<PerformPayload>(),

    fetchOperations: createAction(`${domain}/fetchOperations`)<FetchOperationsPayload>(),

    setBulkActionsItems: createAction(`${domain}${setBulkActionsItems}`)<SetBulkActionsItemsPayload>(),

    setBulkActions: createAction(`${domain}${setBulkActions}`)<SetBulkActionsPayload>(),

    changeBulkActionStatus: createAction(`${domain}${changeBulkActionStatus}`)<ChangeBulkActionStatusPayload>(),

    clearBulkActions: createAction(`${domain}${clearBulkActions}`)<void>(),

    removeBulkActionsWithKey: createAction(`${domain}${removeBulkActions}`)<RemoveBulkActionsPayload>(),

    setValidationResults: createAction(`${domain}${setValidationResults}`)<SetValidationResultsPayload>(),

    saveOperationId: createAction(`${domain}${saveOperationId}`)<SaveOperationIdPayload>(),

    cleanOperationIds: createAction(`${domain}/cleanOperationIds`)<void>(),
};

export type BulkActionsType = ActionType<typeof bulkActionsActions>;
