import React, {useEffect, useState} from 'react';

import {FilterProps, ValueRange, ValueRangeFilter} from '@components/filter';
import {HoursRangeValue} from '@components/filter/valueRangeFilter/types';
import {ValueRangeFilterProps} from '@components/filter/valueRangeFilter/ValueRangeFilter';
import {getNHoursAgoAsSeconds, totalHoursPassedFromNow} from '@utils';

type HoursRangeFilterProps = Omit<ValueRangeFilterProps, 'value' | 'onChange'> & FilterProps<HoursRangeValue>;

export function HoursRangeFilter({value, onChange, ...props}: HoursRangeFilterProps) {
    const [currentValue, setCurrentValue] = useState<ValueRange>(convertRangeSecondsInHours(value));

    useEffect(() => {
        setCurrentValue(convertRangeSecondsInHours(value));
    }, [`${value?.from}-${value?.to}`]);

    function handleChange(value: ValueRange): void {
        onChange(convertRangeHoursInSeconds(value));
    }

    function convertRangeSecondsInHours(value: HoursRangeValue): ValueRange {
        const getValue = (v: number | 'now') => Number(totalHoursPassedFromNow(`${v}`));
        const from = getValue(value?.to);
        const to = getValue(value?.from);

        return {from, to};
    }

    function convertRangeHoursInSeconds({from, to}: ValueRange): HoursRangeValue {
        return {
            from: !to || Number.isNaN(Number(to)) || Number(to) < 0 ? null : getNHoursAgoAsSeconds(to),
            to: !from || Number.isNaN(Number(from)) || Number(from) < 0 ? 'now' : getNHoursAgoAsSeconds(from),
        };
    }

    return <ValueRangeFilter value={currentValue} onChange={handleChange} {...props} />;
}
