import {defineMessages} from 'react-intl';

import {useAutoMapper} from '@auto-mapper';
import {LabelGroup, UserLabel, UserProfile} from '@models/generated/graphql';
import {EntityType, LabelGroupFilterKeys, LabelGroupQueryFields, UserProfileQueryFields, UserProfileServerFilterKeys} from '@redux/entity';
import {RealtimeMessageTrigger, RealtimeUpdatesMode} from '@redux/realtime';
import {useViewInit, ViewType} from '@redux/view';
import {isStringNullOrEmpty} from '@utils';

import {Filter} from '../../common/types';

import {Label, LabelGroupSourceType} from './types';

const localized = defineMessages({
    playerLabelsDisplayName: {
        id: 'playerLabelsDisplayName',
        defaultMessage: 'Player Labels',
    },
});

type UseUserProfileData = {
    userProfile: UserProfile;
};

function useUserProfileData(viewType: ViewType, uid: string): UseUserProfileData {
    const entityType = EntityType.UserProfile;
    const fields: UserProfileQueryFields[] = [
        'uid',
        'labels.id',
        'labels.name',
        'labels.group.id',
        'labels.group.name',
        'labels.group.color',
    ];
    const filters: Filter<string, UserProfileServerFilterKeys>[] = [{key: 'uid', value: uid}];

    const {items} = useViewInit<UserProfile, UserProfileServerFilterKeys, UserProfileQueryFields>({
        viewType,
        displayName: localized.playerLabelsDisplayName,
        entity: {
            entity: entityType,
            fields,
        },
        realtime: {
            entity: entityType,
            mode: RealtimeUpdatesMode.Silent,
            triggers: uid
                ? [
                      {
                          type: RealtimeMessageTrigger.Update,
                          args: {
                              filter: {
                                  field: 'uid',
                                  value: uid,
                              },
                          },
                      },
                  ]
                : undefined,
        },
        defaultFilters: filters,
        validateFilter: filter => !isStringNullOrEmpty(filter?.find(f => f.key === 'uid')?.value),
    });

    return {userProfile: items[0]};
}

type UseLabelGroupsData = {
    labelGroups: LabelGroup[];
};

function useLabelGroupsData(viewType: ViewType, groupIds: string[]): UseLabelGroupsData {
    const entityType = EntityType.LabelGroup;
    const fields: LabelGroupQueryFields[] = ['id', 'source_type'];
    const filters: Filter<string, LabelGroupFilterKeys>[] = [{key: 'id', value: groupIds?.join(',')}];

    const {items: labelGroups} = useViewInit<LabelGroup, LabelGroupFilterKeys, LabelGroupQueryFields>({
        viewType,
        displayName: localized.playerLabelsDisplayName,
        entity: {
            entity: entityType,
            fields,
        },
        realtime: {
            entity: entityType,
            mode: RealtimeUpdatesMode.Silent,
            triggers: [{type: RealtimeMessageTrigger.Update}],
        },
        defaultFilters: filters,
        validateFilter: filter => !isStringNullOrEmpty(filter?.find(f => f.key === 'id')?.value),
    });

    return {labelGroups};
}

type UsePlayerLabelsData = {
    visibleLabels: Label[];
    hiddenLabels: Label[];
    playerLabelIds: string[];
};

export function usePlayerLabelsData(uid: string): UsePlayerLabelsData {
    const mapper = useAutoMapper();
    const viewType: ViewType = 'BlockPlayerLabels';
    const {userProfile} = useUserProfileData(viewType, uid);
    const groupIds = userProfile?.labels?.map(l => l?.group?.id);
    const {labelGroups} = useLabelGroupsData(viewType, groupIds);

    const labels: Label[] =
        userProfile?.labels?.map(m =>
            mapper.map(m, UserLabel, Label, {
                afterMap: (source, destination) =>
                    (destination.group.source_type = labelGroups?.find(g => g.id === source.group?.id)?.source_type),
            })
        ) ?? [];
    labels.sort((a, b) =>
        a.group.source_type === LabelGroupSourceType.System && b.group.source_type === LabelGroupSourceType.Custom ? 1 : -1
    );

    const itemsInFirstRow = 5;
    const indexToSplit = labels?.length > itemsInFirstRow ? itemsInFirstRow : labels.length;
    const visibleLabels = labels.slice(0, indexToSplit);
    const hiddenLabels = labels.slice(indexToSplit);
    const playerLabelIds = labels.map(l => l.id);

    return {visibleLabels, hiddenLabels, playerLabelIds};
}
