import {defineMessages} from 'react-intl';
import {inject, injectable} from 'inversify';
import {Epic} from 'redux-observable';
import {of} from 'rxjs';
import {filter} from 'rxjs/operators';
import {isActionOf, PayloadAction} from 'typesafe-actions';

import {ServiceTypes} from '@inversify';
import {MutationUpdateUserArgs, UserProfile} from '@models/generated/graphql';
import {map, mergeMap} from '@otel';
import {BaseEpicsBuilder} from '@redux';
import {entityActions, EntityType, UserProfileNormalized} from '@redux/entity';
import {IUserProfileService} from '@services';
import {GqlMutationRequest, ServiceResponsePayload} from '@services/types';
import {setObjectFieldByPath} from '@utils';

import {showErrorAction, showMessageAction} from '../message-snack-bar/actions';

import {addressInfoActions, EditAddressInfoRequestPayload} from './actions';
import {configurationManager} from './config';

const localized = defineMessages({
    editAddressCitySuccess: {
        id: 'UserAddressInfoEpicsBuilder_editAddressCitySuccess',
        defaultMessage: 'City successfully changed to {city}',
    },
    editAddressCountrySuccess: {
        id: 'UserAddressInfoEpicsBuilder_editAddressCountrySuccess',
        defaultMessage: 'Country and State successfully changed',
    },
    editAddressPostCodeSuccess: {
        id: 'UserAddressInfoEpicsBuilder_editAddressPostCodeSuccess',
        defaultMessage: 'Post Code successfully changed to {post_code}',
    },
    editAddressStreetSuccess: {
        id: 'UserAddressInfoEpicsBuilder_editAddressStreetSuccess',
        defaultMessage: 'Street successfully changed to {street}',
    },
    editAddressCityFailure: {
        id: 'UserAddressInfoEpicsBuilder_editAddressCityFailure',
        defaultMessage: 'Failed to change City',
    },
    editAddressCountryFailure: {
        id: 'UserAddressInfoEpicsBuilder_editAddressCountryFailure',
        defaultMessage: 'Failed to change Country and State',
    },
    editAddressPostCodeFailure: {
        id: 'UserAddressInfoEpicsBuilder_editAddressPostCodeFailure',
        defaultMessage: 'Failed to change Post Code',
    },
    editAddressStreetFailure: {
        id: 'UserAddressInfoEpicsBuilder_editAddressStreetFailure',
        defaultMessage: 'Failed to change Street',
    },
});

@injectable()
export class UserAddressInfoEpicsBuilder extends BaseEpicsBuilder {
    private readonly _service: IUserProfileService;

    constructor(@inject(ServiceTypes.UserProfileService) service: IUserProfileService) {
        super();
        this._service = service;
    }

    protected buildEpicList(): Epic[] {
        return [
            this.buildEditAddressCityRequestEpic(),
            this.buildEditAddressCountryRequestEpic(),
            this.buildEditAddressPostCodeRequestEpic(),
            this.buildEditAddressStreetRequestEpic(),

            this.buildEditAddressCitySuccessEpic(),
            this.buildEditAddressCountrySuccessEpic(),
            this.buildEditAddressPostCodeSuccessEpic(),
            this.buildEditAddressStreetSuccessEpic(),

            this.buildEditAddressCityFailureEpic(),
            this.buildEditAddressCountryFailureEpic(),
            this.buildEditAddressPostCodeFailureEpic(),
            this.buildEditAddressStreetFailureEpic(),
        ];
    }

    private buildEditAddressCityRequestEpic(): Epic {
        return this.buildRequestEpic<EditAddressInfoRequestPayload, GqlMutationRequest<MutationUpdateUserArgs>, UserProfile>(
            addressInfoActions.editAddressCity,
            (payload, _state) => {
                return this._service.updateAddressInfo(payload.user);
            }
        );
    }

    private buildEditAddressCitySuccessEpic(): Epic {
        return action$ =>
            action$.pipe(
                filter(isActionOf(addressInfoActions.editAddressCity.success)),
                mergeMap((res: PayloadAction<string, ServiceResponsePayload<GqlMutationRequest<MutationUpdateUserArgs>, UserProfile>>) => {
                    const {
                        user: {
                            address: {city},
                        },
                        uid,
                    } = res.payload.requestPayload.variables;
                    const updatedItem: Partial<UserProfileNormalized> = {address: {city}};

                    return of(
                        showMessageAction({message: localized.editAddressCitySuccess, values: {city}}),
                        entityActions.updateItem({type: EntityType.UserProfile, id: uid, updatedItem})
                    );
                })
            );
    }

    private buildEditAddressCityFailureEpic(): Epic {
        return action$ =>
            action$.pipe(
                filter(isActionOf(addressInfoActions.editAddressCity.failure)),
                map(() => showErrorAction({message: localized.editAddressCityFailure}))
            );
    }

    private buildEditAddressCountryRequestEpic(): Epic {
        return this.buildRequestEpic<EditAddressInfoRequestPayload, GqlMutationRequest<MutationUpdateUserArgs>, UserProfile>(
            addressInfoActions.editAddressCountry,
            (payload, _state) => {
                configurationManager.getStateFields(payload.user.address?.country_info?.iso_alpha2_code).forEach(fieldName => {
                    setObjectFieldByPath(payload.user, fieldName, payload.user.address.state);
                });
                return this._service.updateAddressInfo(payload.user);
            }
        );
    }

    private buildEditAddressCountrySuccessEpic(): Epic {
        return action$ =>
            action$.pipe(
                filter(isActionOf(addressInfoActions.editAddressCountry.success)),
                mergeMap((res: PayloadAction<string, ServiceResponsePayload<GqlMutationRequest<MutationUpdateUserArgs>, UserProfile>>) => {
                    const {
                        user: {
                            address: {
                                country_info: {iso_alpha2_code, name},
                                state,
                            },
                        },
                        uid,
                    } = res.payload.requestPayload.variables;
                    const updatedItem: Partial<UserProfileNormalized> = {
                        address: {
                            country_info: {
                                iso_alpha2_code,
                                name,
                            },
                            state,
                        },
                    };

                    return of(
                        showMessageAction({message: localized.editAddressCountrySuccess}),
                        entityActions.updateItem({type: EntityType.UserProfile, id: uid, updatedItem})
                    );
                })
            );
    }

    private buildEditAddressCountryFailureEpic(): Epic {
        return action$ =>
            action$.pipe(
                filter(isActionOf(addressInfoActions.editAddressCountry.failure)),
                map(() => showErrorAction({message: localized.editAddressCountryFailure}))
            );
    }

    private buildEditAddressPostCodeRequestEpic(): Epic {
        return this.buildRequestEpic<EditAddressInfoRequestPayload, GqlMutationRequest<MutationUpdateUserArgs>, UserProfile>(
            addressInfoActions.editAddressPostCode,
            (payload, _state) => {
                return this._service.updateAddressInfo(payload.user);
            }
        );
    }

    private buildEditAddressPostCodeSuccessEpic(): Epic {
        return action$ =>
            action$.pipe(
                filter(isActionOf(addressInfoActions.editAddressPostCode.success)),
                mergeMap((res: PayloadAction<string, ServiceResponsePayload<GqlMutationRequest<MutationUpdateUserArgs>, UserProfile>>) => {
                    const {
                        user: {
                            address: {post_code},
                        },
                        uid,
                    } = res.payload.requestPayload.variables;
                    const updatedItem: Partial<UserProfileNormalized> = {
                        address: {post_code},
                    };

                    return of(
                        showMessageAction({message: localized.editAddressPostCodeSuccess, values: {post_code}}),
                        entityActions.updateItem({type: EntityType.UserProfile, id: uid, updatedItem})
                    );
                })
            );
    }

    private buildEditAddressPostCodeFailureEpic(): Epic {
        return action$ =>
            action$.pipe(
                filter(isActionOf(addressInfoActions.editAddressPostCode.failure)),
                map(() => showErrorAction({message: localized.editAddressPostCodeFailure}))
            );
    }

    private buildEditAddressStreetRequestEpic(): Epic {
        return this.buildRequestEpic<EditAddressInfoRequestPayload, GqlMutationRequest<MutationUpdateUserArgs>, UserProfile>(
            addressInfoActions.editAddressStreet,
            (payload, _tate) => {
                return this._service.updateAddressInfo(payload.user);
            }
        );
    }

    private buildEditAddressStreetSuccessEpic(): Epic {
        return action$ =>
            action$.pipe(
                filter(isActionOf(addressInfoActions.editAddressStreet.success)),
                mergeMap((res: PayloadAction<string, ServiceResponsePayload<GqlMutationRequest<MutationUpdateUserArgs>, UserProfile>>) => {
                    const {
                        user: {
                            address: {address},
                        },
                        uid,
                    } = res.payload.requestPayload.variables;
                    const updatedItem: Partial<UserProfileNormalized> = {address: {address}};

                    return of(
                        showMessageAction({message: localized.editAddressStreetSuccess, values: {street: address}}),
                        entityActions.updateItem({type: EntityType.UserProfile, id: uid, updatedItem})
                    );
                })
            );
    }

    private buildEditAddressStreetFailureEpic(): Epic {
        return action$ =>
            action$.pipe(
                filter(isActionOf(addressInfoActions.editAddressStreet.failure)),
                map(() => showErrorAction({message: localized.editAddressStreetFailure}))
            );
    }
}
