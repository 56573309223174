import React, {useEffect, useRef} from 'react';

import {BaseFiltersProps, KeyValueFilter, QueryFilterAdapterModel, QueryFilterAdapterProps} from '@components/filter/types';

type QueryFilterProps<TFilterKey extends string, TFilterName extends string> = BaseFiltersProps<
    string,
    TFilterName,
    KeyValueFilter<TFilterKey>[]
>;

export function withQueryFilter<TFilterKey extends string, TFilterName extends string, TTextFilterKey extends TFilterKey, TProps = {}>(
    WrappedComponent: React.FC<QueryFilterAdapterProps<TFilterKey, TFilterName, TTextFilterKey> & TProps>,
    textFilterKeysConfig?: Partial<Record<TFilterName, TTextFilterKey[]>>
) {
    return function QueryFilterHoc({
        model,
        onChange,
        availableFilters,
        viewMode,
        ...props
    }: QueryFilterProps<TFilterKey, TFilterName> & TProps) {
        const {model: filterModel, onChange: handleChange} = useQueryFilterHoc({model, onChange, availableFilters, textFilterKeysConfig});
        return (
            <WrappedComponent
                model={filterModel}
                onChange={handleChange}
                availableFilters={availableFilters}
                viewMode={viewMode}
                {...(props as TProps)}
            />
        );
    };
}

export type UseQueryFilterHocProps<TFilterKey extends string, TFilterName extends string, TTextFilterKey extends TFilterKey> = Pick<
    QueryFilterProps<TFilterKey, TFilterName>,
    'model' | 'onChange' | 'availableFilters'
> & {
    textFilterKeysConfig?: Partial<Record<TFilterName, TTextFilterKey[]>>;
};

type UseQueryFilterHocResult<TFilterKey extends string, TTextFilterKey extends TFilterKey> = Pick<
    QueryFilterAdapterProps<TFilterKey, string, TTextFilterKey>,
    'model' | 'onChange'
>;

export function useQueryFilterHoc<TFilterKey extends string, TFilterName extends string, TTextFilterKey extends TFilterKey>({
    model,
    onChange,
    availableFilters,
    textFilterKeysConfig,
}: UseQueryFilterHocProps<TFilterKey, TFilterName, TTextFilterKey>): UseQueryFilterHocResult<TFilterKey, TTextFilterKey> {
    const textFilterKey = Object.keys(textFilterKeysConfig ?? {}).find((i: TFilterName) =>
        availableFilters?.map(f => (typeof f === 'string' ? f : f.filterName)).includes(i)
    ) as TFilterName;
    const textFilterKeys = textFilterKey ? textFilterKeysConfig?.[textFilterKey] : [];

    const currentFilterModel = useRef<QueryFilterAdapterModel<TFilterKey, TTextFilterKey>>(getFilterModel(model));

    useEffect(() => {
        currentFilterModel.current = getFilterModel(model);
    }, [model]);

    function handleChange(newValue: QueryFilterAdapterModel<TFilterKey, TTextFilterKey>) {
        const keyValueFilter: KeyValueFilter<TFilterKey>[] = Object.entries(newValue)?.flatMap(([key, value]) => {
            let result: KeyValueFilter<TFilterKey>[];
            if (key === 'text') {
                result = textFilterKeys?.map(k => (value?.option === k ? {key: value?.option, value: value?.text} : {key: k, value: ''}));
            } else {
                result = [{key: key as TFilterKey, value: value}];
            }
            return result;
        });
        const defaultFilters = getDefaultFilters(keyValueFilter);
        onChange([...keyValueFilter, ...defaultFilters]);
    }

    function getFilterModel(filter: string) {
        const searchObject = new URLSearchParams(filter);
        const filterModel: QueryFilterAdapterModel<string, TTextFilterKey> = {};
        searchObject?.forEach((value, key) => {
            let parsedValue: unknown;
            try {
                parsedValue = JSON.parse(value);
            } catch (err) {
                parsedValue = value;
            }
            filterModel[key] = parsedValue;
        });

        // If array of text keys has been passed, add text filter field
        const option = textFilterKeys?.find(k => filterModel?.[k]);
        if (option) {
            filterModel.text = option && filterModel?.[option] ? {option, text: filterModel?.[option] as string} : undefined;
        }

        return filterModel;
    }

    function getDefaultFilters(newFilters: KeyValueFilter<TFilterKey>[]): KeyValueFilter<TFilterKey>[] {
        const updatedModelKeys = newFilters?.map(i => i.key);
        const currentModelKeys = Object.keys(currentFilterModel.current);
        const unusedKeys = currentModelKeys?.filter((k: TFilterKey) => k !== 'text' && !updatedModelKeys?.includes(k));
        return unusedKeys?.map((key: TFilterKey) => ({
            key,
            value: currentFilterModel.current[key],
        }));
    }

    return {model: currentFilterModel.current, onChange: handleChange};
}
