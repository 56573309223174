import {ReasonCode, TransactionType} from '@models/generated/graphql';

export type ActionType = 'Approve' | 'Decline' | 'OnHold';

const commonReasonCodes = [
    ReasonCode.Correction,
    ReasonCode.P2pTransfer,
    ReasonCode.Test,
    ReasonCode.ConfiscationBonusAbuse,
    ReasonCode.ConfiscationRpf,
    ReasonCode.ConfiscationSec,
    ReasonCode.CasinoManualBonus,
    ReasonCode.RevShareAffiliate,
    ReasonCode.RevShareReferral,
    ReasonCode.OtherCost,
    ReasonCode.OtherRevenue,
    ReasonCode.Other,
];

export const creditReasonCodes = [
    ReasonCode.ManualDeposit,
    ReasonCode.Bonus,
    ReasonCode.Compensation,
    ReasonCode.GoodwillGesture,
    ...commonReasonCodes,
];

export const debitReasonCodes = [ReasonCode.ManualWithdrawal, ...commonReasonCodes];

export class ManualTransactionAddViewModel {
    uid: string;
    transaction_type: TransactionType;
    amount: number;
    reason: ReasonCode;
    reference?: string;
    comment?: string;
    created_by_uid: string;
}

export const singleCreditTransactionMaxAmount = 100000;
