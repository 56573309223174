import {useEffect} from 'react';

import {useAutoMapper} from '@auto-mapper';
import {BoUser, BoUserFields, BoUserServerFilterKeys} from '@models/bo-user';
import {UserProfile} from '@models/generated/graphql';
import {UserProfileViewModelKeys} from '@models/user-profile';
import {EntityType, UserProfileServerTextFilterKeys} from '@redux/entity';
import {UseListViewEntityProps, UseListViewEntityResult, useViewInit} from '@redux/view';

import {Filter, Sorting} from 'src/common/types';
import {useUserProfiles} from '../block-user-profile-list';

import {BoUserViewModel, BoUserViewModelKeys} from './types';

export function useBoUsers({
    viewType,
    displayName,
    defaultFilters,
    validateFilter,
    cleanDelay,
    syncWithUrl,
    fields,
    ids,
}: UseListViewEntityProps<BoUserServerFilterKeys, BoUserViewModelKeys>): UseListViewEntityResult<BoUserViewModel, BoUserViewModelKeys> {
    const mapper = useAutoMapper();

    const {
        items: users,
        totalCount,
        searchFilter,
        viewEntity: {filter: filterString},
        handleFilterChange,
        handlePageChange,
        handlePageSizeChange,
        handleSortChange,
    } = useViewInit<BoUser, BoUserServerFilterKeys, BoUserFields>({
        viewType,
        entity: {entity: EntityType.BoUser, fields: mapBoUserFields(fields)},
        displayName,
        defaultFilters: getDefaultFilter(),
        validateFilter,
        cleanDelay,
        syncWithUrl,
        blockFetchWithInvalidFilter: true,
    });

    const userProfileFields = mapUserProfileFields(fields);
    const {items: players, handleFilterChange: handlePlayerFilterChange} = useUserProfiles({
        viewType,
        fields: userProfileFields,
        validateFilter: () => userProfileFields.length > 0,
        displayName,
        cleanDelay,
    });

    const userIdFilter = users?.map(u => `"${u.id}"`)?.join(' ');
    useEffect(() => {
        if (users?.length > 0) {
            const key: UserProfileServerTextFilterKeys = 'boUserId';
            handlePlayerFilterChange([{key: key, value: userIdFilter}]);
        }
    }, [userIdFilter]);

    const items = users.map(i => {
        let user = mapper.map(i, BoUser, BoUserViewModel);
        const player = players?.find(p => p?.agent_info?.bo_agent_id === i?.id);
        if (player) {
            user = {...user, ...mapper.map(player, UserProfile, BoUserViewModel)};
        }

        return user;
    });

    return {
        items,
        totalCount,
        filterString,
        searchFilter,
        handleFilterChange,
        handlePageChange,
        handlePageSizeChange,
        handleSortChange: handleVmSortChange,
    };

    function mapUserProfileFields(fields: BoUserViewModelKeys[]): UserProfileViewModelKeys[] {
        return mapper.map<BoUserViewModelKeys[], UserProfileViewModelKeys[]>(
            fields,
            nameof<BoUserViewModelKeys>(),
            nameof<UserProfileViewModelKeys>()
        );
    }

    function mapBoUserFields(fields: BoUserViewModelKeys[]): (keyof BoUser)[] {
        return mapper.map<BoUserViewModelKeys[], BoUserFields[]>(fields, nameof<BoUserViewModelKeys>(), nameof<BoUserFields>());
    }

    function mapBoUserField(field: BoUserViewModelKeys): BoUserFields {
        return mapBoUserFields([field])[0];
    }

    function mapSorting(sorting: Sorting<BoUserViewModelKeys>[]): Sorting<BoUserFields>[] {
        return sorting?.map(s => ({
            ...s,
            field: mapBoUserField(s.field as BoUserViewModelKeys),
        }));
    }

    function handleVmSortChange(newSorting: Sorting<BoUserViewModelKeys>[]) {
        handleSortChange(mapSorting(newSorting));
    }

    function getDefaultFilter(): Filter<any, BoUserServerFilterKeys>[] {
        const idKey: BoUserServerFilterKeys = 'id';
        const resultIds = [...new Set(ids?.filter(i => i))];
        return [...(defaultFilters ?? []), ...(resultIds?.length ? [{key: idKey, value: resultIds}] : [])];
    }
}
