import {defineMessages} from 'react-intl';

export const localizedRoleManagement = defineMessages({
    title: {
        id: 'RM_title',
        defaultMessage: 'Roles',
    },
    name: {
        id: 'RM_name',
        defaultMessage: 'Role',
    },
    users: {
        id: 'RM_users',
        defaultMessage: 'Users',
    },
    email: {
        id: 'RM_email',
        defaultMessage: 'Email',
    },
    viewAction: {
        id: 'RM_viewAction',
        defaultMessage: 'View',
    },
    editAction: {
        id: 'RM_editAction',
        defaultMessage: 'Edit',
    },
    item: {
        id: 'RM_item',
        defaultMessage: 'role',
    },
    pagePermissions: {
        id: 'RM_pagePermissions',
        defaultMessage: 'Page Permissions:',
    },
    entityPermissions: {
        id: 'RM_entityPermissions',
        defaultMessage: 'Entity Permissions:',
    },
    businessRulePermissions: {
        id: 'RM_businessRulePermissions',
        defaultMessage: 'Business Rule Permissions:',
    },
    playerPermissions: {
        id: 'RM_playerPermissions',
        defaultMessage: 'Player Permissions:',
    },
});
