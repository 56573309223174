import React from 'react';
import {useDispatch} from 'react-redux';
import {Box, FormControl} from '@mui/material';
import {CSSObject} from 'tss-react';
import {makeStyles} from 'tss-react/mui';

import {CustomTheme} from '@style';
import {toSearchFilter} from '@utils';

import {Filter as FilterKeyValue} from 'src/common/types';
import {useJurisdictionFeature} from '../../../app/config/hooks';
import {gridModuleActions} from '../../../module-shared/actions';
import {Filter, FilterProps} from '../types';

//ModuleFilterHoc will be moved to FilterHoc after all of the filters moved to new form structure
export type FilterInnerProps<T, TOptions = unknown> = {
    onSubmit: (value: any) => void;
    filter: Filter;
    value?: FilterKeyValue<T>[];
    options?: TOptions;
    popoverClassName?: string;
};

const useClasses = makeStyles()((theme: CustomTheme) => ({
    filter: {
        display: 'block',
        margin: theme.spacing(0, 0, 'auto', 0),
        flexShrink: 0,
        [theme.breakpoints.down('sm')]: {
            width: '100% !important',
        },
    },

    filterInputContainer: {
        flexGrow: 1,
        height: '100%',
        '& .MuiTextField-root': {
            background: 'transparent',
            '& .MuiInputBase-root': {
                background: theme.palette.background.paper,
                border: '1px solid #E1E7EB',
                boxSizing: 'border-box',
                boxShadow: `0px 1px 1px ${theme.custom.palette.content.boxShadow}`,
                borderRadius: '8px',
                ...(theme.typography.body2 as CSSObject),
                paddingLeft: theme.spacing(2),
                paddingTop: 0,
                paddingBottom: 0,
                height: theme.custom.denseButtonHeight,
            },
            '& .MuiFormHelperText-root': {
                background: 'transparent',
                border: 0,
                boxShadow: 'none',
            },
        },
    },
}));

function withModuleFilter<T, TOptions>(
    WrappedComponent: React.ComponentType<FilterInnerProps<T, TOptions>>,
    width: number | string = 'auto',
    disableUnderline = false
) {
    return ({filter, filterString, domain, onFilterChange, options, ...props}: FilterProps<TOptions>) => {
        const actions = gridModuleActions(domain);
        const dispatch = useDispatch();
        const {classes} = useClasses();

        const searchFilter = toSearchFilter(filterString);

        const getFilterValueByKey = (key: string) => {
            return searchFilter.filter.find(i => i.key === key)?.value ?? null;
        };

        const value =
            typeof filter.key === 'string'
                ? [{key: filter.key, value: getFilterValueByKey(filter.key)}]
                : (filter.key as string[])?.map(k => ({key: k, value: getFilterValueByKey(k)}));

        const isAvailable = useJurisdictionFeature({
            moduleName: filter.moduleName,
            submoduleName: filter.submoduleName,
            featureName: filter.featureName,
            permissions: filter.permissions,
        });

        const onSubmit = (submittingValue: any) => {
            if (value !== submittingValue && (value || submittingValue !== '')) {
                const payload: FilterKeyValue[] = submittingValue as FilterKeyValue[];
                if (domain) {
                    dispatch(actions.itemsFilter(payload));
                }
                if (onFilterChange) {
                    onFilterChange(Array.isArray(payload) ? payload : [payload]);
                }
            }
        };

        const componentProps = {value, filter, onSubmit, disableUnderline, options, ...props};

        return isAvailable ? (
            <FormControl className={classes.filter} component={Box} style={{width: width}}>
                <Box className={classes.filterInputContainer}>
                    <WrappedComponent {...componentProps} />
                </Box>
            </FormControl>
        ) : (
            <></>
        );
    };
}

export default withModuleFilter;
