import React from 'react';
import {GridCellParams} from '@mui/x-data-grid';

import GridViewEditAction from '../../../features/module-shared/components/GridViewEditAction';
import {domain, RoleGridItem} from '../types';

export const renderViewEditAction = (params: GridCellParams, mode: 'view' | 'edit') => {
    const role: RoleGridItem = params.row as RoleGridItem;
    return <GridViewEditAction domain={domain} id={role.id} isEditable={true} mode={mode} />;
};
