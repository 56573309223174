import {defineMessages, MessageDescriptor} from 'react-intl';

import {ModuleName} from '@models/modules';

export const localizedModuleName = defineMessages<ModuleName, MessageDescriptor>({
    [ModuleName.AccessManagement]: {
        id: 'moduleName_AccessManagement',
        defaultMessage: 'Login/Access Management',
    },
    [ModuleName.PlayerManagement]: {
        id: 'moduleName_PlayerManagement',
        defaultMessage: 'Player Management',
    },
    [ModuleName.LegalContentManagement]: {
        id: 'moduleName_LegalContentManagement',
        defaultMessage: 'Legal Content Management',
    },
    [ModuleName.ActionCenter]: {
        id: 'moduleName_PlayerActions',
        defaultMessage: 'Player Actions',
    },
    [ModuleName.Comments]: {
        id: 'moduleName_Comments',
        defaultMessage: 'Comments',
    },
    [ModuleName.WithdrawalProcessing]: {
        id: 'moduleName_WithdrawalProcessing',
        defaultMessage: 'Withdrawal Processing',
    },
    [ModuleName.KycProcessing]: {
        id: 'moduleName_KycProcessing',
        defaultMessage: 'KYC Processing',
    },
    [ModuleName.SecurityWorkspace]: {
        id: 'moduleName_SecurityWorkspace',
        defaultMessage: 'Security Workspace',
    },
    [ModuleName.CustomerSupport]: {
        id: 'moduleName_CustomerSupport',
        defaultMessage: 'Customer Support',
    },
    [ModuleName.MarketingFunction]: {
        id: 'moduleName_MarketingFunction',
        defaultMessage: 'Marketing Function',
    },
    [ModuleName.PageAgentReport]: {
        id: 'moduleName_AgentReport',
        defaultMessage: 'Agent Report',
    },
    [ModuleName.PageAgentReportVn]: {
        id: 'moduleName_AgentReportVn',
        defaultMessage: 'VN Agent Report',
    },
    [ModuleName.PageAgentReportKr]: {
        id: 'moduleName_AgentReportKr',
        defaultMessage: 'KR Agent Report',
    },
    [ModuleName.TeamWorkspace]: {
        id: 'moduleName_TeamWorkspace',
        defaultMessage: 'Team Workspace',
    },
    [ModuleName.GameAdmin]: {
        id: 'moduleName_GameAdmin',
        defaultMessage: 'Game Admin',
    },
    [ModuleName.EntityGameTable]: {
        id: 'moduleName_EntityGameTable',
        defaultMessage: 'Game Table',
    },
    [ModuleName.EntityGameTemplate]: {
        id: 'moduleName_EntityGameTemplate',
        defaultMessage: 'Game Template',
    },
    [ModuleName.EntityTransactions]: {
        id: 'moduleName_EntityTransactions',
        defaultMessage: 'Transactions',
    },
    [ModuleName.EntityBulk]: {
        id: 'moduleName_EntityBulk',
        defaultMessage: 'Bulk Operations',
    },
    [ModuleName.EntityBonus]: {
        id: 'moduleName_EntityBonus',
        defaultMessage: 'Bonuses',
    },
    [ModuleName.EntityAll]: {
        id: 'moduleName_EntityAll',
        defaultMessage: 'All',
    },
    [ModuleName.Agent]: {
        id: 'moduleName_Agent',
        defaultMessage: 'Agent',
    },
    [ModuleName.PageRules]: {
        id: 'moduleName_PageRules',
        defaultMessage: 'Rules',
    },
    [ModuleName.EntityRule]: {
        id: 'moduleName_EntityRule',
        defaultMessage: 'Rule',
    },
    [ModuleName.Club]: {
        id: 'moduleName_Club',
        defaultMessage: 'Club',
    },
    [ModuleName.EntityReport]: {
        id: 'moduleName_EntityReport',
        defaultMessage: 'Report',
    },
    [ModuleName.AgentVn]: {
        id: 'moduleName_AgentVn',
        defaultMessage: 'Agent VN',
    },
    [ModuleName.AgentKr]: {
        id: 'moduleName_AgentKr',
        defaultMessage: 'Agent KR',
    },
    [ModuleName.PageAgentManagement]: {
        id: 'moduleName_PageAgentManagement',
        defaultMessage: 'Agent Management',
    },
    [ModuleName.PageNdrp]: {
        id: 'moduleName_PageNdrp',
        defaultMessage: 'NDRP',
    },
    [ModuleName.WptGlobal]: {
        id: 'moduleName_WptGlobal',
        defaultMessage: 'WPT Global',
    },
    [ModuleName.WptAsia]: {
        id: 'moduleName_WptAsia',
        defaultMessage: 'WPT Asia',
    },
    [ModuleName.WptPH]: {
        id: 'moduleName_WptPH',
        defaultMessage: 'WPT PH',
    },
    [ModuleName.PageSportsbookOperation]: {
        id: 'moduleName_SportsbookHistoryPage',
        defaultMessage: 'Sportsbook History',
    },
    [ModuleName.PageEmbeddedReport]: {
        id: 'moduleName_PageEmbeddedReport',
        defaultMessage: 'Embedded Agent Report',
    },
    [ModuleName.FullPlayer]: {
        id: 'moduleName_FullPlayer',
        defaultMessage: 'Full Player',
    },
    [ModuleName.CreditLimit1]: {
        id: 'moduleName_CreditLimit1',
        defaultMessage: 'Manual Credit L1',
    },
    [ModuleName.CreditLimit2]: {
        id: 'moduleName_CreditLimit2',
        defaultMessage: 'Manual Credit L2',
    },
    [ModuleName.CreditLimit3]: {
        id: 'moduleName_CreditLimit3',
        defaultMessage: 'Manual Credit L3',
    },
    [ModuleName.Debit]: {
        id: 'moduleName_Debit',
        defaultMessage: 'Manual Debit',
    },
    [ModuleName.Phone]: {
        id: 'moduleName_Phone',
        defaultMessage: 'Phone',
    },
    [ModuleName.Email]: {
        id: 'moduleName_Email',
        defaultMessage: 'Email',
    },
});
