import {defineMessages, MessageDescriptor} from 'react-intl';

import {TransactionStatusWithReason} from '@models/transaction/types';

const defineMessagesInner: Partial<Record<TransactionStatusWithReason, MessageDescriptor>> = {
    'OnHoldPayment.UnderInvestigation': {
        id: 'TransactionStatusWithReason_OnHoldPayment.UnderInvestigation',
        defaultMessage: 'On Hold (Payment) / Under Investigation',
    },
    'OnHoldPayment.Threshold24hrs': {
        id: 'TransactionStatusWithReason_OnHoldPayment.Threshold24hrs',
        defaultMessage: 'On Hold (Payment) / 24hrs Threshold',
    },
    'OnHoldPayment.KYCPending': {
        id: 'TransactionStatusWithReason_OnHoldPayment.KycPending',
        defaultMessage: 'On Hold (Payment) / KYC Pending',
    },
    'OnHoldPayment.PSPNoFunds': {
        id: 'TransactionStatusWithReason_OnHoldPayment.PspNoFunds',
        defaultMessage: 'On Hold (Payment) / PSP No Funds',
    },
    'OnHoldPayment.GameplayReview': {
        id: 'TransactionStatusWithReason_OnHoldPayment.GameplayReview',
        defaultMessage: 'On Hold (Payment) / Gameplay Review',
    },
    'OnHoldPayment.MgmtReview': {
        id: 'TransactionStatusWithReason_OnHoldPayment.MgmtReview',
        defaultMessage: 'On Hold (Payment) / Mgmt Review',
    },
    'OnHoldPayment.Other': {
        id: 'TransactionStatusWithReason_OnHoldPayment.Other',
        defaultMessage: 'On Hold (Payment) / Others',
    },

    'OnHoldRisk.UnderInvestigation': {
        id: 'TransactionStatusWithReason_OnHoldRisk.UnderInvestigation',
        defaultMessage: 'On Hold (Risk) / Under Investigation',
    },
    'OnHoldRisk.Threshold24hrs': {
        id: 'TransactionStatusWithReason_OnHoldRisk.Threshold24hrs',
        defaultMessage: 'On Hold (Risk) / 24hrs Threshold',
    },
    'OnHoldRisk.KYCPending': {
        id: 'TransactionStatusWithReason_OnHoldRisk.KycPending',
        defaultMessage: 'On Hold (Risk) / KYC Pending',
    },
    'OnHoldRisk.PSPNoFunds': {
        id: 'TransactionStatusWithReason_OnHoldRisk.PspNoFunds',
        defaultMessage: 'On Hold (Risk) / PSP No Funds',
    },
    'OnHoldRisk.GameplayReview': {
        id: 'TransactionStatusWithReason_OnHoldRisk.GameplayReview',
        defaultMessage: 'On Hold (Risk) / Gameplay Review',
    },
    'OnHoldRisk.MgmtReview': {
        id: 'TransactionStatusWithReason_OnHoldRisk.MgmtReview',
        defaultMessage: 'On Hold (Risk) / Mgmt Review',
    },
    'OnHoldRisk.Other': {
        id: 'TransactionStatusWithReason_OnHoldRisk.Other',
        defaultMessage: 'On Hold (Risk) / Others',
    },
};

export const transactionStatusWithReasonLocalized = defineMessages(defineMessagesInner);
