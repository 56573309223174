import React, {useEffect, useRef} from 'react';
import {Box, Link, ListItemText, MenuItem, Radio, Select, SelectChangeEvent, Typography} from '@mui/material';
import {makeStyles} from 'tss-react/mui';
import {v4 as uuid} from 'uuid';

import LocalizedText from '@components/i18n/LocalizedText';
import {CustomIcon, Icon, IconColor} from '@components/icons';
import {CustomTheme} from '@style';

import {SelectOption} from '../../../features/module-shared/types';
import {MemoizedChip} from '../chip/ChipRenderer';

import {useDefaultSelectClasses, useFilterSelectClasses, useGeneralSelectClasses} from './selectStyle';
import {SelectProps, SelectStyle} from './types';

//TODO: [BO-2668] Move to src/common/components/dropdown (rename or merge?)
//TODO: [BO-2669] Move dropdown components to input folder (?)
const useSingleSelectClasses = makeStyles()((theme: CustomTheme) => ({
    selectClearActionButton: {
        paddingLeft: theme.spacing(0.5),
        color: theme.palette.text.primary,
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'none',
        },
    },
    selectClearActionButtonChip: {
        paddingLeft: theme.spacing(1),
    },
}));

export const SingleSelect = ({
    options,
    value,
    hasRadio,
    selectStyle,
    emptyValue,
    label,
    onSubmit,
    disabled,
    chipType,
    isSelectedValueChip,
    showResetButton,
    onOpen,
    onClose,
    horizontalPosition,
    startAdornment,
}: SelectProps<string>) => {
    const {classes: singleSelectClasses} = useSingleSelectClasses();
    const [{classes}] = React.useState(selectStyle === SelectStyle.Default ? useDefaultSelectClasses() : useFilterSelectClasses());
    const {classes: generalSelectClasses} = useGeneralSelectClasses();
    const singleselectId = useRef(uuid());
    const defaultValue = '';
    const [selectedValue, setSelectedValue] = React.useState(defaultValue);
    const [selectOpen, setSelectOpen] = React.useState(false);
    const arrow = selectOpen ? CustomIcon.ArrowUp : CustomIcon.ArrowDown;
    const openStyle = selectOpen ? generalSelectClasses.selectButtonOpen : '';
    const selectArrowIcon = (
        <Box component="span" className={`selectIcon ${arrow} ${generalSelectClasses.selectButtonEndIcon} ${openStyle}`} />
    );

    useEffect(() => {
        value ? setSelectedValue(value) : setSelectedValue(defaultValue);
    }, [value]);

    const handleChange = (event: SelectChangeEvent<string>) => {
        const targetValue = event.target.value;

        if (targetValue) {
            setSelectedValue(targetValue);
            onSubmit([targetValue]);
            return;
        }
        setSelectedValue(null);
        onSubmit(null);
    };

    const handleClose = () => {
        setSelectOpen(false);
        onClose && onClose();
    };

    const handleOpen = () => {
        setSelectOpen(true);
        onOpen && onOpen();
    };

    const convertSelectedValueToString = (value: string | string[]) => {
        return Array.isArray(value) && value.length > 0 ? value[0] : value;
    };

    const getRenderValue = (value: string | string[]) => {
        const selectedValue = options?.find(o => o.value === convertSelectedValueToString(value))?.label;
        const selectedAndChip = isSelectedValueChip && selectedValue;
        const notSelectedAndChip = isSelectedValueChip && !selectedValue;
        let wrapperClass = generalSelectClasses.selectedValueEllipsis;

        if (selectedAndChip) {
            wrapperClass = generalSelectClasses.selectedValueWithChip;
        }
        if (notSelectedAndChip) {
            wrapperClass = generalSelectClasses.notSelectedValueWithChip;
        }

        return (
            <Box className={wrapperClass}>
                {label ? (
                    <Typography variant="body1" className={classes.selectLabel} component="span">
                        <LocalizedText label={label} />
                    </Typography>
                ) : (
                    <></>
                )}
                {selectedAndChip ? (
                    <MemoizedChip chipName={chipType} value={value as string} label={label} />
                ) : (
                    <Typography variant="body1" component="span">
                        <LocalizedText label={selectedValue ? selectedValue : emptyValue} />
                    </Typography>
                )}
            </Box>
        );
    };

    return (
        <Select
            variant={'standard'}
            disabled={disabled}
            data-testid="singleSelect"
            inputProps={{'data-testid': 'singleSelectInput'}}
            displayEmpty
            value={selectedValue}
            onChange={handleChange}
            onClose={handleClose}
            onOpen={handleOpen}
            renderValue={getRenderValue}
            MenuProps={{
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: horizontalPosition,
                },
                transformOrigin: {
                    vertical: 'top',
                    horizontal: horizontalPosition,
                },
            }}
            IconComponent={() => selectArrowIcon}
            className={classes.selectInput}
            disableUnderline
            startAdornment={startAdornment}
        >
            <MenuItem value="" className={generalSelectClasses.selectItemHidden}></MenuItem>
            {showResetButton ? (
                <MenuItem value={null} className={generalSelectClasses.selectWithConfirmMenuItem}>
                    <Link
                        className={`${singleSelectClasses.selectClearActionButton} ${
                            chipType ? singleSelectClasses.selectClearActionButtonChip : ''
                        }`}
                    >
                        <LocalizedText label={emptyValue} />
                    </Link>
                </MenuItem>
            ) : null}
            {(options as SelectOption[])?.map((option, index) => (
                <MenuItem
                    className={generalSelectClasses.selectWithConfirmMenuItem}
                    value={option.value as string}
                    key={`singleselect-${singleselectId}-${index}`}
                >
                    {hasRadio && <Radio color="primary" checked={option.value === convertSelectedValueToString(selectedValue)} />}
                    <ListItemText
                        className={generalSelectClasses.listItemText}
                        disableTypography
                        primary={
                            chipType ? (
                                <MemoizedChip chipName={chipType} value={option.value as string} cursorPointer label={option.label} />
                            ) : (
                                <Typography variant="button" className={generalSelectClasses.listItemTextTypography}>
                                    <LocalizedText label={option.label} />
                                </Typography>
                            )
                        }
                    />
                    <Icon icon={CustomIcon.Confirm} color={IconColor.Primary} className={generalSelectClasses.selectWithConfirmIcon} />
                </MenuItem>
            ))}
        </Select>
    );
};
