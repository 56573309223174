import React, {useContext} from 'react';
import {defineMessages} from 'react-intl';
import {MenuItem} from '@mui/material';
import {makeStyles} from 'tss-react/mui';

import {usePolicyAccessCheck} from '@access-control';
import {OutlinedButtonDropdown} from '@components/dropdown/Dropdown';
import LocalizedText from '@components/i18n/LocalizedText';
import {Icon} from '@components/icons/Icon';
import {CustomIcon} from '@components/icons/types';
import {BulkModalContext} from '@components/modal';
import {TransactionType} from '@models/generated/graphql';

import {creditCreateResource, debitCreateResource} from 'src/features/block-transaction-actions/permissions';
import {useBulkAction} from '../../block-bulk-actions';

import {BulkTransactionManualExecutionStepModal} from './BulkTransactionExecutionStepModal';
import {BulkTransactionManualUploadStepModal, localized as localizedTransactionType} from './BulkTransactionManualUploadStepModal';
import {BulkTransactionManualValidationStepModal} from './BulkTransactionManualValidationStepModal';
import {BulkTransactionManualSummaryStepModal} from './BulkTransactionSummaryStepModal';

const localized = defineMessages({
    bulkManualTransactionButtonLabel: {
        id: 'batchTransactionButtonLabel',
        defaultMessage: 'Batch Transaction',
    },
});

const useClasses = makeStyles()({
    bulkManualTransactionButtonIcon: {
        transform: 'rotate(180deg)',
    },
});

export function BulkTransactionManualUploadStepButton() {
    const {classes} = useClasses();
    const {chooseStep, closeModal} = useContext(BulkModalContext);
    const {resetBulkState} = useBulkAction();
    const isCreditAvailable = usePolicyAccessCheck(creditCreateResource);
    const isDebitAvailable = usePolicyAccessCheck(debitCreateResource);
    const openUploadFileStep = (type: TransactionType) => chooseStep(uploadFileStep(type));
    const openValidation = (type: TransactionType) => chooseStep(validationStep(type));
    const openChangesSummary = () => chooseStep(changesSummaryStep);
    const openExecutionSummary = () => chooseStep(executionSummaryStep);

    const uploadFileStep = (type: TransactionType) => (
        <BulkTransactionManualUploadStepModal onNext={() => openValidation(type)} onPrev={closeModal} type={type} />
    );
    const validationStep = (type: TransactionType) => (
        <BulkTransactionManualValidationStepModal onNext={openChangesSummary} onPrev={closeModal} type={type} />
    );
    const changesSummaryStep = <BulkTransactionManualSummaryStepModal onNext={openExecutionSummary} onPrev={closeModal} withFlexibleGrid />;
    const executionSummaryStep = <BulkTransactionManualExecutionStepModal onNext={closeModal} hasRetry={true} withFlexibleGrid />;

    const handleClick = (type: TransactionType) => {
        resetBulkState();
        openUploadFileStep(type);
    };

    return (
        <OutlinedButtonDropdown
            label={localized.bulkManualTransactionButtonLabel}
            startIcon={<Icon icon={CustomIcon.ArrowRightSquareOutline} className={classes.bulkManualTransactionButtonIcon} />}
            anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
            transformOrigin={{vertical: 'top', horizontal: 'left'}}
        >
            {isDebitAvailable ? (
                <MenuItem onClick={() => handleClick(TransactionType.Debit)}>
                    <LocalizedText label={localizedTransactionType.debit} />
                </MenuItem>
            ) : null}
            {isCreditAvailable ? (
                <MenuItem onClick={() => handleClick(TransactionType.Credit)}>
                    <LocalizedText label={localizedTransactionType.credit} />
                </MenuItem>
            ) : null}
        </OutlinedButtonDropdown>
    );
}
