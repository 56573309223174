import React, {useRef} from 'react';
import {defineMessages} from 'react-intl';
import {Divider, ListSubheader, MenuItem, Select} from '@mui/material';
import {v4 as uuid} from 'uuid';

import {StyledInput} from '@components/input';
import {
    MultiSelectFooter,
    MultiSelectItem,
    MultiSelectWithTextCounterValue,
    SelectArrowIcon,
    VirtualizedMultiSelectOptionList,
} from '@components/select';

import {useMultiSelect} from './hooks';
import {useFilterSelectClasses, useGeneralSelectClasses} from './selectStyle';
import {SelectProps} from './types';
import {doesInclude} from './utils';

const localized = defineMessages({
    allSearchOptionLabel: {
        id: 'MultiSelect_allSearchOptionLabel',
        defaultMessage: 'Select All',
    },
});

export const selectAllOptionValue = 'all';

//TODO: [BO-2668] Move to src/common/components/dropdown (rename or merge?)
//TODO: [BO-2669] Move dropdown components to input folder (?)
export const MultiSelect = <T extends unknown[]>({
    options,
    value,
    emptyValue,
    onSubmit,
    enumFormatter,
    label,
    chipType,
    showResetButton,
    horizontalPosition,
    hasSearch,
    iconLabel,
    mode = 'client',
    onFilterChange,
    showPagination,
    page,
    pageSize,
    total,
    onPageChange,
    searchPlaceholder,
    hasSelectAll,
    renderValue,
    onSelectAll,
    defaultSelectAll,
    disabled,
    showApplyButton,
    onApply,
    virtualization,
    popoverClassName,
}: SelectProps<T>) => {
    const maxVisibleOptionsCount = 6;
    const {classes, cx} = useFilterSelectClasses();
    const {classes: generalSelectClasses} = useGeneralSelectClasses();
    const {current: multiselectId} = useRef(uuid());
    const {
        inputValue,
        selectAll,
        selectOpen,
        selectedValue,
        expandValue,
        visibleOptions,
        handleApply,
        handleChange,
        handleClearSelection,
        handleClose,
        handleFilterChange,
        handleItemClick,
        handleOpen,
        handleSearchChange,
        handleSelectAll,
        handleExpand,
        handleCollapse,
    } = useMultiSelect({
        defaultSelectAll,
        mode,
        options,
        value,
        onApply,
        onFilterChange,
        onPageChange,
        onSelectAll,
        onSubmit,
    });

    function getInputValue(selected: T) {
        return renderValue ? (
            renderValue(selected)
        ) : (
            <MultiSelectWithTextCounterValue
                selected={selected as string[]}
                enumFormatter={enumFormatter}
                label={label}
                options={options}
                emptyValue={emptyValue}
            />
        );
    }

    return (
        <Select
            disabled={disabled}
            displayEmpty
            value={selectedValue}
            multiple
            onChange={handleChange}
            onClose={handleClose}
            onOpen={handleOpen}
            open={selectOpen}
            renderValue={iconLabel ? () => iconLabel : getInputValue}
            MenuProps={{
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: horizontalPosition,
                },
                transformOrigin: {
                    vertical: 'top',
                    horizontal: horizontalPosition,
                },
                PopoverClasses: {
                    paper: cx(classes.selectPopover, popoverClassName),
                },
                autoFocus: false,
            }}
            IconComponent={() => (iconLabel ? null : <SelectArrowIcon selectOpen={selectOpen} />)}
            className={cx(classes.selectInput, {[classes.selectInputIconLabel]: !!iconLabel})}
            disableUnderline
            data-testid="multiselect"
            inputProps={{'data-testid': 'multiSelectInput'}}
        >
            {hasSearch ? (
                <ListSubheader disableGutters>
                    <StyledInput
                        value={inputValue}
                        className={classes.selectSearch}
                        onKeyDown={e => e.stopPropagation()}
                        onChange={handleSearchChange}
                        placeholder={searchPlaceholder}
                    />
                </ListSubheader>
            ) : null}
            <MenuItem value="" className={generalSelectClasses.selectItemHidden}></MenuItem>
            {hasSelectAll && !inputValue ? (
                <MultiSelectItem
                    key={`multiselect-${multiselectId}-${selectAllOptionValue}`}
                    value={selectAllOptionValue}
                    label={localized.allSearchOptionLabel}
                    isSelected={selectAll}
                    onClick={handleSelectAll}
                    chipType={chipType}
                />
            ) : null}
            <Divider className={generalSelectClasses.divider} />
            {virtualization ? (
                <VirtualizedMultiSelectOptionList
                    items={visibleOptions}
                    multiselectId={multiselectId}
                    selectedValue={selectedValue}
                    chipType={chipType}
                    onItemClick={handleItemClick}
                    visibleOptionCount={visibleOptions?.length > maxVisibleOptionsCount ? maxVisibleOptionsCount : visibleOptions?.length}
                    expandValue={expandValue}
                    onExpand={handleExpand}
                    onCollapse={handleCollapse}
                />
            ) : (
                visibleOptions?.map(({value, label, subOptions, autoSelectOnExpand, chipValue}) => {
                    const hasSubOptions = subOptions?.length > 0;
                    const isExpanded = doesInclude(expandValue, value.toString());
                    return [
                        <MultiSelectItem
                            key={`multiselect-${multiselectId}-${value}-${uuid()}`}
                            value={value as string}
                            chipValue={(chipValue ?? value) as string}
                            label={label}
                            isSelected={
                                hasSubOptions
                                    ? subOptions?.every(o => doesInclude(selectedValue, o?.value))
                                    : doesInclude(selectedValue, value)
                            }
                            chipType={!autoSelectOnExpand ? chipType : undefined}
                            hasSuboptions={hasSubOptions}
                            isExpanded={isExpanded}
                            onExpand={() => handleExpand(value as T)}
                            onCollapse={() => handleCollapse(value as T)}
                        />,
                        ...(hasSubOptions && isExpanded
                            ? subOptions.map((i, index) => (
                                  <MultiSelectItem
                                      key={`multiselect-${multiselectId}-${i.value}-${uuid()}`}
                                      value={i.value as string}
                                      label={i.label}
                                      isSelected={doesInclude(selectedValue, i.value)}
                                      suboption
                                      divider={index === subOptions.length - 1}
                                      checkbox={!autoSelectOnExpand}
                                  />
                              ))
                            : []),
                    ];
                })
            )}

            <MultiSelectFooter
                showResetButton={showResetButton}
                onClear={handleClearSelection}
                showPagination={showPagination}
                page={page}
                pageSize={pageSize}
                total={total}
                onPageChange={handleFilterChange}
                showApplyButton={showApplyButton}
                onApply={handleApply}
            />
        </Select>
    );
};
